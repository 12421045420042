import { platforms } from "components/app/platforms";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getPublishablePostMedia } from "redux/slices/post_media_slice";
import { getPublishablePostTextBody } from "redux/slices/post_text_body_slice";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import { chunkTweetIntoThreads } from "utils/gen";
import { checkPlatformVideos, platformSpecs } from "utils/platform_utils";
import { PlatformIdentifier } from "../../../../constants";
import { rescaleImage, scanMediaArrangementsForPlatformSpecs } from "../../../../utils/media";
import {
  getAutoFixedPlatformMediasBank,
  setAutoFixedPlatformMediasBank,
  setSelectedPlatformForPreview
} from "../../../../redux/slices/platformSlice";
import {
  canPublishIGVideoAsReel,
  canPublishIGVideoAsStories,
  getIsFacebookPostCarousel,
  getManuallyEditedPlatforms,
  getNotifyPreviewsOfLiveMediaChanges,
  getRemovedMedia,
  getSelectedPlatforms,
  getShouldStartSyncingMediasOnPlatformSelection,
  setNotifyPreviewsOfLiveMediaChanges,
  setPostReadyForPreviews,
  setRemovedMedia,
  getIsFacebookPostReel,
  getGlobalErrorMessage,
  setGlobalErrorMessage
} from "../../../../redux/slices/postslice";
import { getPlatformVideoUploadLimit } from "redux/slices/workspaceslice";
import FigureUtils from 'utils/figures'
import { dispatchSideNotification } from "utils/notification";

const MediaSyncer = () => {
  const dispatch = useDispatch();
  const mediaObjects = useSelector(getPublishablePostMedia);
  const mediaItemsArrangement = useSelector(getMediaArrangement);
  const postTextBody = useSelector(getPublishablePostTextBody);
  const selectedPlatforms = useSelector(getSelectedPlatforms) ?? [];
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const platformsWithLimits = [...platforms];
  const [filteredOutMessage, setFilteredOutMessage] = useState('')
  const publishIGVideoAsStories = useSelector(canPublishIGVideoAsStories);
  const publishIGVideoAsReels = useSelector(canPublishIGVideoAsReel);
  const removedMedia = useSelector(getRemovedMedia)
  const isFacebookPostCarousel = useSelector(getIsFacebookPostCarousel)
  const isFacebookPostReel = useSelector(getIsFacebookPostReel);
  const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
  const globalErrorMessage = useSelector(getGlobalErrorMessage);
  const shouldStartSyncingMediasOnPlatformSelection = useSelector(getShouldStartSyncingMediasOnPlatformSelection);
  const autoFixedPlatformMediasBank = useSelector(getAutoFixedPlatformMediasBank);
  const notifyPreviewsOfLiveMediaChanges = useSelector(getNotifyPreviewsOfLiveMediaChanges);

  let errors = {
    [PlatformIdentifier.FACEBOOK]: [],
    [PlatformIdentifier.INSTAGRAM]: [],
    [PlatformIdentifier.LINKED_IN]: [],
    [PlatformIdentifier.GOOGLE_MY_BUSINESS]: [],
    [PlatformIdentifier.PINTEREST]: [],
    [PlatformIdentifier.YOUTUBE]: [],
    [PlatformIdentifier.TWITTER]: [],
    [PlatformIdentifier.THREADS]: [],
    [PlatformIdentifier.REDDIT]: [],
    [PlatformIdentifier.TIKTOK]: [],
  }

  useEffect(() => {
    dispatch(setSelectedPlatformForPreview(selectedPlatforms[0]?.identifier));
    const twitterSelected = selectedPlatforms.find((p) => p.identifier === PlatformIdentifier.TWITTER);
    if (filteredOutMessage[PlatformIdentifier?.TWITTER] && twitterSelected) {
      // toast.info(filteredOutMessage.twitter, { theme: 'colored' });
      let newNotification = {
        type: 'info',
        postMetadata: true,
        header: ``,
        message: filteredOutMessage.twitter,
        platform: PlatformIdentifier?.TWITTER,
        read: false,
      }
      dispatchSideNotification(newNotification)
      setFilteredOutMessage(prev => ({ ...prev, twitter: '' }))
    }
  }, [selectedPlatforms]);


  const getTwitterThreadTexts = (oldData) => {
    const threadsTexts = chunkTweetIntoThreads(postTextBody);
    let twitter = oldData[PlatformIdentifier.TWITTER] ?? [];
    twitter = [...twitter];
    for (let i = 0; i < threadsTexts.length; i++) {
      let existingThreadEntry = twitter[i];
      if (existingThreadEntry) {
        existingThreadEntry = { ...existingThreadEntry };
        if (!existingThreadEntry.textEdited) {
          existingThreadEntry.text = threadsTexts[i];
        }
        twitter[i] = existingThreadEntry;
      } else {
        twitter.push({
          text: threadsTexts[i]
        })
      }
    }
    return twitter;
  }
  const getTHREADSThreadTexts = (oldData) => {
    const threadsTexts = chunkTweetIntoThreads(postTextBody);
    let threads = oldData[PlatformIdentifier.THREADS] ?? [];
    threads = [...threads];
    for (let i = 0; i < threadsTexts.length; i++) {
      let existingThreadEntry = threads[i];
      if (existingThreadEntry) {
        existingThreadEntry = { ...existingThreadEntry };
        if (!existingThreadEntry.textEdited) {
          existingThreadEntry.text = threadsTexts[i];
        }
        threads[i] = existingThreadEntry;
      } else {
        threads.push({
          text: threadsTexts[i]
        })
      }
    }
    return threads;
  }



  const syncTextChanges = async () => {
    let oldData = { ...mediaItemsArrangement };
    let newData = platformsWithLimits.map((platform) => {
      let oldPlatformData = oldData[platform.identifier];
      if (platform.identifier !== PlatformIdentifier.TWITTER) {
        if (manuallyEditedPlatforms[platform.identifier]) {
          return {
            [platform.identifier]: { ...oldPlatformData }
          };
        }
      }
      if (platform.identifier !== PlatformIdentifier.THREADS) {
        if (manuallyEditedPlatforms[platform.identifier]) {
          return {
            [platform.identifier]: { ...oldPlatformData }
          };
        }
      }
      if (platform.identifier === PlatformIdentifier.TWITTER) {
        return {
          [platform.identifier]: getTwitterThreadTexts(oldData)
        }
      }
      if (platform.identifier === PlatformIdentifier.THREADS) {
        return {
          [platform.identifier]: getTHREADSThreadTexts(oldData)
        }
      }
      return {
        [platform.identifier]: {
          ...oldPlatformData,
          text: platform.textLimit ? postTextBody.substring(0, platform.textLimit) : postTextBody
        }
      }
    });
    // make sure all newData is resolved
    newData = await Promise.all(newData);
    let newArrangement = {};
    for (let newDataEntry of newData) {
      let newEntryArr = Object.entries(newDataEntry).flat();
      newArrangement[newEntryArr[0]] = newEntryArr[1];
    }
    return newArrangement;
  }

  const checkIfMediaIsAlreadyRemovedAndToasted = (media, platformIdentifier) => {
    let platformRemovedMedia = removedMedia[platformIdentifier];
    if (!platformRemovedMedia) {
      let clonedRemovedMedia = { ...removedMedia };
      clonedRemovedMedia[platformIdentifier] = [media];
      dispatch(setRemovedMedia(clonedRemovedMedia));
      return false;
    }
    let _removeMedia = platformRemovedMedia.find(x => x?.url === media?.url);
    if (_removeMedia) {
      return true;
    }
    const newRemovedMedia = [...platformRemovedMedia, media];
    let clonedRemovedMedia = { ...removedMedia };
    clonedRemovedMedia[platformIdentifier] = newRemovedMedia;
    dispatch(setRemovedMedia(clonedRemovedMedia));
    return false;
  }


  function getImageDimension(url) {
    return new Promise(resolve => {
      const image = document.createElement('img');
      image.addEventListener("load", function () {
        const height = this.height;
        const width = this.width;
        resolve({ height, width });
      }, false);
      image.src = url;
    });

  }

  const processAutoFixMedia = async (url, platform, currentWidth, currentHeight, expectedWidth, expectedHeight, skipAspectFinder = false, returnableFormat = 'image/png') => {
    const result = await rescaleImage(url, currentWidth, currentHeight, expectedWidth, expectedHeight, skipAspectFinder, returnableFormat)
    return result;
  }

  const determineIfMediaIsInTheRightPlatformFormat = async (_media, platform) => {
    if (_media?.is_video || _media?.uploaded) {
      return _media;
    }
    if (_media?.external) {
      return { ..._media, external: true, uploaded: true }
    }

    //has this media been attended to before;
    let clonedAutoFixedPlatformMediasBank = { ...autoFixedPlatformMediasBank };
    let platformAutoFixedMedias = clonedAutoFixedPlatformMediasBank[platform] ?? [];
    let alreadyAutoFixed = platformAutoFixedMedias.find(x => x?.old?.url === _media?.url);
    if (alreadyAutoFixed) {
      return alreadyAutoFixed?.new;
    }

    let media = { ..._media }
    const { width, height } = await getImageDimension(media?.url);


    if (platform === PlatformIdentifier.TELEGRAM && media?.file?.type === 'image/webp') {
      const dataURL = await processAutoFixMedia(media?.url, platform, width, height, '', '', true)
      media = { ...media, url: dataURL, optimized: true }
      if (media?.file && media?.file?.type) {
        const blob = await fetch(dataURL).then(res => res.blob());
        const file = new File([blob], media?.file?.name, { type: "image/png", lastModified: new Date() });
        media = { ...media, file: file }
      }
      let clonedAutoFixedPlatformMediasBank = { ...autoFixedPlatformMediasBank };
      let platformAutoFixedMedias = clonedAutoFixedPlatformMediasBank[platform] ?? [];
      let updatedPlatformAutoFixedMedias = [...platformAutoFixedMedias, {
        old: _media,
        new: media
      }];
      clonedAutoFixedPlatformMediasBank[platform] = updatedPlatformAutoFixedMedias;
      dispatch(setAutoFixedPlatformMediasBank(clonedAutoFixedPlatformMediasBank));
      const errorAlreadyExisted = errors[platform]?.find(x => x?.media?.url === _media?.url);
      if (!errorAlreadyExisted) {
        errors[platform].push({
          error: `Image(s) have been converted from WEBP to PNG format for ${platform.toLocaleUpperCase()}.`,
          media: _media
        })
        // toast.info(`Image(s) have been converted from WEBP to PNG format for ${platform.toLocaleUpperCase()}.`, { theme: 'colored' })
        // let newNotification = {
        //   type: 'info',
        //   postMetadata: true,
        //   header: `Auto image conversion`,
        //   message: `Image(s) have been converted from WEBP to PNG format for ${platform.toLocaleUpperCase()}.`,
        //   platform,
        //   read: false,
        // }
        // dispatchSideNotification(newNotification)
      }
      return media;
    }
    if (platform === PlatformIdentifier.INSTAGRAM && (media?.file?.type !== 'image/jpg' && media?.file?.type !== "image/jpeg")) {
      const dataURL = await processAutoFixMedia(media?.url, platform, width, height, platformSpecs[PlatformIdentifier.INSTAGRAM]?.image?.minWidth, platformSpecs[PlatformIdentifier.INSTAGRAM]?.image?.minHeight, false, 'image/jpg')
      media = { ...media, url: dataURL, optimized: true }
      if (media?.file && media?.file?.type) {
        const blob = await fetch(dataURL).then(res => res.blob());
        const file = new File([blob], media?.file?.name, { type: "image/jpg", lastModified: new Date() });
        media = { ...media, file: file }
      }
      let clonedAutoFixedPlatformMediasBank = { ...autoFixedPlatformMediasBank };
      let platformAutoFixedMedias = clonedAutoFixedPlatformMediasBank[platform] ?? [];
      let updatedPlatformAutoFixedMedias = [...platformAutoFixedMedias, {
        old: _media,
        new: media
      }];
      clonedAutoFixedPlatformMediasBank[platform] = updatedPlatformAutoFixedMedias;
      dispatch(setAutoFixedPlatformMediasBank(clonedAutoFixedPlatformMediasBank));
      const errorAlreadyExisted = errors[platform]?.find(x => x?.media?.url === _media?.url);
      if (!errorAlreadyExisted) {
        errors[platform].push({
          error: `Image(s) have been converted from ${media?.file?.type} to JPG format for ${platform.toLocaleUpperCase()}.`,
          media: _media
        })
        // toast.info(`Image(s) have been converted from ${media?.file?.type} to JPG format for ${platform.toLocaleUpperCase()}.`, { theme: 'colored' })
        // let newNotification = {
        //   type: 'info',
        //   postMetadata: true,
        //   header: `Auto image conversion`,
        //   message: `Image(s) have been converted from ${media?.file?.type} to JPG format for ${platform.toLocaleUpperCase()}.`,
        //   platform,
        //   read: false,
        // }
        // dispatchSideNotification(newNotification)
      }
      return media;
    }
    if (platformSpecs[platform] && (platformSpecs[platform]?.image?.minWidth > width || platformSpecs[platform]?.image?.minHeight > height)) {
      const dataURL = await processAutoFixMedia(media?.url, platform, width, height, platformSpecs[platform]?.image?.minWidth, platformSpecs[platform]?.image?.minHeight, false, platformSpecs[platform]?.image?.format ?? 'image/png')
      media = { ...media, url: dataURL, optimized: true }
      if (media?.file && media?.file?.type) {
        const blob = await fetch(dataURL).then(res => res.blob());
        const file = new File([blob], media?.file?.name, { type: media?.file?.type });
        media = { ...media, file: file }
      }
      let clonedAutoFixedPlatformMediasBank = { ...autoFixedPlatformMediasBank };
      let platformAutoFixedMedias = clonedAutoFixedPlatformMediasBank[platform] ?? [];
      let updatedPlatformAutoFixedMedias = [...platformAutoFixedMedias, {
        old: _media,
        new: media
      }];
      clonedAutoFixedPlatformMediasBank[platform] = updatedPlatformAutoFixedMedias;
      dispatch(setAutoFixedPlatformMediasBank(clonedAutoFixedPlatformMediasBank));
      const errorAlreadyExisted = errors[platform]?.find(x => x?.media?.url === _media?.url);
      if (!errorAlreadyExisted) {
        errors[platform].push({
          error: `Image(s) have been auto resized to the minimum required size for ${platform.toLocaleUpperCase()}.`,
          media: _media
        })
        // toast.info(`Image(s) have been auto resized to the minimum required size for ${platform.toLocaleUpperCase()}.`, { theme: 'colored' })
        // let newNotification = {
        //   type: 'info',
        //   postMetadata: true,
        //   header: `Auto image conversion`,
        //   message: `Image(s) have been auto resized to the minimum required size for ${platform.toLocaleUpperCase()}.`,
        //   platform,
        //   read: false,
        // }
        // dispatchSideNotification(newNotification)
      }
      return media;
    }
    return _media;
  }


  const getMutuallyExclusiveMediaType = async (spliceTo = mediaObjects?.length, identifier, cutSizes) => {
    let images = mediaObjects?.filter(x => !x?.is_video);
    const twitterSelected = selectedPlatforms.find((p) => p.identifier === PlatformIdentifier.TWITTER);
    const threadsSelected = selectedPlatforms.find((p) => p.identifier === PlatformIdentifier.THREADS);
    if (!twitterSelected || !threadsSelected) {
      return mediaObjects
    }

    if (cutSizes) {
      images = images?.filter(x => {
        if (!x?.file?.size) {
          return x;
        }
        if ((x?.file?.size / 1000000) < cutSizes) {
          return x
        }
        if ((x?.file?.size / 1000000) > cutSizes) {
          // check if this media is already in the removed media list and if it is, don't add it again and don't show the toast
          const haveBeenToasted = checkIfMediaIsAlreadyRemovedAndToasted(x, identifier)
          // toast.error(`Image(s) above ${cutSizes}MB have been removed from ${identifier.toLocaleUpperCase()}.  Please use the Preview feature to add appropriate images when posting to ${identifier.toLocaleUpperCase()}`, { theme: 'colored' })
          let newNotification = {
            type: 'warning',
            postMetadata: true,
            header: `Image(s) removed`,
            message: `Image(s) above ${cutSizes}MB have been removed from ${identifier.toLocaleUpperCase()}. Please use the Preview feature to add appropriate images when posting to ${identifier.toLocaleUpperCase()}`,
            platform: identifier,
            read: false,
          }
          dispatchSideNotification(newNotification)
        }
      })
    }

    const video = mediaObjects?.find(x => x?.is_video);

    if (video && identifier && PlatformIdentifier.TWITTER && video?.file?.type != 'video/mp4' && !video?.external) {
      if (identifier === PlatformIdentifier.TWITTER && twitterSelected) {
        const haveBeenToasted = checkIfMediaIsAlreadyRemovedAndToasted(video, identifier)
        if (!haveBeenToasted) {
          // toast.error(`${PlatformIdentifier?.TWITTER.toLocaleUpperCase()} does not support MOV format videos. Please use the Preview feature to include suitable videos when sharing content on these platforms`, { theme: 'colored' });
          let newNotification = {
            type: 'warning',
            postMetadata: true,
            header: `Video(s) removed`,
            message: `${PlatformIdentifier?.TWITTER.toLocaleUpperCase()} does not support MOV format videos. Please use the Preview feature to include suitable videos when sharing content on these platforms.`,
            platform: identifier,
            read: false,
          }
          dispatchSideNotification(newNotification)
        }
        return []
      }
    }
    if (video) {
      return [video]
    }
    let newImages = []
    for (const image of images) {
      const newImage = await determineIfMediaIsInTheRightPlatformFormat(image, identifier)
      newImages.push(newImage);
    }
    return newImages.splice(0, spliceTo);
  }

  const platformIsSelected = (identifier) => {
    return selectedPlatforms.find((p) => (p.identifier === identifier && p.selected));
  }

  const handleOtherOptionsInMediaArrangements = (identifier, otherOptions, media) => {
    let _media = { ...media }
    if (identifier === PlatformIdentifier.FACEBOOK) {
      if (otherOptions.includes('carousel')) {
        _media = {
          ..._media, carousel: {
            link: _media?.carousel?.link || '', picture: _media?.carousel?.picture || _media?.url || '', name: _media?.carousel?.name || '',
            description: _media?.carousel?.description || '',
          }
        }
      }
    }
    return _media;
  };

  const getProperSizedImages = async (size, platform, extras = null, otherOptions, videoDurationLimitInSeconds) => {
    if (!platformIsSelected(platform)) return mediaObjects;
    const passedTest = [];
    let errorMessage = `Image(s) above ${size}MB have been removed from ${platform.toLocaleUpperCase()}. Please use the Preview feature to add appropriate images when to ${platform.toLocaleUpperCase()}`;
    let errorMessages = [];

    for (let i = 0; i < mediaObjects.length; i++) {
      const x = mediaObjects[i];
      if (x.is_video) {
        if (platform === PlatformIdentifier.GOOGLE_MY_BUSINESS) {
          const GMB_has_video = mediaItemsArrangement?.PlatformIdentifier?.GOOGLE_MY_BUSINESS?.media?.length
          if (!GMB_has_video) {
            continue;
          }
          // if (!errorMessages.includes(`Video(s) have been removed from GOOGLE MY BUSINESS as it is not supported. Kindly upload images.`)) {
          //   errorMessages.push(`Video(s) have been removed from GOOGLE MY BUSINESS as it is not supported. Kindly upload images.`)
          // }
          let newNotification = {
            type: 'warning',
            postMetadata: true,
            header: `Video(s) removed`,
            message: `Video(s) have been removed from GOOGLE MY BUSINESS as it is not supported. Kindly upload images.`,
            platform: PlatformIdentifier.GOOGLE_MY_BUSINESS,
            read: false,
          }
          dispatchSideNotification(newNotification)
          continue;
        }
        if (platform === PlatformIdentifier.FACEBOOK && isFacebookPostCarousel) {
          // if (!errorMessages.includes(`Video(s) have been removed from FACEBOOK because you have facebook carousel turned on and FACEBOOK does not support videos in carousel.`)) {
          //   errorMessages.push(`Video(s) have been removed from FACEBOOK because you have facebook carousel turned on and FACEBOOK does not support videos in carousel.`)
          // }
          let newNotification = {
            type: 'warning',
            postMetadata: true,
            header: `Video(s) removed`,
            message: `Video(s) have been removed from FACEBOOK because you have facebook carousel turned on and FACEBOOK does not support videos in carousel.`,
            platform: platform,
            read: false,
          }
          dispatchSideNotification(newNotification)
        }
        if (otherOptions?.includes('validate_video_size') && platformVideoUploadLimit[platform] && platformIsSelected(platform)) {
          if (x?.file?.size > platformVideoUploadLimit[platform] && !x?.external) {
            let errorMsg = `Video: ${x?.file?.name} have been removed from ${platform} because it exceeds the allowed sized limit of ${FigureUtils.convertBytes(platformVideoUploadLimit[platform])?.value}.`
            if (platformVideoUploadLimit['canUpgrade']) {
              errorMsg = errorMsg + ` Upgrade your plan to get more upload limits.`;
            }
            if (!globalErrorMessage.includes(errorMsg)) {
              // errorMessages.push(errorMsg)
              let newNotification = {
                type: 'warning',
                postMetadata: true,
                header: `${x?.file?.name} Video removed`,
                message: errorMsg,
                platform: platform,
                read: false,
              }
              dispatchSideNotification(newNotification)
              dispatch(setGlobalErrorMessage([...globalErrorMessage, errorMsg]))
            }
            continue;
          }
          // // check if video exceeds videoDurationLimitInSeconds
          // if (videoDurationLimitInSeconds) {

          // }
        }
        const response = await checkPlatformVideos(x?.file, platform, extras);
        if (!response?.error) {
          passedTest.push(x);
        } else {
          // passedTest.push(x);
          let note;
          note = `ERROR: ${response?.error}`
          if (!errorMessages.includes(note)) {
            const haveBeenToasted = checkIfMediaIsAlreadyRemovedAndToasted(x, platform)
            if (!haveBeenToasted) {
              errorMessages.push(note)
            }
          }
        }
      } else {
        if (!x.is_video) {
          // if (platform === PlatformIdentifier.FACEBOOK && isFacebookPostReel) {
          //   // if (!errorMessages.includes(`Image(s) have been removed from FACEBOOK because you have facebook reel turned on and FACEBOOK does not support images in reel.`)) {
          //   //   errorMessages.push(`Image(s) have been removed from FACEBOOK because you have reel turned on and FACEBOOK does not support images in reel.`)
          //   // }
          //   let newNotification = {
          //     type: 'warning',
          //     postMetadata: true,
          //     header: `${x?.file?.name} Image removed`,
          //     message: `Image(s) have been removed from FACEBOOK because you have facebook reel turned on and FACEBOOK does not support images in reel.`,
          //     platform,
          //     read: false,
          //   }
          //   dispatchSideNotification(newNotification)
          //   continue;
          // }
        }
        if ((x?.file?.size / 1000000) > size) {
          if (!errorMessages.includes(errorMessage)) {
            const haveBeenToasted = checkIfMediaIsAlreadyRemovedAndToasted(x, platform)
            if (!haveBeenToasted) {
              // errorMessages.push(errorMessage)
              let newNotification = {
                type: 'warning',
                postMetadata: true,
                header: `File size exceeded`,
                message: errorMessage,
                platform: platform,
                read: false,
              }
              dispatchSideNotification(newNotification)
            }
          }
          continue;
        }
        if (otherOptions) {
          const response = handleOtherOptionsInMediaArrangements(platform, otherOptions, x);
          const _response = await determineIfMediaIsInTheRightPlatformFormat(response, platform);
          passedTest.push(_response);
        } else {
          const _x = await determineIfMediaIsInTheRightPlatformFormat(x, platform);
          passedTest.push(_x);
        }
      }
    }
    if (errorMessages.length > 0) {
      // toast.error(errorMessages.join('\n'), { theme: 'colored' })
    }
    return passedTest;
  };

  const checkVideoSizeAndFilterAffected = async (media, platform) => {
    if (!media) return null
    if (platformVideoUploadLimit[platform] && platformIsSelected(platform) && media?.file?.size > platformVideoUploadLimit[platform] && !media?.external) {
      let errorMsg = `Video: ${media?.file?.name} have been removed from ${platform} because it exceeds the allowed sized limit of ${FigureUtils.convertBytes(platformVideoUploadLimit[platform])?.value}.`
      if (platformVideoUploadLimit['canUpgrade']) {
        errorMsg = errorMsg + ` Upgrade your plan to get more upload limits.`;
      }
      if (!globalErrorMessage.includes(errorMsg)) {
        // toast.error(errorMsg, { theme: 'colored' })
        let newNotification = {
          type: 'warning',
          postMetadata: true,
          header: `${media?.file?.name} Video removed`,
          message: errorMsg,
          platform: platform,
          read: false,
        }
        dispatchSideNotification(newNotification)
        dispatch(setGlobalErrorMessage([...globalErrorMessage, errorMsg]))
      }
      return null
    }
    return media
  }

  const videoValidation = async (media, platform) => {
    if (!media) {
      return [];
    }
    if (media?.external || media?.uploaded) {
      return [media];
    }
    if (platform === PlatformIdentifier.TIKTOK && platformIsSelected(platform)) {
      let _media = { ...media };
      console.log("🚀 ~ videoValidation ~ _media:", _media)
      let file = _media?.file;

      // if (file?.size > 50000000) {
      //   const haveBeenToasted = checkIfMediaIsAlreadyRemovedAndToasted(media, platform)
      //   !haveBeenToasted && toast.error(`Video(s) above 50MB have been removed from ${platform.toLocaleUpperCase()}.`, { theme: 'colored' });
      //   return [];
      // }

      if (file?.type !== 'video/mp4' && file?.type !== 'video/webm' && file?.type !== 'video/quicktime') {
        const haveBeenToasted = checkIfMediaIsAlreadyRemovedAndToasted(media, platform)
        if (!haveBeenToasted) {
          let newNotification = {
            type: 'warning',
            postMetadata: true,
            header: `Video(s) removed`,
            message: `Video(s) have been removed from ${platform.toLocaleUpperCase()} as it is not supported. Kindly upload videos in MP4, MOV or WebM format.`,
            platform: platform,
            read: false,
          }
          dispatchSideNotification(newNotification)
        }
        return [];
      }

      const filteredMedia = await checkVideoSizeAndFilterAffected(media, platform)
      if (!filteredMedia) return []

      const video = document.createElement('video');
      video.preload = 'metadata';
      video.src = URL.createObjectURL(file);
      // let duration;
      return new Promise((resolve, reject) => {
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          const duration = video.duration;
          if (duration > 600) {
            const haveBeenToasted = checkIfMediaIsAlreadyRemovedAndToasted(media, platform);
            if (!haveBeenToasted) {
              let newNotification = {
                type: 'warning',
                postMetadata: true,
                header: `Video(s) removed`,
                message: `Video(s) have been removed from ${platform.toLocaleUpperCase()} as it is not supported. Kindly upload videos that are less than 10 minutes.`,
                platform: platform,
                read: false,
              }
              dispatchSideNotification(newNotification)
              reject(new Error("Video duration exceeds 10 minutes."));
            }
            resolve([]);
          } else if (duration < 3) {
            const haveBeenToasted = checkIfMediaIsAlreadyRemovedAndToasted(media, platform);
            if (!haveBeenToasted) {
              let newNotification = {
                type: 'warning',
                postMetadata: true,
                header: `Video(s) removed`,
                message: `Video(s) have been removed from ${platform.toLocaleUpperCase()} as it is not supported. Kindly upload videos that are more than 3 seconds.`,
                platform: platform,
                read: false,
              }
              dispatchSideNotification(newNotification)
              // toast.error(`Video(s) have been removed from ${platform.toLocaleUpperCase()} as it is not supported. Kindly upload videos that are more than 3 seconds.`, { theme: 'colored' });
              reject(new Error("Video duration is below 3 seconds."));
            }
            resolve([]);
          }
          else {
            resolve([media])
          }
        };
      });
    } else {
      return media ? [media] : []
    }
  }



  const getPlatformSpecificMedia = async (identifier) => {
    switch (identifier) {
      case PlatformIdentifier.FACEBOOK:
        return await getProperSizedImages(8, PlatformIdentifier.FACEBOOK, null, ['carousel', 'validate_video_size']);
      case PlatformIdentifier.INSTAGRAM:
        return await getProperSizedImages(8, PlatformIdentifier.INSTAGRAM, { publishIGVideoAsStories: publishIGVideoAsStories, publishIGVideoAsReels: publishIGVideoAsReels }, ['validate_video_size']);
      case PlatformIdentifier.LINKED_IN:
        return await getProperSizedImages(5, PlatformIdentifier.LINKED_IN, null, ['validate_video_size']);
      case PlatformIdentifier.GOOGLE_MY_BUSINESS:
        return await getProperSizedImages(5, PlatformIdentifier.GOOGLE_MY_BUSINESS);
      // case PlatformIdentifier.PINTEREST:
      //   return mediaObjects[0] ? [mediaObjects[0]] : [];
      case PlatformIdentifier.YOUTUBE:
        const firstAvailableVideoYT = mediaObjects?.find(x => x?.is_video);
        const filteredVideo = await checkVideoSizeAndFilterAffected(firstAvailableVideoYT, identifier)
        if (filteredVideo) {
          return [filteredVideo]
        }
        return [];
      case PlatformIdentifier.TWITTER:
        return getMutuallyExclusiveMediaType(4, PlatformIdentifier.TWITTER, 5);
      case PlatformIdentifier.THREADS:
        return await getProperSizedImages(8, PlatformIdentifier.THREADS, null, ['validate_video_size'], 300);
      case PlatformIdentifier.REDDIT:
        return getMutuallyExclusiveMediaType();
      case PlatformIdentifier.TIKTOK:
        const firstAvailableVideo = mediaObjects?.find(x => x?.is_video);
        const videoValidated = await videoValidation(firstAvailableVideo, PlatformIdentifier.TIKTOK);
        return videoValidated
      default:
        return mediaObjects;
    }
  }

  const getTwitterThreadMedia = async (identifier, oldData) => {
    let twitter = oldData[PlatformIdentifier.TWITTER] ?? [];
    twitter = [...twitter];
    let firstThreadEntry = twitter[0] ?? {};
    firstThreadEntry = { ...firstThreadEntry };
    if (!firstThreadEntry.mediaEdited) {
      firstThreadEntry.media = await getPlatformSpecificMedia(identifier);
    }
    twitter[0] = firstThreadEntry;
    return twitter;
  }
  const getTHREADSThreadMedia = async (identifier, oldData) => {
    let threads = oldData[PlatformIdentifier.THREADS] ?? [];
    threads = [...threads];
    let firstThreadEntry = threads[0] ?? {};
    firstThreadEntry = { ...firstThreadEntry };
    if (!firstThreadEntry.mediaEdited) {
      firstThreadEntry.media = await getPlatformSpecificMedia(identifier);
    }
    threads[0] = firstThreadEntry;
    return threads;
  }

  const syncMediaChanges = async (mediaArrFromTextSyncing) => {
    let oldData = { ...mediaArrFromTextSyncing };
    let newData = [];
    for (let i = 0; i < platformsWithLimits.length; i++) {
      let platform = platformsWithLimits[i];
      let oldPlatformData = oldData[platform.identifier];
      if (platform.identifier !== PlatformIdentifier.TWITTER) {
        if (manuallyEditedPlatforms[platform.identifier]) {
          newData.push({
            [platform.identifier]: { ...oldPlatformData }
          });
          continue;
        }
      }
      if (platform.identifier !== PlatformIdentifier.THREADS) {
        if (manuallyEditedPlatforms[platform.identifier]) {
          newData.push({
            [platform.identifier]: { ...oldPlatformData }
          });
          continue;
        }
      }
      if (platform.identifier === PlatformIdentifier.TWITTER) {
        newData.push({
          [platform.identifier]: await getTwitterThreadMedia(platform.identifier, oldData)
        });
        continue;
      }
      if (platform.identifier === PlatformIdentifier.THREADS) {
        newData.push({
          [platform.identifier]: await getTHREADSThreadMedia(platform.identifier, oldData)
        });
        continue;
      }
      const newMedia = await getPlatformSpecificMedia(platform.identifier);
      newData.push({
        [platform.identifier]: {
          ...oldPlatformData,
          media: newMedia
        }
      });
    }
    let newArrangement = {};
    for (let newDataEntry of newData) {
      let newEntryArr = Object.entries(newDataEntry).flat();
      newArrangement[newEntryArr[0]] = newEntryArr[1];
    }
    const scannedMediaArrangements = await scanMediaArrangementsForPlatformSpecs(newArrangement)
    dispatch(setMediaArrangement(scannedMediaArrangements));
    dispatch(setNotifyPreviewsOfLiveMediaChanges(notifyPreviewsOfLiveMediaChanges + 1))
    dispatch(setPostReadyForPreviews(true));
  }

  const syncBothMediaAndText = async () => {
    const newArrangementsFromText = await syncTextChanges();
    syncMediaChanges(newArrangementsFromText);
  }

  useEffect(() => {
    syncBothMediaAndText();
  }, [
    postTextBody,
    mediaObjects,
    publishIGVideoAsStories,
    publishIGVideoAsReels
  ])


  useEffect(() => {
    // console.log('detected selected platforms.....shouldStartSyncingMediasOnPlatformSelection: ', shouldStartSyncingMediasOnPlatformSelection)
    // if (!shouldStartSyncingMediasOnPlatformSelection) return;
    // console.log('passed....')
    syncBothMediaAndText();
  }, [selectedPlatforms, shouldStartSyncingMediasOnPlatformSelection])

  return (<div></div>)

};
export default MediaSyncer;