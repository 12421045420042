import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { findPlatformByIdentifier } from 'components/app/platforms';
import { PlatformIdentifier } from '../../../../constants';
import {
    setPinterestTitle,
    getPinterestTitle,
    getPinterestVideoThumbnail,
    getPinterestDestinationLink,
    getPinterestAltText,
    setCanOpenThumbnailPreviewDialog,
    setThumbnailPreviewData,
    setPinterestVideoThumbnail,
    setPinterestDestinationLink,
    setPinterestAltText,
    getUploadableBulkContent,
    setUploadableBulkContent
} from '../../../../redux/slices/postslice';
import MultimediaSelectionDropdown from 'components/common/MultimediaSelectionDropdown';
import { useSelector, useDispatch } from 'react-redux';
import EditableSection from './debounceable_edit_box';
import { Button, Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import PrePublishErrorResolver from './PrePublishErrorResolver';
import Divider from 'components/common/Divider';
import StringUtils from 'utils/string';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

export default function PinterestOptionsField(props) {

    const {
        videoDetected = false,
        bulkContentMode = false,
        rowIndex = 0
    } = props;
    const dispatch = useDispatch();
    const uploadableBulkContent = useSelector(getUploadableBulkContent);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const pinterestPlatform = findPlatformByIdentifier(PlatformIdentifier.PINTEREST);
    const [pinterestErrorMessage, setPinterestErrorMessage] = useState(props.pinterestErrorMessage);
    const vanillaPinterestTitle = useSelector(getPinterestTitle);
    const [bulkContentPinterestTitle, setBulkContentPinterestTitle] = useState(bulkContentRow?.pinterest_title);
    const pinterestVideoThumbnail = useSelector(getPinterestVideoThumbnail);
    const vanillaPinterestDestinationLink = useSelector(getPinterestDestinationLink);
    const [bulkContentPinterestDestinationLink, setBulkContentPinterestDestinationLink] = useState(bulkContentRow?.pinterest_destination_link);
    const vanillaPinterestAltText = useSelector(getPinterestAltText);
    const [bulkContentPinterestAltText, setBulkContentPinterestAltText] = useState(bulkContentRow?.pinterest_alt_text);
    const [show, setShow] = useState(true);

    useEffect(() => {
        setPinterestErrorMessage(props.pinterestErrorMessage);
    }, [props.pinterestErrorMessage]);

    const updateBulkContentRow = (updatedRow) => {
        let uploadableBulkContentCopy = [...uploadableBulkContent];
        uploadableBulkContentCopy[rowIndex] = updatedRow;
        dispatch(setUploadableBulkContent(uploadableBulkContentCopy));
    }

    return (
        <Card className='mb-3'>
            <FalconCardHeader
                title={bulkContentMode ? "Pinterest" : "For Pinterest"}
                endEl={
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                    >
                        <div
                            style={{
                                width: 20,
                                height: 20,
                                borderRadius: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: pinterestPlatform.color
                            }}>
                            <FontAwesomeIcon
                                icon={pinterestPlatform.icon}
                                style={{
                                    color: 'white',
                                    height: 12,
                                    width: 12
                                }}
                            />
                        </div>
                        <Flex
                            className="ms-2"
                            // alignItems="center"
                            // justifyContent="center"
                            style={{ cursor: 'pointer' }}
                        >
                            {
                                show ?
                                    <IoIosArrowUp
                                        onClick={() => setShow(false)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    /> :
                                    <IoIosArrowDown
                                        onClick={() => setShow(true)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    />
                            }
                        </Flex>
                    </Flex>
                } />
            {
                show &&
                <Card.Body className="bg-light">
                    <Flex direction={'column'} className={'gap-3'}>
                        <EditableSection
                            style={{
                                width: '100%'
                            }}
                            title={"Title"}
                            bulkContentMode={bulkContentMode}
                            placeholder={bulkContentMode ? "Title" : "Enter title for Pinterest"}
                            value={bulkContentMode ? bulkContentPinterestTitle : vanillaPinterestTitle}
                            onChange={(value) => {
                                if (bulkContentMode) {
                                    let updatedRow = { ...bulkContentRow }
                                    if (!value) {
                                        value = " ";
                                    }
                                    updatedRow.pinterest_title = value;
                                    setBulkContentPinterestTitle(value);
                                    updateBulkContentRow(updatedRow);
                                } else {
                                    dispatch(setPinterestTitle(value))
                                }
                            }}
                        />
                        <EditableSection
                            style={{
                                width: '100%'
                            }}
                            title={"Destination Link"}
                            bulkContentMode={bulkContentMode}
                            placeholder={bulkContentMode ? "Destination Link" : "Enter optional destination link for Pinterest"}
                            value={bulkContentMode ? bulkContentPinterestDestinationLink : vanillaPinterestDestinationLink}
                            onChange={(value) => {
                                if (bulkContentMode) {
                                    let updatedRow = { ...bulkContentRow }
                                    if (!value) {
                                        value = " ";
                                    }
                                    updatedRow.pinterest_destination_link = value;
                                    setBulkContentPinterestDestinationLink(value);
                                    updateBulkContentRow(updatedRow);
                                } else {
                                    dispatch(setPinterestDestinationLink(value))
                                }
                            }}
                        />
                        <EditableSection
                            style={{
                                width: '100%'
                            }}
                            title={"Alt Text"}
                            bulkContentMode={bulkContentMode}
                            placeholder={bulkContentMode ? "Alt Text" : "Enter optional alt text for Pinterest"}
                            value={bulkContentMode ? bulkContentPinterestAltText : vanillaPinterestAltText}
                            onChange={(value) => {
                                if (bulkContentMode) {
                                    let updatedRow = { ...bulkContentRow }
                                    if (!value) {
                                        value = " ";
                                    }
                                    updatedRow.pinterest_alt_text = value;
                                    setBulkContentPinterestAltText(value);
                                    updateBulkContentRow(updatedRow);
                                } else {
                                    dispatch(setPinterestAltText(value))
                                }
                            }}
                        />
                        {
                            videoDetected &&
                            <div
                                className='mt-3 mb-3'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <MultimediaSelectionDropdown
                                    dropDownAnchor={
                                        <span id='global-photo-span'>
                                            <Button
                                                style={{ width: '100%' }}
                                                variant={'falcon-light'}
                                                id='pinterest-thumbnail-select'>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        gap: 5
                                                    }}>
                                                    <FontAwesomeIcon
                                                        icon={faImage}
                                                    />
                                                    <span className='fs--1'>Select cover image for Pinterest video
                                                    </span>
                                                </div>
                                            </Button>
                                        </span>
                                    }
                                    initiatorId={"pinterest-thumbnail-select"}
                                    prompt={"Select Cover Image From"}
                                    fileType={"image"}
                                    multiple={false}
                                    externalFilePickerTitle={"Enter Cover Image Location"}
                                    externalPickerPromptLabel={"Cover Image"}
                                    platformIdentifier={PlatformIdentifier.PINTEREST}
                                    thumbnail={true}
                                />
                            </div>
                        }
                        {
                            pinterestVideoThumbnail &&
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative'
                                }}>
                                <h6
                                    type={'h5'}
                                    style={{
                                        fontSize: 15
                                    }}>Selected Pinterest Video Cover
                                </h6>
                                <img
                                    onClick={() => {
                                        dispatch(setThumbnailPreviewData(pinterestVideoThumbnail));
                                        dispatch(setCanOpenThumbnailPreviewDialog(true));
                                    }}
                                    src={StringUtils.isStringAFirebaseLink(pinterestVideoThumbnail) ? pinterestVideoThumbnail : pinterestVideoThumbnail['url']}
                                    height={200}
                                    width={'100%'}
                                    alt={"Pinterest Video Thumbnail"}
                                    style={{
                                        borderRadius: 5,
                                        objectFit: 'cover'
                                    }}
                                />
                                <Button
                                    variant="text"
                                    size='sm'
                                    style={{
                                        backgroundColor: 'rgba(0,0,0,0.9)',
                                        color: 'white',
                                        position: 'absolute',
                                        bottom: 10,
                                        left: 0,
                                        right: 0,
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        width: 100
                                    }}
                                    onClick={() => {
                                        dispatch(setPinterestVideoThumbnail(null));
                                    }}
                                >
                                    Remove
                                </Button>
                            </div>
                        }
                        {
                            pinterestErrorMessage &&
                            <PrePublishErrorResolver
                                error={pinterestErrorMessage}
                            />
                        }
                        {
                            !bulkContentMode &&
                            <Flex direction={'column'}>
                                <Divider />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 12,
                                        gap: 5
                                    }}>
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        style={{
                                            width: 15,
                                            height: 15,
                                        }}
                                    />
                                    <span
                                        style={{
                                            fontSize: 12,
                                        }}>Message above will be used as the Pin description
                                    </span>
                                </div>
                            </Flex>
                        }
                    </Flex>
                </Card.Body>
            }
        </Card>
    );
}