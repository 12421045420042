import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { useSelector } from 'react-redux';
import { getBilledYearly } from 'redux/slices/workspaceslice';

const LTDPricingCard = (props) => {

  const [pricing, setPricing] = useState(props.pricing);
  const {
    subTitle,
    price,
    currencySign,
    periodical,
    buttonText,
    isFeatured,
    activePlan,
    featureTitle,
    features,
    ...others
  } = pricing;

  useEffect(() => {
    setPricing(props.pricing);
  }, [props.pricing]);

  const billedYear = useSelector(getBilledYearly);

  return (
    <Col
      lg={12}
      className={classNames('border-top border-200 border border-bottom', {
        'dark__bg-1000 px-4 px-lg-0': isFeatured
      })}
      style={{ backgroundColor: isFeatured && 'rgba(115, 255, 236, 0.18)' }}
    >
      <div className="h100">
        <div className="text-center p-2 pt-2">
          <h3 className="fw-normal my-0 fs-1 text-900 mb-3"><b>Upgrade your LTD</b></h3>
          <h2 className="fw-medium">
            <sup className="fw-normal fs-2 me-1">{currencySign}</sup>
            {billedYear ? price.Year.toLocaleString() : price.Month.toLocaleString()}
          </h2>
          
          <p className="mb-4 fs--1">{subTitle}</p>
        </div>
        <hr className="border-bottom-0 m-0" />
        <div className="text-start px-sm-3 py-3">
          <h5 className="fw-medium fs--1">{featureTitle}</h5>
          <ul className="list-unstyled mt-3">
            {features.map(feature => (
              <li className="py-1 fs-0" key={feature.id}>
                <FontAwesomeIcon icon="check" className="me-1 text-success" />{' '}
                {feature.title}{' '}
                {feature.tag && (
                  <SoftBadge pill bg={feature.tag.type}>
                    {feature.tag.label}
                  </SoftBadge>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Col>
  );
};

LTDPricingCard.propTypes = {
  pricing: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    url: PropTypes.string,
    buttonText: PropTypes.string,
    isFeatured: PropTypes.bool,
    featureTitle: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        tag: PropTypes.shape({
          label: PropTypes.string,
          type: PropTypes.string
        })
      })
    )
  })
};

export default LTDPricingCard;