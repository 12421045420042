import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Col, Form,
  Row, Button,
  OverlayTrigger, Tooltip,
  Modal, Spinner
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveWorkSpace,
  searchWorkSpace, setCachedWorkspaces,
  setOpenBulkWorkspaceCreator, setReloadPage
} from 'redux/slices/workspaceslice';
import { toast } from 'react-toastify';
import ConfirmModal from 'components/common/ConfirmModal';
import APIService from 'http/api_service';
import WorkspaceStats from '../../../utils/workspace_stats';
import BulkWorkspaceNamesEditor from './BulkWorkspaceNamesEditor';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { useTranslation } from 'react-i18next';
import useOrganizationStore, { defaultOrganization } from 'state/organization_store';
import OrganizationsToMoveSelectedWorkspacesTo from '../organization-and-workspace-setup/organization/organizations_to_move_selected_workspaces_to';

const WorkSpacesTableHeader = ({
  workSpaces,
  bulkWorkspacesUpdateHandler,
  workSpaceUpdateHandler,
  onWorkspacesMoved,
  selectedRowIds,
  ...others
}) => {

  const { t } = useTranslation();
  const loggedInUser = useSelector(getLoggedInUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [operationKey, setOperationKey] = useState();
  const [operationMessage, setOperationMessage] = useState();
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  const [openArchivePrompt, setOpenArchivePrompt] = useState(false);
  const [openWorkspaceNamesRenamePrompt, setOpenWorkspaceNamesRenamePrompt] = useState(false);
  const [openOrganizationsToMoveTo, setOpenOrganizationsToMoveTo] = useState(false);
  const [workSpaceStats, setWorkSpaceStats] = useState({});

  const activeWorkspace = useSelector(getActiveWorkSpace);
  const activeOrganizationFromStore = useOrganizationStore(state => state.activeOrganization);
  const activeOrganization = (activeOrganizationFromStore ?? activeWorkspace?.organization ? activeWorkspace.organization : defaultOrganization) ?? defaultOrganization;

  const initWorkSpaceStats = async () => {
    try {
      let stats = await WorkspaceStats.getSync();
      setWorkSpaceStats(stats);
    } catch (e) { }
  }

  useEffect(() => {
    initWorkSpaceStats();
  }, []);

  const selectedWorkSpaces = (ids = []) => {
    let selectedWorkSpaces = [];
    for (let index of ids) {
      let workSpace = workSpaces[index];
      if (workSpace?.owner_id === loggedInUser._id) {
        selectedWorkSpaces.push(workSpace);
      }
    }
    return selectedWorkSpaces;
  }

  const deleteWorkSpaces = () => {
    setProcessing(true);
    setOperationMessage("Deleting...");
    let deletableWorkSpaces = selectedWorkSpaces(Object.keys(selectedRowIds));
    let deletableWorkSpaceIds = deletableWorkSpaces.map(x => x._id);
    APIService.deleteWorkspaces(deletableWorkSpaceIds, (response, error) => {
      setProcessing(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let { message } = response;
      toast.success(message, { theme: 'colored' });
      workSpaces = workSpaces.filter(workSpace => !deletableWorkSpaceIds.includes(workSpace._id));
      if (workSpaceUpdateHandler) {
        workSpaceUpdateHandler(workSpaces);
        dispatch(setCachedWorkspaces([...workSpaces]));
        dispatch(setReloadPage(true));
      }
    });
  }

  const archiveWorkspaces = () => {
    setProcessing(true);
    let archivableWorkspaces = selectedWorkSpaces(Object.keys(selectedRowIds));
    let archive = true;
    if (archivableWorkspaces.every(x => x.archived)) {
      setOperationMessage("Un-Archiving...");
      archive = false;
    } else {
      setOperationMessage("Archiving...");
    }
    let archivableWorkSpaceIds = archivableWorkspaces.map(x => x._id);
    APIService.archiveWorkSpaces(archivableWorkSpaceIds, archive, (response, error) => {
      setProcessing(false);
      if (error) {
        toast.error(error, { theme: 'colored' });
        return;
      }
      let { message } = response;
      toast.success(message, { theme: 'colored' });
      workSpaces = workSpaces.map((workSpace) => {
        if (archivableWorkSpaceIds.includes(workSpace._id)) {
          workSpace.archived = archive;
        }
        return workSpace;
      })
      if (workSpaceUpdateHandler) {
        workSpaceUpdateHandler(workSpaces);
        dispatch(setCachedWorkspaces([...workSpaces]));
        dispatch(setReloadPage(true));
      }
    });
  }

  const bulkWorkspaceNamesUpdateHandler = (updatedData) => {
    setOpenWorkspaceNamesRenamePrompt(false);
    bulkWorkspacesUpdateHandler(updatedData);
  }

  const allArchived = () => selectedWorkSpaces(Object.keys(selectedRowIds)).every(x => x.archived);

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <div>
          <h5 className="fs-0 mb-0 text-nowrap py-2 ">{t('all_workspaces_under_organization', { organization: activeOrganization?.name })}</h5>
          {workSpaceStats?.created || workSpaceStats?.invited || workSpaceStats?.archived ?
            <div
              className='fs--1'
              style={{
                display: 'flex',
                gap: 8
              }}>

              <span>
                Created({workSpaceStats?.created}/{loggedInUser?.active_plan["Workspace"]})
              </span>

              •
              <span>
                Invited({workSpaceStats?.invited})
              </span>
              •
              <span>
                Archived({workSpaceStats?.archived})
              </span>
            </div> :
            <Spinner animation={'border'} size="sm" variant="success" />
          }
        </div>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              onChange={(e) => {
                let selectedValue = e.target.value;
                setOperationKey(selectedValue);
                if (selectedValue === 'rename') {
                  setOpenWorkspaceNamesRenamePrompt(true);
                } else if (selectedValue === "move_to_organization") {
                  setOpenOrganizationsToMoveTo(true);
                }
              }}>
              <option>Pick an Action</option>
              <option value="delete">Delete</option>
              <option value="archive">{allArchived() ? "Unarchive" : "Archive"}</option>
              <option value="rename">Rename</option>
              <option value="move_to_organization">{t('move_to_organization')}</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              disabled={processing}
              onClick={() => {
                if (operationKey === "delete") {
                  setOpenDeletePrompt(true);
                } else if (operationKey === "archive") {
                  setOpenArchivePrompt(true);
                } else if (operationKey === 'rename') {
                  setOpenWorkspaceNamesRenamePrompt(true);
                } else {
                  toast.error("Please pick an option", { theme: 'colored' });
                }
              }}
            >
              {processing ? operationMessage : "Apply"}
            </Button>
          </div>
        ) : (
          <div
            id="actions"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10
            }}>
            <AdvanceTableSearchBox table {...others} />
            <OverlayTrigger
              placement='left'
              overlay={
                <Tooltip id="bulk-workspaces-overlay-trigger">
                  Create a new Workspace
                </Tooltip>
              }>
              <Button
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={() => {
                  dispatch(searchWorkSpace(false));
                  navigate(RoutePaths.NEW_WORKSPACE);
                }}
              >
                <div style={{
                  display: 'flex',
                  gap: 1, alignItems: 'center'
                }}>
                  <FontAwesomeIcon icon={'plus'} />
                  <span className="d-none d-sm-inline-block ms-1">New</span>
                </div>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement='right'
              overlay={
                <Tooltip id="bulk-workspaces-overlay-trigger">
                  Create multiple workspaces at once from a CSV file
                </Tooltip>
              }>
              <Button
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={() => {
                  dispatch(setOpenBulkWorkspaceCreator(true));
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center'
                  }}>
                  <FontAwesomeIcon icon={faLayerGroup} />
                  <span className="d-none d-sm-inline-block ms-1">Bulk</span>
                </div>
              </Button>
            </OverlayTrigger>
          </div>
        )}
      </Col>
      <ConfirmModal
        open={openDeletePrompt}
        title={`Delete ${selectedWorkSpaces(Object.keys(selectedRowIds)).length} Workspace(s)`}
        message={`Are you certain about deleting the selected workspace(s)?`}
        confirmText={"Yes, Delete"}
        cancelText={"No"}
        onConfirm={() => {
          deleteWorkSpaces();
        }}
        onCancel={() => {
          setOpenDeletePrompt(false);
        }}
        centered={false}
      />
      <ConfirmModal
        open={openArchivePrompt}
        title={`${allArchived() ? 'Un-Archive' : 'Archive'} ${selectedWorkSpaces(Object.keys(selectedRowIds)).length} Workspace(s)`}
        message={`Are you certain about ${allArchived() ? "un-archiving" : "archiving"} the selected workspace(s)? ${!allArchived() ? '<br/>Archived workspaces would not be deleted but just rendered unusable until un-archived' : ''}`}
        confirmText={`Yes, ${allArchived() ? "Un-archive" : "Archive"}`}
        cancelText={"No"}
        onConfirm={() => {
          archiveWorkspaces();
        }}
        onCancel={() => {
          setOpenArchivePrompt(false);
        }}
        centered={false}
      />
      <Modal
        show={openWorkspaceNamesRenamePrompt}
        onHide={() => {
          setOpenWorkspaceNamesRenamePrompt(false);
        }}>
        <Modal.Body>
          <Modal.Header>
            <Modal.Title>Update Workspace Name(s)</Modal.Title>
          </Modal.Header>
          <BulkWorkspaceNamesEditor
            workspaces={selectedWorkSpaces(Object.keys(selectedRowIds))}
            updateHandler={bulkWorkspaceNamesUpdateHandler}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={openOrganizationsToMoveTo}
        onHide={() => {
          setOpenOrganizationsToMoveTo(false);
        }}>
        <Modal.Header>
          <Modal.Title>
            <h5 className='fs-1'>{t('move_selected_workspaces_to_organization')}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrganizationsToMoveSelectedWorkspacesTo
            workspaces={selectedWorkSpaces(Object.keys(selectedRowIds))}
            onWorkspacesMoved={(movedWorkspaces) => {
              toast.success(t('selected_workspaces_moved'), { theme: 'colored' });
              if (onWorkspacesMoved) {
                onWorkspacesMoved(movedWorkspaces)
              }
              setOpenOrganizationsToMoveTo(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </Row>
  );
};

WorkSpacesTableHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default WorkSpacesTableHeader;
