import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findPlatformByIdentifier } from 'components/app/platforms';
import Divider from 'components/common/Divider';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import React, { useState } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getMediaArrangement } from 'redux/slices/publishable_media_arrangement_slice';
import { getPlatformVideoUploadLimit } from 'redux/slices/workspaceslice';
import { PlatformIdentifier } from '../../../../constants';
import { faArrowCircleDown, faArrowDown, faArrowDownLong } from '@fortawesome/free-solid-svg-icons';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import FigureUtils from 'utils/figures';

const LinkedInOptionsField = () => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch()
    // const workSpace = useSelector(getActiveWorkSpace);
    const linkedInPlatform = findPlatformByIdentifier(PlatformIdentifier.LINKED_IN);
    const media_items_arrangement = useSelector(getMediaArrangement);
    // const [linkedInMedias, setLinkedInMedia] = useState([]);
    const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
    const [show, setShow] = useState(true);

    // useEffect(() => {
    //     let _media = media_items_arrangement?.linked_in?.media || [];
    //     setLinkedInMedia(_media)
    // }, [media_items_arrangement?.linked_in?.media])

    
    const displayVideoSizeLimit = () => {
        if (!platformVideoUploadLimit) {
            return `N/A`; // Not available
        }
        if (!platformVideoUploadLimit[PlatformIdentifier.LINKED_IN]) {
            return `N/A`; // Not available
        }
        const statement = FigureUtils.convertBytes(platformVideoUploadLimit[PlatformIdentifier.LINKED_IN])?.value
        if (platformVideoUploadLimit?.canUpgrade) {
            return `${statement} (Upgrade to increase limit)`
        }
        return statement;
    }

    return (
        <Card className='mb-3'>
            <FalconCardHeader
                title={"For LinkedIn"}
                // title={bulkContentMode ? "Facebook" : "For Facebook"}
                endEl={
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                    >
                        <div
                            style={{
                                width: 20,
                                height: 20,
                                borderRadius: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: linkedInPlatform.color
                            }}>
                            <FontAwesomeIcon
                                icon={linkedInPlatform.icon}
                                style={{
                                    color: 'white',
                                    height: 12,
                                    width: 12
                                }}
                            />
                        </div>
                        <Flex
                            className="ms-2"
                            // alignItems="center"
                            // justifyContent="center"
                            style={{ cursor: 'pointer' }}
                        >
                            {
                                show ?
                                    <IoIosArrowUp
                                        onClick={() => setShow(false)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    /> :
                                    <IoIosArrowDown
                                        onClick={() => setShow(true)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    />
                            }
                        </Flex>
                    </Flex>
                } />
            {
                show &&
                <Card.Body className='bg-light'>
                    <Flex direction={'column'}>
                        <Divider />
                        <Alert variant="warning" className="p-2 mb-0">
                            <Flex>
                                <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
                                <Alert variant="warning" className="p-2 mb-0">
                                    <Flex>
                                        <div className="ms-3 flex-1">
                                            <h4 className="alert-heading">Please note</h4>
                                            {/* 1.  The recommended specifications are: MP4, 1080 x 1920 px and aspect ratio of 9:16.
                                            <br /> */}
                                            1.  The maximum video size is {displayVideoSizeLimit()}.
                                        </div>
                                    </Flex>
                                </Alert>
                            </Flex>
                        </Alert>
                    </Flex>
                </Card.Body>
            }
        </Card>
    )
}

export default LinkedInOptionsField