import {
    faClose, faImage, faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import MultimediaSelectionDropdown from 'components/common/MultimediaSelectionDropdown';
import APIService from 'http/api_service';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { WithContext as ReactTags } from 'react-tag-input';
import {
    getEditablePost,
    getSelectedPlatforms,
    getWordPressTitle,
    getWordpressCategories,
    getWordpressFeaturedImageThumbnail,
    getWordpressFormat,
    getWordpressPostTags,
    getWordpressSlug,
    setCanOpenThumbnailPreviewDialog,
    setThumbnailPreviewData,
    setWordpressCategories,
    setWordpressFeaturedImageThumbnail,
    setWordpressFormat,
    setWordpressPostPassword,
    setWordpressPostTags,
    setWordpressSlug,
    setWordpressTitle
} from 'redux/slices/postslice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { PlatformIdentifier } from '../../../../constants';
import { findPlatformByIdentifier } from '../../platforms';
import PrePublishErrorResolver from './PrePublishErrorResolver';
import EditableSection from './debounceable_edit_box';
import WordpressCategories from './wordpress_categories';
import StringUtils from 'utils/string';

export default function WordPressOptionsField(props) {

    const dispatch = useDispatch();
    const workspace = useSelector(getActiveWorkSpace)
    const updatablePost = useSelector(getEditablePost);
    const selectedPlatforms = useSelector(getSelectedPlatforms);
    const wordpressPostTags = useSelector(getWordpressPostTags);
    const wordpressFeaturedImageThumbnail = useSelector(getWordpressFeaturedImageThumbnail);
    const postTitle = useSelector(getWordPressTitle);
    const wordPressSlug = useSelector(getWordpressSlug);
    const wordpressCategory = useSelector(getWordpressCategories);
    const wordpressFormat = useSelector(getWordpressFormat);
    const wordpressPlatform = findPlatformByIdentifier(PlatformIdentifier.WORDPRESS);
    const [openTagView, setOpenTagView] = useState(false);
    const [wordpressInitialSlug, setWordpressInitialSlug] = useState(null);
    const [wordpressErrorMessage, setWordPressErrorMessage] = useState(props.wordpressErrorMessage);
    const [wordpressPasswordPost, setIsWordpressPasswordPost] = useState(false);
    const [customizeSlug, setCustomizeSlug] = useState(false);
    const [categories, setCategories] = useState({});

    const [postCategory, setPostCategory] = useState({});
    const [wordpressCategoriesIdentifiers, setWordpressCategoriesIdentifiers] = useState([]);
    const [openCategoryView, setOpenCategoryView] = useState(false);
    const [openCategoryField, setOpenCategoryField] = useState(false);
    const [wordpressTag, setWordpressTag] = useState([]);
    const [selectedWordpressCategory, setSelectedWordpressCategory] = useState([]);
    const [permalink, setPermalink] = useState(null);

    const handleWordpressCategoryExtractions = () => {
        let newCategories = [];
        let siteUrls = [];
        selectedPlatforms.forEach((platform) => {
            if (platform.identifier === PlatformIdentifier.WORDPRESS) {
                platform.accounts.forEach((account) => {
                    for (let index = 0; index < account?.connected_pages_and_groups?.length; index++) {
                        const element = account?.connected_pages_and_groups[index];
                        let category = {
                            label: element.name || 'Wordpress Site',
                            siteUrl: element.siteUrl,
                        }
                        siteUrls.push(element.siteUrl);
                        newCategories.push(category);
                    }
                })
            }
        })
        setWordpressCategoriesIdentifiers(newCategories);
    }

    useEffect(() => {
        handleWordpressCategoryExtractions();
    }, []);


    function get_url_extension(url) {
        try{
            return url.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
        } catch(e){
            return false
        }
    }

    const initialSetupForUpdatablePost = () => {
        const wordpressFeaturedImage = updatablePost['data']['wordpressFeaturedImageThumbnail'];
        const wordpressTitle = updatablePost['data']['wordpressTitle'];
        const extensions = get_url_extension(wordpressFeaturedImage)
        let newObject = {
            extension: extensions ?? 'jpg',
            is_video: false,
            name: wordpressFeaturedImage,
            thumbnail: true,
            thumbnail_for_wordpress: true,
            uploaded: true,
            url: wordpressFeaturedImage
        }
        dispatch(setWordpressFeaturedImageThumbnail(newObject));
        dispatch(setWordpressTitle(wordpressTitle));
    }

    useEffect(() => {
        if (updatablePost) {
            initialSetupForUpdatablePost();
        }
    }, [])


    useEffect(() => {
        setWordPressErrorMessage(props.wordpressErrorMessage);
    }, [props.wordpressErrorMessage]);

    useEffect(() => {
        if (!wordpressCategoriesIdentifiers.length) return
        setCategories({});
        for (let index = 0; index < wordpressCategoriesIdentifiers.length; index++) {
            const element = wordpressCategoriesIdentifiers[index];
            if (element?.siteUrl.includes("wordpress.com")) {
                setCategories((prevCategory) => {
                    prevCategory[element?.label] = [];
                    return { ...prevCategory }
                })
            }
            APIService.getWordpressPostCategories(workspace?._id, element?.siteUrl, (response, error) => {
                if (response) {
                    let data = response['data'];
                    if (data.length > 0) {
                        setCategories((prevCategory) => {
                            prevCategory[element?.label] = data;
                            prevCategory['Techcrunch'] = data;
                            return { ...prevCategory }
                        })
                    }
                }
            });
        }
    }, [wordpressCategoriesIdentifiers]);

    const handleAddition = tag => {
        setWordpressTag([...wordpressTag, tag]);
    }

    const handleDelete = i => {
        let newTags = wordpressTag.filter((tags, index) => index !== i);
        setWordpressTag(newTags);
    }

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = wordpressPostTags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        dispatch(setWordpressPostTags(newTags));
    }

    const KeyCodes = {
        comma: 188,
        enter: 13
    };
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const wordpressFormatOptions = [
        'Standard',
        'Aside', 'Chat',
        'Gallery',
        'Link',
        'Image',
        'Quote',
        'Status',
        'Video',
        'Audio'
    ];

    useEffect(() => {
        let getTags = wordpressTag.map(x => x.text);
        dispatch(setWordpressPostTags([getTags][0]))
    }, [wordpressTag]);

    useEffect(() => {
        let getCategory = selectedWordpressCategory.map(x => x.text);
        dispatch(setWordpressCategories([getCategory][0]));
    }, [selectedWordpressCategory]);

    return (
        <Card className="mb-3">
            <FalconCardHeader
                title={"For WordPress"}
                endEl={
                    <div
                        style={{
                            width: 20,
                            height: 20,
                            borderRadius: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: wordpressPlatform.color
                        }}>
                        <FontAwesomeIcon
                            icon={wordpressPlatform.icon}
                            style={{
                                color: 'white',
                                height: 12,
                                width: 12
                            }} />
                    </div>
                } />
            <Card.Body className='bg-light'>
                <Flex direction={'column'} className={'gap-3'}>
                    <Col>
                        <EditableSection
                            title={"Title"}
                            placeholder={"Enter title for WordPress"}
                            style={{
                                width: '100%'
                            }}
                            value={postTitle}
                            onChange={(value) => {
                                if (!value) {
                                    value = " ";
                                }
                                let titleValue = value.toLowerCase();
                                let blogSlug = titleValue.split(/ /g).join("_");
                                setWordpressInitialSlug(blogSlug);
                                setPermalink(blogSlug);
                                dispatch(setWordpressTitle(value));
                                dispatch(setWordpressSlug(blogSlug));
                            }}
                        />
                        {
                            wordpressErrorMessage &&
                            <PrePublishErrorResolver error={wordpressErrorMessage} />
                        }
                    </Col>

                    <Flex
                        justifyContent={"space-around"} alignItems={'center'}>
                        <Col>
                            {wordPressSlug && (
                                <>
                                    <p style={{ fontSize: '13px' }}>
                                        <span style={{ fontWeight: 700 }}>Generated Permalink: </span>
                                        <span>{wordpressInitialSlug}</span>
                                    </p>

                                </>
                            )}
                        </Col>

                        <Form.Check
                            type={'checkbox'}
                            id={'custom-slug'}
                            label={'Customize Permalink?'}
                            onChange={(e) => {
                                let value = e.target.checked;
                                setCustomizeSlug(value);
                            }}
                        />
                    </Flex>

                    <Flex
                        justifyContent="center"
                        alignItems={'center'}
                        className="gap-3"
                        style={{
                            fontSize: "13px"
                        }}>
                        <span>Permalink preview:</span>
                        <a href="#!">{`https://www.mysite.com/${permalink}`}</a>
                    </Flex>

                    {customizeSlug &&
                        <Col>
                            <EditableSection
                                title={"Permalink"}
                                placeholder={'Enter Wordpress Permalink'}
                                value={wordPressSlug}
                                onChange={(value) => {
                                    if (!value) {
                                        value = " ";
                                    }
                                    let titleValue = value.toLowerCase();
                                    let postSlug = titleValue.split(/ /g).join("_");
                                    setPermalink(postSlug);
                                    dispatch(setWordpressSlug(postSlug));
                                }}
                            />
                        </Col>
                    }

                    <Row>
                        <Col>
                            <h5>Categories</h5>
                            {
                                Object.entries(categories).length > 0 &&
                                Object.entries(categories).map((category) => {
                                    return (
                                        <WordpressCategories
                                            title={category[0]}
                                            category={postCategory}
                                            setCategory={setPostCategory}
                                            categories={category[1]}
                                            openCategoryField={openCategoryField}
                                            openCategoryView={openCategoryView}
                                            setOpenCategoryView={setOpenCategoryView}
                                            setOpenCategoryField={setOpenCategoryField}
                                            selectedCategory={selectedWordpressCategory}
                                            setSelectedCategory={setSelectedWordpressCategory}
                                        />
                                    )
                                })
                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Post Format</Form.Label>
                                <Form.Select
                                    value={wordpressFormat}
                                    placeholder={'Select Format for Wordpress Post'}
                                    onChange={(e) => {
                                        dispatch(setWordpressFormat(e.target.value));
                                    }}

                                >
                                    {
                                        wordpressFormatOptions.map((x) => {
                                            return (<option value={x.toLowerCase()}>{x}</option>)
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        <Col>
                            <Flex direction={'column'}>
                                <h6>Tags
                                </h6>
                                {
                                    !openTagView &&
                                    <Button
                                        variant='falcon-light'
                                        size='sm'
                                        onClick={() => {
                                            setOpenTagView(!openTagView);
                                        }}
                                        style={{
                                            fontSize: 13,
                                            border: '1px dashed gray'
                                        }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: 2,
                                                alignItems: 'center'
                                            }}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            <span>New Tag</span>
                                        </div>
                                    </Button>
                                }
                                {
                                    openTagView &&
                                    <Flex alignItems={'center'} className={'gap-3'}>
                                        <ReactTags
                                            placeholder='Type and press enter or comma to add a new tag'
                                            tags={wordpressTag}
                                            delimiters={delimiters}
                                            handleDelete={handleDelete}
                                            handleAddition={handleAddition}
                                            handleDrag={handleDrag}
                                            inputFieldPosition="bottom"
                                            autocomplete
                                        />
                                        <FontAwesomeIcon
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            icon={faClose}
                                            onClick={() => {
                                                setOpenTagView(!openTagView);
                                            }}
                                        />
                                    </Flex>
                                }
                            </Flex>
                        </Col>
                    </Row>

                    <Col>
                        <Form.Check
                            type={'checkbox'}
                            id={'password-post'}
                            label={'Make post private?'}
                            onChange={(e) => {
                                let value = e.target.checked;
                                setIsWordpressPasswordPost(value);
                            }}
                        />
                        {
                            wordpressPasswordPost && (
                                <EditableSection
                                    value={''}
                                    title={"Wordpress Password Post"}
                                    placeholder={"Enter Password for Post"}
                                    onChange={(value) => {
                                        if (!value) {
                                            value = " ";
                                        }
                                        dispatch(setWordpressPostPassword(value));
                                    }}
                                />
                            )
                        }
                    </Col>

                    <div
                        className='mt-3 mb-3'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <MultimediaSelectionDropdown
                            dropDownAnchor={
                                <span id='global-photo-span'>
                                    <Button
                                        style={{ width: '100%' }}
                                        variant={'falcon-light'}
                                        id='wordpress-featured-image-select'>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: 5
                                            }}>
                                            <FontAwesomeIcon
                                                icon={faImage}
                                            />
                                            <span className='fs--1'>Select featured image for Blog post
                                            </span>
                                        </div>
                                    </Button>
                                </span>
                            }
                            initiatorId={"wordpress-featured-image-select"}
                            prompt={"Select Cover Image From"}
                            fileType={"image"}
                            multiple={false}
                            externalFilePickerTitle={"Enter Cover Image Location"}
                            externalPickerPromptLabel={"Cover Image"}
                            platformIdentifier={PlatformIdentifier.WORDPRESS}
                            thumbnail={true}
                        />
                    </div>
                    {
                        wordpressFeaturedImageThumbnail &&
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative'
                            }}>
                            <h6
                                type={'h5'}
                                style={{
                                    fontSize: 15
                                }}>Selected Wordpress Featured Image
                            </h6>
                            <img
                                onClick={() => {
                                    dispatch(setThumbnailPreviewData(wordpressFeaturedImageThumbnail));
                                    dispatch(setCanOpenThumbnailPreviewDialog(true));
                                }}
                                src={StringUtils.isStringAFirebaseLink(wordpressFeaturedImageThumbnail) ? wordpressFeaturedImageThumbnail : wordpressFeaturedImageThumbnail['url']}
                                height={200}
                                width={'100%'}
                                alt={"Wordpress Video Thumbnail"}
                                style={{
                                    borderRadius: 5,
                                    objectFit: 'cover'
                                }}
                            />
                            <Button
                                variant="text"
                                size='sm'
                                style={{
                                    backgroundColor: 'rgba(0,0,0,0.9)',
                                    color: 'white',
                                    position: 'absolute',
                                    bottom: 10,
                                    left: 0,
                                    right: 0,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    width: 100
                                }}
                                onClick={() => {
                                    dispatch(setWordpressFeaturedImageThumbnail(null));
                                }}
                            >
                                Remove
                            </Button>
                        </div>
                    }

                </Flex>
            </Card.Body>
        </Card>
    );
}