export const ltdPricingData = [
  {
    id: 0,
    title: 'One-Time Payment',
    MonthlySub: "810743",
    AnnualSub: "810743",
    subTitle:
      '30 days money back guarantee',
    plan: "LTD69",
    price: {
      Month: 69,
      Year: 69
    },
    currencySign: '$',
    url: '/ltd',
    buttonText: 'Buy Now',
    isFeatured: false,
    periodical: true,
    // featureTitle: '',
    features: [
      { id: 1, title: '5 Workspaces' },
      { id: 2, title: '30 Social Accounts' },
      { id: 3, title: 'Unlimited Posts' },
      // { id: 4, title: '1 Team Member' },
      { id: 5, title: '20k AI characters/month' },
      { id: 6, title: 'No bulk post' },
      { id: 7, title: 'No access to automation' },
      { id: 8, title: 'No multiple approval' },

    ]
  },
];

export const ltdUpgradePricingData = [
  {
    id: 1,
    plan: 'LTD UPGRADE',
    title: 'LTD UPGRADE',
    quantity: 1,
    subTitle: '30 days money back guarantee',
    price: {
      Month: 999,
      Year: 999
    },
    currencySign: '$',
    url: '/ltd',
    buttonText: 'Buy Now',
    isFeatured: false,
    periodical: true,
    // featureTitle: '',
    features: [
      { id: 1, title: '200 Workspaces' },
      { id: 2, title: 'Unlimited Social Accounts' },
      { id: 3, title: 'Unlimited Posts' },
      // { id: 4, title: 'Access to automations' },
      { id: 4, title: '100k AI characters/month' },
      { id: 5, title: 'Bulk post' },
      { id: 6, title: 'Multiple approval' }
    ],
    payment_for_ltd_one_time_upgrade: true
  },
];

export const postlyLTDUpgradePricing = {
  plan: 'SIX_MONTHS_OLD_FREE_USER_UPGRADE',
  title: 'Postly LTD',
  MonthlySub: "810743",
  AnnualSub: "810743",
  "Workspace": 5,
  "Name": "Postly LTD",
  "Key": "SIX_MONTHS_OLD_FREE_USER_UPGRADE",
  "Users": "Unlimited",
  "SocialAccounts": 30,
  "ScheduledPosts": "Unlimited",
  "Price": {
    "Monthly": "$99/month",
    "Yearly": "$99/year"
  },
  "MetaData": "Unlimited Scheduled Posts Monthly",
  "Upgradable": false,
  "Upgraded": true,
  "Priority": 1,
  "LTD": true,
  "AI_Writer_Words": 4000,
  "AI_Writer_Character_Validity": "1month",
  "ContentLimits": {
    "facebookpage": 24,
    "facebookgroup": 20,
    "facebookgrouplinks": 7,
    "instagram": 20,
    "tiktok": 20,
    "twitter": 300,
    "linked_in_profile": 12,
    "linked_in_page": 18,
    "pinterest": 50,
    "google_my_business": 10,
    "youtube": 10,
    "telegram": 10
  },
  "revision": 2
}

export const ltdUpgradePricing = [{
  plan: 'LTD UPGRADE',
  title: 'LTD UPGRADE',
  MonthlySub: "790756",
  AnnualSub: "790756",
  price: '$999',
  "Workspace": 200,
  "Name": "LTD UPGRADE",
  "Key": "LTD UPGRADE",
  "Users": "Unlimited",
  "SocialAccounts": "Unlimited",
  "ScheduledPosts": "Unlimited",
  "Price": {
    "Monthly": "$497/month",
    "Yearly": "$497/year"
  },
  "MetaData": "Unlimited Scheduled Posts Monthly",
  "Upgradable": false,
  "Upgraded": true,
  "Priority": 1,
  "LTD": true,
  "AI_Writer_Words": 4000,
  "AI_Writer_Character_Validity": "1month",
  "ContentLimits": {
    "facebookpage": 24,
    "facebookgroup": 20,
    "facebookgrouplinks": 7,
    "instagram": 20,
    "tiktok": 20,
    "twitter": 300,
    "linked_in_profile": 12,
    "linked_in_page": 18,
    "pinterest": 50,
    "google_my_business": 10,
    "youtube": 10,
    "telegram": 10
  },
  "revision": 2
},];

export const imageEditorBackgroundRemovalCreditPricings = [
  {
    plan: "Background Remover 10 Credits",
    product: "801708",
    price: "$9",
    credits: "10"
  },
  {
    plan: "Background Remover 50 Credits",
    product: "801709",
    price: "$40",
    credits: "50"
  },
  {
    plan: "Background Remover 100 Credits",
    product: "801710",
    price: "$70",
    credits: "100"
  },
  {
    plan: "Background Remover 200 Credits",
    product: "801711",
    price: "$120",
    credits: "200"
  },
  {
    plan: "Background Remover 500 Credits",
    product: "801712",
    price: "$250",
    credits: "500"
  },
  {
    plan: "Background Remover 1000 Credits",
    product: "801713",
    price: "$400",
    credits: "1000"
  }
]

export const addonsPricing = [
  {
    id: 1,
    title: 'Unlimited Postly AI',
    plan: 'Unlimited AI Writer',
    subTitle: 'Unlimited',
    price: {
      Month: 29,
      Year: 276
    },
    url: '/ai-writer',
    buttonText: 'Subscribe',
    isFeatured: false,
  }
]