import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { initFacebookSdk } from './utils/platform_utils';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import './i18n';

initFacebookSdk().then(startApp);
  
function startApp() {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <Main>
            <App />
          </Main>
        </Provider>
      </React.StrictMode>,
      document.getElementById('main')
    );
}
