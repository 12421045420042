import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';

const AddMoreAccountsToChat = ({ connectAccounts }) => (
  <Flex style={{ position: "sticky", bottom: "0" }} className="contacts-search-wrapper" onSubmit={e => e.preventDefault()}>
    <Button
      onClick={() => connectAccounts()}
      variant={'transparent'}
      size="sm"
      type="submit"
    >
      <Flex className={'gap-2'} alignItems={'center'}>
        <FontAwesomeIcon icon="plus" className="fs--1" />
        <span className='fs--1'>Connect more Pages/Accounts</span>
      </Flex>
    </Button>
  </Flex>
);

export default AddMoreAccountsToChat;
