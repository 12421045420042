/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import APIService from '../http/api_service';
import { PlatformIdentifier } from '../constants';
import { format } from 'date-fns';
import { getActiveWorkspaceFromLocalStorage } from './workspace_utils';
import { canLoggedInUserBeDemo, getLoggedInUserFromLocalStorage } from './user_utils';
import { findPlatformByIdentifier } from 'components/app/platforms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { munchTarget } from 'components/app/social/feed/audience';
import { setMunchedPostTargetIds, setSelectedPlatforms } from 'redux/slices/postslice';

// const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
const facebookAppId = '442981013391123';

export function initFacebookSdk() {
    return new Promise(resolve => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: facebookAppId,
                cookie: true,
                xfbml: true,
                version: 'v19.0'
            });
            window.FB.getLoginStatus(({ _authResponse }) => {
                localStorage.removeItem("fb_down");
                resolve();
            }, true);
        };
        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
            js.onerror = () => {
                localStorage.setItem("fb_down", true);
                resolve();
            }
        }(document, 'script', 'facebook-jssdk'));
    });
}

export const platformSpecs = {
    [PlatformIdentifier.FACEBOOK]: {
        name: "Facebook",
        image: {
            minWidth: 600,
            minHeight: 315,
        },
        media: {
            maxNumber: 10,
        }
    },
    [PlatformIdentifier.INSTAGRAM]: {
        name: "Instagram",
        image: {
            minWidth: 1080,
            minHeight: 566,
            format:'image/jpg'
        },
        media: {
            maxNumber: 10,
        },
        // video: {
        //     disallowed: true,
        // }
    },
    [PlatformIdentifier.GOOGLE_MY_BUSINESS]: {
        name: "Google My Business",
        image: {
            minWidth: 250,
            minHeight: 250,
            maxNumber: 1
        },
        video: {
            disallowed: true,
        }
    },
    [PlatformIdentifier.LINKED_IN]: {
        name: "LinkedIn",
        image: {
            minWidth: 1080,
            minHeight: 1080,
        },
        media: {
            maxNumber: 10,
        }
    },
    [PlatformIdentifier.TWITTER]: {
        name: "Twitter",
        image: {
            minWidth: 600,
            minHeight: 335,
            maxWidth: 8192,
            maxHeight: 8192,
        }
    },
    [PlatformIdentifier.PINTEREST]: {
        name: "Pinterest",
        media: {
            maxNumber: 1,
        }
    },
}

export const facebookLoginScopes = [
    "email",
    "public_profile",
    "pages_read_engagement",
    "pages_manage_posts",
    "pages_read_user_content",
    "pages_show_list",
    "publish_video",
    "business_management",
    "read_insights",
    "pages_messaging",
    "pages_manage_metadata"
];

export const instagramLogInScopes = [
    "instagram_basic",
    "instagram_content_publish",
    "business_management",
    "pages_show_list",
    "publish_video",
    "pages_messaging",
    "pages_manage_metadata",
    "instagram_manage_insights",
    "instagram_manage_comments",
    // "instagram_manage_messages"
]

export async function connectFacebook(workSpace, platformName = "Facebook", identifier = PlatformIdentifier.FACEBOOK, cb) {
    if (localStorage.getItem("fb_down")) {
        cb(null, `Sorry, but Facebook is currently down. Please check back later`);
        return
    }
    let loggedInUser = getLoggedInUserFromLocalStorage();
    window.FB.login(function (response) {
        let { status, authResponse } = response;
        if (!authResponse) {
            cb(null, `${platformName} Platform integration denied`);
        } else {
            if (status === "connected") {
                let userId = authResponse.userID;
                let activeWorkSpace = getActiveWorkspaceFromLocalStorage();
                let activeWorkSpaceId = activeWorkSpace['_id'];
                if (activeWorkSpaceId) {
                    APIService.addNewWorkSpacePlatform(activeWorkSpaceId, {
                        name: platformName,
                        identifier: identifier,
                        access_token: authResponse.accessToken,
                        user_id: userId,
                        permissions: facebookLoginScopes
                    }, (apiResponse, error) => {
                        if (error) {
                            cb(null, error);
                            return;
                        }
                        let message = apiResponse['message'];
                        cb(message, null);
                    });
                } else {
                    cb(null, `Oops! something went wrong while integrating ${platformName}`);
                }
            } else {
                cb(null, `${platformName} Platform integration failed`);
            }
        }
    }, {
        scope: [facebookLoginScopes, canLoggedInUserBeDemo(loggedInUser) ? ["read_insights"] : []].flat().join(","),
        prompt: 'consent',
        return_scopes: true,
    });
}

export async function connectInstagram(workSpace, platformName = "Instagram", identifier = PlatformIdentifier.INSTAGRAM, cb) {
    if (localStorage.getItem("fb_down")) {
        cb(null, `Sorry, but Instagram is currently down. Please check back later`);
        return
    }
    let loggedInUser = getLoggedInUserFromLocalStorage();
    window.FB.login(function (response) {
        let { status, authResponse } = response;
        if (!authResponse) {
            cb(null, `${platformName} Platform integration denied`);
        } else {
            if (status === "connected") {
                let userId = authResponse.userID;
                let activeWorkSpace = getActiveWorkspaceFromLocalStorage();
                let activeWorkSpaceId = activeWorkSpace['_id'];
                if (activeWorkSpaceId) {
                    APIService.addNewWorkSpacePlatform(activeWorkSpaceId, {
                        name: platformName,
                        identifier: identifier,
                        access_token: authResponse.accessToken,
                        user_id: userId,
                        permissions: instagramLogInScopes
                    }, (apiResponse, error) => {
                        if (error) {
                            cb(null, error);
                            return;
                        }
                        let message = apiResponse['message'];
                        cb(message, null);
                    });
                } else {
                    cb(null, `Oops! something went wrong while integrating ${platformName}`);
                }
            } else {
                cb(null, `${platformName} Platform integration failed`);
            }
        }
    }, {
        scope: [instagramLogInScopes, canLoggedInUserBeDemo(loggedInUser) ? ["instagram_manage_insights"] : []].flat().join(","),
        prompt: 'consent',
        return_scopes: true,
    });
}

export async function disconnectFacebook(cb) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        cb();
    } else {
        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                window.FB.logout(function (response) {
                    cb();
                });
            } else {
                cb();
            }
        }, true);
    }
}

export async function connectTwitter(workSpace, identifider, options = null, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Twitter",
        identifier: identifider,
        user_id: "adadad",
        options
    }, cb);
}

export async function connectYoutube(workSpace, identifider, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "YouTube",
        identifier: identifider,
        user_id: "adadad"
    }, cb);
}

export async function connectTikTok(workSpace, identifider, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "TikTok",
        identifier: identifider,
        user_id: "adadad"
    }, cb);
}

export async function connectLinkedIn(workSpace, identifider, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "LinkedIn",
        identifier: identifider,
        user_id: "adadad"
    }, cb);
}
export async function connectThreads(workSpace, identifider, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Threads",
        identifier: identifider,
        user_id: "adadad"
    }, cb);
}

export async function connectPinterest(workSpace, identifider, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Pinterest",
        identifier: identifider,
        user_id: "adadad"
    }, cb);
}

export async function connectGMB(workSpace, identifier, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Google My Business",
        identifier: identifier,
        user_id: "adadad"
    }, cb);
}

export async function connectReddit(workSpace, identifier, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Reddit",
        identifier: identifier,
        user_id: "adadad"
    }, cb);
}
export async function connectWordpress(workSpace, identifier, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "WordPress",
        identifier: identifier,
        user_id: "adadad"
    }, cb);
}

export async function connectTelegram(workSpace, user_id, username, user_photo, identifier, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Telegram",
        identifier: identifier,
        user_id,
        username,
        user_photo
    }, cb);
}

export async function connectBlogger(workSpace, identifier, cb) {
    APIService.addNewWorkSpacePlatform(workSpace['_id'], {
        name: "Blogger",
        identifier: identifier,
        user_id: "adadad"
    }, cb);
}

export async function connectRSSFeed(workSpace, identifier, options = null, cb) {
    APIService.addNewRSSPlatform(workSpace['_id'], {
        name: 'RSS Feed',
        identifier: identifier,
        user_id: 'adadad',
        options
    }, cb);
}

const getPostLink = (successfulPostData, target) => {
    if (!successfulPostData) {
        return {
            status: false,
            postLink: null
        }
    }
    const result = Object.keys(successfulPostData).filter((key, index) => {
        const value = successfulPostData[key];
        if (value.target === target) {
            return value;
        }
    });
    const targetData = successfulPostData[result[0]];
    if (target === PlatformIdentifier.LINKED_IN) {
        const publishedData = targetData?.published_post_response_data
        const postLink = `https://www.linkedin.com/feed/update/${publishedData?.id}/`;
        return {
            status: true,
            postLink
        }
    }
    if (target === PlatformIdentifier.REDDIT) {
        const postLink = targetData?.published_post_response_data?.url
        return {
            status: true,
            postLink
        }
    }
    if (target === PlatformIdentifier.PINTEREST) {
        const publishedData = targetData?.published_post_response_data
        const postLink = `https://www.pinterest.com/pin/${publishedData}`;
        return {
            status: true,
            postLink
        }
    }
}

const getPostApprover = (approvals) => {
    if (!approvals) return null;
    const keys = Object.keys(approvals);
    let firstApproval = approvals[keys[0]];
    if (firstApproval) {
        return firstApproval;
    }
}

const getWeekdayFromNumber = (number) => {
    switch (number) {
        case 1:
            return 'Monday'
        case 2:
            return 'Tuesday'
        case 3:
            return 'Wednesday'
        case 4:
            return 'Thursday'
        case 5:
            return 'Friday'
        case 6:
            return 'Saturday'
        case 7:
            return 'Sunday'
        default:
            return null
    }
}

const getOrdinalPosition = (number) => {
    const lastDigit = number % 10;
    switch (lastDigit) {
        case 1:
            return `${number}st`
        case 2:
            return `${number}nd`
        case 3:
            return `${number}rd`
        default:
            return `${number}th`
    }
}

const getPostRecurringOptionStatement = (recurringOption) => {
    if (!recurringOption) return `This is not a recurring post.`
    if (recurringOption?.type === 'custom') {
        if (recurringOption?.data?.end?.type === 'never') {
            return 'Recurring post will be published forever. Starting from ' + format(new Date(recurringOption?.data?.start), 'dd MMM yyyy') + '.';
        }
        if (recurringOption?.data?.end?.type === 'on') {
            return `Recurring post will be published until ${format(new Date(recurringOption?.data?.end?.date), 'dd MMM yyyy')}. Starting from ${format(new Date(recurringOption?.data?.start), 'dd MMM yyyy')}.`
        }
        if (recurringOption?.data?.end?.type === 'after') {
            return `Recurring post will be published ${recurringOption?.data?.end?.occurrences} times. Starting from ${format(new Date(recurringOption?.data?.start), 'dd MMM yyyy')}.`
        }
    }
    if (recurringOption?.type === 'daily') {
        return `Recurring post will be published daily.`
    }
    if (recurringOption?.type === 'weekly_on_(weekday)') {
        return `Recurring post will be published weekly on ${getWeekdayFromNumber(recurringOption?.data?.weekday)}.`
    }
    if (recurringOption?.type === 'monthly_on_the_(ordinal)_(weekday)') {
        return `Recurring post will be published monthly on the ${getOrdinalPosition(recurringOption?.data?.ordinal)} ${getWeekdayFromNumber(recurringOption?.data?.weekday)}.`
    }
    if (recurringOption?.type === 'annually_on_(month)_(date_of_month)') {
        const month = format(new Date(recurringOption?.data?.month), 'MMMM');
        const dateOfMonth = recurringOption?.data?.date_of_month;
        return `Recurring post will be published annually on ${getOrdinalPosition(dateOfMonth)} ${month}.`;
    }
    if (recurringOption?.type === 'every_weekday_(week_days)') {
        const weekdays = recurringOption?.data?.week_days?.map(day => getWeekdayFromNumber(day)).join(', ');
        return `Recurring post will be published every weekday (${weekdays}).`;
    }
}

export const displayPlatformIcon = (identifier) => {
    let platform = findPlatformByIdentifier(identifier);
    if (platform?.icon) {
        return <FontAwesomeIcon
            icon={platform?.icon}
            style={{
                color: platform.color,
                // color: activePlatformTab === identifier ? "white" : platform.color,
            }}
        />
    } else {
        return <img
            src={platform?.svg}
            alt={platform?.name}
            style={{
                width: 20,
                height: 20,
                objectFit: "contain",
                color: platform.color,
            }}
        />
    }
}


export function getPlatformMetaData(target, postData, post) {
    if (!target && !post) { //it is for general metadata
        let metaData = {};
        let prompted_missing_text_alert = postData['prompted_missing_text_alert'];
        let user_ignored_missing_text_alert = postData['user_ignored_missing_text_alert'];
        if (prompted_missing_text_alert) {
            metaData['prompted_missing_text_alert'] = prompted_missing_text_alert;
            metaData['user_ignored_missing_text_alert'] = user_ignored_missing_text_alert;
            return metaData;
        } else {
            return null
        }
    }

    let times_edited = postData['edited'] || 0;
    let time_to_post = postData['time_to_post'];
    let possibleRedditKeys = ['reddit_title'];
    let possibleInstagramKeys = ['publishIGVideoAsStories', 'publishIGVideoAsReel', 'publishIGImageAsStories', 'firstComment'];
    let possibleYoutubeKeys = ['notifyYouTubeSubscribers', 'youTubeVideoCategory', 'youTubeVideoEmbeddable', 'youTubeVideoLicense', 'youTubeVideoMadeForKids', 'youTubeVideoPrivacy', 'youTubeVideoTitle', 'youTubeVideoTags'];
    let possibleGmbDataKeys = ["gmbCTAActionType", "gmbCTAActionUrl", "gmbCouponCode", "gmbCouponRedeemURL", "gmbEventTitle", "gmbPostType", "gmbSummary", "gmbTermsAndConditions, gmbEventSchedule"]
    let possiblePinterestKeys = ['pinterestAltText', 'pinterestDestinationLink', 'pinterestTitle']
    let possibleTiktokKeys = ['tiktokTitle', 'tiktokAllowComment', 'tiktokAllowDuet', 'tiktokAllowStitch', 'tiktokViewSetting']
    let possibleFacebookKeys = ['isFacebookPostCarousel', 'facebookCarouselLinks'];
    let createdBy = post['created_by'];
    let metaData = {};
    metaData['created_by'] = `${createdBy?.email} (${createdBy?.first_name && createdBy?.first_name} ${createdBy?.last_name && createdBy?.last_name})`;
    metaData['number_of_times_post_was_edited#times'] = times_edited;
    const postApprover = getPostApprover(postData['approvals']);
    if (postApprover) {
        metaData['post_approver'] = postApprover;
    }
    if (post?.can_display_under_recurring && postData?.repeat_option) {
        metaData['recurring'] = getPostRecurringOptionStatement(postData?.repeat_option);
    }


    if (target === PlatformIdentifier.TWITTER) {
        metaData['PostLink'] = 'NOT AVAILABLE'
        metaData['published_date'] = time_to_post;
        return metaData;
    }
    if (target === PlatformIdentifier.TIKTOK) {
        possibleTiktokKeys.forEach(key => {
            if (postData[key]) {
                metaData[key] = postData[key];
            }
        })
        metaData['PostLink'] = 'NOT AVAILABLE'
        metaData['published_date'] = time_to_post;
        return metaData;
    }
    if (target === PlatformIdentifier.LINKED_IN) {
        let postLinkRequest = getPostLink(postData?.successfully_published_to, PlatformIdentifier.LINKED_IN);
        if (postLinkRequest.status) {
            metaData['PostLink'] = postLinkRequest.postLink;
        }
        metaData['published_date'] = time_to_post;

        return metaData;
    }

    if (target === PlatformIdentifier.REDDIT) {
        let postLinkRequest = getPostLink(postData?.successfully_published_to, PlatformIdentifier.REDDIT);
        if (postLinkRequest.status) {
            metaData['PostLink'] = postLinkRequest.postLink;
        }
        possibleRedditKeys.forEach(key => {
            if (postData[key]) {
                metaData[key] = postData[key];
            }
        })
        metaData['published_date'] = time_to_post;
        return metaData;
    }
    if (target === PlatformIdentifier.INSTAGRAM) {
        possibleInstagramKeys.forEach(key => {
            if (key === 'firstComment') {
                metaData['firstCommentText'] = postData[key]?.firstCommentText;
            } else if (postData[key]) {
                metaData[key] = postData[key];
            }
        })
        metaData['PostLink'] = 'NOT AVAILABLE'
        metaData['published_date'] = time_to_post;
        return metaData;
    }
    if (target === PlatformIdentifier.YOUTUBE) {
        possibleYoutubeKeys.forEach(key => {
            if (key === 'youTubeVideoTags') {
                metaData['youTubeVideoTags'] = postData[key]?.map(tag => tag.text?.toLowerCase()).join(', ')
            } else if (postData[key]) {
                metaData[key] = postData[key];
            }
        })
        metaData['published_date'] = time_to_post;
        return metaData;
    }
    if (target === PlatformIdentifier.FACEBOOK) {
        possibleFacebookKeys.forEach(key => {
            metaData[key] = postData[key];
        })
        metaData['PostLink'] = 'NOT AVAILABLE'
        metaData['published_date'] = time_to_post;
        return metaData;
    }
    if (target === PlatformIdentifier.GOOGLE_MY_BUSINESS) {
        possibleGmbDataKeys.forEach(key => {
            if (key === 'gmbEventSchedule') {
                metaData['start_time'] = format(new Date(postData?.gmb_data[key]?.startTime), 'dd MMM yyyy')
                metaData['end_time'] = format(new Date(postData?.gmb_data[key]?.endTime), 'dd MMM yyyy');
            }
            else if (postData?.gmb_data) {
                if (postData?.gmb_data[key]) {
                    metaData[key] = postData?.gmb_data[key];
                }
            }
        })
        metaData['PostLink'] = 'NOT AVAILABLE'
        metaData['published_date'] = time_to_post;
        return metaData;
    }
    if (target === PlatformIdentifier.PINTEREST) {
        let postLinkRequest = getPostLink(postData?.successfully_published_to, PlatformIdentifier.PINTEREST);
        if (postLinkRequest.status) {
            metaData['PostLink'] = postLinkRequest.postLink;
        }
        possiblePinterestKeys.forEach(key => {
            if (postData[key]) {
                metaData[key] = postData[key];
            }
        })
        metaData['published_date'] = time_to_post;
        return metaData;
    }
}

export function convertToTitleFormat(input, end = false) {
    // Convert underscore to camel case;
    // check if input has # char in it
    let usableText = input
    if (input.includes('#')) {
        let splitApart = input.split('#');
        usableText = end ? splitApart[1] : splitApart[0];
    }
    const camelCase = usableText.replace(/_([a-z])/g, function (match, letter) {
        return letter.toUpperCase();
    });
    // Insert space before capital letters
    const titleFormat = camelCase.replace(/([A-Z])/g, ' $1');

    // Capitalize the first letter
    const capitalized = titleFormat.charAt(0).toUpperCase() + titleFormat.slice(1);
    if (end) return capitalized?.toLowerCase()
    return capitalized;
}

export const checkPlatformVideos = async (file, identifier, extras) => {
    if (!file) return {
        valid: false,
        error: ""
    };
    if (identifier === PlatformIdentifier.THREADS) {
        const ACCEPTED_FORMATS = ['mp4', 'mov'];
        const MAX_FILE_SIZE_GB = 1;
        const MIN_DURATION_SECONDS = 1;
        const MAX_DURATION_SECONDS = 300; // 5 minutes in seconds
        const MIN_RESOLUTION_WIDTH = 256;
        const MIN_RESOLUTION_HEIGHT = 144;
        const MAX_RESOLUTION_WIDTH = 4096;
        const MAX_RESOLUTION_HEIGHT = 2304;

        // Check file format
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!ACCEPTED_FORMATS.includes(fileExtension)) {
            return { valid: false, error: 'Invalid Threads video format. Accepted formats: MP4, MOV' };
        }

        // Check file size
        const fileSizeGB = file.size / (1024 * 1024 * 1024);
        if (fileSizeGB > MAX_FILE_SIZE_GB) {
            return { valid: false, error: 'Video has been removed from Threads because file size exceeds 1 GB.' };
        }

        // Create a video element to get duration and resolution
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(file);

        return new Promise((resolve) => {
            videoElement.addEventListener('loadedmetadata', () => {
                // Check video duration
                const duration = videoElement.duration;
                if (duration < MIN_DURATION_SECONDS || duration > MAX_DURATION_SECONDS) {
                    resolve({ valid: false, error: 'Video has been removed from Threads because duration must be between 3 seconds and 5 minutes.' });
                }
                // // Check video resolution
                // const videoWidth = videoElement.videoWidth;
                // const videoHeight = videoElement.videoHeight;
                // if (
                //     videoWidth < MIN_RESOLUTION_WIDTH ||
                //     videoHeight < MIN_RESOLUTION_HEIGHT ||
                //     videoWidth > MAX_RESOLUTION_WIDTH ||
                //     videoHeight > MAX_RESOLUTION_HEIGHT
                // ) {
                //     resolve({
                //         valid: false,
                //         error: 'Threads video resolution must be between 256x144 and 4096x2304 pixels.',
                //     });
                // }
                resolve({ valid: true });
            });
        });

    }
    if (identifier === PlatformIdentifier.LINKED_IN) {
        const ACCEPTED_FORMATS = ['mp4', 'asf', 'mpeg', 'mkv', 'h264', 'vp8', 'vp9', 'wmv2', 'wmv3'];
        const MAX_FILE_SIZE_GB = 1;
        const MIN_DURATION_SECONDS = 3;
        const MAX_DURATION_SECONDS = 600; // 10 minutes in seconds
        const MIN_RESOLUTION_WIDTH = 256;
        const MIN_RESOLUTION_HEIGHT = 144;
        const MAX_RESOLUTION_WIDTH = 4096;
        const MAX_RESOLUTION_HEIGHT = 2304;

        // Check file format
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!ACCEPTED_FORMATS.includes(fileExtension)) {
            return { valid: false, error: 'Invalid Linkedin video format. Accepted formats: MP4, ASF, MPEG-1 and MPEG-4, MKV, H264/AVC, VP8, VP9, WMV2, WMV3.' };
        }

        // Check file size
        const fileSizeGB = file.size / (1024 * 1024 * 1024);
        if (fileSizeGB > MAX_FILE_SIZE_GB) {
            return { valid: false, error: 'Linkedin video file size exceeds 5 GB.' };
        }

        // Create a video element to get duration and resolution
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(file);

        return new Promise((resolve) => {
            videoElement.addEventListener('loadedmetadata', () => {
                // Check video duration
                const duration = videoElement.duration;
                if (duration < MIN_DURATION_SECONDS || duration > MAX_DURATION_SECONDS) {
                    resolve({ valid: false, error: 'Linkedin video duration must be between 3 seconds and 10 minutes.' });
                }

                // Check video resolution
                const videoWidth = videoElement.videoWidth;
                const videoHeight = videoElement.videoHeight;
                if (
                    videoWidth < MIN_RESOLUTION_WIDTH ||
                    videoHeight < MIN_RESOLUTION_HEIGHT ||
                    videoWidth > MAX_RESOLUTION_WIDTH ||
                    videoHeight > MAX_RESOLUTION_HEIGHT
                ) {
                    resolve({
                        valid: false,
                        error: 'Linkedin video resolution must be between 256x144 and 4096x2304 pixels.',
                    });
                }

                resolve({ valid: true });
            });
        });

    }
    if (identifier === PlatformIdentifier.TIKTOK) {
        const ACCEPTED_FORMATS = ['mp4'];
        const MAX_FILE_SIZE_GB = 1;
        const MIN_DURATION_SECONDS = 5;
        const MAX_DURATION_SECONDS = 600; // 10 minutes in seconds

        // Check file format
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!ACCEPTED_FORMATS.includes(fileExtension)) {
            return { valid: false, error: 'Invalid Tiktok video format. Accepted formats: MP4, ASF, MPEG-1 and MPEG-4, MKV, H264/AVC, VP8, VP9, WMV2, WMV3.' };
        }

        // Check file size
        const fileSizeGB = file.size / (1024 * 1024 * 1024);
        if (fileSizeGB > MAX_FILE_SIZE_GB) {
            return { valid: false, error: `Tiktok video file size exceeds ${MAX_FILE_SIZE_GB} GB.` };
        }

        // Create a video element to get duration and resolution
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(file);

        return new Promise((resolve) => {
            videoElement.addEventListener('loadedmetadata', () => {
                // Check video duration
                const duration = videoElement.duration;
                if (duration < MIN_DURATION_SECONDS || duration > MAX_DURATION_SECONDS) {
                    resolve({ valid: false, error: 'Tiktok video duration must be between 3 seconds and 10 minutes.' });
                }

                resolve({ valid: true });
            });
        });
    }
    if (identifier === PlatformIdentifier.REDDIT) {
        const MAX_FILE_SIZE_GB = 1;
        const MIN_DURATION_SECONDS = 5;
        const MAX_DURATION_SECONDS = 900; // 15 minutes in seconds
        const SUPPORTED_FORMATS = ['mp4', 'mov'];
        const RECOMMENDED_ASPECT_RATIOS = ['1:1', '4:5', '4:3', '16:9'];
        const RECOMMENDED_MIN_LENGTH_SECONDS = 5;
        const RECOMMENDED_MAX_LENGTH_SECONDS = 30;
        const MAX_FRAME_RATE = 30;

        // Check file size
        const fileSizeGB = file.size / (1024 * 1024 * 1024);
        if (fileSizeGB > MAX_FILE_SIZE_GB) {
            return { valid: false, error: 'Reddit video file size exceeds 1 GB.' };
        }

        // Check file format
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!SUPPORTED_FORMATS.includes(fileExtension)) {
            return { valid: false, error: 'Invalid Reddit video format. Accepted formats: MP4, MOV.' };
        }

        // Create a video element to get duration and frame rate
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(file);


        return new Promise((resolve) => {
            videoElement.addEventListener('loadedmetadata', () => {
                // Check video duration
                const duration = videoElement.duration;
                if (duration < MIN_DURATION_SECONDS || duration > MAX_DURATION_SECONDS) {
                    resolve({
                        valid: false,
                        error: 'Video duration must be between 5 seconds and 15 minutes.',
                    });
                }

                // Check frame rate
                const frameRate = videoElement.webkitDecodedFrameCount / duration;
                if (frameRate > MAX_FRAME_RATE) {
                    resolve({
                        valid: false,
                        error: 'Frame rate exceeds 30 FPS.',
                    });
                }

                // Check aspect ratio (resolution)
                const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
                const formattedAspectRatio = aspectRatio.toFixed(2);
                if (!RECOMMENDED_ASPECT_RATIOS.includes(formattedAspectRatio)) {
                    resolve({
                        valid: false,
                        error: 'Unsupported aspect ratio. Recommended aspect ratios: 1:1 square; 4:5 vertical; 4:3 classic; 16:9 full landscape.',
                    });
                }

                // Check video length
                if (duration < RECOMMENDED_MIN_LENGTH_SECONDS || duration > RECOMMENDED_MAX_LENGTH_SECONDS) {
                    resolve({
                        valid: false,
                        error: 'Video length should be between 5 and 30 seconds for optimal results on Reddit.',
                    });
                }

                resolve({ valid: true });
            });
        });

    }
    if (identifier === PlatformIdentifier.FACEBOOK) {
        const MAX_FILE_SIZE_GB = 1;
        const MAX_DURATION_SECONDS = 4 * 60 * 60; // 4 hours in seconds
        // Check file size
        const fileSizeGB = file.size / (1024 * 1024 * 1024);
        if (fileSizeGB > MAX_FILE_SIZE_GB) {
            return { valid: false, error: 'Facebook video file size exceeds 1 GB.' };
        }

        // Create a video element to get duration
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(file);

        return new Promise((resolve) => {
            videoElement.addEventListener('loadedmetadata', () => {
                // Check video duration
                const duration = videoElement.duration;
                if (duration > MAX_DURATION_SECONDS) {
                    resolve({
                        valid: false,
                        error: 'Video duration exceeds 4 hours.',
                    });
                }

                resolve({ valid: true });
            });
        });

    }
    if (identifier === PlatformIdentifier.INSTAGRAM) {
        let reels = extras['publishIGVideoAsReels']
        let stories = extras['publishIGVideoAsStories']
        let checkIgNormalVideo = extras['checkIgNormalVideo']

        if (reels) {
            const MAX_FILE_SIZE_GB = 1;
            const MIN_DURATION_SECONDS = 3;
            const MAX_DURATION_SECONDS = 900; // 15 minutes in seconds
            const MAX_COLUMNS_PIXELS = 1920;
            const ASPECT_RATIO_MIN = 0.01;
            const ASPECT_RATIO_MAX = 10;
            const RECOMMENDED_ASPECT_RATIO = 9 / 16;
            const MAX_VIDEO_BITRATE_MBPS = 25;
            const MAX_AUDIO_BITRATE_KBPS = 128;


            // Check file size
            const fileSizeGB = file.size / (1024 * 1024 * 1024);
            if (fileSizeGB > MAX_FILE_SIZE_GB) {
                return { valid: false, error: 'Instagram video file size exceeds 1 GB.' };
            }

            // Check file format
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (!['mov', 'mp4'].includes(fileExtension)) {
                return { valid: false, error: 'Invalid Instagram video format. Accepted formats: MP4, MOV.' };
            }

            // Create a video element to get duration and resolution
            const videoElement = document.createElement('video');
            videoElement.src = URL.createObjectURL(file);

            return new Promise((resolve) => {
                videoElement.addEventListener('loadedmetadata', () => {
                    const duration = videoElement.duration;
                    if (duration < MIN_DURATION_SECONDS || duration > MAX_DURATION_SECONDS) {
                        resolve({
                            valid: false,
                            error: `Your video duration is ${duration.toFixed(0)} seconds. Instagram reels video duration must be between 3 seconds and 900 seconds (15 minutes).`,
                        });
                    }

                    const videoWidth = videoElement.videoWidth;
                    if (videoWidth > MAX_COLUMNS_PIXELS) {
                        resolve({
                            valid: false,
                            error: `Your video width is ${videoWidth} pixels. Instagram reels video width must be less than 1920 pixels.`,
                        });
                    }

                    const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
                    if (aspectRatio < ASPECT_RATIO_MIN || aspectRatio > ASPECT_RATIO_MAX) {
                        resolve({
                            valid: false,
                            error: `Your video aspect ratio is not supported by Instagram. Recommended aspect ratio is 9:16.`,
                        });
                    }

                    const videoBitrate = file.size / (duration * 1024 * 1024);
                    if (videoBitrate > MAX_VIDEO_BITRATE_MBPS) {
                        resolve({
                            valid: false,
                            error: `Your video bitrate is ${videoBitrate} Mbps. Instagram reels video bitrate must be less than 25 Mbps.`,
                        });
                    }

                    try {
                        const audioBitrate = videoElement.audioTracks[0]?.bitrate / 1024;
                        if (audioBitrate > MAX_AUDIO_BITRATE_KBPS) {
                            resolve({
                                valid: false,
                                error: `Your video audio bitrate is not supported. Instagram reels audio bitrate must not exceed 128 kbps.`,
                            });
                        }
                    } catch (e) {
                        console.log(e)
                    }

                    resolve({ valid: true });
                });
            });

        }

        if (stories) {
            const MAX_FILE_SIZE_MB = 100;
            const MIN_DURATION_SECONDS = 3;
            const MAX_DURATION_SECONDS = 60;
            const MAX_COLUMNS_PIXELS = 1920;
            const ASPECT_RATIO_MIN = 0.1;
            const ASPECT_RATIO_MAX = 10;
            const RECOMMENDED_ASPECT_RATIO = 9 / 16;
            const MAX_VIDEO_BITRATE_MBPS = 25;
            const MAX_AUDIO_BITRATE_KBPS = 128;

            const fileSizeMB = file.size / (1024 * 1024);
            if (fileSizeMB > MAX_FILE_SIZE_MB) {
                return { valid: false, error: `Instagram stories video file size exceeds ${MAX_FILE_SIZE_MB} MB.` };
            }

            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (!['mov', 'mp4'].includes(fileExtension)) {
                return { valid: false, error: 'Invalid Instagram stories video format. Accepted formats: MP4, MOV.' };
            }

            const videoElement = document.createElement('video');
            videoElement.src = URL.createObjectURL(file);

            return new Promise((resolve) => {
                videoElement.addEventListener('loadedmetadata', () => {
                    // Check video duration
                    const duration = videoElement.duration;
                    if (duration < MIN_DURATION_SECONDS || duration > MAX_DURATION_SECONDS) {
                        resolve({
                            valid: false,
                            error: `Your video duration is ${duration.toFixed(0)} seconds. Instagram video duration must be between 3 seconds and 60 seconds. Please upload a video with a duration between 3 seconds and 60 seconds.`,
                        });
                    }

                    // Check video resolution
                    const videoWidth = videoElement.videoWidth;
                    if (videoWidth > MAX_COLUMNS_PIXELS) {
                        resolve({
                            valid: false,
                            error: `Your video resolution width is ${videoWidth} pixels. Instagram stories video resolution width exceeds 1920 pixels. Please upload a video with a resolution width less than 1920 pixels.`
                        });
                    }

                    // Check video aspect ratio
                    const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
                    if (aspectRatio < ASPECT_RATIO_MIN || aspectRatio > ASPECT_RATIO_MAX) {
                        resolve({
                            valid: false,
                            error: `Your video aspect ratio is not supported. Recommended aspect ratio is 9:16.`
                        });
                    }

                    // Check video bitrate
                    const videoBitrate = file.size / (duration * 1024 * 1024);
                    if (videoBitrate > MAX_VIDEO_BITRATE_MBPS) {
                        resolve({
                            valid: false,
                            error: `Your video bitrate is ${videoBitrate} Mbps. Instagram stories video bitrate exceeds 25 Mbps. Please upload a video with a bitrate less than 25 Mbps.`,
                        });
                    }

                    try {
                        // Check audio bitrate
                        const audioBitrate = videoElement.audioTracks[0].bitrate / 1024;
                        if (audioBitrate > MAX_AUDIO_BITRATE_KBPS) {
                            resolve({
                                valid: false,
                                error: `Your video audio bitrate is unsupported by Instagram. Instagram stories audio bitrate should not 128 kbps.`,
                            });
                        }

                    } catch (e) {
                        console.log(e)
                    }


                    resolve({ valid: true });
                });
            });
        }

        if (checkIgNormalVideo) {
            // const MAX_FILE_SIZE_MB = 100;
            // const MIN_DURATION_SECONDS = 3;
            // const MAX_DURATION_SECONDS = 60;
            // const MAX_COLUMNS_PIXELS = 1920;
            // const MIN_ASPECT_RATIO = 4 / 5;
            // const MAX_ASPECT_RATIO = 16 / 9;
            // const MAX_VIDEO_BITRATE_MBPS = 25;
            // const MAX_AUDIO_BITRATE_KBPS = 128;

            // const fileSizeMB = file.size / (1024 * 1024);
            // if (fileSizeMB > MAX_FILE_SIZE_MB) {
            //     return { valid: false, error: `Instagram video file size exceeds ${MAX_FILE_SIZE_MB} MB.` };
            // }
            // const fileExtension = file.name.split('.').pop().toLowerCase();
            // if (!['mov', 'mp4'].includes(fileExtension)) {
            //     return { valid: false, error: 'Invalid Instagram video format. Accepted formats: MP4, MOV.' };
            // }

            // const videoElement = document.createElement('video');
            // videoElement.src = URL.createObjectURL(file);

            // return new Promise((resolve) => {
            //     videoElement.addEventListener('loadedmetadata', () => {
            //         // Check video duration
            //         const duration = videoElement?.duration;
            //         if (duration < MIN_DURATION_SECONDS || duration > MAX_DURATION_SECONDS) {
            //             resolve({
            //                 valid: false,
            //                 error: `Your video duration is ${duration.toFixed(0)} seconds. Instagram video duration must be between 3 seconds and 60 seconds. Please upload a video with a duration between 3 seconds and 60 seconds.`,
            //             });
            //         }

            //         // Check video resolution
            //         const videoWidth = videoElement?.videoWidth;
            //         if (videoWidth > MAX_COLUMNS_PIXELS) {
            //             resolve({
            //                 valid: false,
            //                 error: `Your video resolution width is ${videoWidth} pixels. 'Instagram video resolution width must not exceeds 1920 pixels. Please upload a video with a resolution width less than 1920 pixels.`,
            //             });
            //         }

            //         // Check video aspect ratio
            //         const aspectRatio = videoElement?.videoWidth / videoElement?.videoHeight;
            //         if (aspectRatio < MIN_ASPECT_RATIO || aspectRatio > MAX_ASPECT_RATIO) {
            //             resolve({
            //                 valid: false,
            //                 error: 'Your video aspect ratio is not supported. Instagram video aspect ratio must be between 4:5 and 16:9. Please upload a video with an aspect ratio between 4:5 and 16:9.',
            //             });
            //         }

            //         // Check video bitrate
            //         const videoBitrate = file?.size / (duration * 1024 * 1024);
            //         if (videoBitrate > MAX_VIDEO_BITRATE_MBPS) {
            //             resolve({
            //                 valid: false,
            //                 error: `Your video bitrate is ${videoBitrate} Mbps. Instagram video bitrate must not exceed 25 Mbps. Please upload a video with a bitrate less than 25 Mbps. `,
            //             });
            //         }

            //         try {
            //             // Check audio bitrate
            //             const audioBitrate = videoElement?.audioTracks[0]?.bitrate / 1024;
            //             if (audioBitrate > MAX_AUDIO_BITRATE_KBPS) {
            //                 resolve({
            //                     valid: false,
            //                     error: `Your video audio bitrate is ${audioBitrate} kbps. Instagram video audio bitrate must not exceed 128 kbps. Please upload a video with an audio bitrate less than 128 kbps.`,
            //                 });
            //             }
            //         } catch (e) {
            //             console.log(e)
            //         }

            //         resolve({ valid: true });
            //     });
            // });
            // // return { valid: true };

            // IG now defaults to reels:


            const MAX_FILE_SIZE_GB = 1;
            const MIN_DURATION_SECONDS = 3;
            const MAX_DURATION_SECONDS = 900; // 15 minutes in seconds
            const MAX_COLUMNS_PIXELS = 1920;
            const ASPECT_RATIO_MIN = 0.01;
            const ASPECT_RATIO_MAX = 10;
            const RECOMMENDED_ASPECT_RATIO = 9 / 16;
            const MAX_VIDEO_BITRATE_MBPS = 25;
            const MAX_AUDIO_BITRATE_KBPS = 128;


            // Check file size
            const fileSizeGB = file.size / (1024 * 1024 * 1024);
            if (fileSizeGB > MAX_FILE_SIZE_GB) {
                return { valid: false, error: 'Instagram video file size exceeds 1 GB.' };
            }

            // Check file format
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (!['mov', 'mp4'].includes(fileExtension)) {
                return { valid: false, error: 'Invalid Instagram video format. Accepted formats: MP4, MOV.' };
            }

            // Create a video element to get duration and resolution
            const videoElement = document.createElement('video');
            videoElement.src = URL.createObjectURL(file);

            return new Promise((resolve) => {
                videoElement.addEventListener('loadedmetadata', () => {
                    const duration = videoElement.duration;
                    if (duration < MIN_DURATION_SECONDS || duration > MAX_DURATION_SECONDS) {
                        resolve({
                            valid: false,
                            error: `Your video duration is ${duration.toFixed(0)} seconds. Instagram video duration must be between 3 seconds and 900 seconds (15 minutes).`,
                        });
                    }

                    const videoWidth = videoElement.videoWidth;
                    if (videoWidth > MAX_COLUMNS_PIXELS) {
                        resolve({
                            valid: false,
                            error: `Your video width is ${videoWidth} pixels. Instagram video width must be less than 1920 pixels.`,
                        });
                    }

                    const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
                    if (aspectRatio < ASPECT_RATIO_MIN || aspectRatio > ASPECT_RATIO_MAX) {
                        resolve({
                            valid: false,
                            error: `Your video aspect ratio is not supported by Instagram. Recommended aspect ratio is 9:16.`,
                        });
                    }

                    const videoBitrate = file.size / (duration * 1024 * 1024);
                    if (videoBitrate > MAX_VIDEO_BITRATE_MBPS) {
                        resolve({
                            valid: false,
                            error: `Your video bitrate is ${videoBitrate} Mbps. Instagram video bitrate must be less than 25 Mbps.`,
                        });
                    }

                    try {
                        const audioBitrate = videoElement.audioTracks[0]?.bitrate / 1024;
                        if (audioBitrate > MAX_AUDIO_BITRATE_KBPS) {
                            resolve({
                                valid: false,
                                error: `Your video audio bitrate is not supported. Instagram audio bitrate must not exceed 128 kbps.`,
                            });
                        }
                    } catch (e) {
                        console.log(e)
                    }

                    resolve({ valid: true });
                });
            });


        }

        if (!checkIgNormalVideo && !stories && !reels) {
            return { valid: true }; // allow pass through
        }
    }
}

export const platformConnectionGuides = {
    [PlatformIdentifier.FACEBOOK]: [
        {
            id: 1,
            label: `Full account admin access is required`,
            icon: 'check'
        },
        {
            id: 2,
            label: 'Accept all connection permissions.',
            icon: 'check'
        },
        {
            id: 3,
            label: 'After linking your account, ensure to connect your Facebook Pages.',
            icon: 'check'
        },
        {
            id: 4,
            label: 'For a detailed guide and demo video: {{link:https://www.postly.ai/facebook}}',
            icon: 'check',
            ignoreForWhiteLabel: true
        },
    ],
    [PlatformIdentifier.INSTAGRAM]: [
        {
            id: 1,
            label: `Instagram can only be connected through your Facebook account; logging in with an Instagram username and password is not supported.`,
            icon: 'check'
        },
        {
            id: 2,
            label: `Only Instagram Business accounts can publish via the web dashboard, while Creator and Personal accounts require the Postly mobile app to publish.`,
            icon: 'check'
        },
        {
            id: 3,
            label: `To reconnect your Instagram account via Facebook, always disconnect both accounts first, then reconnect Facebook first, followed by Instagram.`,
            icon: 'check'
        },
        {
            id: 4,
            label: 'For a detailed guide and demo video: {{link:https://www.postly.ai/instagram}}',
            icon: 'check',
            ignoreForWhiteLabel: true
        },
    ],
    [PlatformIdentifier.LINKED_IN]: [
        {
            id: 1,
            label: `Full account admin access is required.`,
            icon: 'check'
        },
        {
            id: 2,
            label: 'Accept all connection permissions.',
            icon: 'check'
        },
        {
            id: 3,
            label: 'After linking your account, ensure to connect your LinkedIn Profile and Pages.',
            icon: 'check'
        },
        {
            id: 4,
            label: 'For a detailed guide and demo video: {{link:https://www.postly.ai/linkedin}}',
            icon: 'check',
            ignoreForWhiteLabel: true
        },
    ],
    [PlatformIdentifier.GOOGLE_MY_BUSINESS]: [
        {
            id: 1,
            label: `Full account admin access is required.`,
            icon: 'check'
        },
        {
            id: 2,
            label: 'Accept all connection permissions.',
            icon: 'check'
        },
        {
            id: 3,
            label: 'After linking your account, ensure to connect your Locations.',
            icon: 'check'
        },
        {
            id: 4,
            label: 'For a detailed guide and demo video: {{link:https://www.postly.ai/google-business-profile}}',
            icon: 'check',
            ignoreForWhiteLabel: true
        },
    ],
    [PlatformIdentifier.YOUTUBE]: [
        {
            id: 1,
            label: `Full account admin access is required.`,
            icon: 'check'
        },
        {
            id: 2,
            label: 'Only channels created by the connected account will be shown. To allow your clients to connect their accounts, you can add them as team members.',
            icon: 'check'
        },
        {
            id: 3,
            label: 'Accept all connection permissions.',
            icon: 'check'
        },
        {
            id: 4,
            label: 'After linking your account, ensure to connect your Channels.',
            icon: 'check'
        },
        {
            id: 5,
            label: 'For a detailed guide and demo video: {{link:https://www.postly.ai/youtube}}',
            icon: 'check',
            ignoreForWhiteLabel: true
        },
    ],
    [PlatformIdentifier.TIKTOK]: [
        {
            id: 1,
            label: `Full account admin access is required.`,
            icon: 'check'
        },
        {
            id: 2,
            label: 'Accept all connection permissions.',
            icon: 'check'
        },
        {
            id: 3,
            label: 'For a detailed guide and demo video: {{link:https://www.postly.ai/tiktok}}',
            icon: 'check',
            ignoreForWhiteLabel: true
        },
    ],
    [PlatformIdentifier.PINTEREST]: [
        {
            id: 1,
            label: `Full account admin access is required.`,
            icon: 'check'
        },
        {
            id: 2,
            label: 'Accept all connection permissions.',
            icon: 'check'
        },
        {
            id: 3,
            label: 'For a detailed guide and demo video: {{link:https://www.postly.ai/pinterest}}',
            icon: 'check',
            ignoreForWhiteLabel: true
        },
    ],
    [PlatformIdentifier.REDDIT]: [
        {
            id: 1,
            label: `Full account admin access is required.`,
            icon: 'check'
        },
        {
            id: 2,
            label: 'Accept all connection permissions.',
            icon: 'check'
        },
        {
            id: 3,
            label: 'For a detailed guide and demo video: {{link:https://www.postly.ai/reddit}}',
            icon: 'check',
            ignoreForWhiteLabel: true
        },
    ],
}

export const arrangeSelectPlatforms = (targets, munchedTargetIds, dispatch) => {
    if (targets.length > 0) {
        let cachedPlatforms = localStorage.getItem("cached_platforms");
        if (cachedPlatforms) {
            cachedPlatforms = JSON.parse(cachedPlatforms);
        }
        for (let target of targets) {
            let existingPlatform;
            if (target.selected) {
                if (cachedPlatforms) {
                    existingPlatform = cachedPlatforms.find(
                        (x) => x.identifier === target.identifier
                    );
                    if (existingPlatform) {
                        let indexOfExistingPlatform =
                            cachedPlatforms.indexOf(existingPlatform);
                        existingPlatform.selected = true;
                        cachedPlatforms[indexOfExistingPlatform] = existingPlatform;
                    }
                }
            }
            for (let account of target.accounts) {
                let accountMunch = munchTarget(account._id, account.identifier);
                if (account.connected_pages_and_groups) {
                    for (let page of account.connected_pages_and_groups) {
                        let pageMunch = munchTarget(page.id, page.target);
                        if (page.selected) {
                            if (!munchedTargetIds.includes(pageMunch)) {
                                munchedTargetIds.push(pageMunch);
                            }
                        }
                    }
                    let areAllPagesSelected =
                        account.connected_pages_and_groups.every((x) => x.selected);
                    if (areAllPagesSelected) {
                        if (!munchedTargetIds.includes(accountMunch)) {
                            munchedTargetIds.push(accountMunch);
                        }
                    }
                } else {
                    if (account.selected) {
                        if (!munchedTargetIds.includes(accountMunch)) {
                            munchedTargetIds.push(accountMunch);
                        }
                    }
                }
                if (existingPlatform) {
                    let domiciledAccount = existingPlatform.accounts.find(
                        (y) => y._id === account._id
                    );
                    if (domiciledAccount) {
                        let indexOfDomiciledAccount =
                            existingPlatform.accounts.indexOf(domiciledAccount);
                        existingPlatform.accounts[indexOfDomiciledAccount] =
                            account;
                    }
                }
            }
        }
        if (dispatch) {
            dispatch(setMunchedPostTargetIds([...munchedTargetIds]));
        }
        if (dispatch) {
            dispatch(setSelectedPlatforms(targets));
        }
        if (cachedPlatforms) {
            localStorage.setItem(
                "cached_platforms",
                JSON.stringify(cachedPlatforms)
            );
        }
    }
}