import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setTopPostId } from 'redux/slices/analytics_slice';
import PostModal from '../PostModal';
import { PlatformIdentifier } from '../../../../constants';

const TopPages = ({ tableData, perPage = 8, loading, identifier }) => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [postId, setPostId] = useState(null);
  const [post, setPost] = useState({});

  const handlePostModal = (rowData) => {
    setModalShow(!modalShow);
    setPostId(rowData.row.original.id);
    dispatch(setTopPostId(rowData.row.original.id));
  };

  const getSelectedPost = () => {
    let targetPost = tableData.find((x) => x.id === postId);
    setPost({ ...targetPost });
  };

  useEffect(() => {
    getSelectedPost();
  }, [postId]);

  const columns = [
    {
      accessor: 'title',
      Header: 'Post Title',
      Cell: rowData => (
        <div
          id="post"
          className="text-primary fw-semi-bold"
          onClick={() => handlePostModal(rowData)}
          style={{ cursor: 'pointer' }}
        >
          {rowData.row.original.title}
        </div>
      )
    },
    {
      accessor: 'impressions',
      Header: 'Post Impressions',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      }
    },
    {
      accessor: 'engagement',
      Header: 'Post Engagements',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      }
    },
    {
      accessor: 'likes',
      Header: 'Post Likes',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      }
    },
    {
      accessor: 'share',
      Header: 'Shares',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      }
    },
    {
      accessor: 'created',
      Header: 'Post Created',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      }
    }
  ];

  if (identifier === PlatformIdentifier.INSTAGRAM) {
    let post_type = {
      accessor: 'post_type',
      Header: 'Post Type',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      }
    };
    columns.push(post_type);
  }

  if (identifier === PlatformIdentifier.YOUTUBE) {
    let post_metrics = {
      accessor: 'views',
      Header: 'Video Views',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end'
      }
    };
    columns.push(post_metrics);
  }
  return (
    <>
      {loading && <Flex
        alignItems={'center'}
        justifyContent={'center'}
        className={'vh-50'}>
        <Spinner animation='border' size={40} />
      </Flex>}

      {modalShow &&
        <PostModal
          postId={postId}
          show={modalShow}
          post={post}
          onHide={() => setModalShow(false)}
        />}

      {!loading && tableData &&
        <AdvanceTableWrapper
          columns={columns}
          data={tableData}
          sortable
          pagination
          perPage={perPage}
        >
          <Card className="h-100">
            <Card.Header>
              <Row className="flex-between-center">
                <Col xs="auto" sm={6} lg={7}>
                  <h6 className="mb-0 text-nowrap py-2 py-xl-0">
                    Top performing posts
                  </h6>
                </Col>
                <Col xs="auto" sm={6} lg={5}>
                  <AdvanceTableSearchBox table placeholder="Search for a post" />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-0">
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                rowCount={tableData.length}
                table
                rowInfo
                navButtons
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>}
    </>
  );
};

TopPages.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      views: PropTypes.number.isRequired,
      time: PropTypes.string.isRequired,
      exitRate: PropTypes.string.isRequired
    })
  ).isRequired,
  perPage: PropTypes.number
};

export default TopPages;
