import React, { useEffect, useState, useImperativeHandle } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { findPlatformByIdentifier } from '../../../app/platforms';
import { PlatformIdentifier } from '../../../../constants';
import {
    setRedditTitle,
    getRedditTitle,
    setLoadingPostData,
    getLoadingPostData,
    getUploadableBulkContent,
    setUploadableBulkContent
} from '../../../../redux/slices/postslice';
import { useSelector, useDispatch } from 'react-redux';
import APIService from 'http/api_service';
import SubRedditFlairSelector from './subreddit_flair_selector';
import EditableSection from './debounceable_edit_box';
import { Card, Spinner } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import Divider from 'components/common/Divider';
import PrePublishErrorResolver from './PrePublishErrorResolver';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const organizeBulkContentPlatformsWithinAccounts = (selectedPlatforms) => {
    let refinedPlatforms = [];
    for (let platform of selectedPlatforms) {
        let platformEntry = refinedPlatforms.find(x => x.identifier === platform.identifier);
        if (!platformEntry) {
            let accounts = [];
            accounts.push(platform);
            refinedPlatforms.push({
                identifier: platform.identifier,
                accounts: accounts
            })
        } else {
            let indexOfPlatformEntry = refinedPlatforms.indexOf(platformEntry);
            let existingAccounts = platformEntry.accounts ?? [];
            existingAccounts.push(platform);
            platformEntry.accounts = existingAccounts;
            refinedPlatforms[indexOfPlatformEntry] = platformEntry;
        }
    }
    return refinedPlatforms;
}

const preSelectedFlairs = (selectedPlatforms) => {
    let preselectedFlairs = {};
    if (selectedPlatforms) {
        let redditPlatform = selectedPlatforms.find(platform => platform.identifier === PlatformIdentifier.REDDIT);
        if (redditPlatform) {
            for (let account of redditPlatform.accounts) {
                if (account.selected) {
                    let subReddits = account.connected_pages_and_groups;
                    if (subReddits) {
                        for (let subReddit of subReddits) {
                            if (subReddit.selected) {
                                if (subReddit.flair) {
                                    preselectedFlairs[subReddit.name] = subReddit.flair;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return preselectedFlairs;
}

function RedditOptionsField(props, ref) {

    const {
        bulkContentMode = false,
        rowIndex = 0
    } = props;
    const uploadableBulkContent = useSelector(getUploadableBulkContent);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const workSpace = useSelector(getActiveWorkSpace);
    const dispatch = useDispatch();
    const redditPlatform = findPlatformByIdentifier(PlatformIdentifier.REDDIT);
    const [redditErrorMessage, setRedditErrorMessage] = useState(props.redditErrorMessage);
    const redditTitle = useSelector(getRedditTitle);
    const [bulkContentRedditTitle, setBulkContentRedditTitle] = useState(bulkContentRow?.reddit_title);
    const [selectedPlatforms, setSelectedPlatforms] = useState(bulkContentMode ? organizeBulkContentPlatformsWithinAccounts(bulkContentRow?.platforms) : props.selectedPlatforms);
    const [flair, setFlair] = useState({});
    const [selectedFlairs, setSelectedFlairs] = useState(preSelectedFlairs(selectedPlatforms));
    const { flairSelectedHandler } = props;
    const loadingPostData = useSelector(getLoadingPostData);
    const [show, setShow] = useState(true);

    const updateBulkContentRow = (updatedRow) => {
        let uploadableBulkContentCopy = [...uploadableBulkContent];
        uploadableBulkContentCopy[rowIndex] = updatedRow;
        dispatch(setUploadableBulkContent(uploadableBulkContentCopy));
    }

    useEffect(() => {
        if (!bulkContentMode) {
            setSelectedPlatforms(props.selectedPlatforms);
        }
    }, [props.selectedPlatforms]);

    useEffect(() => {
        if (selectedPlatforms) {
            if (bulkContentMode) {
                return;
            }
            let redditPlatform = selectedPlatforms.find(platform => platform.identifier === PlatformIdentifier.REDDIT);
            if (redditPlatform) {
                for (let account of redditPlatform.accounts) {
                    if (account.selected) {
                        let subReddits = account.connected_pages_and_groups;
                        if (subReddits) {
                            for (let subReddit of subReddits) {
                                if (subReddit.selected) {
                                    if (!loadingPostData && !bulkContentMode) {
                                        dispatch(setLoadingPostData(true));
                                    }
                                    APIService.fetchSubredditFlairs(workSpace._id, account.user_id, subReddit.name, (response, error) => {
                                        dispatch(setLoadingPostData(false));
                                        if (response) {
                                            let { data } = response;
                                            if (data.length > 0) {
                                                setFlair((prevFlair) => {
                                                    prevFlair[subReddit.name] = data;
                                                    return { ...prevFlair };
                                                });
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
    }, [selectedPlatforms]);

    useEffect(() => {
        setRedditErrorMessage(props.redditErrorMessage);
    }, [props.redditErrorMessage]);

    useEffect(() => {
        if (selectedPlatforms) {
            let copiedPlatforms = JSON.parse(JSON.stringify(selectedPlatforms));
            let redditPlatform = copiedPlatforms.find(platform => platform.identifier === PlatformIdentifier.REDDIT);
            if (redditPlatform) {
                for (let account of redditPlatform.accounts) {
                    let subReddits = account.connected_pages_and_groups;
                    if (subReddits) {
                        for (let i = 0; i < subReddits.length; i++) {
                            let subReddit = subReddits[i];
                            subReddit['flair'] = selectedFlairs[subReddit.name];
                        }
                    }
                }
            }
            areAllFlairsReconciled();
            if (flairSelectedHandler) {
                flairSelectedHandler(copiedPlatforms);
            } else {
                if (bulkContentMode) {
                    let flattenedPlatforms = copiedPlatforms.map(x => x.accounts).flat();
                    let updatedRow = { ...bulkContentRow };
                    updatedRow.platforms = flattenedPlatforms;
                    updateBulkContentRow(updatedRow);
                }
            }
        }
    }, [selectedFlairs]);

    const areAllFlairsReconciled = () => {
        // return Object.entries(flair).length === Object.entries(selectedFlairs).length;
        //INFO: (information) flair not compulsory for reddit posts.
        return true
    }

    useImperativeHandle(ref, () => ({
        canPostToReddit: () => {
            let allResolved = areAllFlairsReconciled();
            if (!allResolved) {
                setRedditErrorMessage("Please select flair for affected reddit communities");
            } else {
                setRedditErrorMessage(null);
            }
            return allResolved;
        },
        getErrorMessage: () => {
            return redditErrorMessage;
        }
    }));

    return (
        <Card className='mb-3'>
            <FalconCardHeader
                title={bulkContentMode ? "Reddit" : "For Reddit"}
                endEl={
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                    >
                        <div
                            style={{
                                width: 20,
                                height: 20,
                                borderRadius: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: redditPlatform.color
                            }}>
                            <FontAwesomeIcon
                                icon={redditPlatform.icon}
                                style={{
                                    color: 'white',
                                    height: 12,
                                    width: 12
                                }}
                            />
                        </div>
                        <Flex
                            className="ms-2"
                            // alignItems="center"
                            // justifyContent="center"
                            style={{ cursor: 'pointer' }}
                        >
                            {
                                show ?
                                    <IoIosArrowUp
                                        onClick={() => setShow(false)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    /> :
                                    <IoIosArrowDown
                                        onClick={() => setShow(true)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    />
                            }
                        </Flex>
                    </Flex>
                } />
            {
                show &&
                <Card.Body className="bg-light">
                    <Flex direction={'column'} className={'gap-3'}>
                        <EditableSection
                            style={{
                                width: '100%'
                            }}
                            title={"Title"}
                            placeholder="Enter title for Reddit"
                            value={bulkContentMode ? bulkContentRedditTitle : redditTitle}
                            onChangeHandler={(value) => {
                                if (bulkContentMode) {
                                    let updatedRow = { ...bulkContentRow }
                                    if (!value) {
                                        value = " ";
                                    }
                                    updatedRow.reddit_title = value;
                                    setBulkContentRedditTitle(value);
                                    updateBulkContentRow(updatedRow);
                                } else {
                                    dispatch(setRedditTitle(value));
                                }
                            }}
                        />
                        {
                            !bulkContentMode && loadingPostData && <div
                                style={{
                                    paddingTop: 15,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 10
                                }}>
                                <Spinner animation='border' size={'sm'} />
                                <span
                                    style={{
                                        fontSize: 13
                                    }}>Please wait...
                                </span>
                            </div>
                        }
                        {
                            !bulkContentMode &&
                            <div
                                style={{
                                    display: 'flex',
                                    gap: 10,
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                {
                                    Object.entries(flair).length > 0 &&
                                    Object.entries(flair).map(flair => {
                                        return (
                                            <SubRedditFlairSelector
                                                key={flair[0]}
                                                flair={flair}
                                                selectedFlair={selectedFlairs[flair[0]]?.value}
                                                flairSelectionChangeListener={(selectedFlair) => {
                                                    setSelectedFlairs((prevSelectedFlairs) => {
                                                        let { subreddit, ...others } = selectedFlair;
                                                        prevSelectedFlairs[selectedFlair.subreddit] = { ...others }
                                                        return { ...prevSelectedFlairs };
                                                    });
                                                }}
                                            />
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            redditErrorMessage &&
                            <PrePublishErrorResolver
                                error={redditErrorMessage}
                            />
                        }
                        {
                            !bulkContentMode &&
                            <Flex direction={'column'}>
                                <Divider />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 12,
                                        gap: 5
                                    }}>
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        style={{
                                            width: 15,
                                            height: 15,
                                        }}
                                    />
                                    <span
                                        style={{
                                            fontSize: 12,
                                        }}>Message above will be used as the Reddit text
                                    </span>
                                </div>
                            </Flex>
                        }
                    </Flex>
                </Card.Body>
            }
        </Card>
    );
}
export default React.forwardRef(RedditOptionsField);