import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Flex from './Flex';

const ConfirmModal = (props) => {

    const {
        title, message, setPromptGetter,
        onConfirm, onCancel,
        confirmText, cancelText,
        centered = true,
        showDialoguePrompt = false,
        titleColor,
    } = props;

    const [open, setOpen] = useState(props.open);
    const [canShowDialogPromptAgain, setCanShowDialogPromptAgain] = useState(false);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <Modal
            show={open}
            onHide={() => setOpen(false)}
            onExit={() => {
                if (onCancel) {
                    onCancel();
                }
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered={centered}
            style={{
                zIndex: 10000,
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title
                    style={{
                        color: titleColor || 'black',
                    }}
                    id="contained-modal-title-vcenter">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p dangerouslySetInnerHTML={{ __html: message }}></p>
                {
                    showDialoguePrompt &&
                    <Form.Check
                        id={"delete-post_post-prompt_check"}
                        type="checkbox"
                        label={"Don't show this again?"}
                        checked={canShowDialogPromptAgain}
                        onChange={(e) => {
                            setCanShowDialogPromptAgain(!canShowDialogPromptAgain);
                            setPromptGetter(canShowDialogPromptAgain)
                        }}
                    />
                    // localStorage.getItem('showPromptHandler') &&
                    // <Form.Check
                    //     id={"delete-post_post-prompt_check"}
                    //     type="checkbox"
                    //     label={"Don't show this again?"}
                    //     checked={canShowDialogPromptAgain}
                    //     onChange={(value) => {
                    //         setCanShowDialogPromptAgain(!canShowDialogPromptAgain);
                    //         localStorage.setItem('showPromptHandler', canShowDialogPromptAgain)
                    //     }}
                    // />
                }
            </Modal.Body>
            <Modal.Footer>
                <Flex className={'gap-2'} alignItems={'center'}>
                    <Button variant='light' onClick={() => {
                        onCancel(showDialoguePrompt && canShowDialogPromptAgain);
                        setOpen(false)
                    }}>{cancelText}
                    </Button>
                    <Button variant='primary' onClick={() => {
                        onConfirm(showDialoguePrompt && canShowDialogPromptAgain);
                        setOpen(false);
                    }}>{confirmText}
                    </Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmModal;