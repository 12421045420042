import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findPlatformByIdentifier } from 'components/app/platforms';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import React, { useEffect, useState } from 'react';
import { Alert, Card, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { PlatformIdentifier } from '../../../../constants';
import {
    getStitchAllowTiktok,
    getTiktokAllowComments,
    getTiktokAllowDuet,
    getTiktokCommercialForBrandedContent,
    getTiktokCommercialForYourBrand,
    getTiktokDiscloseVideoContent,
    getTiktokSelectedViewSetting,
    getTiktokVideoTitle,
    getUploadableBulkContent,
    setStitchAllowTiktok,
    setTiktokAllowComments,
    setTiktokAllowDuet,
    setTiktokCommercialForBrandedContent,
    setTiktokCommercialForYourBrand,
    setTiktokDiscloseVideoContent,
    setTiktokSelectedViewSetting,
    setTiktokVideoTitle,
    setUploadableBulkContent
} from '../../../../redux/slices/postslice';
import EditableSection from './debounceable_edit_box';
import { toast } from 'react-toastify';
import InfoBar from 'components/common/InfoBar';
import { getPlatformVideoUploadLimit } from 'redux/slices/workspaceslice';
import FigureUtils from 'utils/figures';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

export default function TiktokOptionsField(props) {

    const {
        videoDetected = false,
        bulkContentMode = false,
        rowIndex = 0
    } = props;
    const dispatch = useDispatch();
    const uploadableBulkContent = useSelector(getUploadableBulkContent);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const tiktokPlatform = findPlatformByIdentifier(PlatformIdentifier.TIKTOK);
    const [bulkContentTiktokTitle, setBulkContentTiktokTitle] = useState(bulkContentRow?.tiktok_title);
    const [bulkContentAllowDuetOnTikTok, setBulkContentAllowDuetOnTikTok] = useState(bulkContentRow?.allow_duet_on_tiktok);
    const [bulkContentAllowCommentOnTiktok, setBulkContentAllowCommentOnTiktok] = useState(bulkContentRow?.allow_comment_on_tiktok);
    const [bulkContentViewAudienceOnTiktok, setBulkContentViewAudienceOnTiktok] = useState(bulkContentRow?.viewing_audience_on_tiktok);
    const [bulkContentAllowStitchOnTiktok, setBulkContentAllowStitchOnTiktok] = useState(bulkContentRow?.allow_stitch_on_tiktok);
    const tiktokVideoTitle = useSelector(getTiktokVideoTitle);
    const [viewSettings, setViewSettings] = useState(['public to everyone', 'mutual fellow friends', 'private to me']);
    const allowComment = useSelector(getTiktokAllowComments);
    const tiktokAllowDuet = useSelector(getTiktokAllowDuet);
    const tiktokDiscloseVideoContent = useSelector(getTiktokDiscloseVideoContent);
    const tiktokCommercialForYourBrand = useSelector(getTiktokCommercialForYourBrand);
    const tiktokCommercialForBrandedContent = useSelector(getTiktokCommercialForBrandedContent);
    const stitchAllowTiktok = useSelector(getStitchAllowTiktok);
    const selectedViewSetting = useSelector(getTiktokSelectedViewSetting);
    const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
    const [infoBarStatement, setInfoBarStatement] = useState({
        variant: '',
        info: ``
    });
    const [show, setShow] = useState(true);

    const updateBulkContentRow = (updatedRow) => {
        let uploadableBulkContentCopy = [...uploadableBulkContent];
        uploadableBulkContentCopy[rowIndex] = updatedRow;
        dispatch(setUploadableBulkContent(uploadableBulkContentCopy));
    }


    const formatBulkContentStringsValuesIntoView = (value) => {
        if (typeof (value) === 'boolean') {
            return value;
        }
        return ['no', 'No', 'NO'].includes(value) ? false : true;
    }

    const displayTikTokVideoSizeLimit = () => {
        if (!platformVideoUploadLimit) {
            return `N/A`; // Not available
        }
        if (!platformVideoUploadLimit[PlatformIdentifier.TIKTOK]) {
            return `N/A`; // Not available
        }
        const statement = FigureUtils.convertBytes(platformVideoUploadLimit[PlatformIdentifier.TIKTOK])?.value
        if (platformVideoUploadLimit?.canUpgrade) {
            return `${statement} (Upgrade to increase limit)`
        }
        return statement;
    }

    const prompter = () => {
        if (!tiktokDiscloseVideoContent) return;
        if (!tiktokCommercialForYourBrand && !tiktokCommercialForBrandedContent) {
            toast.info("Please select either 'Your brand' or 'Branded content' to continue", {
                theme: 'colored'
            });
            setInfoBarStatement({
                variant: '',
                info: ''
            })
            return;
        }
        if (tiktokCommercialForYourBrand && tiktokCommercialForBrandedContent) {
            toast.info(`Your video will be labeled as 'Paid partnership'`, {
                theme: 'colored'
            });
            setInfoBarStatement({
                variant: 'info',
                info: `By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/bc-policy/en">Branded Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation.</a>`
            });
            if (selectedViewSetting === 'private to me' || selectedViewSetting === 'mutual fellow friends') {
                toast.error(`You cannot select 'Paid partnership' as your video label and set the viewing audience to 'private to me' or 'mutual fellow friends'. We have changed your viewership to everyone.`, {
                    theme: 'colored'
                });
                dispatch(setTiktokSelectedViewSetting('public to everyone'));
                return;
            }
            return;
        }
        if (tiktokCommercialForYourBrand && !tiktokCommercialForBrandedContent) {
            toast.info(`Your video will be labeled as 'Promotional content'`, {
                theme: 'colored'
            });
            setInfoBarStatement({
                variant: 'info',
                info: `By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation.</a>`
            });
            return;
        }
        if (!tiktokCommercialForYourBrand && tiktokCommercialForBrandedContent) {
            toast.info(`Your video will be labeled as 'Paid partnership'`, {
                theme: 'colored'
            });
            setInfoBarStatement({
                variant: 'info',
                info: `By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/bc-policy/en">Branded Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation.</a>`
            });
            if (selectedViewSetting === 'private to me' || selectedViewSetting === 'mutual fellow friends') {
                toast.error(`You cannot select 'Paid partnership' as your video label and set the viewing audience to 'private to me' or 'mutual fellow friends. We have changed your viewership to everyone.`, {
                    theme: 'colored'
                });
                dispatch(setTiktokSelectedViewSetting('public to everyone'));
                return;
            }
            return;
        }
    }

    useEffect(() => {
        prompter()
    }, [tiktokCommercialForYourBrand, tiktokCommercialForBrandedContent, tiktokDiscloseVideoContent])


    return (
        <Card className='mb-3'>
            <FalconCardHeader
                title={bulkContentMode ? "Tiktok" : "For Tiktok"}
                endEl={
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                    >
                        <div
                            style={{
                                width: 20,
                                height: 20,
                                borderRadius: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: tiktokPlatform.color
                            }}>
                            <FontAwesomeIcon
                                icon={tiktokPlatform.icon}
                                style={{
                                    color: 'white',
                                    height: 12,
                                    width: 12
                                }}
                            />
                        </div>
                        <Flex
                            className="ms-2"
                            // alignItems="center"
                            // justifyContent="center"
                            style={{ cursor: 'pointer' }}
                        >
                            {
                                show ?
                                    <IoIosArrowUp
                                        onClick={() => setShow(false)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    /> :
                                    <IoIosArrowDown
                                        onClick={() => setShow(true)}
                                        style={{
                                            // color: 'black',
                                            height: 20,
                                            width: 20
                                        }}
                                    />
                            }
                        </Flex>
                    </Flex>
                } />
            {
                show &&
                <Card.Body className="bg-light">
                    <Flex direction={'column'} className={'gap-3'}>
                        <EditableSection
                            style={{
                                width: '100%'
                            }}
                            title={"Title"}
                            type={'textarea'}
                            bulkContentMode={bulkContentMode}
                            placeholder={bulkContentMode ? "Title" : "Enter title for Tiktok"}
                            value={bulkContentMode ? bulkContentTiktokTitle : tiktokVideoTitle}
                            onChange={(value) => {
                                if (bulkContentMode) {
                                    let updatedRow = { ...bulkContentRow }
                                    if (!value) {
                                        value = " ";
                                    }
                                    updatedRow.tiktok_title = value;
                                    setBulkContentTiktokTitle(value);
                                    updateBulkContentRow(updatedRow);
                                } else {
                                    dispatch(setTiktokVideoTitle(value))
                                }
                            }}
                        />

                        <Form.Check
                            type='switch'
                            id='eventsPanel4'
                            checked={tiktokDiscloseVideoContent}
                            label='Disclose video content'
                            onChange={(e) => {
                                let newValue = e.target.checked;
                                dispatch(setTiktokDiscloseVideoContent(newValue));
                            }}
                        />
                        <p style={{ fontSize: '0.8rem', margin: 0, padding: 0, marginTop: -25 }}>
                            Turn on to disclose that this video promotes goods or services in exchange for something of value.
                        </p>
                        {
                            tiktokDiscloseVideoContent && <>

                                <Form.Check
                                    type='switch'
                                    id='eventsPanel5'
                                    checked={tiktokCommercialForYourBrand}
                                    label='Your brand'
                                    style={{
                                        marginLeft: '1.5rem'
                                    }}
                                    onChange={(e) => {
                                        let newValue = e.target.checked;
                                        dispatch(setTiktokCommercialForYourBrand(newValue));
                                    }}
                                />
                                <p style={{ fontSize: '0.8rem', margin: 0, padding: 0, marginTop: -25, marginLeft: '1.5rem' }}>
                                    You are promoting yourself or your own business.
                                </p>
                            </>

                        }
                        {
                            tiktokDiscloseVideoContent &&
                            <>
                                <Form.Check
                                    type='switch'
                                    id='eventsPanel6'
                                    checked={tiktokCommercialForBrandedContent}
                                    label='Branded content'
                                    style={{
                                        marginLeft: '1.5rem'
                                    }}
                                    onChange={(e) => {
                                        let newValue = e.target.checked;
                                        dispatch(setTiktokCommercialForBrandedContent(newValue));
                                    }}
                                />
                                <p style={{ fontSize: '0.8rem', margin: 0, padding: 0, marginTop: -25, marginLeft: '1.5rem' }}>
                                    You are promoting another brand or a third party.
                                </p>
                            </>
                        }

                        <Form.Group>
                            <Form.Label>Choose your viewing audience</Form.Label>
                            <Form.Select
                                value={bulkContentMode ? bulkContentViewAudienceOnTiktok : selectedViewSetting}
                                onChange={(e) => {
                                    if (bulkContentMode) {
                                        let updatedRow = { ...bulkContentRow }
                                        if (!e) {
                                            e = " ";
                                        }
                                        updatedRow.viewing_audience_on_tiktok = e;
                                        setBulkContentViewAudienceOnTiktok(e);
                                        updateBulkContentRow(updatedRow);
                                    } else {
                                        dispatch(setTiktokSelectedViewSetting(e.target.value))
                                    }
                                }}>
                                {
                                    viewSettings.map(x => {
                                        if (tiktokDiscloseVideoContent) {
                                            if (x === 'private to me' || x === 'mutual fellow friends') {
                                                return null;
                                            }
                                        }
                                        return <option value={x}>{x}</option>
                                    })
                                }
                            </Form.Select>
                        </Form.Group>

                        <Form.Check
                            type='switch'
                            id='eventsPanel'
                            checked={bulkContentMode ? formatBulkContentStringsValuesIntoView(bulkContentAllowCommentOnTiktok) : allowComment}
                            label='Allow comment on TikTok'
                            onChange={(e) => {
                                if (bulkContentMode) {
                                    let updatedRow = { ...bulkContentRow }
                                    if (!e) {
                                        e = " ";
                                    }
                                    updatedRow.allow_comment_on_tiktok = e;
                                    setBulkContentAllowCommentOnTiktok(e);
                                    updateBulkContentRow(updatedRow);
                                } else {
                                    let newValue = e.target.checked;
                                    dispatch(setTiktokAllowComments(newValue));
                                }
                            }}
                        />
                        {
                            !tiktokDiscloseVideoContent &&
                            <Form.Check
                                type='switch'
                                id='eventsPanel2'
                                checked={bulkContentMode ? formatBulkContentStringsValuesIntoView(bulkContentAllowDuetOnTikTok) : tiktokAllowDuet}
                                label='Allow duet on TikTok'
                                onChange={(e) => {
                                    if (bulkContentMode) {
                                        let updatedRow = { ...bulkContentRow }
                                        if (!e) {
                                            e = " ";
                                        }
                                        updatedRow.allow_duet_on_tiktok = e;
                                        setBulkContentAllowDuetOnTikTok(e);
                                        updateBulkContentRow(updatedRow);
                                    } else {
                                        let newValue = e.target.checked;
                                        dispatch(setTiktokAllowDuet(newValue));
                                    }
                                }}
                            />
                        }

                        {
                            !tiktokDiscloseVideoContent &&
                            <Form.Check
                                type='switch'
                                id='eventsPanel3'
                                checked={bulkContentMode ? formatBulkContentStringsValuesIntoView(bulkContentAllowStitchOnTiktok) : stitchAllowTiktok}
                                label='Allow stitch on TikTok'
                                onChange={(e) => {
                                    if (bulkContentMode) {
                                        let updatedRow = { ...bulkContentRow }
                                        if (!e) {
                                            e = " ";
                                        }
                                        updatedRow.allow_stitch_on_tiktok = e;
                                        setBulkContentAllowStitchOnTiktok(e);
                                        updateBulkContentRow(updatedRow);
                                    } else {
                                        let newValue = e.target.checked;
                                        dispatch(setStitchAllowTiktok(newValue));
                                    }
                                }}
                            />
                        }
                    </Flex>
                    <Alert variant="warning" className="p-2 mb-0">
                        <Flex>
                            <FontAwesomeIcon icon="exclamation-triangle" className="fs-2" />
                            <div className="ms-3 flex-1">
                                <h4 className="alert-heading">Please note</h4>
                                <p>(a) Supported media formats: MP4-recommended, WebM, Mov.</p>
                                <p>(b) Duration restrictions: All TikTok creators can post 3-minute videos, while some have access to post 5-minute or 10-minute videos.</p>
                                <p>(c) Size restrictions: Maximum of {displayTikTokVideoSizeLimit()}.</p>
                                <p> (d) Video for TikTok should have a format of 1080 x 1920 px.</p>
                                <p>(e) Note: When posting on TikTok, remember that the platform only displays the content written in the title field. Please ensure to include all necessary details in the title field to effectively communicate your message.</p>
                            </div>
                        </Flex>
                    </Alert>
                    {
                        infoBarStatement?.info &&
                        <InfoBar
                            variant={infoBarStatement?.variant}
                            info={infoBarStatement?.info}
                        />
                    }
                </Card.Body>
            }
        </Card >
    );
}