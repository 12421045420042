import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import {
    getBulkWorkSpacesToCreate,
    setBulkWorkSpacesToCreate
} from "redux/slices/workspaceslice";

const BulkContentSelect = (props) => {

    const {
        rowIndex = 0,
        property,
        options = []
    } = props;
    const dispatch = useDispatch();
    const uploadableBulkContent = useSelector(getBulkWorkSpacesToCreate);
    const bulkContentRow = uploadableBulkContent[rowIndex] ?? {};
    const [value, setValue] = useState(bulkContentRow[property]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let uploadableBulkContentCopy = [...uploadableBulkContent];
            let updatedRow = { ...bulkContentRow };
            updatedRow[property] = value;
            uploadableBulkContentCopy[rowIndex] = updatedRow;
            dispatch(setBulkWorkSpacesToCreate(uploadableBulkContentCopy));
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [value])

    return (
        <Form.Select
            name={property}
            value={value}
            onChange={(e) => {
                let value = e.target.value;
                if (!value) {
                    value = '';
                }
                setValue(value);
            }}
        >
            {
                options.map(x => {
                    return <option value={x}>{x}</option>
                })
            }
        </Form.Select>
    )
}
export default BulkContentSelect;