import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
// import ReportExportDropdown from '../ReportExportDropdown';
import FollowersByGenderChart from './FollowersByGenderChart';
import TableRow from './TableRow';

const FollowersByGender = ({
  data,
  regionLoading,
  emptyDataMessage,
  tempData,
  title
}) => {
  return (
    <Card className="h-100">
      <FalconCardHeader
        title={title}
        titleTag="h6"
        className="py-3"
        light
      // endEl={
      //   <ReportExportDropdown
      //     reportData={tempData}
      //     reportTitle={title}
      //   />}
      />
      <Card.Body
        as={Flex}
        direction="column"
        justifyContent="between"
        className="py-5"
      >
        {regionLoading && <Flex
          alignItems={'center'}
          justifyContent={'center'}
          className={'vh-50'}
        >
          <Spinner animation='border' size={40} />
        </Flex>}
        {
          !regionLoading &&
          (tempData && tempData.length < 0 ?
            <Flex alignItems={'center'} direction={'column'} justifyContent={'center'} className="my-auto">
              <h4>No Followers by Gender</h4>
              <p>{emptyDataMessage}</p>
            </Flex> :
            <>
              <div className="my-auto py-5 py-md-5">
                <FollowersByGenderChart data={data} />
              </div>
              <div className="border-top">
                <Table size="sm" className="mb-0">
                  <tbody>
                    {data.map(item => (
                      <TableRow key={item.label} data={item} />
                    ))}
                  </tbody>
                </Table>
              </div>
            </>)
        }
      </Card.Body>
    </Card>
  );
};

FollowersByGender.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(TableRow.propTypes.data)).isRequired
};

export default FollowersByGender;
