import AppContext from 'context/Context';
import React, { useContext } from 'react'
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import { lightTheme } from 'utils/theme';
import { Button, Card } from 'react-bootstrap';
import { useState } from 'react';
import MultimediaSelectionDropdown from 'components/common/MultimediaSelectionDropdown';
import CircularButton from 'components/common/circularbutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faImage, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { ActionContainer } from './ImageEditorStyles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RoutePaths } from 'constants';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import FileUploader from 'components/app/file-uploader';
import ConfirmModal from 'components/common/ConfirmModal';
import { ImageContainer } from './image_editor_fullscreen_styles';
import StringUtils from 'utils/string';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { isFreeUser } from 'utils/user_utils';
import { getPublishablePostMedia, setPublishablePostMedia } from 'redux/slices/post_media_slice';
import { setPopupUpgradeAlert } from 'redux/slices/authSlice';

const ImageEditor = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { config } = useContext(AppContext);
    const mediaObject = useSelector(getPublishablePostMedia);
    const [openEditArea, setOpenEditArea] = useState(false);
    const [imageToEditUrl, setImageToEditUrl] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [finalImageData, setFinalImageData] = useState(null);
    const [downloadableData, setDownloadableData] = useState(null);
    const [finishedProcessing, setFinishedProcessing] = useState(false);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [processing, setProcessing] = useState(false);
    const loggedInUser = useSelector(getLoggedInUser);

    const closeImgEditor = () => {
        setOpenEditArea(false);
    };

    useEffect(() => {
        function click(e) {
            if (!e)
                e = window.event;
            if ((e.type && e.type == "contextmenu") || (e.button && e.button == 2) || (e.which && e.which == 3)) {
                if (window.opera)
                    window.alert("");
                return false;
            }
        }
        if (document.layers)
            document.captureEvents(Event.MOUSEDOWN);
        document.onmousedown = click;
        document.oncontextmenu = click;
    }, [])


    const downloadImage = async (imgeUrl, fileName) => {
        if (isFreeUser(loggedInUser)) {
            dispatch(setPopupUpgradeAlert(true))
            return
        }
        const link = document.createElement("a");
        link.href = imgeUrl;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        await link.click();
        setFinishedProcessing(false);
    }

    const urlToFile = async (url, filename, mimeType) => {
        let fileExtension = StringUtils.substringAfterLast(filename, ".");
        if (fileExtension.toLowerCase() === "webp") {
            filename = filename.replace(fileExtension, ".png");
        }
        const res = await fetch(url);
        const buf = await res.arrayBuffer();
        return new File([buf], filename, { type: mimeType });
    };

    const handleSave = async (data, extension) => {
        const { type, name } = imageFile;
        const newFile = await urlToFile(data, name, type);
        const newMedia = {
            url: data,
            is_video: false,
            name: name,
            file: newFile,
            extension: extension,
        }
        setFinalImageData([...mediaObject, newMedia])
        setDownloadableData(newMedia)
        setFinishedProcessing(true);
        setOpenEditArea(false);
    }

    const introArea = (
        <>
            <FileUploader
                hint={"Select or Drag an image here"}
                accept={"image/*"}
                responseType={"image"}
                onFileLoaded={(data) => {
                    let file = URL.createObjectURL(data[0])
                    setImageFile(data[0]);
                    setImageToEditUrl(file);
                    setOpenEditArea(true)
                }}
            />
            {
                isFreeUser(loggedInUser) && <>
                    <hr />
                    <p>
                        To enjoy full features, upgrade to paid plan. 
                        <span style={{ fontWeight: '700' }} 
                            onClick={() =>
                                 navigate(`${RoutePaths.ACCOUNT}?t=billing`)}
                            >Click Here</span>
                    </p>
                </>
            }
        </>
    )

    const ImagePreview = (
        <>
            <ImageContainer>
                <img
                    src={downloadableData?.url}
                    alt="post preview"
                    height="100%"
                    width="100%"
                    style={{ objectFit: "fill" }}
                />
            </ImageContainer>
        </>
    )

    const finalizePrompt = (
        <>
            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                Your Image is ready!
            </p>
            {ImagePreview}
            <hr />
            <ActionContainer>
                <Button
                    variant='outline-primary'
                    className="rounded-pill me-1 mb-1"
                    style={{
                        minWidth: 90
                    }}
                    onClick={() => {
                        dispatch(setPublishablePostMedia(finalImageData));
                        navigate(RoutePaths.POSTS);
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 5,
                        }}>
                        <FontAwesomeIcon
                            icon={faLocationArrow}
                        />
                        <span>Post
                        </span>
                    </div>
                </Button>
                <Button
                    variant='outline-primary'
                    className="rounded-pill me-1 mb-1"
                    style={{
                        minWidth: 90
                    }}
                    onClick={() => {
                        downloadImage(downloadableData?.url, downloadableData?.name);
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 5,
                        }}>
                        <FontAwesomeIcon
                            icon={faDownload}
                        />
                        <span>Download
                        </span>
                    </div>
                </Button>

            </ActionContainer>

        </>
    )

    const editor = <div
        style={{
            position: `fixed`,
            top: 0,
            left: 0,
            height: "100vh",
            width: "100%",
            zIndex: 10,
        }}
    >
        <FilerobotImageEditor
            source={imageToEditUrl}
            theme={
                {
                    palette: {
                        'bg-secondary': !config.isDark ? lightTheme?.rootBackground : '#0b1727',
                        'txt-primary': !config.isDark ? '#2e2e2e' : '#919090',
                    },
                    typography: {
                        fontFamily: 'Roboto, Arial',
                    },
                }
            }
            onBeforeSave={() => false}
            onSave={(editedImageObject, designState) => {
                let canvas = editedImageObject.imageCanvas;
                handleSave(canvas.toDataURL(), editedImageObject?.extension);
            }}
            // showBackButton={true}
            onClose={closeImgEditor}
            annotationsCommon={{
                fill: "#ff0000",
            }}
            Text={{ text: "Postly..." }}
            tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK, TABS.FILTERS, TABS.FINETUNE, TABS.RESIZE]}
            defaultTabId={TABS.ANNOTATE} // or 'Annotate'
            defaultToolId={TOOLS.TEXT} // or 'Text'
        />
    </div>



    return (
        <>
            <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card className="text-center">
                    <Card.Body className="p-5">
                        {
                            !finishedProcessing ? introArea : finalizePrompt
                        }
                        {
                            openEditArea &&
                            editor
                        }
                    </Card.Body>
                </Card>
            </div>
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Action requires Upgrade"}
                message={"Sorry, download option is not avaliable to free users. Upgrade your plan to use the download feature. Thank you!"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(`${RoutePaths.BILLING_STRIPE}`);
                }}
            />
        </>
    )
}

export default ImageEditor