import React from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { TermsAndPolicies } from '../../../../constants';

const NameAndSudomainForm = ({ register, errors }) => {
  return (
    <>
      <WizardInput
        id={'app_name'}
        label="Brand Name"
        name="brand_name"
        placeholder='Use a name other than Postly'
        errors={errors}
        formGroupProps={{ className: 'mb-4' }}
        formControlProps={{
          ...register('brand_name', {
            required: 'App name is very important',
            validate: value => !value.toLowerCase().includes("postly") || 'Brand Name should not include Postly'
          })
        }}
      />
      <WizardInput
        type="text"
        errors={errors}
        label="Subdomain"
        placeholder='Enter a subdomain'
        name="subdomain"
        endArdonmentText={'.postlyai.com'}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('subdomain', {
            required: 'Subdomain field is very important',
            validate: value => !value.includes(".postlyai.com") || 'Subdomain should not include the postlyai.com suffix'
          })
        }}
      />
      <WizardInput
        type="checkbox"
        errors={errors}
        label={
          <>
            I accept the <a target={'_blank'} href={TermsAndPolicies.TERMS_OF_SERVICE}> terms</a> and{' '}
            <a target={'_blank'} href={TermsAndPolicies.PRIVACY_POLICY}> privacy policy</a>
          </>
        }
        name="agreedToTerms"
        formControlProps={{
          ...register('agreedToTerms', {
            required: 'You need to agree to the terms and privacy policy.'
          })
        }}
      />
    </>
  );
};

NameAndSudomainForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func
};

export default NameAndSudomainForm;
