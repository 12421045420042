import React from 'react';
import orderBy from 'lodash/orderBy';
import { toast } from 'react-toastify';

export const messageReducer = (state, action) => {
  const { type, room_id, payload, sortBy, order, isAddToStart, isUpdatedStart } =
    action;
  switch (type) {
    case 'FETCH_MESSAGES':
      if (!payload) {
        return state; // If payload is empty, do not update the state
      }
      // Assuming payload is an array of new messages
      return [...payload];
    // Modify your reducer to handle new chats and messages
    case 'ADD_CHAT':
      if (!payload || !Array.isArray(payload)) {
        return state;
      }
      if (isAddToStart) {
        return [payload, ...state];
      }
      return [...state, ...payload]; // Add the new chat to the existing chats
    case 'ADD_MESSAGE':
      if (room_id !== 0 && !room_id) {
        return state;
      }
      if (isUpdatedStart) {
        const filteredState = state.filter(item => item.room_id !== room_id);
        return [payload, ...filteredState];
      }
      return state.map(item => (item.room_id === room_id ? payload : item));

    // else {
    //   const index = state.findIndex(item => item.room_id === roomId);

    //   if (index !== -1) {
    //     // Create a copy of the state array
    //     const updatedState = [...state];

    //     // Remove the item with the matching roomId from its current position
    //     updatedState.splice(index, 1);

    //     // Add the payload to the beginning of the array
    //     updatedState.unshift(payload);

    //     console.log("🚀 ~ file: arrayReducer.js:45 ~ messageReducer ~ updatedState:", updatedState)
    //     return updatedState;
    //   } else {
    //     // If the item doesn't exist, just return the original state
    //     return state;
    //   }
    // }


    case 'SORT':
      if (!sortBy || !order) {
        return state;
      }
      return orderBy(state, sortBy, order);
    default:
      return state;
  }
};
// export const messageReducer = (state, action) => {
//   const { type, roomId, payload, sortBy, order, isAddToStart, isUpdatedStart } =
//     action;
//   switch (type) {
//     case 'ADD':
//       if (!payload) {
//         return state;
//       }
//       if (state.find(item => item.roomId === payload.roomId)) {
//         toast(
//           <span className="text-warning">Item already exists in the list!</span>
//         );
//         return state;
//       }
//       if (isAddToStart) {
//         return [payload, ...state];
//       }
//       return [...state, payload];
//     case 'REMOVE':
//       if (roomId !== 0 && !roomId) {
//         return state;
//       }
//       return state.filter(item => item.roomId !== roomId);
//     case 'EDIT':
//       if (roomId !== 0 && !roomId) {
//         return state;
//       }
//       if (isUpdatedStart) {
//         const filteredState = state.filter(item => item.roomId !== roomId);
//         return [payload, ...filteredState];
//       }
//       return state.map(item => (item.roomId === roomId ? payload : item));
//     case 'SET_MESSAGES':
//       if (!payload) {
//         return state; // If payload is empty, do not update the state
//       }
//       // Assuming payload is an array of new messages
//       return payload;

//     case 'SORT':
//       if (!sortBy || !order) {
//         return state;
//       }
//       return orderBy(state, sortBy, order);
//     default:
//       return state;
//   }
// };
export const arrayReducer = (state, action) => {
  const { type, id, payload, sortBy, order, isAddToStart, isUpdatedStart } =
    action;
  switch (type) {
    case 'ADD':
      if (!payload) {
        return state;
      }
      if (state.find(item => item.id === payload.id)) {
        toast(
          <span className="text-warning">Item already exists in the list!</span>
        );
        return state;
      }
      if (isAddToStart) {
        return [payload, ...state];
      }
      return [...state, payload];
    case 'REMOVE':
      if (id !== 0 && !id) {
        return state;
      }
      return state.filter(item => item.id !== id);
    case 'EDIT':
      if (id !== 0 && !id) {
        return state;
      }
      if (isUpdatedStart) {
        const filteredState = state.filter(item => item.id !== id);
        return [payload, ...filteredState];
      }
      return state.map(item => (item.id === id ? payload : item));
    case 'SORT':
      if (!sortBy || !order) {
        return state;
      }
      return orderBy(state, sortBy, order);
    default:
      return state;
  }
};
