import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useEffect, useState } from 'react';
import {
    Button, Card,
    OverlayTrigger,
    Table, Tooltip
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../../constants';
import CircularButton from 'components/common/circularbutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import {
    getActiveWorkSpace,
    searchWorkSpace,
    setOpenBulkWorkspaceCreator
} from 'redux/slices/workspaceslice';
import WorkspaceStats from '../../../../utils/workspace_stats';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { isFreeWorkspace } from 'utils/workspace_utils';
import IconButton from 'components/common/IconButton';

const WorkspaceSlotsCard = () => {

    const navigate = useNavigate();
    const [workSpaceStats, setWorkSpaceStats] = useState({});
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getLoggedInUser);
    const workSpace = useSelector(getActiveWorkSpace);

    const initWorkSpaceStats = async () => {
        try {
            let stats = await WorkspaceStats.getSync();
            setWorkSpaceStats(stats);
        } catch (e) { }
    }

    useEffect(() => {
        initWorkSpaceStats();
    }, []);

    return (
        <Card className="mb-3">
            <FalconCardHeader title="All Workspaces" endEl={<CircularButton><span>{loggedInUser?.active_plan["Workspace"]}</span></CircularButton>} />
            <Card.Body>
                <Table>
                    <tbody>
                        <tr key={0}>
                            <td className="bg-100" style={{ width: '30%' }}>
                                You've created
                            </td>
                            <td>
                                {workSpaceStats?.created} out of {loggedInUser?.active_plan["Workspace"]}
                                {
                                    isFreeWorkspace(workSpace?.active_plan) && <IconButton
                                        icon="crown"
                                        variant="success"
                                        iconClassName="me-2"
                                        // className="ml-5"
                                        style={{
                                            marginLeft: 20
                                        }}
                                        onClick={() => {
                                            navigate(RoutePaths.ALL_BILLING)
                                        }}
                                    >
                                        Get unlimited workspaces
                                    </IconButton>
                                }
                            </td>
                        </tr>
                        <tr key={2}>
                            <td className="bg-100" style={{ width: '30%' }}>
                                You were invited
                            </td>
                            <td>{workSpaceStats?.invited}</td>
                        </tr>
                        <tr key={2}>
                            <td className="bg-100" style={{ width: '30%' }}>
                                You archived
                            </td>
                            <td>{workSpaceStats?.archived}</td>
                        </tr>
                    </tbody>
                </Table>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    <Button
                        variant="falcon-default"
                        size="sm"
                        transform="shrink-3"
                        onClick={() => {
                            dispatch(searchWorkSpace(true));
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: 1, alignItems: 'center'
                            }}>
                            <FontAwesomeIcon icon={'eye'} />
                            <span className="d-none d-sm-inline-block ms-1">View Workspaces</span>
                        </div>
                    </Button>
                    <div
                        style={{
                            flex: 1
                        }}></div>
                    <div
                        className='text-end'
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            gap: 10
                        }}>
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id="bulk-workspaces-overlay-trigger">
                                    Create a new Workspace
                                </Tooltip>
                            }>
                            <Button
                                variant="falcon-default"
                                size="sm"
                                transform="shrink-3"
                                onClick={() => {
                                    navigate(RoutePaths.NEW_WORKSPACE);
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 1, alignItems: 'center'
                                    }}>
                                    <FontAwesomeIcon icon={'plus'} />
                                    <span className="d-none d-sm-inline-block ms-1">New</span>
                                </div>
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id="bulk-workspaces-overlay-trigger">
                                    Create multiple workspaces at once from a CSV file
                                </Tooltip>
                            }>
                            <Button
                                variant="falcon-default"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                                onClick={() => {
                                    dispatch(setOpenBulkWorkspaceCreator(true));
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 1,
                                        alignItems: 'center'
                                    }}>
                                    <FontAwesomeIcon icon={faLayerGroup} />
                                    <span className="d-none d-sm-inline-block ms-1">Bulk</span>
                                </div>
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default WorkspaceSlotsCard;
