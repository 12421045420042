/* eslint-disable react-hooks/exhaustive-deps */
import AppContext from 'context/Context';
import React, { useContext } from 'react'
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import { lightTheme } from 'utils/theme';
import { Button, Card, Form } from 'react-bootstrap';
import { useState } from 'react';
import MultimediaSelectionDropdown from 'components/common/MultimediaSelectionDropdown';
import CircularButton from 'components/common/circularbutton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faImage, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { ActionContainer } from './ImageEditorStyles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RoutePaths } from 'constants';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import FileUploader from 'components/app/file-uploader';
import ConfirmModal from 'components/common/ConfirmModal';
import { CreditBox, CreditBoxLeft, ImageBgContainer, ImageContainer, Text } from './image_editor_fullscreen_styles';
import APIService from '../../http/api_service.js';
import DebounceableEditBox from '../app/social/feed/debounceable_edit_box.js'
import { BarLoader } from 'react-spinners';
import ImageBackgroundBiller from './ImageBackgroundBiller';
import { RiCoinsLine } from 'react-icons/ri'
import { BsCart4 } from 'react-icons/bs'
import { getLoggedInUser, setLoggedInUser } from 'redux/slices/user_slice';
import { isFreeUser } from 'utils/user_utils';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { getPublishablePostMedia, setPublishablePostMedia } from 'redux/slices/post_media_slice';
import { setPopupUpgradeAlert } from 'redux/slices/authSlice';

const ImageBackgroundRemoval = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { config } = useContext(AppContext);
    const mediaObject = useSelector(getPublishablePostMedia);
    const [loading, setLoading] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [backgroundImageFile, setBackgroundImageFile] = useState(null);
    const [finalImageData, setFinalImageData] = useState(null);
    const [downloadableData, setDownloadableData] = useState(null);
    const [finishedProcessing, setFinishedProcessing] = useState(false);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [base64img, setBase64Img] = useState(null);
    const [backgroundBase64img, setBackgroundBase64Img] = useState(null);
    const [url, setUrl] = useState('');
    const [bgUrl, setBgUrl] = useState('');
    const [urlValid, setUrlValid] = useState(false);
    const [bgUrlValid, setBgUrlValid] = useState(false);
    const [disableImage, setDisableImage] = useState(false);
    const [canExpand, setCanExpand] = useState(false);
    const [bg_color, setBg_color] = useState('');
    const [format, setFormat] = useState('auto');
    const [viewUrl, setViewUrl] = useState(null);
    const [viewBgUrl, setViewBgUrl] = useState(null);
    const [showBiller, setShowBiller] = useState(false);
    const [image_editor_credits, set_image_editor_credits] = useState(null)
    const [selectedCreditPlanToPurchase, setSelectedCreditPlanToPurchase] = useState(null);
    const [creditMessage, setCreditMessage] = useState(false)
    const [workspaceOwnerData, setWorkspaceOwnerData] = useState(false)
    const workSpace = useSelector(getActiveWorkSpace);
    const loggedInUser = useSelector(getLoggedInUser);
    let user = useSelector(getLoggedInUser);

    const refreshLoggedInUserData = () => {
        APIService.fetchLatestMe((latestMeResponse, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                if (error.toLowerCase().includes('Invalid credentials provided'.toLowerCase())) {
                    window.localStorage.clear();
                    setTimeout(() => {
                        navigate(RoutePaths.AUTHENTICATION);
                    }, 100);
                }
                return;
            }
            let latestUserData = latestMeResponse.data;
            dispatch(setLoggedInUser({ ...latestUserData }));
        });
    }

    const refreshWorkspaceOwnerData = () => {
        APIService.fetchWorkSpaceOwner(workSpace?._id, (response, error) => {
            if (error) {
                console.log(error, "error")
                toast.error(error, { theme: 'colored' });
                if (error?.toLowerCase().includes('Invalid credentials provided'.toLowerCase())) {
                    window.localStorage.clear();
                    setTimeout(() => {
                        navigate(RoutePaths.AUTHENTICATION);
                    }, 100);
                }
                return;
            }
            let workspaceOwner = response.data;
            set_image_editor_credits(workspaceOwner?.image_editor_credits?.value ?? 0);
            if (!workspaceOwner?.image_editor_credits?.value) {
                setCreditMessage(true);
            }
            setWorkspaceOwnerData(workspaceOwner)

        })
    }

    const handlePayment = (pricingData) => {
        let workspace = workSpace;
        const Paddle = window.Paddle;
        Paddle.Checkout.open({
            product: parseInt(pricingData.product),
            email: user?.email,
            passthrough: JSON.stringify({
                accountId: user._id,
                workspaceId: workspace._id,
                plan: pricingData.plan,
                coupon: pricingData.coupon
            }),
            successCallback: () => {
                refreshWorkspaceOwnerData();
            }
        });
    }

    useEffect(() => {
        if (!backgroundImageFile) {
            setViewBgUrl(null)
            return
        }
        let fileUrl = URL.createObjectURL(backgroundImageFile, { autoRevoke: false });
        setViewBgUrl(fileUrl);
        return;
    }, [backgroundImageFile])

    useEffect(() => {
        refreshLoggedInUserData();
        refreshWorkspaceOwnerData();
    }, [])

    useEffect(() => {
        if (!selectedCreditPlanToPurchase) return;
        handlePayment(selectedCreditPlanToPurchase);
        setSelectedCreditPlanToPurchase(null);
    }, [selectedCreditPlanToPurchase])

    const revertAll = () => {
        setImageFile(null);
        setFinalImageData(null);
        setDownloadableData(null);
        setFinishedProcessing(false);
        setOpenUpgradeDialog(false);
        setLoading(false)
        setBase64Img(null)
        setUrl(null);
        setUrlValid(false)
        setDisableImage(false);
        setCanExpand(false);
        setBg_color('');
        setFormat('auto');
        setViewUrl(null);
    }

    const closeImgEditor = () => {
        setLoading(false);
    };

    useEffect(() => {
        if (imageFile) {
            let fileUrl = URL.createObjectURL(imageFile, { autoRevoke: false });
            setViewUrl(fileUrl);
            return;
        }
        if (urlValid) {
            setViewUrl(url);
            return
        }
    }, [imageFile, urlValid, url])
    useEffect(() => {
        if (bgUrlValid) {
            setViewBgUrl(bgUrl);
            return
        }
    }, [bgUrlValid, bgUrl])


    const processImage = async () => {
        if (!image_editor_credits) {
            toast.error('Please buy credits to remove image background.', { theme: 'colored' });
            return;
        }
        setLoading(true);
        setDisableImage(true)
        let requestBody = {
            url,
            size: 'auto',
            type: 'person',
            bg_color: !bgUrl ? bg_color : '',
            format,
            base64img,
            bg_image_url: bgUrl ?? null,
            workspaceOwnerId: workspaceOwnerData?._id,
            workspaceId: workSpace?._id,
        }
        APIService.removeImageBackground(requestBody, (response, error) => {
            if (error) {
                setLoading(false)
                setDisableImage(false)
                toast.error(error, { theme: 'colored' });
                return;
            }
            setLoading(false)
            setDownloadableData(response?.data);
            setDisableImage(false)
            setFinishedProcessing(true);
        });
    }

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    useEffect(async () => {
        if (!imageFile) return;
        const result = await convertFileToBase64(imageFile)
        setBase64Img(result);
    }, [imageFile])

    useEffect(async () => {
        if (!backgroundImageFile) {
            setBackgroundBase64Img(null)
            return;
        }
        const result = await convertFileToBase64(backgroundImageFile)
        setBackgroundBase64Img(result);
    }, [backgroundImageFile])

    useEffect(() => {
        function click(e) {
            if (!e)
                e = window.event;
            if ((e.type && e.type == "contextmenu") || (e.button && e.button == 2) || (e.which && e.which == 3)) {
                if (window.opera)
                    window.alert("");
                return false;
            }
        }
        if (document.layers)
            document.captureEvents(Event.MOUSEDOWN);
        document.onmousedown = click;
        document.oncontextmenu = click;
    }, [])

    const genRand = (len) => {
        return Math.random().toString(36).substring(2, len + 2);
    }

    const downloadImage = async (imgeUrl) => {
        let fileName = imageFile?.name ?? genRand(6);
        if (isFreeUser(loggedInUser)) {
            dispatch(setPopupUpgradeAlert(true))
            return
        }
        const link = document.createElement("a");
        link.href = imgeUrl;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        await link.click();
        // setFinishedProcessing(false);
    }

    const handleSave = (data, extension) => {
        const newMedia = {
            url: data,
            is_video: false,
            name: imageFile?.name ?? genRand(6),
            file: imageFile ?? null,
            extension: extension,
        }
        setFinalImageData([...mediaObject, newMedia])
        // setDownloadableData(newMedia)
        setLoading(false);
    }

    useEffect(() => {
        if (!finishedProcessing) return;
        handleSave(downloadableData, format);
    }, [finishedProcessing])


    const handleEnter = () => {

    }

    const testImage = (url, timeoutT) => {
        setDisableImage(true)
        return new Promise(function (resolve, reject) {
            var timeout = timeoutT || 5000;
            var timer, img = new Image();
            img.onerror = img.onabort = function () {
                clearTimeout(timer);
                setUrlValid(false)
                setBgUrlValid(false)
                setDisableImage(false);
                reject("error");
            };
            img.onload = function () {
                clearTimeout(timer);
                setUrlValid(true)
                setBgUrlValid(true)
                setDisableImage(false)
                resolve("success");
            };
            timer = setTimeout(function () {
                // reset .src to invalid URL so it stops previous
                // loading, but doesn't trigger new load
                img.src = "//!!!!/test.jpg";
                reject("timeout");
            }, timeout);
            img.src = url;
        });
    }

    const introArea = (
        <>

            {
                !urlValid && <FileUploader
                    hint={"Select or Drag an image here to remove background"}
                    accept={"image/*"}
                    responseType={"image"}
                    onFileLoaded={(data) => {
                        let file = URL.createObjectURL(data[0])
                        setImageFile(data[0]);
                    }}
                />
            }
            {
                !imageFile && <DebounceableEditBox
                    title={!viewUrl && 'Or'}
                    type='text'
                    placeholder={"Paste Image Url Here"}
                    onKeyDown={(e) => e.key === 'Enter' && handleEnter()}
                    onChange={(value) => {
                        setUrl(value);
                        testImage(value, 3000);
                    }}
                    style={{
                        width: '100%',
                        marginBottom: 20,
                    }}
                />
            }
            {
                (urlValid || imageFile) && <div style={{ display: 'flex', marginTop: 10 }}>
                    <Form.Check
                        id={"can_expand-prompt_check"}
                        type="checkbox"
                        label={"Additional features"}
                        checked={canExpand}
                        onChange={(value) => {
                            setCanExpand(!canExpand);
                        }}
                    />
                </div>
            }

            {
                (urlValid || imageFile) && canExpand && <div style={{
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 15,
                }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, gap: 10 }}>
                        <Form.Control
                            type="color"
                            id="selectcolor"
                            onChange={(value) => {
                                setBg_color(value?.target?.value, "value")
                            }}
                        />
                        <p className='mt-3'>Background Color for your image. Transparent by default</p>
                    </div>
                    {
                        // !backgroundImageFile &&
                        <DebounceableEditBox
                            type='text'
                            placeholder={"Paste image url you wish to use as background"}
                            // onKeyDown={(e) => e.key === 'Enter' && handleEnter()}
                            onChange={(value) => {
                                setBgUrl(value);
                                testImage(value, 3000);
                            }}
                            style={{
                                width: '100%',
                                marginBottom: 20,
                            }}
                        />
                    }
                    {
                        bgUrlValid && <ImageBgContainer>
                            <img
                                src={bgUrl}
                                alt="post preview"
                                height="100%"
                                width="100%"
                                style={{ objectFit: "fill" }}
                            />
                        </ImageBgContainer>
                    }
                    <Form.Select onChange={(e) => {
                        setFormat(e?.target?.value)
                    }} aria-label="Default select example">
                        <option>Choose Image Format</option>
                        <option value="auto">AUTO</option>
                        <option value="png">PNG</option>
                        <option value="jpg">JPG</option>
                    </Form.Select>
                </div>
            }
            {
                (imageFile || urlValid) && <div style={{ marginBottom: 10, marginTop: 10 }}>
                    <img src={viewUrl} alt="" style={{ maxWidth: 400, maxHeight: 300, objectFit: 'cover' }} />
                </div>
            }
            {
                (imageFile || urlValid) && <Button
                    variant="falcon-default"
                    size="sm"
                    transform="shrink-3"
                    disabled={disableImage}
                    onClick={() => {
                        processImage();
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: 1, alignItems: 'center',
                        }}>
                        <FontAwesomeIcon icon={'eye'} />
                        <span className="d-none d-sm-inline-block ms-1">Remove Background</span>
                    </div>
                </Button>
            }
            {
                isFreeUser(loggedInUser) && <>
                    <hr />
                    <p>
                        To enjoy full features, upgrade to paid plan. <span style={{ fontWeight: '700' }} onClick={() => navigate(`${RoutePaths.ACCOUNT}?t=billing`)}>Click Here</span>
                    </p>
                </>
            }
        </>
    )

    const ImagePreview = (
        <>
            <ImageContainer>
                <img
                    src={downloadableData}
                    alt="post preview"
                    height="100%"
                    width="100%"
                    style={{ objectFit: "fill" }}
                />
            </ImageContainer>
        </>
    )


    const finalizePrompt = (
        <>
            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                Your Image is ready!
            </p>
            <br />
            {ImagePreview}
            <hr />
            <ActionContainer>
                <Button
                    variant='outline-primary'
                    className="rounded-pill me-1 mb-1"
                    style={{
                        minWidth: 90,
                        marginTop: 20
                    }}
                    onClick={() => {
                        dispatch(setPublishablePostMedia(finalImageData));
                        navigate(RoutePaths.POSTS);
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 5,
                        }}>
                        <FontAwesomeIcon
                            icon={faLocationArrow}
                        />
                        <span>Post
                        </span>
                    </div>
                </Button>
                <Button
                    variant='outline-primary'
                    className="rounded-pill me-1 mb-1"
                    style={{
                        minWidth: 90,
                        marginTop: 20
                    }}
                    onClick={() => {
                        downloadImage(downloadableData, downloadableData?.name);
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 5,
                        }}>
                        <FontAwesomeIcon
                            icon={faDownload}
                        />
                        <span>Download
                        </span>
                    </div>
                </Button>

            </ActionContainer>

        </>
    )

    const editor = <div
        style={{
            position: `fixed`,
            top: 0,
            left: 0,
            height: "100vh",
            width: "100%",
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}
    >
        Image is processing. Please wait.
    </div>

    return (
        <>
            {
                <ImageBackgroundBiller
                    setSelectedCreditPlanToPurchase={setSelectedCreditPlanToPurchase}
                    setShowBiller={setShowBiller}
                    showBiller={showBiller}
                    handlePayment={handlePayment}
                    creditMessage={creditMessage}
                />
            }
            <div style={{ width: '100%', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {
                    !showBiller &&
                    <CreditBox>
                        <CreditBoxLeft>
                            <RiCoinsLine size={20} color={'#2569c3'} />
                            <Text>
                                {image_editor_credits} Credits
                            </Text>
                        </CreditBoxLeft>
                        <Button
                            variant="primary"
                            size="sm"
                            transform="shrink-3"
                            onClick={() => {
                                setShowBiller(true)
                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
                                <BsCart4 />
                                Buy Credits
                            </div>
                        </Button>
                    </CreditBox>
                }
                {
                    !finishedProcessing && !loading &&
                    <Card className="text-center">
                        <Card.Body className="p-5">
                            {
                                introArea
                            }

                        </Card.Body>
                    </Card>
                }
                {
                    !finishedProcessing && loading &&
                    <BarLoader
                        color={'blue'}
                        loading={loading}
                        size={150}
                    />
                }
                {
                    finishedProcessing && <Card className="text-center">
                        <Card.Body className="p-5">
                            {
                                finalizePrompt
                            }

                        </Card.Body>
                    </Card>
                }
            </div>
            <ConfirmModal
                open={creditMessage}
                title={"Credits Required"}
                message={"You need Credits to use this feature"}
                confirmText={"Buy Credit"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setCreditMessage(false);
                }}
                onConfirm={() => {
                    setCreditMessage(false);
                    setShowBiller(true);
                }}
            />
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Action requires Upgrade"}
                message={"Sorry, download option is not avaliable to free users. Upgrade your plan to use the download feature. Thank you!"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(`${RoutePaths.BILLING_STRIPE}`);
                }}
            />
        </>
    )
}

export default ImageBackgroundRemoval