import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import APIService from 'http/api_service';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    getDatePeriod,
    getOpenExportModal,
    getPageId,
    setDatePeriod,
    setInsightData,
    setOpenExportModal,
    setOverviewData
} from 'redux/slices/analytics_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { convertToTitleFormat } from 'utils/platform_utils';
import DateSelection from '../../DateSelection/DateSelection';
import YoutubeOverview from '../../Overview';
import PlatformHeader from '../../PlatformHeader';
import ExportReportHeader from '../../ReportExportHeader';
import ReportExportModal from "../../ReportExportPreview";
import YoutubeAudience from '../../audience/Audience';
import TopPosts from '../../top-posts/TopPosts';

const YoutubeAnalyticsPage = (props) => {
    const dispatch = useDispatch();
    const { account, platform } = props;

    const pageId = useSelector(getPageId);
    const workSpace = useSelector(getActiveWorkSpace);
    const datePeriod = useSelector(getDatePeriod);
    const openExportModal = useSelector(getOpenExportModal);
    const [pages, setPages] = useState([]);
    const [channelProfileStats, setChannelProfileStats] = useState([]);
    const [audienceData, setAudienceData] = useState([]);
    const [audienceDataLoading, setAudienceDataLoading] = useState(false);
    const [audienceValue, setAudienceValue] = useState([]);
    const [topPosts, setTopPosts] = useState([]);
    const [postLoading, setPostLoading] = useState(false);
    const [reportData, setReportData] = useState([]);

    useEffect(() => {
        APIService.fetchPagesAndGroups(
            workSpace,
            account.identifier,
            account.user_id, (response, error) => {
                if (error) {
                    toast.error(error, { theme: "colored" });
                    return;
                }
                const { data } = response;
                let youtubeChannels = [];
                for (let page of data) {
                    if (!page.self) {
                        youtubeChannels.push(page);
                    }
                }
                setPages(youtubeChannels);
            });
    }, [account]);

    useEffect(() => {
        let { since, until } = datePeriod;
        if (!(since && until)) {
            let startTime = startOfDay(subDays(new Date(), 27));
            let endTime = endOfDay(new Date());
            since = format(startTime, "yyyy-MM-dd");
            until = format(endTime, "yyyy-MM-dd");
            dispatch(setDatePeriod({ since, until }));
        }
    }, [datePeriod]);

    const fetchPageProfileStats = () => {
        dispatch(setOverviewData([]));
        APIService.fetchPageProfileOverview(
            workSpace._id,
            account.user_id,
            { page_id: pageId },
            platform.identifier, (response, error) => {
                if (error) {
                    return;
                }
                if (response) {
                    if (!response.data) {
                        return;
                    }
                    const [responseData] = response?.data ?? [];
                    let profileFields = ['views', 'subscribers', 'videos'];
                    let profileData = profileFields.map(key => ({ title: convertToTitleFormat(key), value: responseData[key] }));
                    setChannelProfileStats(profileData);
                    dispatch(setOverviewData(profileData));
                }
            });
    }

    useEffect(() => {
        if (!pageId) {
            return;
        }
        fetchPageProfileStats();
    }, [pageId]);

    const fetchAudienceInsights = async () => {
        dispatch(setInsightData([]));
        const { since, until } = datePeriod;
        setAudienceDataLoading(true);
        if (pageId) {
            const requestBody = {
                page_id: pageId,
                since: since,
                until: until
            };
            APIService.fetchPageInsights(workSpace._id,
                account.user_id,
                requestBody,
                platform.identifier, (response, error) => {
                    if (error) {
                        setAudienceDataLoading(false);
                        return;
                    }
                    setAudienceDataLoading(false);
                    const responseData = response["data"][0] ?? [];
                    if (responseData) {
                        setReportData([responseData]);
                        const insightKeys = [
                            "views",
                            "comments",
                            "likes",
                            "unlikes",
                            "average_watch_time",
                            "average_view_duration",
                            "new_subscribers",
                            "lost_subscribers",
                            "impressions",
                            "click_through_rate"
                        ];
                        let totalValues = {};
                        let insightValue = {};
                        const pageInsightValues = insightKeys.map((key) => {
                            if (responseData?.hasOwnProperty(key)) {
                                insightValue[key] = responseData[key];
                                let values = insightValue[key]?.length ? insightValue[key] : [];
                                values = values?.map(obj => obj?.value);
                                const totalValue = values?.reduce((acc, curr) => acc + curr, 0);
                                if (key === "average_watch_time") {
                                    const averageHours = Math.floor(totalValue / 60)
                                    const averageMinutes = totalValue % 60;
                                    totalValues[key] = `${averageHours}hrs ${averageMinutes}mins`
                                } else {
                                    totalValues[key] = totalValue;
                                }
                            }
                            else {
                                insightValue[key] = [];
                            };
                            return {
                                title: convertToTitleFormat(key),
                                value: insightValue[key]
                            };
                        });
                        setAudienceValue({ ...totalValues });
                        setAudienceData(pageInsightValues);
                        dispatch(setInsightData(pageInsightValues));
                    }
                });
        }
    }

    useEffect(() => {
        if (!pageId) {
            return;
        }
        fetchAudienceInsights();
    }, [pageId, datePeriod]);

    const fetchTopPosts = () => {
        setPostLoading(true);
        let requestBody = {
            page_id: pageId
        };
        APIService.fetchTopPosts(workSpace._id, account.user_id, requestBody, platform.identifier, (response, error) => {
            if (error) {
                setPostLoading(false);
                toast.error(error, { theme: "colored" });
            }
            setPostLoading(false);
            const { data } = response ?? [];
            setTopPosts(data);
        })
    };

    useEffect(() => {
        if (!pageId) {
            return;
        }
        fetchTopPosts();
    }, [pageId]);

    return (
        <>
            <PlatformHeader pages={pages} platform={platform} account={account} />
            <Row className="g-3 mb-3">
                <Col xxl={8}>
                    <DateSelection />
                </Col>

                <Col lg={12} xxl={12}>
                    <ExportReportHeader identifier={platform.identifier} data={reportData} />
                </Col>

                {channelProfileStats?.length > 0 &&
                    <Row
                        className="mt-3"
                        style={{ backgroundColor: "none" }}>
                        <h5 className="mb-3 mt-2">Overview</h5>
                        {channelProfileStats?.map(({ title, value }) => (
                            <Col lg={6} xxl={4} md={6}>
                                <YoutubeOverview title={title} value={value} />
                            </Col>
                        ))}
                    </Row>
                }

                <Col xxl={8} className="mt-4">
                    <YoutubeAudience
                        values={audienceValue}
                        chartData={audienceData}
                        loading={audienceDataLoading}
                        identifier={platform.identifier}
                        className="mb-3"
                    />
                </Col>

                <Col lg={12} xxl={8}>
                    <TopPosts
                        tableData={topPosts}
                        loading={postLoading}
                        identifier={platform.identifier} />
                </Col>
            </Row>

            {openExportModal &&
                <ReportExportModal
                    show={openExportModal}
                    onHide={() => dispatch(setOpenExportModal(false))}
                />}
        </>
    )
}

export default YoutubeAnalyticsPage;