import React, { useEffect, useState } from "react";
import { Alert, Card, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getBilledYearly } from "redux/slices/workspaceslice";
import { useSelector } from "react-redux";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import IconButton from "components/common/IconButton";
import { getLoggedInUser } from "redux/slices/user_slice";
import MonthlyYearlySwitch from "components/app/account/pricing/MonthlyYearlySwitch";
import { whiteLabelPricing } from "components/app/account/pricing/pricingDataNew";
import { isFreeUser, isFreePostlyWhiteLabel } from "utils/user_utils";
import FalconCardHeader from 'components/common/FalconCardHeader';

import { Offcanvas, Button } from 'react-bootstrap';
import AppContext from 'context/Context';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import APIService from 'http/api_service';
import { Link } from 'react-router-dom';
import { RoutePaths, Strings } from 'constants';


const WhiteLabelSubscriptionCard = ({
  pricing: { buttonText = "All set", isFeatured },
  handleBilling,
  userIsOnWhitelabel,
  handleAIWriterSubscriptionCancel,
  cancellingAIWriterSubscription,
}) => {
  const currentURL = window.location.href;
  const activeWorkspace = useSelector(getActiveWorkSpace);

  const loggedInUser = useSelector(getLoggedInUser);
  const billedYearly = useSelector(getBilledYearly);
  const [customDomainStatus, setCustomDomainStatus] = useState('checking')
  const [customEmailAddressStatus, setCustomEmailAddressStatus] = useState('checking')

  const getAllCustomDomainForWorkSpace = () => {
    APIService.fetchCustomDomainsInWorkSpace(activeWorkspace._id, (response, error) => {
      if (error) {
        console.log("🚀 ~ file: CustomDomains.js:34 ~ APIService.fetchCustomDomainsInWorkSpace ~ error:", error)
        return;
      }
      const { data } = response;
      if (!data?.length) {
        setCustomEmailAddressStatus('issue_found')
        setCustomDomainStatus('issue_found')
        return
      }
      const thereIsUnverifiedDomain = data?.find(d => !d.verified);
      const thereIsUnverifiedCustomEmailAddress = data?.find(d => !d.customEmailVerified);
      if (thereIsUnverifiedCustomEmailAddress) {
        setCustomEmailAddressStatus('issue_found')
      } else {
        setCustomEmailAddressStatus('no_issue')
      }
      if (thereIsUnverifiedDomain) {
        setCustomDomainStatus('issue_found')
      } else {
        setCustomDomainStatus('no_issue')
      }
    });
  }

  useEffect(() => {
    getAllCustomDomainForWorkSpace();
  }, [])

  const hashUpdatableUrl = () => {
    let cancelUrl = !isFreePostlyWhiteLabel.cancel_url;
    let updateUrl = !isFreePostlyWhiteLabel.update_url;
    if (updateUrl) {
      return updateUrl;
    }
    if (cancelUrl) {
      return cancelUrl.replace("cancel", "update");
    }
    return null;
  };

  const updatableUrl = hashUpdatableUrl();

  return (
    <Col md>
      <Card className="border rounded-3 overflow-hidden mb-3 mb-md-0">
        <FalconCardHeader title={<Flex>
          <FontAwesomeIcon icon="palette" className="fs-1 text-info" />
          <div className="ms-1 flex-1">
            <h4 className="alert-heading text-info fs-1">Customization checklist</h4>
          </div>
        </Flex>}
        />
        <Card.Body className="bg-light">
          <>
            <Flex justifyContent="between">
              {loggedInUser.brand_name ?
                <FontAwesomeIcon
                  className="me-2 text-success"
                  icon={faCheckCircle}
                  style={{ marginLeft: 5, marginRight: 5 }}
                /> :
                <FontAwesomeIcon
                  className="me-2 text-danger"
                  icon={faTimesCircle}
                  style={{ marginLeft: 5, marginRight: 5 }}
                />
              }

              <div className="flex-1">
                <h5 className="fs-0">Brand name</h5>
              </div>
              {loggedInUser.brand_name ? null :
                <Button
                  style={{
                    alignSelf: 'flex-end'
                  }}
                  variant='secondary'>
                  Add
                </Button>
              }
            </Flex>
            <hr />
            <Flex justifyContent="between">
              {loggedInUser.brand_logo ?
                <FontAwesomeIcon
                  className="me-2 text-success"
                  icon={faCheckCircle}
                  style={{ marginLeft: 5, marginRight: 5 }}
                /> :
                <FontAwesomeIcon
                  className="me-2 text-danger"
                  icon={faTimesCircle}
                  style={{ marginLeft: 5, marginRight: 5 }}
                />
              }
              <div className="flex-1">
                <h5 className="fs-0">Brand logo</h5>
              </div>
              {loggedInUser.brand_logo ? null :
                <Button
                  onClick={() => {
                    window.location.href = '#postly-white-label-brand-logo'; // Replace 'https://example.com' with your desired URL
                  }}
                  style={{
                    alignSelf: 'flex-end'
                  }}
                  variant='secondary'>
                  Add
                </Button>
              }
            </Flex>
            <hr />
            <Flex justifyContent="between">
              {loggedInUser.brand_privacy_policy ?
                <FontAwesomeIcon
                  className="me-2 text-success"
                  icon={faCheckCircle}
                  style={{ marginLeft: 5, marginRight: 5 }}
                /> :
                <FontAwesomeIcon
                  className="me-2 text-danger"
                  icon={faTimesCircle}
                  style={{ marginLeft: 5, marginRight: 5 }}
                />
              }

              <div className="flex-1">
                <h5 className="fs-0">Brand privacy policy</h5>
              </div>
              {loggedInUser.brand_privacy_policy ? null :
                <Button
                  style={{
                    alignSelf: 'flex-end'
                  }}
                  variant='secondary'>
                  Add
                </Button>
              }
            </Flex>
            <hr />
            <Flex justifyContent="between">
              {loggedInUser.brand_term_of_service ?
                <FontAwesomeIcon
                  className="me-2 text-success"
                  icon={faCheckCircle}
                  style={{ marginLeft: 5, marginRight: 5 }}
                /> :
                <FontAwesomeIcon
                  className="me-2 text-danger"
                  icon={faTimesCircle}
                  style={{ marginLeft: 5, marginRight: 5 }}
                />
              }

              <div className="flex-1">
                <h5 className="fs-0">Brand terms of service</h5>
              </div>
              {loggedInUser.brand_term_of_service ? null :
                <Button
                  style={{
                    alignSelf: 'flex-end'
                  }}
                  variant='secondary'>
                  Add
                </Button>
              }
            </Flex>
            <hr />

            {/* <hr />
            <Flex justifyContent="between">
              {loggedInUser.white_label_brand_primary_color ?
                <FontAwesomeIcon
                  className="me-2 text-success"
                  icon={faCheckCircle}
                  style={{ marginLeft: 5, marginRight: 5 }}
                /> :
                <FontAwesomeIcon
                  className="me-2 text-danger"
                  icon={faTimesCircle}
                  style={{ marginLeft: 5, marginRight: 5 }}
                />
              }
              <div className="flex-1">
                <h5 className="fs-0">Brand colors</h5>
              </div>
              {loggedInUser.white_label_brand_primary_color ? null :
                <Button
                  onClick={() => {
                    window.location.href = '#postly-white-label-brand-primary-color'; // Replace 'https://example.com' with your desired URL
                  }}
                  style={{
                    alignSelf: 'flex-end'
                  }}
                  variant='secondary'>
                  Add
                </Button>
              }
            </Flex> */}
            <Flex justifyContent="between">
              {loggedInUser.subdomain ?
                <FontAwesomeIcon
                  className="me-2 text-success"
                  icon={faCheckCircle}
                  style={{ marginLeft: 5, marginRight: 5 }}
                /> :
                <FontAwesomeIcon
                  className="me-2 text-danger"
                  icon={faTimesCircle}
                  style={{ marginLeft: 5, marginRight: 5 }}
                />
              }
              <div className="flex-1">
                <h5 className="fs-0">Subdomain</h5>
              </div>
              {loggedInUser.subdomain ? null :
                <Button
                  style={{
                    alignSelf: 'flex-end'
                  }}
                  variant='secondary'>
                  Add
                </Button>
              }
            </Flex>
            <hr />
            <Flex justifyContent="between">
              <FontAwesomeIcon
                className={customDomainStatus === 'checking' ? 'me-2 text-info' : customDomainStatus === 'issue_found' ? 'me-2 text-danger' : 'me-2 text-success'}
                icon={customDomainStatus === 'no_issue' ? faCheckCircle : faTimesCircle}
                style={{ marginLeft: 5, marginRight: 5 }}
              />
              <div className="flex-1">
                <h5 className="fs-0">Custom domain</h5>
              </div>
              {customDomainStatus === 'no_issue' ? "" :
                <Button
                  onClick={() => {
                    window.location.href = '#postly-white-label-custom-domain';
                  }}
                  style={{
                    alignSelf: 'flex-end'
                  }}
                  variant='secondary'>
                  Add
                </Button>
              }

            </Flex>
            <hr />
            <Flex justifyContent="between">
              <FontAwesomeIcon
                className={customEmailAddressStatus === 'checking' ? 'me-2 text-info' : customEmailAddressStatus === 'issue_found' ? 'me-2 text-danger' : 'me-2 text-success'}
                icon={customEmailAddressStatus === 'no_issue' ? faCheckCircle : faTimesCircle}
                style={{ marginLeft: 5, marginRight: 5 }}
              />
              <div className="flex-1">
                <h5 className="fs-0">Custom email address</h5>
              </div>

              {customEmailAddressStatus === 'no_issue' ? "" :
                <Button
                  onClick={() => {
                    window.location.href = '#postly-white-label-custom-domain';
                  }}
                  style={{
                    alignSelf: 'flex-end'
                  }}
                  variant='secondary'>
                  Add
                </Button>
              }
            </Flex>
            <hr />
          </>
          <div className="bg-light">
            {/* Make sure user is on a Paddle verified domain before subscribing. Else, it will fail */}
            {currentURL === `app.postly.ai` || currentURL === `http://localhost:3000` ? (
              <OverlayTrigger
                placement='top'
                className="mr-5"
                overlay={
                  <Tooltip>
                    {(!loggedInUser.brand_name && "Brand name is missing") ||
                      (loggedInUser.plans.active.postly_white_label?.Key === "PostlyWhiteLabelPremium" && "Subscribed") ||
                      (!loggedInUser.subdomain && "Subdomain is missing") ||
                      "Click to continue"
                    }
                  </Tooltip>
                }>
                <span>
                  {!userIsOnWhitelabel &&
                    <Button
                      as={Link}
                      to={RoutePaths.BILLING_STRIPE}
                      disabled={
                        !loggedInUser.brand_name ||
                        !loggedInUser.subdomain
                      }
                      variant={
                        !isFreePostlyWhiteLabel
                          ? "outline-danger"
                          : isFeatured
                            ? "primary"
                            : "success"
                      }
                      className="d-block w-100"
                    >
                      
                      Upgrade to continue
                    </Button>
                  }
                </span>
              </OverlayTrigger>
            ) : (
              <a
                href={`https://app.postly.ai/white-label-settings`}
                className="text-white fw-bold"
              >
                <IconButton
                  icon="rocket"
                  variant="success"
                  iconClassName="me-2"
                  className="w-100"
                >
                  Start trial and launch
                </IconButton>
              </a>
            )}

            {/* {updatableUrl && <div className="mt-3"></div>} */}
            {/* {updatableUrl && (
              <a
                href={updatableUrl}
                className={"text-decoration-none cursor-pointer"}
              >
                <Flex
                  className={"gap-1 text-warning"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <FontAwesomeIcon
                    style={{ width: 10, height: 10 }}
                    icon={faPen}
                  />
                  <span className="fs--1">Update Payment Details</span>
                </Flex>
              </a>
            )} */}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};
export default WhiteLabelSubscriptionCard;
