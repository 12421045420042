import React from 'react'
import { iconStyle, disabledIconStyle } from './icon_styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';

export const FacebookIcon = ({ disabled, type }) => (
    <div style={!disabled ? iconStyle : { ...iconStyle, ...disabledIconStyle }}>
        <FontAwesomeIcon
            icon={faFacebookF}
            style={{
                margin: 5,
                fontSize: 18,
                color: '#4285f4'
            }}
        />
    </div>
)
