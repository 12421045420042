import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import { PlatformIdentifier, RoutePaths } from "constants";
import React, { useState } from "react";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { isFreeWorkspace } from "utils/workspace_utils";
import ConnectedAccount from "../connected_accounts/connected_account";
import { NoteContainer } from "../styles";
import { getCurrentHostnameDomain } from 'utils/domain';

const TwitterConnectionPage = ({ props }) => {
  const {
    processing,
    connectAccount,
    connectedAccounts,
    NewAccountConnectionView,
  } = props;
  const navigate = useNavigate();
  const workSpace = useSelector(getActiveWorkSpace);
  const [twitterApiKey, setTwitterApiKey] = useState("");
  const [twitterApiSecret, setTwitterApiSecret] = useState("");
  const [openTwitterApiKeysInput, setOpenTwitterApiKeysInput] = useState(false);
  const hostnameDomain = getCurrentHostnameDomain();
  const currentURL = window.location.href;

  const howToConnectTwitterCard = (
    <Card
      canHover={false}
      style={{
        width: "100%",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
      }}
    >
      <NoteContainer>
        Please Note:
        <ListGroup as="ul" style={{ border: "none" }}>
          <ListGroup.Item as="li" style={{ border: "none" }}>
            <FontAwesomeIcon icon="check" className="me-1 text-success" />{" "}
            Connect using your Twitter Developer API key.
          </ListGroup.Item>
          <ListGroup.Item as="li" style={{ border: "none" }}>
            <FontAwesomeIcon icon="check" className="me-1 text-success" />{" "}
            Access to the API key is free.
          </ListGroup.Item>
          <ListGroup.Item as="li" style={{ border: "none" }}>
            <FontAwesomeIcon icon="check" className="me-1 text-success" />{" "}
            Remember to create your project and app.
          </ListGroup.Item>
          <ListGroup.Item as="li" style={{ border: "none" }}>
            <FontAwesomeIcon icon="check" className="me-1 text-success" /> Add
            the Callback URL as required.
          </ListGroup.Item>
          <ListGroup.Item as="li" style={{ border: "none" }}>
            <FontAwesomeIcon icon="check" className="me-1 text-success" />{" "}
            Generate your API key and API secret key from the 'Consumer keys'
            section.
          </ListGroup.Item>
          <ListGroup.Item as="li" style={{ border: "none" }}>
            <FontAwesomeIcon icon="check" className="me-1 text-success" /> Use
            the copy icon to directly transfer your keys from Twitter.
          </ListGroup.Item>
          <ListGroup.Item as="li" style={{ border: "none" }}>
            <FontAwesomeIcon icon="check" className="me-1 text-success" /> For a
            detailed guide and demo video:{" "}
            { (currentURL === "app.postly.ai" || currentURL === "localhost") ?
              <a
              href="https://postly.ai/twitter"
              target="_blank"
              rel="noreferrer"
            >
              https://postly.ai/twitter
            </a> :
              <>Contact support</>
            }
            
          </ListGroup.Item>
        </ListGroup>
      </NoteContainer>
    </Card>
  );

  const updateKeysSection = (
    <>
      <Card>
        <Card.Body className="bg-light">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 30,
              }}
            >
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 20,
                  fontWeight: 900,
                  textAlign: "left",
                }}
              >
                Consumer Keys
              </span>

              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={12} controlId="password">
                  <Form.Label>{"Enter your Twitter API Key"}</Form.Label>
                  <Form.Control
                    type={"password"}
                    value={twitterApiKey}
                    placeholder={"Twitter API Key"}
                    onChange={(e) => {
                      setTwitterApiKey(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} lg={12} controlId="password">
                  <Form.Label>{"Enter your Twitter API Secret"}</Form.Label>
                  <Form.Control
                    type={"password"}
                    value={twitterApiSecret}
                    placeholder={"Twitter API Secret"}
                    onChange={(e) => {
                      setTwitterApiSecret(e.target.value);
                    }}
                  />
                </Form.Group>
              </Row>
              <Button
                variant={"primary"}
                disabled={processing}
                isWorking={processing}
                onClick={() =>
                  connectAccount({
                    [PlatformIdentifier.TWITTER]: {
                      api_key: twitterApiKey,
                      api_secret: twitterApiSecret,
                    },
                  })
                }
                style={{
                  color: "white",
                  minWidth: "100%",
                  display: "flex",
                  fontSize: 16,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {processing ? "Please wait..." : "Connect Twitter Account"}
              </Button>
            </div>
            {howToConnectTwitterCard}
          </div>
        </Card.Body>
      </Card>
    </>
  );

  const connectPage = (
    <Card>
      <Card.Body className="bg-light">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 30,
            }}
          >
            <Button
              variant={"primary"}
              disabled={processing}
              isWorking={processing}
              onClick={() =>
                setOpenTwitterApiKeysInput(!openTwitterApiKeysInput)
              }
              style={{
                color: "white",
                minWidth: "100%",
                display: "flex",
                fontSize: 16,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {processing ? "Please wait..." : "Connect"}
            </Button>
          </div>
          {howToConnectTwitterCard}
        </div>
      </Card.Body>
    </Card>
  );

  const atLeastOneAccountIsConnectedView = (
    <>
      <Flex direction={"column"} justifyContent={"center"}>
        <Flex
          alignItems={"center"}
          className={"gap-3 pt-2"}
          justifyContent={"center"}
          wrap={"wrap"}
        >
          {connectedAccounts?.length > 0 &&
            connectedAccounts.map((account, index) => {
              return (
                <div key={account._id} id={account._id}>
                  <ConnectedAccount workSpace={workSpace} account={account} />
                </div>
              );
            })}
          <NewAccountConnectionView
            setOpenTwitterApiKeysInput={setOpenTwitterApiKeysInput}
          />
          {howToConnectTwitterCard}
        </Flex>
      </Flex>
    </>
  );

  if (openTwitterApiKeysInput) {
    return updateKeysSection;
  }

  if (connectedAccounts?.length > 0) {
    return atLeastOneAccountIsConnectedView;
  }
  return connectPage
  
};

export default TwitterConnectionPage;
