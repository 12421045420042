/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import {
  faBookmark,
  faComment,
  faFaceSmile,
  faHeart,
  faPaperPlane,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAt, faEllipsis, faImage,
  faPencil,
  faRemove,
  faVideo
} from "@fortawesome/free-solid-svg-icons";
import MultimediaSelectionDropdown from "components/common/MultimediaSelectionDropdown";
import parse from "html-react-parser";
import React, { useContext, useEffect, useRef, useState } from "react";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import {
  canOpenPixelFilePickerDialog,
  getGoogleDriveManager,
  getManuallyEditedPlatforms,
  getMediaAddedViaSpecificPlaformUrlMethod,
  getNotifyPreviewsOfLiveMediaChanges,
  getSelectingPhotoFromHome,
  getSpecificMediaPlatformTied,
  getUserTags,
  setGoogleDriveManager,
  setManuallyEditedPlatforms,
  setOpenPixelFilePickerDialog,
  setSpecificMediaPlatformTied,
  setUserTags
} from "../../../../../redux/slices/postslice";

import { useDispatch, useSelector } from "react-redux";
import { Swiper } from "swiper/react";
import {
  DotsBox,
  // EditContainer,
  FirstLayer,
  ImageDelete,
  // ImageHolder,
  Info,
  LeftAction,
  ParentSection,
  PostImage,
  PreviewContentArea,
  RightAction,
  SecondLayer,
  ThirdLayer,
  Wrapper
} from "./styles/instagram";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "components/common/ConfirmModal";
import PopDrop from "components/common/PopDrop";
import CircularButton from "components/common/circularbutton";
import GDriveDashboard from "components/common/g-drive/dashboard";
import AppContext from "context/Context";
import { Button, Modal } from "react-bootstrap";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { FaTag, FaUserAlt } from "react-icons/fa";
import { RiDragDropLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { setSelectedPlatformForPreview } from "redux/slices/platformSlice";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import recursiveAspectFinder from "utils/recursiveAspectFinder";
import { darkTheme, lightTheme } from "utils/theme";
import { PlatformData, PlatformIdentifier } from "../../../../../constants";
import { platforms } from "../../../../app/platforms";
import TaggingPop from "../TaggingPop";
import DragToPositionImage from "./DragToPositionImage";
import ExtrernalImageSearchDialogue from "./ExtrernalImageSearchDialogue";
import GDriveConnectionRequestListener from "./GDriveConnectionRequestListener";
import IgTagDrop from "./IgTagDrop";
import "./addOnsStyles.css";
import Carousel from "./carousel";
import EditTextBox, { reformatHashTexts } from "./editTextBox.js";
import IgTagUsersComponent from "./igImageTag/IgTagUsersComponent";
import InstagramMediaDisplay from "./instagram_media_display";
import PlayMediaInstagram from "./playMediaInstagram.js";
import { scanImages } from "./scanImages";
import InfoBar from "components/common/InfoBar";
import Flex from "components/common/Flex";
// import TextareaAutoSize from "react-textarea-autosize";
import { displayPlatformIcon } from "utils/platform_utils";
import { url_replacer } from "utils/url";
import { getPlatformVideoUploadLimit } from "redux/slices/workspaceslice";
import FigureUtils from "utils/figures";


const Instagram = ({ viewOnly }) => {
  const dispatch = useDispatch();
  const googleDriveManager = useSelector(getGoogleDriveManager)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const openPixelDialoguePicker = useSelector(canOpenPixelFilePickerDialog);
  const [toggleEditTextInput, setToggleEditTextInput] = useState(false);
  const mediaAddedViaSpecificPlaformUrlMethod = useSelector(
    getMediaAddedViaSpecificPlaformUrlMethod
  );
  const specificMediaPlatformTied = useSelector(getSpecificMediaPlatformTied);
  const mediaObjectsTotalLength = 10;
  const media_items_arrangement = useSelector(getMediaArrangement);
  const [max_min_imageSize, setMax_min_imageSize] = useState(null);
  const [useableMedia, setUseableMedia] = useState([]);
  const [openMediaSelction, setOpenMediaSelection] = useState(false);
  const [currentMediaType, setCurrentMediaType] = useState(null);
  const selectingPhotoFromHome = useSelector(getSelectingPhotoFromHome);
  const myRef = useRef(null);
  const focusImageRef = useRef(null);
  const textEditRef = useRef(null);
  const [timer, setTimer] = useState();
  const [limitedPostTextBody, setLimitedPostTextBody] = useState("");
  const [openEditArea, setOpenEditArea] = useState(false);
  const [imageToEditUrl, setImageToEditUrl] = useState("");
  const [comparebleUrl, setComparebleUrl] = useState("");
  const [popDropClose, setPopDropClose] = useState(false)
  const [repostionImages, setRepositionImages] = useState(false)
  const [openImageTaggingPop, setOpenImageTaggingPop] = useState(false)
  const [canOPenPopOver, setCanOpenPopOver] = useState(true);
  const [openVideoEditArea, setOpenVideoEditArea] = useState(false);
  const [blockMediaItemsUpdate, setBlockMediaItemsUpdate] = useState(true);
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const [formattedPostBodyForLinks, setFormattedPostBodyForLinks] =
    useState("");
  const userTags = useSelector(getUserTags);
  const googleDriveRef = useRef(null);
  const platformsWithLimits = [...platforms];
  const { config } = useContext(AppContext);
  const [currentTaggableImageIndex, setCurrentTaggableImageIndex] = useState(0);
  const tags = useSelector(getUserTags)
  const [canUpdateTags, setCanUpdateTags] = useState(true)
  const notifyPreviewsOfLiveMediaChanges = useSelector(getNotifyPreviewsOfLiveMediaChanges);
  const [platform] = useState(PlatformIdentifier.INSTAGRAM)
  const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
  let typingTimeout;

  const arrangeTagsForTaggableImages = () => {
    const video = useableMedia.find((media) => media.is_video === true);
    if (video) {
      setCanUpdateTags(false)
      dispatch(setUserTags([]));
      return;
    }
    const _useableMedia = [...useableMedia];
    const _tags = [...tags];
    const newTags = [];
    for (let i = 0; i < _useableMedia.length; i++) {
      const _media = _useableMedia[i];
      const _mediaTags = _tags.filter((tag) => tag.imageUrl === _media.url);
      const replacedTags = _mediaTags.map((tag) => {
        return {
          ...tag,
          index: i,
        };
      });
      newTags.push(...replacedTags);
    }
    setCanUpdateTags(false)
    dispatch(setUserTags(newTags));
  }

  useEffect(() => {
    arrangeTagsForTaggableImages()
  }, [useableMedia])

  useEffect(() => {
    if (!canUpdateTags) return;
    arrangeTagsForTaggableImages()
  }, [tags])


  const handleTaggableImageIndexChange = (action) => {
    if (action === "increment") {
      if (currentTaggableImageIndex < useableMedia.length - 1) {
        setCurrentTaggableImageIndex(currentTaggableImageIndex + 1);
      }
    } else if (action === "decrement") {
      if (currentTaggableImageIndex > 0) {
        setCurrentTaggableImageIndex(currentTaggableImageIndex - 1);
      }
    }
  }

  let editInfoText = `Instagram images should be at least 1080 x 566`;

  const closeImgEditor = () => {
    setOpenEditArea(false);
  };

  useEffect(async () => {
    const imagesMedia = media_items_arrangement?.instagram?.media?.filter(
      (x) => x.is_video === false
    );
    await scanImages(imagesMedia);
    setUseableMedia(media_items_arrangement?.instagram?.media);
  }, [notifyPreviewsOfLiveMediaChanges]);

  const mediaRemoveHandler = (removableMediaObject) => {
    try {
      let cachedArrayMedia = useableMedia;
      const filterMedia = cachedArrayMedia?.filter(
        (item) => item.url !== removableMediaObject.url
      );
      setBlockMediaItemsUpdate(false);
      setUseableMedia((prevState) => (prevState = filterMedia));
    } catch (error) { }
  };

  const handleDeleteMedia = (data) => {
    if (!manuallyEditedPlatforms[PlatformIdentifier.INSTAGRAM]) {
      const newData = { ...manuallyEditedPlatforms, instagram: true };
      dispatch(setManuallyEditedPlatforms(newData || {}));
    }
    mediaRemoveHandler(data);
  };

  useEffect(() => {
    const shouldOpenTextEdit = localStorage.getItem('open_hashtag_pop');
    if (shouldOpenTextEdit === 'true') {
      handleEditClick();
    }
  }, [])

  const updateMediaItems = async (
    files,
    canCreateObjectURL = true,
    moreProps = {}
  ) => {
    let expectedFilesLength = files.length + useableMedia.length;
    if (
      files.length > mediaObjectsTotalLength ||
      useableMedia.length > mediaObjectsTotalLength || expectedFilesLength > mediaObjectsTotalLength
    ) {
      toast.error(
        `Sorry, you can only upload a maximum of ${mediaObjectsTotalLength} files at a time`,
        { theme: "colored" }
      );
      return;
    }

    let mo = [];
    for (let file of files) {
      if (canCreateObjectURL) {
        let fileUrl = URL.createObjectURL(file);
        let fileName = file.name;
        let existingFile = mo.find((x) => x.url === fileUrl);
        if (!existingFile) {
          let fileExtension = `.${fileName.split(".").pop()}`;
          let newThreadObject = {
            name: fileName,
            url: fileUrl,
            extension: fileExtension,
            file: file,
            ...moreProps,
          };
          if (file?.size / 1000000 > 8 && moreProps?.is_video === false) {
            toast.error(
              `Image(s) above 8MB have been removed from Instagram. Kindly upload images below 8MB.`,
              { theme: "colored" }
            );
            return;
          }

          if (platformVideoUploadLimit[platform] && file.size > platformVideoUploadLimit[platform]) {
            let errorMsg = `${file?.name} have been removed from ${platform} because it exceeds the allowed sized limit of ${FigureUtils.convertBytes(platformVideoUploadLimit[platform])?.value}.`
            if (platformVideoUploadLimit['canUpgrade']) {
              errorMsg = errorMsg + ` Upgrade your plan to get more upload limits.`;
            }
            toast.error(errorMsg, { theme: "colored" });
          } else {
            mo.push(newThreadObject);
          }
        }
      } else {
        let fileWithName = mo.find((item) => item.name === file.name);
        if (!fileWithName) {
          mo.push(file);
        }
      }
    }
    const imagesMedia = mo?.filter((x) => x.is_video === false);
    await scanImages(imagesMedia);
    mo?.map((item) => {
      setBlockMediaItemsUpdate(false);
      setUseableMedia((oldArray) => [...oldArray, item]);
    });
    if (!manuallyEditedPlatforms[PlatformIdentifier.INSTAGRAM]) {
      const newData = { ...manuallyEditedPlatforms, instagram: true };
      dispatch(setManuallyEditedPlatforms(newData || {}));
    }
    setOpenMediaSelection(false);
    return;
  };

  const mediaOpener = (
    <div
      ref={myRef}
      style={{
        position: "relative",
        zIndex: 5,
        width: "96%",
        marginLeft: "4%",
        marginBottom: 20,
        marginTop: 20,
      }}
    >
      {
        <MultimediaSelectionDropdown
          specificPlatformTied={PlatformIdentifier.INSTAGRAM}
          fromPreview={true}
          initiatorId={
            currentMediaType === "image"
              ? `${PlatformIdentifier.INSTAGRAM}-photo-chooser`
              : `${PlatformIdentifier.INSTAGRAM}-video-chooser`
          }
          externalFilePickerTitle={
            currentMediaType === "image"
              ? "Enter Photo Location"
              : "Enter Video Location"
          }
          prompt={
            currentMediaType === "image" ? "Add Photo From" : "Add Video From"
          }
          fileType={currentMediaType === "image" ? "image" : "video"}
          externalPickerPromptLabel={
            currentMediaType === "image" ? "Photo" : "Video"
          }
          multiple={currentMediaType === "image" ? true : false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />
      }
    </div>
  );

  const getImageDimension = (source) => {
    let img = new Image();
    img.src = source;
    return {
      width: img.width,
      height: img.height,
    };
  };

  const handleOpeningEditArea = async (source, width, height) => {
    const { new_width, new_height } = await recursiveAspectFinder(
      1080,
      566,
      width,
      height,
      1
    );
    try {
      const image = new Image();
      image.src = source;
      const canvas = document.createElement("canvas");
      canvas.width = new_width;
      canvas.height = new_height;
      let scale = Math.max(
        canvas.width / image.naturalWidth,
        canvas.height / image.naturalHeight
      );
      let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
      let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
      canvas
        .getContext("2d")
        .drawImage(
          image,
          x,
          y,
          image.naturalWidth * scale,
          image.naturalHeight * scale
        );
      const dataURL = canvas.toDataURL('image/jpeg', 0.95);
      setImageToEditUrl(dataURL);
      setComparebleUrl(source);
      setOpenEditArea(!openEditArea);
    } catch (error) {
      const image = new Image();
      image.src = source;
      image.crossOrigin = "anonymous";
      const canvas = document.createElement("canvas");
      setTimeout(() => {
        canvas.width = 1080;
        canvas.height = 566;
        let scale = Math.max(
          canvas.width / image.naturalWidth,
          canvas.height / image.naturalHeight
        );
        let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
        let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
        canvas
          .getContext("2d")
          .drawImage(
            image,
            x,
            y,
            image.naturalWidth * scale,
            image.naturalHeight * scale
          );
        const dataURL = canvas.toDataURL();
        setImageToEditUrl(dataURL);
        setComparebleUrl(source);
        setOpenEditArea(!openEditArea);
      }, 1000);
    }
  };

  const displaySingleMedia = useableMedia?.map((item, index) => {
    return <InstagramMediaDisplay
      item={item}
      handleOpeningEditArea={handleOpeningEditArea}
      handleDeleteMedia={handleDeleteMedia}
      max_min_imageSize={max_min_imageSize}
      index={index}
      openVideoEditArea={openVideoEditArea}
      setOpenVideoEditArea={setOpenVideoEditArea}
      setUseableMedia={setUseableMedia}
      useableMedia={useableMedia}
      viewOnly={viewOnly}
    />
  });

  const syncNewThread = async () => {
    let syncingThread = [];
    try {
      const mappedThread = useableMedia?.map((thread, index) => {
        const newThreadEntry = {
          name: thread?.name,
          url: thread?.url,
          is_video: thread?.is_video,
          optimized: thread?.optimized ?? false,
          external: thread?.external ?? false,
          uploaded: thread?.uploaded ?? false,
          file: thread?.file,
        };
        return newThreadEntry;
      });
      const oldData = { ...media_items_arrangement };
      const diapatchableData = {
        ...oldData,
        instagram: { ...oldData.instagram, media: mappedThread },
      };
      dispatch(setMediaArrangement(diapatchableData));
    } catch (error) { }
    return syncingThread;
  };

  const handleUpdateTextFromEditBox = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      if (!manuallyEditedPlatforms[PlatformIdentifier.INSTAGRAM]) {
        const newData = { ...manuallyEditedPlatforms, instagram: true };
        dispatch(setManuallyEditedPlatforms(newData || {}));
      }
      const oldData = { ...media_items_arrangement };
      const getTextLimit = platformsWithLimits.find(
        (x) => x.identifier === PlatformIdentifier.INSTAGRAM
      );
      const diapatchableData = {
        ...oldData,
        instagram: {
          ...oldData.instagram,
          text: text.substring(0, getTextLimit.textLimit),
        },
      };
      dispatch(setMediaArrangement(diapatchableData));
    }, 1000);
  };

  useEffect(() => {
    if (!blockMediaItemsUpdate) {
      setBlockMediaItemsUpdate(true);
      syncNewThread();
    }
  }, [useableMedia]);

  const limitChars = (text, limit) => {
    setLimitedPostTextBody(text?.substring(0, limit));
  };

  const waitTime = 1000;

  function runWhenUserStopTying() {
    window.clearTimeout(timer);
    setTimer(
      window.setTimeout(() => {
        if (!manuallyEditedPlatforms[PlatformIdentifier.INSTAGRAM]) {
          limitChars(media_items_arrangement?.instagram?.text, 2200);
        }
      }, waitTime)
    );
  }

  useEffect(() => {
    runWhenUserStopTying();
  }, [media_items_arrangement?.instagram?.text]);

  useEffect(() => {
    limitChars(media_items_arrangement?.instagram?.text, 2200);
  }, []);

  const handleEditClick = () => {
    setToggleEditTextInput(true);
  };

  const addMediaFromUrl = (obj = mediaAddedViaSpecificPlaformUrlMethod[0]) => {
    const { extension, thumbnail, ...rest } =
      obj;
    setBlockMediaItemsUpdate(false);
    useableMedia.length
      ? setUseableMedia((oldArray) => [...oldArray, rest])
      : setUseableMedia([rest]);

    if (!manuallyEditedPlatforms[PlatformIdentifier.INSTAGRAM]) {
      const newData = { ...manuallyEditedPlatforms, instagram: true };
      dispatch(setManuallyEditedPlatforms(newData || {}));
    }
    if (googleDriveManager?.docs) {
      dispatch(setGoogleDriveManager({ ...googleDriveManager, docs: null }))
    }
    dispatch(setSpecificMediaPlatformTied("global"));
    setOpenMediaSelection(false);
  };

  useEffect(() => {
    if (!googleDriveManager?.docs || localStorage.getItem('gdrivePlatform') !== PlatformIdentifier.INSTAGRAM) return;
    addMediaFromUrl(googleDriveManager?.docs);
    immediateActionWhenClieked(true);
  }, [googleDriveManager])

  useEffect(() => {
    specificMediaPlatformTied === "instagram" &&
      mediaAddedViaSpecificPlaformUrlMethod.length &&
      addMediaFromUrl();
  }, [mediaAddedViaSpecificPlaformUrlMethod]);

  const saveDesignStateImage = (newUrl) => {
    closeImgEditor();
    setUseableMedia((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.url === comparebleUrl) {
          setBlockMediaItemsUpdate(false);
          return { ...obj, url: newUrl, optimized: true };
        }
        return obj;
      });
      if (!manuallyEditedPlatforms[PlatformIdentifier.INSTAGRAM]) {
        const newData = { ...manuallyEditedPlatforms, instagram: true };
        dispatch(setManuallyEditedPlatforms(newData || {}));
      }
      dispatch(setSelectedPlatformForPreview("refresh"));
      setTimeout(() => {
        dispatch(setSelectedPlatformForPreview(PlatformIdentifier.INSTAGRAM));
      }, 1000);
      return newState;
    });
  };

  useEffect(() => {
    !openEditArea ? setCanOpenPopOver(true) : setCanOpenPopOver(false);
  }, [openEditArea]);

  const styleHashTags = (str = "") => {
    let { newText } = reformatHashTexts(str);
    return newText;
  };

  useEffect(() => {
    let result = styleHashTags(limitedPostTextBody ?? "");
    userTags.map((item) => {
      result = result.replace(`${item?.username}`, `<span style="color:#0052cc;">${item?.username}</span>`);
    })
    setFormattedPostBodyForLinks(result);
  }, [limitedPostTextBody]);

  useEffect(() => {
    const result = url_replacer(limitedPostTextBody ?? "");
    setFormattedPostBodyForLinks(result);
  }, [limitedPostTextBody]);

  const handleConnect = async () => {
    try {
      googleDriveRef.current.click();
    } catch (error) {

    }
  }

  const immediateActionWhenClieked = (remove) => {
    if (remove) {
      localStorage.removeItem("gdrivePlatform");
      return
    }
    localStorage.setItem("gdrivePlatform", PlatformIdentifier.INSTAGRAM);
  }

  useEffect(() => {
    if (googleDriveManager['fromPreviews'] && googleDriveManager['promptConnect']) {
      setOpenConfirmation(true)
    }
  }, [googleDriveManager])

  // if (!useableMedia.length && !limitedPostTextBody && !manuallyEditedPlatforms[PlatformIdentifier.INSTAGRAM]) {
  //   return <></>
  // }

  return useableMedia.length ? (
    <>
      <GDriveConnectionRequestListener
        googleDriveRef={googleDriveRef}
      />
      {
        googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.INSTAGRAM} /> : null
      }
      {openEditArea && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100%",
            zIndex: 10000,
          }}
        >
          <FilerobotImageEditor
            source={imageToEditUrl}
            theme={{
              palette: {
                "bg-secondary": !config.isDark
                  ? lightTheme?.rootBackground
                  : "#0b1727",
                "txt-primary": !config.isDark ? "#2e2e2e" : "#919090",
              },
              typography: {
                fontFamily: "Roboto, Arial",
              },
            }}
            onBeforeSave={() => false}
            onSave={(editedImageObject, designState) => {
              let canvas = editedImageObject.imageCanvas;
              saveDesignStateImage(canvas.toDataURL());
            }}
            onClose={closeImgEditor}
            annotationsCommon={{
              fill: "#ff0000",
            }}
            Text={{ text: "Postly..." }}
            tabsIds={[
              TABS.ADJUST,
              TABS.ANNOTATE,
              TABS.WATERMARK,
              TABS.FILTERS,
              TABS.FINETUNE,
              TABS.RESIZE,
            ]}
            defaultTabId={TABS.ANNOTATE} // or 'Annotate'
            defaultToolId={TOOLS.TEXT} // or 'Text'
          />
        </div>
      )}
      {
        !viewOnly &&
        <Flex
          gap={3}
          className={`mt-2`}
          wrap={'wrap'}
        >
          <MultimediaSelectionDropdown
            dropDownAnchor={
              <CircularButton onClick={() => immediateActionWhenClieked(false)} title="Add Image">
                <FontAwesomeIcon icon={faImage} />
              </CircularButton>
            }
            specificPlatformTied={PlatformIdentifier.INSTAGRAM}
            fromPreview={true}
            initiatorId={`${PlatformIdentifier.INSTAGRAM}-photo-chooser`}
            externalFilePickerTitle={"Enter Photo Location"}
            prompt={"Add Photo From"}
            fileType={"image"}
            externalPickerPromptLabel={"Photo"}
            multiple={true}
            fileSelectionHandler={(response) => {
              if (response) {
                let { files, from_local, ...others } = response;
                updateMediaItems(files, from_local, others);
              }
            }}
          />

          <MultimediaSelectionDropdown
            dropDownAnchor={
              <CircularButton title="Add Video">
                <FontAwesomeIcon icon={faVideo} />
              </CircularButton>
            }
            specificPlatformTied={PlatformIdentifier.INSTAGRAM}
            fromPreview={true}
            initiatorId={`${PlatformIdentifier.INSTAGRAM}-video-chooser`}
            externalFilePickerTitle={"Enter Video Location"}
            prompt={"Add Video From"}
            fileType={"video"}
            externalPickerPromptLabel={"Video"}
            multiple={false}
            fileSelectionHandler={(response) => {
              if (response) {
                let { files, from_local, ...others } = response;
                updateMediaItems(files, from_local, others);
              }
            }}
          />
          {!toggleEditTextInput && (
            <CircularButton
              editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
              onClick={() => handleEditClick()}
              title="Edit Text"
            >
              <FontAwesomeIcon icon={faPencil} />
            </CircularButton>
          )}
          <CircularButton
            editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
            title="Tag a user"
          >
            <PopDrop
              dropDownAnchor={
                <Button
                  size='sm'
                  variant={'none'}
                  className='px-2 cursor-pointer'>
                  <FontAwesomeIcon icon={faAt} fontSize={18} />
                </Button>
              }
              close={popDropClose}
              setPopDropClose={setPopDropClose}
              alignment={'start'}
              width={290}
              PopContainer={
                <TaggingPop
                  setPopDropClose={setPopDropClose}
                  platform={PlatformIdentifier.INSTAGRAM}
                  value={limitedPostTextBody}
                  setValue={setLimitedPostTextBody}
                  setUpdateDirectly={handleUpdateTextFromEditBox}
                />
              }
            />
          </CircularButton>

          <CircularButton
            editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
            onClick={() => {
              const video = useableMedia.find((media) => media.is_video === true);
              if (video) {
                setCanUpdateTags(false)
                dispatch(setUserTags([]));
                toast.info('You cannot create a tag-in-image on a video or a carousel post with video included.', {
                  theme: 'colored'
                })
                return;
              }
              setOpenImageTaggingPop(!openImageTaggingPop)
            }}
            title="tag users on image"
          >
            <FaTag size={20} />
          </CircularButton>

          <CircularButton
            editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
            onClick={() => setRepositionImages(!repostionImages)}
            title="re-position images"
          >
            <RiDragDropLine size={20} />
          </CircularButton>

        </Flex>
      }
      <ParentSection>
        <Wrapper>
          {toggleEditTextInput && (
            <EditTextBox
              ref={textEditRef}
              isPlatformEdited={PlatformIdentifier.INSTAGRAM}
              incomingRef={textEditRef}
              value={limitedPostTextBody}
              setValue={setLimitedPostTextBody}
              setToggleEditTextInput={setToggleEditTextInput}
              platform={"instagram"}
              setter={{ ...manuallyEditedPlatforms, instagram: true }}
              updateText={handleUpdateTextFromEditBox}
            />
          )}
          <PreviewContentArea
            mainBgColor={!config.isDark ? "white" : "#0b1727"}
            theme={!config.isDark ? "1px 1px 3px #949494" : "none"}
            style={{
              borderColor: `${!config.isDark ? "#ededed" : "transparent"}`,
            }}
            ref={focusImageRef}
          >
            <div style={{ position: 'absolute', bottom: 0, right: 5, fontSize: 11, }}>
              Character Count: {formattedPostBodyForLinks?.length} / 2200
            </div>
            {manuallyEditedPlatforms[PlatformIdentifier.INSTAGRAM] && (
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  fontStyle: "italic",
                  position: "absolute",
                  top: 0,
                  right: 5,
                  fontSize: 11,
                }}
              >
                <p style={{ color: !config.isDark ? "grey" : "grey" }}>
                  Edited
                </p>
              </div>
            )}
            <FirstLayer>
              {/* <ImageHolder> */}
              {displayPlatformIcon(PlatformIdentifier.INSTAGRAM)}
              {/* </ImageHolder> */}
              <Info>
                <p
                  type={"p"}
                  style={{
                    fontWeight: "600",
                    fontSize: 11,
                    color: `${!config.isDark ? "#262626" : "whitesmoke"}`,
                  }}
                >
                  {"Instagram Preview"}
                </p>
              </Info>
              <DotsBox>
                <FontAwesomeIcon
                  style={{
                    color: `${!config.isDark ? "#262626" : "whitesmoke"}`,
                    fontSize: 9,
                  }}
                  icon={faEllipsis}
                />
              </DotsBox>
            </FirstLayer>
            {repostionImages ? <DragToPositionImage useableMedia={useableMedia} setter={setUseableMedia} platform={PlatformIdentifier.INSTAGRAM} /> : <SecondLayer>
              {useableMedia.length === 1 ? (
                displaySingleMedia
              ) : (
                <Swiper
                  navigation={true}
                  pagination={true}
                  modules={[Navigation, Pagination]}
                  className="mySwiper"
                >
                  <Carousel
                    key={useableMedia}
                    mediaEdited={
                      manuallyEditedPlatforms[PlatformIdentifier.INSTAGRAM]
                    }
                    ImageDelete={ImageDelete}
                    handleDeleteMedia={handleDeleteMedia}
                    FontAwesomeIcon={FontAwesomeIcon}
                    useableMedia={useableMedia}
                    faRemove={faRemove}
                    platform={"instagram"}
                    editInfoText={editInfoText}
                    handleOpeningEditArea={handleOpeningEditArea}
                    PostImage={PostImage}
                    setCanOpenPopOver={setCanOpenPopOver}
                    canOPenPopOver={canOPenPopOver}
                    max_min_imageSize={max_min_imageSize}
                    getImageDimension={getImageDimension}
                    setMax_min_imageSize={setMax_min_imageSize}
                    PlayMediaInstagram={PlayMediaInstagram}
                    openVideoEditArea={openVideoEditArea}
                    setOpenVideoEditArea={setOpenVideoEditArea}
                    viewOnly={viewOnly}
                  />
                </Swiper>
              )}
            </SecondLayer>}

            <ThirdLayer>
              <LeftAction>
                <FontAwesomeIcon
                  style={{
                    color: `${!config.isDark ? "#2b2b2b" : "#b3b3b3"}`,
                    width: 15,
                    height: 15,
                  }}
                  icon={faHeart}
                />

                <FontAwesomeIcon
                  style={{
                    color: `${!config.isDark ? "#2b2b2b" : "#b3b3b3"}`,
                    width: 15,
                    height: 15,
                  }}
                  icon={faComment}
                  flip="horizontal"
                />

                <FontAwesomeIcon
                  style={{
                    width: 15,
                    height: 15,
                  }}
                  icon={faPaperPlane}
                  Light
                />
              </LeftAction>
              <RightAction>
                <FontAwesomeIcon
                  style={{
                    color: `${!config.isDark ? "#2b2b2b" : "#b3b3b3"}`,
                    width: 15,
                    height: 15,
                  }}
                  icon={faBookmark}
                />
              </RightAction>
            </ThirdLayer>
            <div
              style={{
                width: "100%",
                height: "auto",
                fontFamily:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
                fontWeight: 400,
              }}
            >
              <pre
                type={"p"}
                style={{
                  color: `${!config.isDark ? "#262626" : "#b3b3b3"}`,
                  textAlign: "left",
                  maxWidth: "100%",
                  width: "100%",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                  marginBottom: 10,
                }}
              >
                {parse(formattedPostBodyForLinks)}
              </pre>
            </div>
            <ThirdLayer>
              <LeftAction>
                <FontAwesomeIcon
                  style={{
                    color: `${!config.isDark ? "#2b2b2b" : "#b3b3b3"}`,
                    width: 15,
                    height: 15,
                    marginTop: -3,
                  }}
                  icon={faFaceSmile}
                />

                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    padding: "2%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <p
                    type={"p"}
                    style={{
                      color: "#cccccc",
                      fontSize: 9
                    }}
                  >
                    Add a comment
                  </p>
                </div>
              </LeftAction>
              <RightAction>
                <p
                  type={"p"}
                  style={{
                    fontWeight: "600",
                    color: "#95b6f0",
                    fontSize: 9
                  }}
                >
                  Post
                </p>
              </RightAction>
            </ThirdLayer>
            {toggleEditTextInput && (
              <EditTextBox
                ref={textEditRef}
                incomingRef={textEditRef}
                value={limitedPostTextBody}
                setValue={setLimitedPostTextBody}
                setToggleEditTextInput={setToggleEditTextInput}
                platform={"instagram"}
              />
            )}
          </PreviewContentArea>
        </Wrapper>

        {/* <EditContainer
          style={{ justifyContent: "space-around" }}
          editBgColor={
            !config.isDark ? lightTheme.surfaceColor : darkTheme.rootBackground
          }
        >
        
        </EditContainer> */}
        <Modal
          show={openImageTaggingPop}
          onHide={() => {
            setOpenImageTaggingPop(false);
          }}
          onExit={() => {
            setOpenImageTaggingPop(false);
          }}
          centered={true}
        >
          <Modal.Header>
            <Modal.Title>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                  minWidth: "100%",
                }}
              >
                <span
                  style={{
                    fontSize: 13,
                    width: "90%",
                    // color: "grey",
                  }}
                >
                  Tag users on image: Click on the image to tag users and use the arrow to navigate to the next image.
                </span>
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90px",
                }}>
                  <BsArrowLeft
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleTaggableImageIndexChange('decrement')}
                    color={currentTaggableImageIndex === 0 ? 'grey' : 'black'}
                  />
                  <BsArrowRight
                    style={{
                      cursor: "pointer",
                    }}
                    color={useableMedia?.length - 1 === currentTaggableImageIndex ? 'grey' : 'black'}
                    onClick={() => handleTaggableImageIndexChange('increment')}
                  />
                </div>
                {
                  useableMedia?.length && useableMedia[currentTaggableImageIndex]?.is_video &&
                  <PopDrop
                    dropDownAnchor={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "80px",
                          alignItems: "center",
                          fontSize: 14,
                          cursor: 'pointer'
                        }}
                      >
                        <FaUserAlt />
                        Tag User
                      </div>
                    }
                    close={popDropClose}
                    setPopDropClose={setPopDropClose}
                    alignment={'start'}
                    width={395}
                    PopContainer={
                      <IgTagDrop
                        tags={tags}
                        currentTaggableImageIndex={currentTaggableImageIndex}
                        useableMedia={useableMedia}
                        setCanUpdateTags={setCanUpdateTags}
                      />
                    }
                  />
                }
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <IgTagUsersComponent
              useableMedia={useableMedia}
              currentTaggableImageIndex={currentTaggableImageIndex}
              setCanUpdateTags={setCanUpdateTags}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={openPixelDialoguePicker}
          onHide={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
          onExit={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
        >
          <Modal.Header>
            <Modal.Title>Pexels.com</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExtrernalImageSearchDialogue
              setUseableMedia={setUseableMedia}
              determiner={PlatformIdentifier.INSTAGRAM}
              modalClose={() => {
              }}
            />
          </Modal.Body>
        </Modal>
        <ConfirmModal
          open={openConfirmation}
          title={"Connect Your Google Drive Account"}
          message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
          confirmText={"PROCEED"}
          cancelText={"CANCEL"}
          showDialoguePrompt
          onCancel={() => {
            setOpenConfirmation(false);
            dispatch(setGoogleDriveManager({
              ...googleDriveManager,
              promptConnect: false,
            }))
          }}
          onConfirm={() => {
            handleConnect();
            dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
          }}
        />
      </ParentSection>
    </>
  ) : (
    <div
      style={{
        display: "flex",
        minHeight: "100px",
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        marginTop: "50px",
      }}
    >
      <GDriveConnectionRequestListener
        googleDriveRef={googleDriveRef}
      />
      {
        googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.INSTAGRAM} /> : null
      }
      <Flex
        gap={3}
        className={`mt-2`}
      >
        <MultimediaSelectionDropdown
          dropDownAnchor={
            <CircularButton onClick={() => immediateActionWhenClieked(false)} title="Add Image">
              <FontAwesomeIcon icon={faImage} />
            </CircularButton>
          }
          specificPlatformTied={PlatformIdentifier.INSTAGRAM}
          fromPreview={true}
          initiatorId={`${PlatformIdentifier.INSTAGRAM}-photo-chooser`}
          externalFilePickerTitle={"Enter Photo Location"}
          prompt={"Add Photo From"}
          fileType={"image"}
          externalPickerPromptLabel={"Photo"}
          multiple={true}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />

        <MultimediaSelectionDropdown
          dropDownAnchor={
            <CircularButton title="Add Video">
              <FontAwesomeIcon icon={faVideo} />
            </CircularButton>
          }
          specificPlatformTied={PlatformIdentifier.INSTAGRAM}
          fromPreview={true}
          initiatorId={`${PlatformIdentifier.INSTAGRAM}-video-chooser`}
          externalFilePickerTitle={"Enter Video Location"}
          prompt={"Add Video From"}
          fileType={"video"}
          externalPickerPromptLabel={"Video"}
          multiple={false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />
      </Flex>

      {!openMediaSelction &&
        <InfoBar
          info={`You must upload and image or video to Instagram.`}
          customStyles={{
            fontSize: 13,
          }}
        />
      }
      {!viewOnly && openMediaSelction && !selectingPhotoFromHome && mediaOpener}
      <Modal
        show={openPixelDialoguePicker}
        onHide={() => {
          dispatch(setOpenPixelFilePickerDialog(false));
        }}
        onExit={() => {
          dispatch(setOpenPixelFilePickerDialog(false));
        }}
      >
        <Modal.Header>
          <Modal.Title>Pexels.com</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ExtrernalImageSearchDialogue
            setUseableMedia={setUseableMedia}
            determiner={PlatformIdentifier.INSTAGRAM}
            modalClose={() => {
            }}
          />
        </Modal.Body>
      </Modal>
      <ConfirmModal
        open={openConfirmation}
        title={"Connect Your Google Drive Account"}
        message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
        confirmText={"PROCEED"}
        cancelText={"CANCEL"}
        showDialoguePrompt
        onCancel={() => {
          setOpenConfirmation(false);
          dispatch(setGoogleDriveManager({
            ...googleDriveManager,
            promptConnect: false,
          }))
        }}
        onConfirm={() => {
          handleConnect();
          dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
        }}
      />
    </div>
  );
};

export default Instagram;