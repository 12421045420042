import ExternalFilePickerDialog from "components/common/ExternalFilePickerDialog";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getExternalFilePickerDialogOptions,
  setOpenExternalFilePickerDialog,
  setSelectedMediaOption,
  getSelectedMediaOption,
  setShowMediaOptions,
} from "redux/slices/postslice";
import { MediaCart, MediaOptions, SelectInput } from "./addMediapopUpStyle";
import {
  faImage,
  faVideo,
  faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleDriveImagePicker from "components/common/GoogleDriveImagePicker";
import PixelImagePicker from "components/common/PixelImagePicker";
import ShutterStockImagePicker from "components/common/ShutterStockImagePicker";
import ImageEditor from "components/common/ImageEditor";
import VideoEditor from "components/common/VideoEditor";
import { Dropdown } from "react-bootstrap";
import { useState } from "react";
import LocalStoragePicker from "./LocalStoragePicker";
import { BsCameraVideo } from "react-icons/bs";
import { IoImageOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { FaVideo } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "constants";
import PostlyCloudImagePicker from "components/common/PostlyCloudImagePicker";

const AddMediaPop = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  // const [choosedMedia, setChoosedMedia] = useState(props?.fileType);
  const [multiples, setMultiples] = useState(false);

  const {
    dropDownAnchor,
    prompt = "Add File",
    alignment = "end",
    specificPlatformTied,
    ...others
  } = props;

  const externalFilePickerDialogOptions = useSelector(
    getExternalFilePickerDialogOptions
  );
  const selectedMediaOption = useSelector(getSelectedMediaOption);

  const handleOptionChange = (event) => {
    dispatch(setSelectedMediaOption(event.target.value));
    console.log(selectedMediaOption, "as selectedMediaOption");
    // toast.info(selectedMediaOption);
  };

  return (
    <>
      <MediaCart>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            gap: "60px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {/* <IoImageOutline size={20} stroke="inherit" /> */}
            <FontAwesomeIcon icon={faImage} />
            <span style={{ color: "inherit" }}>Image</span>
            <input
              type="radio"
              name="image"
              value={"image"}
              checked={selectedMediaOption === "image" ? true : false}
              onChange={handleOptionChange}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {/* <BsCameraVideo size={20} fill="inherit" /> */}
            <FontAwesomeIcon icon={faVideoCamera} />

            <span style={{ color: "inherit" }}>Video</span>
            <input
              type="radio"
              name="video"
              value={"video"}
              checked={selectedMediaOption === "video" ? true : false}
              onChange={handleOptionChange}
            />
          </div>
        </div>
        <ExternalFilePickerDialog
          fileType={selectedMediaOption}
          options={externalFilePickerDialogOptions}
          multiple={multiples}
          setMultiples={setMultiples}
          modalClose={() => {
            dispatch(setOpenExternalFilePickerDialog(false));
          }}
        />
      </MediaCart>
      {/* <hr style={{width: "100%", border: "1px solid #e6e9ee"}}/> */}
      <LocalStoragePicker fileType={selectedMediaOption} {...others} />
      {/* <hr style={{width: "100%", border: "1px solid #e6e9ee", margin: "20px 0px"}}/> */}
      <MediaCart>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            gap: "30px",
            // margin: "10px"
          }}
        >
          <span
            style={{
              fontSize: "13px",
              color: "inherit",
              fontWeight: "bold",
              marginRight: "50px",
            }}
          >
            {"Import from"}
          </span>
          <PostlyCloudImagePicker fileType={selectedMediaOption} {...others} />
          
          <GoogleDriveImagePicker fileType={selectedMediaOption} {...others} />

          <PixelImagePicker fileType={selectedMediaOption} {...others} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // marginLeft: 8,
              cursor: "pointer",
              gap: "5px",
            }}
            onClick={() => {
              navigate(`${RoutePaths.SHUTTERSTOCK_CONTENTS}`)
              dispatch(setShowMediaOptions(false))
            }}
          >
            <img
              src="https://images.fineartamerica.com/images/gallerylogos/shutterstock-1539793328-square.jpg"
              style={{ width: 40, height: 40 }}
              alt="ShutterStock Icon From Postly"
            />
            <span
              style={{
                fontSize: "10px",
                // flex: 1,
                width: "auto",
                // fontWeight: 500,
                // paddingLeft: 20,
                // textAlign: 'center',
                color: "inherit",
              }}
            >
              ShutterStock
            </span>
          </div>
        </div>
      </MediaCart>
      {/* <hr style={{width: "100%", border: "1px solid #e6e9ee", margin: "20px 0px"}}/>
      <MediaCart>
          <div style={{  display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%", gap: "80px"}}>
              <div
            style={{
              fontSize: "13px",
              color: "black",
              fontWeight: "bold",
              // width: "100%",
            }}
          >
            {"Design with AI"}
          </div>
              <ImageEditor fileType={selectedMediaOption} />
              <VideoEditor fileType={selectedMediaOption} />
          </div>
      </MediaCart> */}
    </>
  );
};

export default AddMediaPop;
