import { faCompass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotificationDropdown from "components/navbar/top/NotificationDropdown";
import ProfileDropdown from "components/navbar/top/ProfileDropdown";
import AppContext from "context/Context";
import APIService from "http/api_service";
import React, { useContext } from "react";
import { Button, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoggedInUser,
  setShowTourGuide
} from "redux/slices/user_slice";
import {
  getActiveWorkSpace,
  setActiveWorkspace,
} from "redux/slices/workspaceslice";
import "./addOns.css";
import "react-tooltip/dist/react-tooltip.css";
import Flex from "components/common/Flex";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

const TopNavRightSideNavItem = () => {

  const { t } = useTranslation();
  const { config: { isDark }, setConfig } = useContext(AppContext);
  const dispatch = useDispatch();
  const loggedInUser = useSelector(getLoggedInUser);
  const activeWorkSpace = useSelector(getActiveWorkSpace);
  const currentURL = window.location.href;
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row gap-4 align-items-center"
      as="ul"
    >
      {
        (currentURL === "app.postly.ai" || currentURL === "https://localhost:3000") &&
        <Button
          size="sm"
          variant={`outline-${isDark ? 'light' : 'dark'}`}
          className="rounded-pill"
          onClick={() => window.open("https://postly.ai/demo-videos", "_blank")}
        >
          <Flex alignItems={'center'} className={'gap-1'}>
            <span className="fs--1">
              {t('watch_demo_videos')}
            </span>
            <FontAwesomeIcon icon={["fab", "youtube"]} style={{ color: "#FF0000", fontSize: 14 }} />
          </Flex>
        </Button>
      }
      {
        (currentURL === "app.postly.ai" || currentURL === "https://localhost:3000") &&
        <Tooltip id="tour-guide-tooltip" title={t('dashboard_tour_guide')}>
          <Button
            variant={isDark ? "light" : "text"}
            className="rounded-pill m-0 p-0 outline-none border-none border-0 outline-0 shadow-none"
            onClick={() => {
              dispatch(setShowTourGuide(true));
            }}>
            <FontAwesomeIcon
              icon={faCompass}
              className="fs-0"
              style={{ width: 22, height: 22 }}
            />
          </Button>
        </Tooltip>
      }
      {
        (currentURL === "app.postly.ai" || currentURL === "https://localhost:3000") &&
        <Tooltip id="ThemeColor" title={t('help_center')}>
          <Button
            variant={isDark ? "light" : "text"}
            className="rounded-pill m-0 p-0 outline-none border-none border-0 outline-0 shadow-none"
            onClick={() => {
              window.open("https://www.postly.ai/help-center", "_blank");
            }}>
            <FontAwesomeIcon
              icon={"question-circle"}
              className="fs-0"
              style={{ width: 22, height: 22 }}
            />
          </Button>
        </Tooltip>
      }
      <Tooltip title={t('switch_to_var_theme', { theme: isDark ? 'light' : 'dark' })}>
        <Button
          variant={isDark ? "light" : "text"}
          className="rounded-pill m-0 p-0 outline-none border-none border-0 outline-0 shadow-none"
          onClick={() => {
            let darkNow = !isDark;
            setConfig("isDark", darkNow);
            let themeChoice = darkNow ? "dark" : "light";
            APIService.updateWorkspaceTheme(
              activeWorkSpace._id,
              themeChoice, (_, __) => {
                let themeChoices = JSON.parse(JSON.stringify(activeWorkSpace.theme_choices ?? {}));
                let userId = loggedInUser._id;
                themeChoices[userId] = themeChoice;
                dispatch(
                  setActiveWorkspace({
                    ...activeWorkSpace,
                    theme_choices: themeChoices,
                  })
                );
              }
            );
          }}>
          <FontAwesomeIcon
            icon={isDark ? "sun" : "moon"}
            className="fs-0"
            style={{ width: 22, height: 22 }}
          />
        </Button>
      </Tooltip>
      <Tooltip title={t('view_your_notifications')}>
        <span>
          <NotificationDropdown />
        </span>
      </Tooltip>
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
