import {
    faFacebookF,
    faGoogle,
    faInstagram, faLinkedin,
    faPinterest,
    faReddit,
    faTelegram,
    faTiktok,
    faTwitter,
    faWordpress,
    faYoutube,
    faBlogger,
    faThreads,
    faSquareThreads,
} from '@fortawesome/free-brands-svg-icons';
import { faRss } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RSSFeedSvgIcon from 'assets/images/rss.svg';
import GoogleMyBusinessSvgIcon from 'assets/images/google-my-business.svg';
import InstagramSvgIcon from 'assets/images/instagram.svg';
import RedditSvgIcon from 'assets/images/reddit.svg';
import TikTokSvgIcon from 'assets/images/tiktok.svg';
import wordpressBlueIcon from 'assets/images/wordpress_blue.svg';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import APIService from 'http/api_service';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { userCanPerformActionInWorkspace } from 'utils/workspace_utils';
import { PERMISSION_VIEW_AND_MANAGE_PLATFORMS, PlatformIdentifier, RoutePaths } from '../../../constants';
import PlatformsGrid from './PlatformsGrid';
import IconButton from 'components/common/IconButton';
import { isFreeUser } from 'utils/user_utils';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { useNavigate } from 'react-router-dom';
import { isLTD } from 'utils/ltd_utils';

const moreItems = [
    {
        id: 1,
        label: 'Most platforms are connected via API tokens which expire once in 60 days.',
        icon: 'check'
    },
    {
        id: 2,
        label: 'Signing out of your social accounts will disconnect them on Postly',
        icon: 'check'
    },
    { id: 3, label: 'Changing your social account passwords will disconnect them on Postly', icon: 'check' },
    {
        id: 4,
        label: 'Getting suspensions on your social accounts will disconnect them on Postly',
        icon: 'check'
    },
    {
        id: 5,
        label: 'Exceeding the daily post limit will cause them to disconnect.',
        icon: 'check'
    },
    {
        id: 6,
        label: 'Your Instagram account is connected via your Facebook account, to reconnect any of them, always disconnect both and reconnect Facebook first, then Instagram last.',
        icon: 'check'
    },
    {
        id: 7,
        label: 'Facebook, Twitter, and TikTok do not allow multiple accounts connection, you can sign in to all your accounts on a different browser tab and then switch to each in order to connect it to Postly. Alternatively, you can sign in and out of each account as you connect them',
        icon: 'check'
    }
];

export let platforms = [
    {
        name: "Facebook",
        color: "#4267B2",
        icon: faFacebookF,
        identifier: PlatformIdentifier.FACEBOOK,
        selected: false,
        textLimit: 62206,
        metaFamily: true,
        disabled: false,
        pro: false,
    },
    {
        name: "Twitter",
        color: "#1DA1F2",
        icon: faTwitter,
        identifier: PlatformIdentifier.TWITTER,
        selected: false,
        pro: true,
    },
    {
        name: "Instagram",
        color: "#800080",
        // color: "#EB4869",
        icon: faInstagram,
        identifier: PlatformIdentifier.INSTAGRAM,
        svg: InstagramSvgIcon,
        selected: false,
        textLimit: 2200,
        metaFamily: true,
        disabled: false,
        pro: false,
    },
    {
        name: "LinkedIn",
        color: '#0077b5',
        icon: faLinkedin,
        identifier: PlatformIdentifier.LINKED_IN,
        selected: false,
        textLimit: 3000,
        pro: true,
    },
    {
        name: "Google My Business",
        color: '#4285F4',
        // icon: faGoogle,
        identifier: PlatformIdentifier.GOOGLE_MY_BUSINESS,
        svg: GoogleMyBusinessSvgIcon,
        selected: false,
        textLimit: 750,
        pro: true,
    },
    {
        name: "Reddit",
        color: '#FF5700',
        icon: faReddit,
        svg: RedditSvgIcon,
        identifier: PlatformIdentifier.REDDIT,
        selected: false,
        textLimit: 10000,
        pro: false,
    },
    {
        name: "Telegram",
        color: '#0088cc',
        icon: faTelegram,
        identifier: PlatformIdentifier.TELEGRAM,
        selected: false,
        textLimit: 4096,
        pro: false,
    },
    {
        name: "Pinterest",
        color: '#E60023',
        icon: faPinterest,
        identifier: PlatformIdentifier.PINTEREST,
        selected: false,
        pro: false,
    },
    {
        name: "YouTube",
        color: "#FF0000",
        icon: faYoutube,
        identifier: PlatformIdentifier.YOUTUBE,
        selected: false,
        textLimit: 5000,
        pro: true,
    },
    {
        name: "TikTok",
        color: '#000000',
        icon: faTiktok,
        svg: TikTokSvgIcon,
        identifier: PlatformIdentifier.TIKTOK,
        selected: false,
        textLimit: 300,
        pro: true,
    },
    {
        name: "Wordpress",
        color: '#000000',
        icon: faWordpress,
        svg: wordpressBlueIcon,
        identifier: PlatformIdentifier.WORDPRESS,
        selected: false,
        textLimit: 300,
        pro: false,
        disabled: false,
    },
    {
        name: "RSS Feed",
        color: '#FFA500',
        icon: faRss,
        svg: RSSFeedSvgIcon,
        identifier: PlatformIdentifier.RSS_FEED,
        selected: false,
        textLimit: 300,
        pro: false,
        disabled: false,
    },
    {
        name: "Blogger",
        color: '#FF5722',
        icon: faBlogger,
        identifier: PlatformIdentifier.BLOGGER,
        selected: false,
        textLimit: 300,
        pro: false,
    },
    {
        name: "Threads",
        color: '#303030',
        icon: faSquareThreads,
        identifier: PlatformIdentifier.THREADS,
        selected: false,
        textLimit: 300,
        pro: false,
    }
];

export function findPlatformByIdentifier(identifier) {
    let platformArrays = Object.entries(platforms).map((entry) => entry);
    let platform = platformArrays.find((x) => x[1]['identifier'] === identifier);
    if (platform) {
        platform = { ...platform[1] };
    }
    return platform;
}

export function findPlatformByName(name) {
    let platformArrays = Object.entries(platforms).map((entry) => entry);
    let platform = platformArrays.find((x) => x[1]['name'].toLowerCase() === name.toLowerCase());
    if (platform) {
        platform = { ...platform[1] };
    }
    return platform;
}

const PlatformPage = () => {
    const navigate = useNavigate();
    const workSpace = useSelector(getActiveWorkSpace);
    const [connectedPlatformsAccountStat, setConnectedPlatformsAccountStat] = useState();
    const loggedInUser = useSelector(getLoggedInUser);

    const fetchConnectedSocialsCount = () => {
        APIService.fetchConnectedSocialsCount(workSpace._id, (response, error) => {
            if (response) {
                let { data } = response;
                let { connected, total } = data;
                let stats = { total, connected };
                stats['true_connected'] = connected;
                connected = Math.max(connected, 1);
                stats['completed'] = Math.max(connected, 10);
                if (typeof total !== 'number') {
                    stats['max'] = connected;
                } else {
                    stats['max'] = Math.min(total, 100);
                }
                setConnectedPlatformsAccountStat(stats);
            }
        });
    }

    useEffect(() => {
        fetchConnectedSocialsCount();
    }, []);

    return (
        <>
            <PageHeader title="" titleTag="h5" className="mb-3">
                <h4 className="text-800">Platforms</h4>
                <h5 className="text-600 fs-0 mb-3">
                    Connect your social account(s) to start posting
                </h5>
                <h5 className="text-600 fs-0 mb-3">
                    Your connected social accounts: <span className="text-primary fw-semi-bold">{connectedPlatformsAccountStat && userCanPerformActionInWorkspace(workSpace, PERMISSION_VIEW_AND_MANAGE_PLATFORMS) &&
                        <>
                            <SoftBadge pill bg="success" className="fs-1">
                                {`${connectedPlatformsAccountStat.connected}/${connectedPlatformsAccountStat.total}`}
                            </SoftBadge>
                        </>
                    }</span>
                    {
                        !isLTD(workSpace?.active_plan) && <IconButton
                            icon="crown"
                            variant="success"
                            iconClassName="me-2"
                            // className="ml-5"
                            style={{
                                marginLeft: 20
                            }}
                            onClick={() => {
                                navigate(RoutePaths.ALL_BILLING)
                            }}
                        >
                            Get more accounts
                        </IconButton>
                    }
                </h5>
                {/* <h5 className="text-800">NOTES:</h5>
                <ul className="fa-ul ms-2 ps-card mb-2">
                    {moreItems.map(item => (
                        <li key={item.id} className="py-1">
                            <h6 className="text-700">
                                <FontAwesomeIcon
                                    icon={item.icon}
                                    className="fa-li text-success"
                                />
                                {item.label}
                            </h6>
                        </li>
                    ))}
                </ul> */}
            </PageHeader>
            <PlatformsGrid />
        </>
    );
};

export default PlatformPage;