/* eslint-disable react/jsx-pascal-case */
/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { faComment, faHeart } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowUpFromBracket,
  faAt,
  faEllipsis,
  faImage,
  faMinus,
  faPencil,
  faPlus,
  faRemove,
  faRetweet,
  // faUpDownLeftRight,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import MultimediaSelectionDropdown from "components/common/MultimediaSelectionDropdown";
import React, { useContext, useEffect, useState } from "react";
import {
  canOpenPixelFilePickerDialog,
  getAltCancelTrigger,
  getFormattedMediaForTwitter,
  getGoogleDriveManager,
  getManuallyEditedPlatforms,
  getShowPreview,
  getTimeToPostContent,
  getUnhideThreadEdit,
  getUnhideThreadTextEdit,
  getUserTags,
  setGoogleDriveManager,
  setOpenPixelFilePickerDialog,
  setTwitterCurrentThreadIndexToAddMedia,
  setUnhideAltEdit,
  setUnhideThreadTextEdit
} from "../../../../../redux/slices/postslice";

import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findPlatformByIdentifier } from "components/app/platforms";
import ConfirmModal from "components/common/ConfirmModal";
import PopDrop from "components/common/PopDrop";
import CircularButton from "components/common/circularbutton";
import GDriveDashboard from "components/common/g-drive/dashboard";
import AppContext from "context/Context";
import parse from "html-react-parser";
import moment from "moment";
import { useRef } from "react";
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import { RiDragDropLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import recursiveAspectFinder from "utils/recursiveAspectFinder";
import { darkTheme, lightTheme } from "utils/theme";
import {
  extractLinksFromTweet,
  getPreviewFromUrl,
  url_replacer,
} from "utils/url";
import { PlatformData, PlatformIdentifier } from "../../../../../constants";
import TaggingPop from "../TaggingPop";
import DragToPositionImage from "./DragToPositionImage";
import ExtrernalImageSearchDialogue from "./ExtrernalImageSearchDialogue";
import GDriveConnectionRequestListener from "./GDriveConnectionRequestListener";
import "./addOnsStyles.css";
import BaseVideoPlayer from "./baseVideoPlayer";
import EditTextBox from "./editTextBox";
import ImageEditButton from "./image_edit_button";
import LinkPreviewer from "./link_previewer";
import { scanImages } from "./scanImages";
import { EditPopUpBtnDiv } from "./styles/facebook";
import { EditPopUp } from "./styles/instagram";
import {
  AltAdd,
  BottomHint,
  CenterRange,
  ChunckedBox,
  EditContainer,
  GridImage,
  GridImageOne,
  ImageDelete,
  ImageHolder,
  LeftSide,
  LineThrough,
  LineThroughTop,
  LowerRange,
  MiddleSide,
  OverFlowList,
  OverFlowSide,
  ParentSection,
  PostImage,
  PostImageOne,
  RightSide,
  UpperRange,
  Wrapper,
} from "./styles/twitter";
import TwitterFieldOptions from "./twitter_field_options";
import { rescaleImage } from "utils/media";
import { platformSpecs } from "utils/platform_utils";
import { HiPencilAlt } from "react-icons/hi";

const TwitterThreadPreviewAndEdit = ({
  item,
  setProcessAltSave,
  processAltSave,
  index,
  imgSrc,
  setChunkedTweet,
  chunkedTweet,
  updateMediaObjects,
  handleUpdateTextFromEditBox,
  saveAltValue,
  mediaRemoveHandler,
  mediaToReplace,
  addTwitterThread,
  deleteThread,
  viewOnly
}) => {
  const dispatch = useDispatch();
  const showedPreview = useSelector(getShowPreview);
  const googleDriveManager = useSelector(getGoogleDriveManager)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const googleDriveRef = useRef(null);
  const altCancelTrigger = useSelector(getAltCancelTrigger);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const mediaObjects = [...useSelector(getFormattedMediaForTwitter)];
  const [openMediaSelction, setOpenMediaSelection] = useState(false);
  const unhideThreadEdit = useSelector(getUnhideThreadEdit);
  const unhideThreadTextEdit = useSelector(getUnhideThreadTextEdit);
  const timeToPost = useSelector(getTimeToPostContent);
  const [storedIndex, setStoredIndex] = useState(index);
  const [currentMediaType, setCurrentMediaType] = useState(null);
  const [videoExist, setVideoExist] = useState(0);
  const [pictureCount, setPictureCount] = useState(0);
  const [toastShown, setToastShown] = useState(false);
  const [openAltSelction, setOpenAltSelction] = useState(false);
  const [currentUrlToCompare, setCurrentUrlToCompare] = useState("");
  const [altValue, setAltValue] = useState("");
  const [canOPenPopOver, setCanOpenPopOver] = useState(true);
  const [textToDisplay, setTextToDisplay] = useState(item?.text);
  const [openTextEdit, setOpenTextEdit] = useState(false);
  const [openEditArea, setOpenEditArea] = useState(false);
  const [imageToEditUrl, setImageToEditUrl] = useState("");
  const [comparebleUrl, setComparebleUrl] = useState("");
  const [openVideoEditArea, setOpenVideoEditArea] = useState(false);
  const [formattedPostBodyForLinks, setFormattedPostBodyForLinks] =
    useState("");
  const openPixelDialoguePicker = useSelector(canOpenPixelFilePickerDialog);
  const [linkExtracted, setLinkExtracted] = useState();
  const [repostionImages, setRepositionImages] = useState(false)
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const [previewerData, setPreviewerData] = useState(null);
  const [useableMedia, setUseableMedia] = useState([]);
  const { config } = useContext(AppContext);
  const userTags = useSelector(getUserTags);
  const [popDropClose, setPopDropClose] = useState(false)

  useEffect(() => {
    const shouldOpenTextEdit = localStorage.getItem('open_hashtag_pop');
    if (shouldOpenTextEdit == 'true') {
      handleTextEditClick();
    }
  }, [])

  let platform = findPlatformByIdentifier(PlatformIdentifier.TWITTER);

  function getImageDimensionCopy(url) {
    return new Promise(resolve => {
      const image = document.createElement('img');
      image.addEventListener("load", function () {
        const height = this.height;
        const width = this.width;
        resolve({ height, width });
      }, false);
      image.src = url;
    });
  }

  const handleFixTwitterImages = async (items) => {
    let isThreadEdited = item?.textEdited || item?.mediaEdited
    if (!isThreadEdited) return
    let newItems = []
    let isThereFaultyImage = false
    for (const item of items) {
      const { width, height } = await getImageDimensionCopy(item?.url);
      if (!item?.external && (width < platformSpecs[PlatformIdentifier.TWITTER]?.image?.minWidth || height < platformSpecs[PlatformIdentifier.TWITTER]?.image?.minHeight)) {
        const dataURL = await rescaleImage(item?.url, width, height, platformSpecs[PlatformIdentifier.TWITTER]?.image?.minWidth, platformSpecs[PlatformIdentifier.TWITTER]?.image?.minHeight, false)
        let _item = { ...item, url: dataURL, optimized: true }
        if (_item?.file && _item?.file?.type) {
          const blob = await fetch(dataURL).then(res => res.blob());
          const file = new File([blob], _item?.file?.name, { type: _item?.file?.type });
          _item = { ..._item, file: file }
        }
        isThereFaultyImage = true
        newItems.push(_item)
      } else {
        newItems.push(item)
      }
    }
    let toastMessage = `Image(s) has been fixed to meet the minimum image size requirements for ${PlatformIdentifier.TWITTER}.`
    if (isThereFaultyImage) {
      await mediaToReplace(null, null, index, newItems);
      toast.info(toastMessage, { theme: "colored" })
    }
  }


  useEffect(async () => {
    try {
      const imagesMedia = item?.media?.filter((x) => x.is_video == false);
      await scanImages(imagesMedia);
      setUseableMedia(item?.media);
    } catch (error) { }
    if (item?.media?.length) {
      handleFixTwitterImages(item?.media)
    }
  }, [item?.media]);

  let editInfoText = `Twitter images should be at least 600 x 335.`;
  let editInfoTextMax = `Twitter images should be at most 8192 x 8192. Please change this image.`;
  let videoInfoText = `Twitter videos should be at least 1280 x 720.`;
  let editVideoFormat = `Twitter videos supports MP4 format`;
  let videoSizeError = `Maximum allowed file size is ${PlatformData.TWITTER_MAXIMUM_ALLOWED_FILE_UPLOAD_SIZE / 1e6
    }MB`

  useEffect(() => {
    setTextToDisplay(item?.text);
  }, []);

  const assignInitialAltvalue = (threadIndex, url) => {
    let cloneTwitterThreads = media_items_arrangement.twitter;
    cloneTwitterThreads?.map((item, index) => {
      if (index === threadIndex) {
        useableMedia?.map((x) => {
          if (x?.url === url) {
            setAltValue(x?.alt_text ?? "");
          }
        });
      }
    });
  };

  const formattingMedias = async () => {
    const isVideoExisiting = useableMedia?.filter(
      (item) => item.is_video === true
    );
    const isImageExisiting = useableMedia?.filter(
      (item) => item.is_video !== true
    );
    if (isImageExisiting?.length > 0 && isVideoExisiting?.length > 0) {
      !toastShown &&
        toast.info(
          `Videos have been filtered from Twitter because it does not allow images and videos on the same thread.`,
          { theme: "colored" }
        );
      setToastShown(true);
      setVideoExist(0);
      return;
    }
  };

  const checkIfPictureExist = (arr) => {
    try {
      if (!arr) {
        return;
      }
      const isExist = arr?.filter((item) => item.is_video !== true);
      if (isExist?.length) {
        setPictureCount(arr?.length);
        return;
      }
      setPictureCount(0);
    } catch (error) { }
  };

  useEffect(() => {
    formattingMedias();
    checkIfPictureExist(useableMedia);
  }, [useableMedia]);

  useEffect(() => {
    let result = url_replacer(textToDisplay ?? "");
    userTags.map((item) => {
      result = result.replace(`@${item?.username}`, `<span style="color:#0052cc;">@${item?.username}</span>`);
    })
    setFormattedPostBodyForLinks(result);
  }, [textToDisplay]);

  useEffect(() => {
    const result = extractLinksFromTweet(textToDisplay ?? "");
    setLinkExtracted(result[0]);
  }, [textToDisplay]);

  const getPreviewLinkDetails = async (url) => {
    try {
      const result = await getPreviewFromUrl(url);
      setPreviewerData(result?.data);
    } catch (error) { }
  };

  useEffect(() => {
    if (!linkExtracted) {
      return;
    }
    getPreviewLinkDetails(linkExtracted);
  }, [linkExtracted]);

  const handleTextEditClick = () => {
    dispatch(setUnhideThreadTextEdit(storedIndex));
    openTextEdit ? setOpenTextEdit(false) : setOpenTextEdit(true);
  };

  const handleDeleteMedia = (data) => {
    mediaRemoveHandler(data, storedIndex);
  };

  useEffect(() => {
    if (processAltSave) {
      saveAltValue(storedIndex, currentUrlToCompare, altValue);
      setCanOpenPopOver(false);
      setTimeout(() => {
        setCanOpenPopOver(true);
      }, 10);
    }
  }, [processAltSave]);

  const handleAltEditOpener = (threadIndex, url, closing) => {
    !closing && assignInitialAltvalue(storedIndex, url);
    if (openAltSelction) {
      setOpenAltSelction(false);
      dispatch(setUnhideAltEdit(storedIndex));
    }
    try {
      dispatch(setUnhideAltEdit(storedIndex));
      openAltSelction ? setOpenAltSelction(false) : setOpenAltSelction(true);
    } catch (error) { }
  };

  useEffect(() => {
    handleAltEditOpener(index, null, true);
  }, [altCancelTrigger]);

  const getImageDimension = (source) => {
    let img = new Image();
    img.src = source;
    return {
      width: img.width,
      height: img.height,
    };
  };

  const handleOpeningEditArea = async (
    source,
    maxLimitReached,
    width,
    height
  ) => {
    const { new_width, new_height } = await recursiveAspectFinder(
      600,
      335,
      width,
      height,
      1
    );
    try {
      const image = new Image();
      image.src = source?.url;
      const canvas = document.createElement("canvas");
      if (!maxLimitReached) {
        canvas.width = new_width;
        canvas.height = new_height;
      }
      if (maxLimitReached) {
        canvas.width = 8192;
        canvas.height = 8192;
      }
      let scale = Math.max(
        canvas.width / image.naturalWidth,
        canvas.height / image.naturalHeight
      );
      let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
      let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
      canvas
        .getContext("2d")
        .drawImage(
          image,
          x,
          y,
          image.naturalWidth * scale,
          image.naturalHeight * scale
        );
      const dataURL = canvas.toDataURL();
      setImageToEditUrl(dataURL);
      setComparebleUrl(source?.url);
      setOpenEditArea(!openEditArea);
    } catch (error) {
      const image = new Image();
      image.src = source?.url;
      const canvas = document.createElement("canvas");
      setTimeout(() => {
        if (!maxLimitReached) {
          canvas.width = 600;
          canvas.height = 400;
        }
        if (maxLimitReached) {
          canvas.width = 8192; // 8192 is the max width and height of canvas;
          canvas.height = 8192; // 8192 is the max width and height of canvas;
        }
        let scale = Math.max(
          canvas.width / image.naturalWidth,
          canvas.height / image.naturalHeight
        );
        let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
        let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
        canvas
          .getContext("2d")
          .drawImage(
            image,
            x,
            y,
            image.naturalWidth * scale,
            image.naturalHeight * scale
          );
        const dataURL = canvas.toDataURL();
        setImageToEditUrl(dataURL);
        setComparebleUrl(source?.url);
        setOpenEditArea(!openEditArea);
      }, 1000);
    }
  };

  const handleConnect = async () => {
    try {
      googleDriveRef.current.click();
    } catch (error) {

    }
  }

  useEffect(() => {
    if (googleDriveManager['fromPreviews'] && googleDriveManager['promptConnect']) {
      setOpenConfirmation(true)
    }
  }, [googleDriveManager])

  const displaySingleThreadMedias = useableMedia?.map((singleitem, indexer) => {
    let notValid = false;
    let maxLimitReached = false;
    const { width, height } = getImageDimension(singleitem?.url);
    if (!singleitem?.external && (width < 600 || height < 335)) {
      notValid = true;
    }
    if (height > 8192 || width > 8192) {
      notValid = true;
      maxLimitReached = true;
    }
    if (useableMedia?.length === 1) {
      return (
        indexer < 4 && (
          <PostImageOne>
            {!viewOnly && singleitem?.is_video === false && notValid && singleitem?.external && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#949ca4AA",
                  zIndex: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "65%",
                      backgroundColor: `${config.isDark
                        ? darkTheme.workSpaceSettingsHeaderBar
                        : lightTheme.emptyButtonActive
                        }`,
                      padding: 10,
                      borderRadius: 10,
                    }}
                  >
                    <EditPopUp
                      style={{
                        color: `${!config.isDark && "black"}`,
                        fontSize: 13,
                      }}
                    >
                      {maxLimitReached ? editInfoTextMax : editInfoText}
                      {/* <EditPopUpBtnDiv>
                        {!maxLimitReached && (
                          <Button
                            variant="primary"
                            onClick={() =>
                              handleOpeningEditArea(
                                singleitem,
                                maxLimitReached,
                                width,
                                height
                              )
                            }
                            style={{ width: "auto", padding: "0px 10px" }}
                          >
                            Fix
                          </Button>
                        )}
                      </EditPopUpBtnDiv> */}
                    </EditPopUp>
                  </div>
                }
              </div>
            )}
            {!viewOnly && !notValid && !singleitem?.external && (
              <ImageEditButton
                variant="primary"
                onClick={() =>
                  handleOpeningEditArea(
                    singleitem,
                    maxLimitReached,
                    width,
                    height
                  )
                }
                style={{
                  position: "absolute",
                  margin: 10,
                }}
              />
            )}
            {singleitem?.is_video ? (
              <BaseVideoPlayer
                styles={{ borderRadius: 15 }}
                controls={true}
                height={"100%"}
                width={"100%"}
                fileSizeError={videoSizeError}
                maxVideoDuration={140}
                editVideoFormat={editVideoFormat}
                videoInfoText={videoInfoText}
                platform="twitter"
                videoUri={singleitem?.url}
                file={singleitem?.file}
                videoWidthLimit={1199}
                videoHeightLimit={719}
                checkingVideo={true}
                fullFile={item}
                openVideoEditArea={openVideoEditArea}
                setOpenVideoEditArea={setOpenVideoEditArea}
                threadIndex={index}
                viewOnly={viewOnly}
              />
            ) : (
              <img
                src={singleitem?.url}
                alt="post preview"
                height="100%"
                width="100%"
              />
            )}
            {
              !viewOnly &&
              <ImageDelete
                editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"}
                onClick={(e) => handleDeleteMedia(singleitem)}
              >
                <FontAwesomeIcon icon={faRemove} />
              </ImageDelete>
            }
            {!viewOnly && !item?.is_video && canOPenPopOver && (
              <OverlayTrigger
                trigger={"click"}
                overlay={
                  <Popover>
                    <TwitterFieldOptions
                      setProcessAltSave={setProcessAltSave}
                      title={"Enter an optional description for your photo"}
                      setAltValue={setAltValue}
                      defaultVal={altValue}
                      url={singleitem?.url}
                      altValue={altValue}
                      storedIndex={storedIndex}
                      setOpenAltSelction={setOpenAltSelction}
                      setCurrentUrlToCompare={setCurrentUrlToCompare}
                      setCanOpenPopOver={setCanOpenPopOver}
                    />
                  </Popover>
                }
              >
                <span>
                  <AltAdd
                    editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
                    onClick={(e) =>
                      handleAltEditOpener(storedIndex, singleitem?.url)
                    }
                  >
                    ALT
                  </AltAdd>
                </span>
              </OverlayTrigger>
            )}
          </PostImageOne>
        )
      );
    }
    if (useableMedia?.length > 1) {
      return (
        indexer < 4 && (
          <PostImage
            style={{
              gridRow: indexer === 0 && useableMedia?.length === 3 && "span 2",
            }}
            bgImg={singleitem?.url}
          >
            {!viewOnly && singleitem?.is_video === false && notValid && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  minHeight: "100%",
                  minWidth: "100%",
                  backgroundColor: "#949ca4AA",
                  zIndex: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "80%",
                      backgroundColor: `${config.isDark
                        ? darkTheme.workSpaceSettingsHeaderBar
                        : lightTheme.emptyButtonActive
                        }`,
                      padding: 10,
                      borderRadius: 10,
                    }}
                  >
                    <EditPopUp
                      style={{
                        color: `${!config.isDark && "black"}`,
                        fontSize: 10,
                      }}
                    >
                      {maxLimitReached ? editInfoTextMax : editInfoText}
                      <EditPopUpBtnDiv>
                        {!maxLimitReached && (
                          <Button
                            variant="primary"
                            onClick={() =>
                              handleOpeningEditArea(
                                singleitem,
                                maxLimitReached,
                                width,
                                height
                              )
                            }
                            style={{ width: "auto", padding: "0px 10px" }}
                          >
                            Fix
                          </Button>
                        )}
                      </EditPopUpBtnDiv>
                    </EditPopUp>
                  </div>
                }
              </div>
            )}
            {!viewOnly && !notValid && !singleitem?.external && (
              <ImageEditButton
                variant="primary"
                onClick={() =>
                  handleOpeningEditArea(
                    singleitem,
                    maxLimitReached,
                    width,
                    height
                  )
                }
                style={{
                  position: "absolute",
                  margin: 10,
                }}
              />
            )}
            {
              !viewOnly &&
              <ImageDelete
                editBgColor={!config.isDark ? "#e0e0e0" : "#0b1727"}
                onClick={(e) => handleDeleteMedia(singleitem)}
              >
                <FontAwesomeIcon icon={faRemove} />
              </ImageDelete>
            }
            {!viewOnly && !item?.is_video && canOPenPopOver && (
              <OverlayTrigger
                trigger={"click"}
                overlay={
                  <Popover>
                    <TwitterFieldOptions
                      setProcessAltSave={setProcessAltSave}
                      title={"Enter an optional description for your photo"}
                      setAltValue={setAltValue}
                      url={singleitem?.url}
                      defaultVal={altValue}
                      altValue={altValue}
                      setOpenAltSelction={setOpenAltSelction}
                      storedIndex={storedIndex}
                      setCurrentUrlToCompare={setCurrentUrlToCompare}
                      setCanOpenPopOver={setCanOpenPopOver}
                    />
                  </Popover>
                }
              >
                <span>
                  <AltAdd
                    editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
                    onClick={(e) =>
                      handleAltEditOpener(storedIndex, singleitem?.url)
                    }
                  >
                    ALT
                  </AltAdd>
                </span>
              </OverlayTrigger>
            )}
          </PostImage>
        )
      );
    }
  });

  useEffect(() => {
    openMediaSelction &&
      dispatch(setTwitterCurrentThreadIndexToAddMedia(storedIndex));
  }, [openMediaSelction]);

  const handleUpdateText = (text, indexStored = storedIndex) => {
    handleUpdateTextFromEditBox(text, indexStored);
  };

  const immediateActionWhenClieked = (remove) => {
    localStorage.setItem('twitterIndex', storedIndex)
    if (remove) {
      localStorage.removeItem("gdrivePlatform");
      return;
    }
    localStorage.setItem("gdrivePlatform", PlatformIdentifier.TWITTER);
  }


  const mediaOpener = (
    <div
      style={{
        position: "relative",
        width: "96%",
        marginLeft: "4%",
        zIndex: 5,
        marginBottom: 40,
      }}
    >
      {
        <MultimediaSelectionDropdown
          fromPreview={true}
          specificPlatformTied={PlatformIdentifier.TWITTER}
          initiatorId={
            currentMediaType === "image"
              ? `${PlatformIdentifier.TWITTER}-photo-chooser`
              : `${PlatformIdentifier.TWITTER}-video-chooser`
          }
          externalFilePickerTitle={
            currentMediaType === "image"
              ? "Enter Photo Location"
              : "Enter Video Location"
          }
          prompt={
            currentMediaType === "image" ? "Add Photo From" : "Add Video From"
          }
          fileType={currentMediaType === "image" ? "image" : "video"}
          externalPickerPromptLabel={
            currentMediaType === "image" ? "Photo" : "Video"
          }
          multiple={currentMediaType === "image" ? true : false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaObjects(files, from_local, item, index, others);
            }
          }}
        />
      }
    </div>
  );
  const closeImgEditor = () => {
    setOpenEditArea(false);
  };
  const saveDesignStateImage = (newUrl) => {
    closeImgEditor();
    mediaToReplace(comparebleUrl, newUrl, index);
  };

  const displayThreads = (
    <>
      {
        googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.TWITTER} /> : null
      }
      {openEditArea && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100%",
            zIndex: 10000,
          }}
        >
          <FilerobotImageEditor
            source={imageToEditUrl}
            theme={{
              palette: {
                "bg-secondary": !config.isDark
                  ? lightTheme?.rootBackground
                  : "#0b1727",
                "txt-primary": !config.isDark ? "#2e2e2e" : "#919090",
              },
              p: {
                fontFamily: "Roboto, Arial",
              },
            }}
            onBeforeSave={() => false}
            onSave={(editedImageObject, designState) => {
              let canvas = editedImageObject.imageCanvas;
              saveDesignStateImage(canvas.toDataURL());
            }}
            onClose={closeImgEditor}
            annotationsCommon={{
              fill: "#ff0000",
            }}
            Text={{ text: "Postly..." }}
            tabsIds={[
              //
              TABS.ADJUST,

              // TABS.ANNOTATE,

              // TABS.WATERMARK,

              // TABS.FILTERS,

              // TABS.FINETUNE,

              TABS.RESIZE,
            ]}
            // defaultTabId={TABS.ADJUST} // or 'Annotate'
            defaultToolId={TOOLS.TEXT} // or 'Text'
          />
        </div>
      )}
      <ParentSection>
        <Wrapper>
          {!viewOnly && openMediaSelction && unhideThreadEdit === index && mediaOpener}
          {openTextEdit && unhideThreadTextEdit === index && (
            <EditTextBox
              icon={
                <FontAwesomeIcon
                  style={{
                    color: platform.color,
                  }}
                  icon={faTwitter}
                />
              }
              value={textToDisplay}
              setValue={setTextToDisplay}
              setToggleEditTextInput={setOpenTextEdit}
              platform={"twitter"}
              addTwitterThread={addTwitterThread}
              indexer={index}
              setter={{ ...manuallyEditedPlatforms, facebook: true }}
              updateText={handleUpdateText}
            />
          )}
          { }

          <ChunckedBox
            mainBgColor={!config.isDark ? "white" : "#0b1727"}
            style={{
              height: "fit-content",
              borderColor: `${!config.isDark ? "#ededed" : "transparent"}`,
            }}
            enableTransition={showedPreview ? true : false}
            theme={!config.isDark ? "1px 1px 3px #949494" : "none"}
          >
            {(item?.textEdited || item?.mediaEdited) && (
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  fontStyle: "italic",
                  position: "absolute",
                  top: 0,
                  right: 5,
                  fontSize: 11,
                }}
              >
                <p
                  style={{
                    color: !config.isDark ? "grey" : "grey",
                  }}
                >
                  Edited
                </p>
              </div>
            )}

            <LeftSide>
              {index !== 0 && (
                <LineThroughTop
                  lineThroughBorder={
                    !config.isDark ? "2px solid #dbdbdb" : "2px solid #585959"
                  }
                ></LineThroughTop>
              )}
              <ImageHolder>
                <img
                  style={{
                    minHeight: "20px",
                    minWidth: "20px",
                    maxHeight: "20px",
                    maxWidth: "20px",
                    borderRadius: "100%",
                    backgroundColor: "white",
                  }}
                  src="https://app.postly.ai/static/media/postly_logo.2e3243cd201fdf7e1b5c.png"
                  alt="dp"
                />
              </ImageHolder>
              {index !== chunkedTweet?.length - 1 && (
                <LineThrough
                  lineThroughBorder={
                    !config.isDark ? "2px solid #dbdbdb" : "2px solid #585959"
                  }
                ></LineThrough>
              )}
            </LeftSide>
            <MiddleSide>
              <UpperRange style={{ flex: 1, height: "100%", width: "100%" }}>
                <p
                  style={{
                    color: `${!config.isDark ? "black" : "whitesmoke"}`,
                    fontWeight: "600",
                    fontSize: 10
                  }}
                >
                  {"Twitter Preview"}
                </p>
                <p
                  type={"p"}
                  style={{
                    color: "grey",
                    fontSize: 10
                  }}
                >
                  @usepostly
                </p>

                <p
                  type={"p"}
                  style={{
                    color: "grey",
                    fontSize: 10
                  }}
                >
                  - {moment(timeToPost).format("ss")}s
                </p>
              </UpperRange>
              {
                repostionImages ? <DragToPositionImage useableMedia={useableMedia} setter={setUseableMedia} platform={PlatformIdentifier.TWITTER} /> :
                  <CenterRange>
                    <pre
                      style={{
                        marginBottom: 10,
                        color: `${!config.isDark ? "black" : "#b3b3b3"}`,
                        maxWidth: "100%",
                        width: "100%",
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        fontSize: 11
                      }}
                    >
                      {parse(formattedPostBodyForLinks)}
                    </pre>
                    {previewerData && !imgSrc && (
                      <GridImageOne>
                        {
                          <LinkPreviewer
                            domain={previewerData?.siteName}
                            img={previewerData?.images?.length ? previewerData?.images[0] : null}
                            requestUrl={previewerData?.url}
                            title={previewerData?.title}
                            favicon={previewerData?.favicons?.length ? previewerData?.favicons[0] : null}
                          />
                        }
                      </GridImageOne>
                    )}
                    {imgSrc && (
                      <PostImage
                        src={mediaObjects[0].url}
                        alt="post preview"
                      ></PostImage>
                    )}
                    {storedIndex === 0 && useableMedia?.length === 1 ? (
                      <GridImageOne>{displaySingleThreadMedias}</GridImageOne>
                    ) : storedIndex === 0 && useableMedia?.length > 1 ? (
                      <GridImage
                        gridRow={useableMedia?.length < 3 ? "250px" : "120px"}
                        gridColumn={useableMedia?.length === 1 ? "1fr" : "1fr 1fr"}
                      >
                        {displaySingleThreadMedias}
                      </GridImage>
                    ) : storedIndex !== 0 && useableMedia?.length === 1 ? (
                      <GridImageOne>{displaySingleThreadMedias}</GridImageOne>
                    ) : storedIndex !== 0 && useableMedia?.length > 1 ? (
                      <GridImage
                        gridRow={useableMedia?.length < 3 ? "250px" : "120px"}
                        gridColumn={useableMedia?.length === 1 ? "1fr" : "1fr 1fr"}
                      >
                        {displaySingleThreadMedias}
                      </GridImage>
                    ) : null}
                  </CenterRange>
              }
              <LowerRange>
                <FontAwesomeIcon
                  id={`twitterIconPlus-${index}`}
                  style={{
                    color: "#78909C",
                    cursor: 'pointer',
                    transition: 'all 0.3s ease-in-out'
                  }}
                  icon={faPlus}
                  onClick={() => {
                    const iconElement = document.getElementById(`twitterIconPlus-${index}`);
                    iconElement.classList.add('zoom-in-out');
                    setTimeout(() => {
                      iconElement.classList.remove('zoom-in-out');
                    }, 500);
                    addTwitterThread(index)
                  }}
                />

                {!viewOnly && !videoExist && pictureCount < 5 && (
                  <MultimediaSelectionDropdown
                    dropDownAnchor={
                      <CircularButton noBgColor={true} onClick={() => immediateActionWhenClieked(false)} title="Add Image">
                        <FontAwesomeIcon icon={faImage} fontSize={13} />
                      </CircularButton>
                    }
                    specificPlatformTied={PlatformIdentifier.TWITTER}
                    fromPreview={true}
                    initiatorId={`${PlatformIdentifier.TWITTER}-photo-chooser`}
                    externalFilePickerTitle={"Enter Photo Location"}
                    prompt={"Add Photo From"}
                    fileType={"image"}
                    externalPickerPromptLabel={"Photo"}
                    multiple={true}
                    fileSelectionHandler={(response) => {
                      if (response) {
                        let { files, from_local, ...others } = response;
                        updateMediaObjects(files, from_local, item, parseInt(localStorage.getItem('twitterIndex')), others);
                      }
                    }}
                  />
                )}
                {!viewOnly && pictureCount < 1 && !videoExist && (
                  <MultimediaSelectionDropdown
                    dropDownAnchor={
                      <CircularButton noBgColor={true} onClick={() => localStorage.setItem('twitterIndex', storedIndex)} title="Add Video">
                        <FontAwesomeIcon icon={faVideo} fontSize={13} />
                      </CircularButton>
                    }
                    specificPlatformTied={PlatformIdentifier.TWITTER}
                    fromPreview={true}
                    initiatorId={`${PlatformIdentifier.TWITTER}-video-chooser`}
                    externalFilePickerTitle={"Enter Video Location"}
                    prompt={"Add Video From"}
                    fileType={"video"}
                    externalPickerPromptLabel={"Video"}
                    multiple={true}
                    fileSelectionHandler={(response) => {
                      if (response) {
                        let { files, from_local, ...others } = response;
                        updateMediaObjects(files, from_local, item, index, others);
                      }
                    }}
                  />
                )}
                {/* <CircularButton
                  noBgColor={true}
                  // onClick={() => handleTextEditClick()}
                  title="Tag a user"
                >
                  <PopDrop
                    dropDownAnchor={
                      <Button
                        size='sm'
                        variant={'none'}
                        className='px-2 cursor-pointer'>
                        <FontAwesomeIcon icon={faAt} fontSize={13} />
                      </Button>
                    }
                    close={popDropClose}
                    setPopDropClose={setPopDropClose}
                    alignment={'start'}
                    width={395}
                    PopContainer={
                      <TaggingPop
                        setPopDropClose={setPopDropClose}
                        platform={PlatformIdentifier.TWITTER}
                        value={textToDisplay}
                        setValue={setTextToDisplay}
                        setUpdateDirectly={handleUpdateText}
                      />
                    }
                  />
                </CircularButton> */}
                {!viewOnly && !openTextEdit && (
                  <CircularButton
                    noBgColor={true}
                    onClick={() => handleTextEditClick()}
                    title="Edit Text"
                  >
                    <HiPencilAlt size={14} />
                  </CircularButton>
                )}
                {
                  !viewOnly &&
                  <CircularButton
                    noBgColor={true}
                    onClick={() => [setRepositionImages(!repostionImages), localStorage.setItem('twitterIndex', storedIndex)]}
                    title="re-position images"
                  >
                    <RiDragDropLine size={14} />
                  </CircularButton>
                }

                {
                  !viewOnly &&
                  <FontAwesomeIcon
                    id={`twitterIconMinus-${index}`}
                    style={{
                      color: "#78909C",
                      cursor: 'pointer'
                    }}
                    icon={faMinus}
                    onClick={() => {
                      const iconElement = document.getElementById(`twitterIconMinus-${index}`);
                      iconElement.classList.add('zoom-in-out');
                      setTimeout(() => {
                        iconElement.classList.remove('zoom-in-out');
                      }, 500);
                      deleteThread(index)
                    }}
                  />
                }
              </LowerRange>

              <BottomHint>
                <div style={{ position: 'absolute', bottom: 0, right: 0, fontSize: 11, }}>
                  Character Count: {textToDisplay?.length} / 280
                </div>
              </BottomHint>
            </MiddleSide>
            <RightSide>
              <OverlayTrigger
                trigger={"click"}
                overlay={
                  !viewOnly ? <Popover>
                    <OverFlowSide
                      bgColorOverFlow={
                        !config.isDark
                          ? lightTheme.textAreaBackground
                          : darkTheme.textAreaBackground
                      }
                    >
                      <OverFlowList
                        listColor={
                          !config.isDark
                            ? darkTheme.textAreaBackground
                            : "white"
                        }
                        listHoverColor={
                          !config.isDark ? lightTheme.primary : "#b0b0b0"
                        }
                        onClick={() => addTwitterThread(index)}
                      >
                        Add New Thread
                      </OverFlowList>
                      <OverFlowList
                        listColor={
                          !config.isDark
                            ? darkTheme.textAreaBackground
                            : "white"
                        }
                        listHoverColor={
                          !config.isDark ? lightTheme.primary : "#b0b0b0"
                        }
                        onClick={() => deleteThread(index)}
                      >
                        Delete Thread
                      </OverFlowList>
                    </OverFlowSide>
                  </Popover> : <></>
                }
              >
                <span>
                  <FontAwesomeIcon
                    style={{
                      color: "#78909C",
                    }}
                    icon={faEllipsis}
                  />
                </span>
              </OverlayTrigger>
            </RightSide>
          </ChunckedBox>
        </Wrapper>
        
        <Modal
          show={openPixelDialoguePicker}
          onHide={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
          onExit={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
        >
          <Modal.Header>
            <Modal.Title>Pexels.com</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExtrernalImageSearchDialogue
              handleUpdateText={handleUpdateText}
              setUseableMedia={setUseableMedia}
              setChunkedTweet={setChunkedTweet}
              determiner={PlatformIdentifier.TWITTER}
              threadIndex={index}
              // options={externalFilePickerDialogOptions}
              modalClose={() => {
                // dispatch(setOpenExternalFilePickerDialog(false));
              }}
            />
          </Modal.Body>
        </Modal>
        <ConfirmModal
          open={openConfirmation}
          title={"Connect Your Google Drive Account"}
          message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
          confirmText={"PROCEED"}
          cancelText={"CANCEL"}
          showDialoguePrompt
          onCancel={() => {
            setOpenConfirmation(false);
            dispatch(setGoogleDriveManager({
              ...googleDriveManager,
              promptConnect: false,
            }))
          }}
          onConfirm={() => {
            handleConnect();
            dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
          }}
        />
      </ParentSection>
    </>
  );
  return <>
    <GDriveConnectionRequestListener
      googleDriveRef={googleDriveRef}
    />
    {displayThreads}
  </>;
};
export default TwitterThreadPreviewAndEdit;