export default class StringUtils {

    static substringBeforeLast(parent, str) {
        let idx = parent.lastIndexOf(str);
        if (idx !== -1) {
            return parent.substr(0, idx);
        }
        return parent;
    }

    static substringAfterLast = function (parent, str) {
        let idx = parent.lastIndexOf(str);
        if (idx !== -1) {
            return parent.substr(idx + str.length);
        }
        return parent;
    }

    static substringAfter = function (parent, str) {
        let idx = parent.indexOf(str);
        if (idx !== -1) {
            return parent.substr(idx + str.length);
        }
        return parent;
    }

    static substringBefore = function (parent, str) {
        let idx = parent.indexOf(str);
        if (idx !== -1) {
            return parent.substr(0, idx);
        }
        return parent;
    }

    static generateRandomString = function (length = 60) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    static capitalizeString = function (str = "") {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    static isStringAFirebaseLink = function (str) {
        if (typeof str !== "string") {
            return false;
        }
        return str.includes("firebasestorage.googleapis.com");
    }

    static convertSnakeCaseToUpperCase = function (str) {
        return str.split('_').map(word => word.toUpperCase()).join(' ');
    }

    static getFileExtensionFromLink = function (link) {
        // check if the link is a firebase link
        if (StringUtils.isStringAFirebaseLink(link)) {
            console.log('trying to split now')
            let baseUrl = link?.split('?')[0];
            console.log("🚀 ~ StringUtils ~ baseUrl:", baseUrl)
            let parts = baseUrl.split('.');
            let extension = parts[parts.length - 1];
            return extension;
        } else {
            let parts = link.split('.');
            let extension = parts[parts.length - 1];
            return extension;
        }
    }

    static highlightMatches = function (text, searchQuery = "") {

        if (!searchQuery) return text;
        const escapedQuery = searchQuery.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
        const regex = new RegExp(`(${escapedQuery})`, 'gi');
        const parts = text.split(regex);

        return parts.map((part, index) =>
            regex.test(part) ? (
                <span key={index} className="text-warning">
                    {part}
                </span>
            ) : (
                part
            )
        );
    }

}
