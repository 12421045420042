import EndPoints from 'http/endpoints';
import { useEffect } from 'react';
import { useState } from 'react';
import io from 'socket.io-client';

let socket = null;

// const socket = io.connect(`${EndPoints.SOCIAL_INBOX_BASE}`, {
//     reconnection: true,
// });
const eventListeners = {};

//? Get socket current state
export const getSocketState = () => {
    return socket.connected ? 'connected' : 'disconnected';
};

export const useSocketState = () => {
    const [socketState, setSocketState] = useState(getSocketState());

    const updateSocketState = () => {
        setSocketState(getSocketState());
    };

    useEffect(() => {
        const handleConnect = () => {
            updateSocketState();
        };

        const handleDisconnect = () => {
            updateSocketState();
        };

        socket.on('connect', handleConnect);
        socket.on('disconnect', handleDisconnect);

        return () => {
            socket.off('connect', handleConnect);
            socket.off('disconnect', handleDisconnect);
        };
    }, []);

    return socketState;
};

// Export functions or objects related to Socket.io
// Function to emit a custom event
export const emitCustomEvent = (data) => {
    socket.emit('custom-event', data);
};
export const onNewUser = (userId) => {
    socket.emit('add-new-user', { userId });
};

export const emitReadMessages = (roomId, senderId) => {
    socket.emit('mark-as-read', { roomId, senderId });
};

// Function to listen for a custom event
export const onCustomEvent = (callback) => {
    socket.on('custom-event', callback);
};
// export const onNewChat = (callback) => {
//     socket.on('new-chat', callback);
// };
// export const onNewMessage = (callback) => {
//     socket.on('new-message', callback);
// };

// Function to set up the "new-chat" event listener
export const onNewChat = (callback) => {
    if (!eventListeners['new-chat']) {
        eventListeners['new-chat'] = [];
    }
    eventListeners['new-chat'].push(callback);

    socket.on('new-chat', (data) => {
        eventListeners['new-chat'].forEach((listener) => {
            listener(data);
        });
    });
};


export const onChatReset = (callback) => {
    if (!eventListeners['reset-chat']) {
        eventListeners['reset-chat'] = [];
    }
    eventListeners['reset-chat'].push(callback);

    socket.on('reset-chat', (data) => {
        eventListeners['reset-chat'].forEach((listener) => {
            listener(data);
        });
    });
};
export const getOnlineUsers = (callback) => {
    if (!eventListeners['get-online-users']) {
        eventListeners['get-online-users'] = [];
    }
    eventListeners['get-online-users'].push(callback);

    socket.on('get-online-users', (data) => {
        console.log("🚀 ~ file: chatSocket.js:53 ~ socket.on ~ data:", data)
        eventListeners['get-online-users'].forEach((listener) => {
            console.log("🚀 ~ file: chatSocket.js:55 ~ eventListeners['get-online-users'].forEach ~ listener:", listener)
            listener(data);
        });
    });
};
export const onMessageDelivery = (callback) => {
    if (!eventListeners['message-delivery']) {
        eventListeners['message-delivery'] = [];
    }
    eventListeners['message-delivery'].push(callback);

    socket.on('message-delivery', (data) => {
        eventListeners['message-delivery'].forEach((listener) => {
            listener(data);
        });
    });
};
export const onReaction = (callback) => {
    if (!eventListeners['reaction']) {
        eventListeners['reaction'] = [];
    }
    eventListeners['reaction'].push(callback);

    socket.on('reaction', (data) => {
        eventListeners['reaction'].forEach((listener) => {
            listener(data);
        });
    });
};

// Function to set up the "new-message" event listener
export const onNewMessage = (callback) => {
    if (!eventListeners['new-message']) {
        eventListeners['new-message'] = [];
    }
    eventListeners['new-message'].push(callback);

    socket.on('new-message', (data) => {
        eventListeners['new-message'].forEach((listener) => {
            listener(data);
        });
    });
};
export const onMessageRead = (callback) => {
    if (!eventListeners['mark-as-read']) {
        eventListeners['mark-as-read'] = [];
    }
    eventListeners['mark-as-read'].push(callback);

    socket.on('mark-as-read', (data) => {
        eventListeners['mark-as-read'].forEach((listener) => {
            listener(data);
        });
    });
};

// Function to remove a specific event listener
export const removeListener = (event, callback) => {
    if (eventListeners[event]) {
        const index = eventListeners[event].indexOf(callback);
        if (index !== -1) {
            eventListeners[event].splice(index, 1);
        }
    }
};

// Function to remove all listeners for a specific event
export const removeAllListeners = (event) => {
    if (eventListeners[event]) {
        eventListeners[event] = [];
    }
};

// Function to disconnect the socket
export const disconnectSocket = () => {
    socket.disconnect();
};
export const connectSocket = () => {
    socket.connect();
};

export const removeEventListeners = (callback) => {
    socket.off('new-chat', callback);
    socket.off('new-message', callback);
};