import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import {
    setDisablePostButton,
    setFirstComment
} from '../../../../redux/slices/postslice';
import { Button, Form, Card, Modal } from "react-bootstrap";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { faHashtag, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import StringUtils from "utils/string";
import ConfirmModal from "components/common/ConfirmModal";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { isFreeWorkspace } from "utils/workspace_utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "constants";
import { setPopupUpgradeAlert } from "redux/slices/authSlice";
import { setCollectMetric } from "redux/slices/analytics_slice";
import { MetricsProps } from "constants";

export default function FirstCommentView(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const firstComment = props.firstComment;
    const postTextBody = props.postTextBody;
    const mediaObjects = props.mediaObjects;
    const [firstCommentText, setFirstCommentText] = useState(firstComment?.firstCommentText);
    const [openFirstCommentBox, setOpenFirstCommentBox] = useState(false);
    const [openEditor, setOpenEditor] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [firstCommentErrorFlagged, setFirstCommentErrorFlagged] = useState({
        error: false,
        message: "50 words limit. Hashtags not allowed"
    });
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const workspace = useSelector(getActiveWorkSpace);
    const isFreeWorkSpace = isFreeWorkspace(workspace);

    useEffect(() => {
        setOpenFirstCommentBox(postTextBody?.length > 0 || mediaObjects.length > 0);
    }, [postTextBody, mediaObjects]);

    useEffect(() => {
        dispatch(setDisablePostButton((postTextBody?.length > 0 || mediaObjects.length > 0) && openEditor));
    }, [openEditor]);

    const validateFirstComment = () => {
        if (!firstCommentText) {
            setFirstCommentErrorFlagged({
                error: false,
                message: "50 words limit. Hashtags not allowed"
            });
            return;
        }
        let words = firstCommentText.trim().split(/\s+/);
        if (firstCommentText.includes("#") && words.length > 50) {
            setFirstCommentErrorFlagged({
                error: true,
                message: "You have exceeded the 50 words limit. Hashtags is not allowed"
            });
            return;
        }
        if (firstCommentText.includes("#")) {
            setFirstCommentErrorFlagged({
                error: true,
                message: "Hashtags not allowed"
            });
            return;
        }
        if (words.length > 50) {
            setFirstCommentErrorFlagged({
                error: true,
                message: "You have exceeded the 50 words limit."
            });
            return;
        }
        setFirstCommentErrorFlagged({
            error: false,
            message: "50 words limit. Hashtags not allowed"
        });

    }

    useEffect(() => {
        validateFirstComment()
    }, [firstCommentText])


    const handleTextChange = (e) => {
        try {
            const value = e.target.value;
            let words = value.trim().split(/\s+/);
            setWordCount(words.length);
            setFirstCommentText(value);

        } catch (e) {
            console.log(e, "as error from handleTextChange");
        }
    }

    const handleAddFirstComment = () => {
        if (firstCommentErrorFlagged.error) {
            setOpenErrorModal(true);
            return;
        }
        dispatch(setFirstComment({
            ...firstComment,
            firstCommentText,
            id: StringUtils.generateRandomString(10)
        }))
        setOpenEditor(false);
    }

    return (
        <>
            {
                openFirstCommentBox ?
                    <>
                        <Card className="bg-100 mt-2 shadow-none" style={{ borderRadius: 0 }}>
                            {
                                openEditor ?
                                    <Flex className={'m-2'}>
                                        <div
                                            style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}
                                        >
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                style={{
                                                    padding: '10px',
                                                    borderColor: firstCommentErrorFlagged.error ? 'red' : '#ced4da',
                                                }}
                                                placeholder="Type first comment"
                                                value={firstCommentText}
                                                onChange={(e) => {
                                                    handleTextChange(e);
                                                }}
                                            />
                                            <div
                                                style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                                            >
                                                <p
                                                    className="fs--1 text-start p-1"
                                                >
                                                    {
                                                        firstCommentErrorFlagged.error ?
                                                            <span className="text-danger">{firstCommentErrorFlagged.message}</span> :
                                                            <span style={{ color: '#509bfc' }}>{firstCommentErrorFlagged.message}</span>
                                                    }
                                                </p>
                                                <p
                                                    className="fs--1 text-start p-1"
                                                >
                                                    {
                                                        wordCount > 50 ?
                                                            <span className="text-danger">{wordCount}/50 words</span> :
                                                            <span>{wordCount}/50 words</span>
                                                    }

                                                </p>
                                            </div>
                                        </div>
                                        <Flex
                                            justifyContent={'end'}
                                            className={'gap-2'}
                                            alignItems={'center'}>
                                            <Button
                                                variant="default"
                                                size="sm"
                                                onClick={() => {
                                                    setOpenEditor(false);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                size="sm"
                                                variant="outline-primary"
                                                onClick={() => {
                                                    handleAddFirstComment();
                                                }}>
                                                Add
                                            </Button>
                                        </Flex>
                                    </Flex> :
                                    <Button
                                        onClick={() => {
                                            if (isFreeWorkSpace) {
                                                let metricInitializer = {
                                                    action: MetricsProps.INSTAGRAM_FIRST_COMMENT,
                                                    detailedAction: `Upgrade notice: Attempted to add first comment on Instagram post`,
                                                    timestamp: new Date().toISOString(),
                                                    route: window.location.pathname.replace(workspace._id, '').replace('//', '/'),
                                                  }
                                                  dispatch(setCollectMetric(metricInitializer))
                                                dispatch(setPopupUpgradeAlert(true))
                                                return;
                                            }
                                            setOpenEditor(true);
                                        }} variant={'default'}>
                                        <Flex
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            className={'gap-2 p-2'}>
                                            <div style={{ position: 'relative' }}>
                                                <FontAwesomeIcon className="text-primary" icon={faMessage} />
                                                <FontAwesomeIcon
                                                    icon={faHashtag}
                                                    className="text-primary"
                                                    style={{
                                                        position: 'absolute',
                                                        left: 0,
                                                        top: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                        margin: 'auto',
                                                        marginTop: '39%',
                                                        width: 8,
                                                        height: 8,
                                                        zIndex: 1
                                                    }}
                                                />
                                            </div>
                                            <span className="fs--1 text-start p-1">{firstCommentText ? firstCommentText : 'Click here to add your First Comment on Instagram (optional)'}</span>
                                            {
                                                firstCommentText &&
                                                <Flex alignItems={'center'} className={'flex-1 gap-2'} justifyContent={'end'}>
                                                    <span className="fs--1">Edit</span>
                                                    <FontAwesomeIcon size="sm" icon={faPencilAlt} />
                                                </Flex>
                                            }
                                        </Flex>
                                    </Button>

                            }

                        </Card>
                    </> : <></>
            }
            <Modal
                show={openErrorModal}
                onHide={() => {
                    setOpenErrorModal(false);
                }}
                onExit={() => {
                    setOpenErrorModal(false);
                }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className="text-danger ">Error</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-lg">
                        {firstCommentErrorFlagged.message}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant={"primary"}
                        onClick={() => {
                            setOpenErrorModal(false);
                        }}
                    >
                        Got it
                    </Button>
                </Modal.Footer>
            </Modal>
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Upgrade Account"}
                message={"This feature requires an account upgrade. Kindly upgrade to a premium account to continue"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(`${RoutePaths.BILLING_STRIPE}`)
                }}
            />
        </>
    )
}