/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { darkTheme, lightTheme } from "utils/theme";
import { PlatformIdentifier } from "../../../../../constants";
import {
  setTriggerComponentRemount
} from "../../../../../redux/slices/postslice";
import { toast } from "react-toastify";
import "./editTextBox.css";
import AppContext from "context/Context";
import { Button } from "react-bootstrap";
import ConfirmModal from "components/common/ConfirmModal";
import PopDrop from "components/common/PopDrop";
import HashTagPop from "../HashTagPop";
import APIService from "http/api_service";
import HashTagWatcher from "../HashTagWatcher";
import TaggingPop from "../TaggingPop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import { getLoggedInUser } from "redux/slices/user_slice";
import { useSelector } from 'react-redux';
import { canLoggedInUserBeDemo } from "utils/user_utils";

export const reformatHashTexts = (str) => {
  let result = str.match(/#[\w]+(?=\s|$)/g)
  let newResult = str;
  let currentWord;
  result?.map((word, index) => {
    if (index == result?.length - 1) {
      currentWord = word
    }
    newResult = newResult.replace(word, `<span style="color:#0052cc;">${word}</span>`);
  })
  return {
    newText: newResult
  };
}
export const getDifference = (a, b) => {
  var i = 0;
  var j = 0;
  var result = "";

  while (j < b.length) {
    if (a[i] != b[j] || i == a.length)
      result += b[j];
    else
      i++;
    j++;
  }
  return result;
}

const EditTextBox = ({
  value,
  setValue,
  setToggleEditTextInput,
  incomingRef,
  platform,
  updateText,
  setter,
  isPlatformEdited,
  addTwitterThread,
  indexer,
  icon
}) => {
  const btnRef = useRef(null);
  const dispatch = useDispatch();
  const [displayConfirm, setDisplayConfirm] = useState(false);
  const [backupInitialValue, setBackupInitialValue] = useState(value);
  const { config } = useContext(AppContext);
  const loggedInUser = useSelector(getLoggedInUser);
  const isDemoAccount = canLoggedInUserBeDemo(loggedInUser);
  const [beginMonitoring, setBeginMonitoring] = useState(false);
  const [showHashTag, setShowHastTag] = useState(false);
  const [currentSearchTag, setCurrentSearchTag] = useState('')
  const [popDropClose, setPopDropClose] = useState(false)

  const lunchInitBtnClickForHashTag = () => {
    const shouldOpenTextEdit = localStorage.getItem('open_hashtag_pop');
    if (shouldOpenTextEdit == 'true') {
      btnRef.current.click();
      localStorage.removeItem('open_hashtag_pop')
    }
  }


  useEffect(() => {
    setBackupInitialValue(value);
    lunchInitBtnClickForHashTag()
  }, []);

  const updateStateInput = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleAbortClick = (e) => {
    try {
      setValue(backupInitialValue);
      setTimeout(() => {
        setToggleEditTextInput(false);
      }, 400);
    } catch (err) {
    }
  };

  const handleDoneClick = async () => {
    if (platform === PlatformIdentifier.TWITTER) {
      if (!value) {
        toast.error(`Sorry. You can't have this thread empty.`, { theme: 'colored' });
        return
      }
      updateText(value)
      setTimeout(() => {
        setToggleEditTextInput(false);
      }, 500);
      return;
    }
    // !return here
    updateText(value)
    setTimeout(() => {
      setToggleEditTextInput(false);
    }, 500);
  };

  const getTextLimit = (platform) => {
    if (platform === PlatformIdentifier.TWITTER) {
      return "280";
    }
    if (platform === PlatformIdentifier.FACEBOOK) {
      return "62206";
    }
    if (platform === PlatformIdentifier.INSTAGRAM) {
      return "2200";
    }
    if (platform === PlatformIdentifier.LINKED_IN) {
      return "3000";
    }
    if (platform === PlatformIdentifier.REDDIT) {
      return "10000";
    }
    if (platform === PlatformIdentifier.GOOGLE_MY_BUSINESS) {
      return "750";
    }
    if (platform === PlatformIdentifier.TIKTOK) {
      return "300";
    }
    if (platform === PlatformIdentifier.TELEGRAM) {
      return "4096";
    }
    if (platform === PlatformIdentifier.YOUTUBE) {
      return "100";
    }
  };

  const byteSize = (str) => new Blob([str]);

  const cutOffBytes = (text) => {
    let determiner = text.length - 1
    if (byteSize(text).size < 280) {
      setValue(text);
      return;
    }
    cutOffBytes(text.slice(0, determiner));
  };

  const textLimitWatcher = () => {
    const limit = parseInt(getTextLimit(platform));
    if (platform === PlatformIdentifier.TWITTER) {
      if (byteSize(value).size > limit) {
        setDisplayConfirm(true);
      }
      return;
    }
    if (value?.length > limit) {
      setValue(value.slice(0, limit));
      toast.info(`You have reached the text limit for ${platform}.`, { theme: 'colored' });
      return;
    }
    return;
  };

  useEffect(() => {
    textLimitWatcher();
  }, [value]);

  const handleOnClose = () => {
    cutOffBytes(value);
    setDisplayConfirm(false);
  };

  const handleConfirm = async () => {
    try {
      addTwitterThread(indexer, value.slice(0, 280))
      setDisplayConfirm(false);
      dispatch(setTriggerComponentRemount("refresh"));
      setTimeout(() => {
        dispatch(setTriggerComponentRemount(PlatformIdentifier.TWITTER));
      }, 1500);
    } catch (error) {
    }
  };

  return (
    <>
      <section ref={incomingRef} className="mainEditBoxSection">
        <div
          style={{
            height: "auto",
            width: "96%",
            marginLeft: "4%",
            paddingBottom: 10,
            backgroundColor: `${!config.isDark
              ? lightTheme.surfaceColor
              : darkTheme.rootBackground
              }`,
          }}
        >
          {/* <HashTagWatcher
          value={value}
          setShowHastTag={setShowHastTag}
          showHashTag={showHashTag}
          setCurrentSearchTag={setCurrentSearchTag}
          setBeginMonitoring={setBeginMonitoring}
          beginMonitoring={beginMonitoring}
        /> */}
          <textarea
            style={{
              backgroundColor: `transparent`,
              color: `${!config.isDark ? "black" : "whitesmoke"}`,
              outline: 'none'
            }}
            value={value}
            onChange={updateStateInput}
          >
          </textarea>
          <div
            style={{
              height: "auto",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              fontSize: 11,
              padding: "0 1%",
              color: `${!config.isDark ? "black" : "whitesmoke"}`,
            }}
          >
            {/* {showHashTag &&
            <HashTagPop
              mainWidth="100%"
              value={value}
              updater={setValue}
              setBeginMonitoring={setBeginMonitoring}
              identifier={platform}
              icon={icon}
              fromPreviews={true}
              searchedWord={currentSearchTag}
              setSearchedWord={setCurrentSearchTag}
              fromTyping={true}
            />
          } */}
            <div style={{ display: 'flex', gap: 10 }}>
              {!showHashTag && <PopDrop
                alignment={'start'}
                dropDownAnchor={
                  <Button
                    ref={btnRef}
                    size='sm'
                    variant={'outline-secondary'}
                    className='mx-2 fs--2 pe-2 fw-medium cursor-pointer'>#HASHTAGS
                  </Button>
                }
                PopContainer={
                  <HashTagPop
                    value={value}
                    updater={setValue}
                    setBeginMonitoring={setBeginMonitoring}
                    identifier={platform}
                    fromPreviews={true}
                    icon={icon}
                    fromTyping={false}
                    mainWidth={'400px'}
                  />
                }
              />}
              {/* {
                (platform === PlatformIdentifier.TWITTER || platform === PlatformIdentifier.INSTAGRAM) &&
                <PopDrop
                  dropDownAnchor={
                    <Button
                      size='sm'
                      variant={'none'}
                      className='px-2 cursor-pointer'>
                      <FontAwesomeIcon icon={faAt} fontSize={18} />
                    </Button>
                  }
                  close={popDropClose}
                  setPopDropClose={setPopDropClose}
                  alignment={'start'}
                  width={395}
                  PopContainer={
                    <TaggingPop
                      setPopDropClose={setPopDropClose}
                      platform={platform}
                      value={value}
                      setValue={setValue}
                    />
                  }
                />
              } */}

            </div>

            {!showHashTag && <p>
              {platform === 'twitter' ? byteSize(value).size : value?.length}/{getTextLimit(platform)}
            </p>}

          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 10,
            padding: 10
          }}
        >
          <Button
            size={'sm'}
            variant="light"
            onClick={handleAbortClick}
          >
            Cancel
          </Button>
          <Button
            size={'sm'}
            variant={"primary"}
            onClick={handleDoneClick}
          >
            Done
          </Button>
        </div>
        <ConfirmModal
          open={displayConfirm}
          onCancel={() => {
            handleOnClose();
          }}
          title={"Message"}
          message={
            "You have reach the text limit for this thread. Do you want create new thread to proceed?"
          }
          cancelText="CANCEL"
          confirmText="CONTINUE"
          onConfirm={() => {
            handleConfirm();
          }}
        />
      </section>
    </>
  );
};

export default EditTextBox;
