import React, { useState } from "react";
import { Button, Card, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import FalconCardHeader from 'components/common/FalconCardHeader';
import { toast } from 'react-toastify';
import APIService from 'http/api_service';
import { getBilledYearly, getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { getLoggedInUser } from 'redux/slices/user_slice';

const WhiteLabelStripeConnectCard = () => {
  const loggedInUser = useSelector(getLoggedInUser);
  const billedYearly = useSelector(getBilledYearly);
  const activeWorkSpace = useSelector(getActiveWorkSpace);

  const [creatingConnectedAccount, setCreatingConnectedAccount] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [stripeConnectedAccount, setStripeConnectedAccount] = useState(undefined);
  const [retrievingConnectedAccount, setRetrievingConnectedAccount] = useState(false);
  const [businessType, setBusinessType] = useState('individual');
  const [openBusinessTypePrompt, setOpenBusinessTypePrompt] = useState(false);

  const handleClose = () => {
    setOpenBusinessTypePrompt(false);
  };

  const createConnectedAccount = async () => {
    setCreatingConnectedAccount(true);
    try {
      const response = await APIService.createConnectedAccount(businessType);
      const { data } = response;
      window.location.href = data;
    } catch (error) {
      setErrorMessage(error.message);
      toast.error(error.message, { theme: 'colored' });
    } finally {
      setCreatingConnectedAccount(false);
    }
  };

  const fetchConnectedAccount = async () => {
    setRetrievingConnectedAccount(true);
    try {
      const response = await APIService.fetchStripeConnectedAccount();
      const { data } = response;
      setStripeConnectedAccount(data);
    } catch (error) {
      setErrorMessage(error.message);
      toast.error(error.message, { theme: 'colored' });
    } finally {
      setRetrievingConnectedAccount(false);
    }
  };

  return (
    <Col md>
      <Card className='mb-3' id='stripe-connect'>
        <FalconCardHeader title="Stripe Connect settings" />
        <Card.Body className="bg-light">
          <Button
            onClick={() => {
              handleClose();
              createConnectedAccount();
            }}
            autoFocus
            disabled={creatingConnectedAccount}
          >
            {creatingConnectedAccount ? 'Connecting...' : 'Connect Stripe'}
          </Button>
          {errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default WhiteLabelStripeConnectCard;
