import React, { useEffect, useState } from 'react';
import { Button, Card, Image, Modal } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import FalconLightBox from 'components/common/FalconLightBox';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveWorkSpace, getBulkWorkSpacesToCreate, setActiveWorkspace } from 'redux/slices/workspaceslice';
import BulkContentEditBox from './BulkContentEditBox';
import BulkContentSelect from './BulkContentSelect';
import StringUtils from 'utils/string';
import { toast } from 'react-toastify';
import usePubSub from 'pubsub';
import APIService from 'http/api_service';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import Flex from 'components/common/Flex';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../constants';
import { UpgradeToPremiumAccountCard } from '../new-workspace';
import { getLoggedInUser } from 'redux/slices/user_slice';

const approvalOptions = [
    "None",
    "Optional",
    "Required",
    "Multilevel"
];

const columns = [
    {
        accessor: 'logo',
        Header: 'Logo',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { logo } = rowData.row.original;
            return (
                <FalconLightBox image={logo}>
                    <Image
                        fluid
                        rounded
                        src={logo}
                        width={150}
                        height={250}
                        style={{
                            objecFit: 'cover'
                        }}
                    />
                </FalconLightBox>
            );
        }
    },
    {
        accessor: 'name',
        Header: 'Name',
        Cell: (rowData) => {
            const { id } = rowData.row;
            return <BulkContentEditBox rowIndex={parseInt(id)} property={'name'} />
        }
    },
    {
        accessor: 'approval_workflow',
        Header: 'Approval Workflow',
        Cell: rowData => {
            const { id } = rowData.row;
            return <BulkContentSelect options={approvalOptions} rowIndex={parseInt(id)} property={'approval_workflow'} />
        }
    }
];

const BulkWorkspacesPreviewer = (props) => {

    const { pickNewFile } = props;
    const dispatch = useDispatch();
    const workSpaces = useSelector(getBulkWorkSpacesToCreate);
    const [creatingWorkspaces, setCreatingWorkspaces] = useState(false);
    const loggedInUser = useSelector(getLoggedInUser);
    const [openCreationSuccessDialog, setOpenCreationSuccessDialog] = useState(false);
    const [successMessage, setSuccessMessage] = useState("Workspaces Created");
    const [pageReloadRequired, setPageReloadRequired] = useState(false);
    const [openUpgradeCard, setOpenUpgradeCard] = useState(false);
    const navigate = useNavigate();
    const activeWorkSpace = useSelector(getActiveWorkSpace);
    const { addPubSubEventListener, removePubSubEventListener } = usePubSub();

    useEffect(() => {
        let workSpacesEventListener = (response) => {
            let { error, message } = response;
            setCreatingWorkspaces(false);
            if (error) {
                if (error.toLowerCase().includes('upgrade'.toLowerCase())) {
                    setOpenUpgradeCard(true);
                } else {
                    toast.error(error, { theme: 'colored' });
                }
                return;
            }
            setSuccessMessage(message);
            setOpenCreationSuccessDialog(true);
        }
        addPubSubEventListener(`${loggedInUser?._id}_bulk_workspaces_status`, workSpacesEventListener);
        return () => {
            removePubSubEventListener(`${loggedInUser?._id}_bulk_workspaces_status`, workSpacesEventListener);
            if (pageReloadRequired) {
                dispatch(setActiveWorkspace(null));
                navigate(RoutePaths.DEFAULT);
                window.location.reload();
            }
        }
    }, []);

    const createWorkspaces = async () => {
        setCreatingWorkspaces(true);
        const bulkContentStringified = JSON.stringify(workSpaces);
        const bulkContentBlob = new Blob([bulkContentStringified], {
            type: 'application/json;charset=utf-8'
        });
        let requestBody = new FormData();
        let bulkContentUploadFile = new File([bulkContentBlob], `${StringUtils.generateRandomString(10)}.json`);
        requestBody.append('file', bulkContentUploadFile);
        let response = await APIService.uploadNewFile(activeWorkSpace?._id, requestBody, 0, null, 1);
        let result = response.data;
        setTimeout(async () => {
            await APIService.uploadBulkWorkspaces({ bulk_workspaces_url: result }, (_response, error) => {
                if (error) {
                    setCreatingWorkspaces(false);
                    toast.error(error, { theme: 'colored' });
                    return;
                }
            });
        }, 3000);
    }

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={workSpaces}
            pagination
            perPage={10}
        >
            <Card>
                <FalconCardHeader
                    title="Workspaces to Create"
                    endEl={
                        <div
                            style={{
                                display: 'flex',
                                gap: 10
                            }}>
                            <Button
                                type="button"
                                icon="plus"
                                size="sm"
                                className="ms-2"
                                variant="falcon-default"
                                onClick={() => {
                                    pickNewFile();
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 1, alignItems: 'center'
                                    }}>
                                    <FontAwesomeIcon icon={'plus'} />
                                    <span className="d-none d-sm-inline-block ms-1">Pick a New CSV File</span>
                                </div>
                            </Button>
                            <Button
                                disabled={creatingWorkspaces || workSpaces.length < 4}
                                size="sm"
                                className="ms-2"
                                variant="falcon-primary"
                                onClick={() => {
                                    createWorkspaces();
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: 1, alignItems: 'center'
                                    }}>
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                    <span className="d-none d-sm-inline-block ms-1">{creatingWorkspaces ? "Creating Workspaces..." : "Create Workspaces"}</span>
                                </div>
                            </Button>
                        </div>
                    }
                ><span className='fs--1'>Min number of rows must be 4</span></FalconCardHeader>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowCount={workSpaces.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                    />
                </Card.Footer>
            </Card>
            <Modal
                backdrop="static"
                show={openCreationSuccessDialog}
                onHide={() => setOpenCreationSuccessDialog(false)}
                onExit={() => setOpenCreationSuccessDialog(false)}
                centered={false}>
                <Modal.Header>
                    <Modal.Title>
                        Workspaces Created
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Flex
                        direction={'column'}
                        className={'gap-3'}>
                        <span className='text-success'>{successMessage}</span>
                        <p>Kindly click on refresh for your workspaces to be properly setup</p>
                    </Flex>
                </Modal.Body>
                <Modal.Footer>
                    <Flex
                        className={'gap-1'}
                        alignItems={'center'}>
                        <Button
                            variant={'default'}
                            onClick={() => {
                                setPageReloadRequired(true);
                                setOpenCreationSuccessDialog(false);
                            }}>Not Now
                        </Button>
                        <Button
                            variant={'warning'}
                            onClick={() => {
                                setOpenCreationSuccessDialog(false);
                                dispatch(setActiveWorkspace(null));
                                navigate(RoutePaths.DEFAULT);
                                window.location.reload();
                            }}>Refresh
                        </Button>
                    </Flex>
                </Modal.Footer>
            </Modal>
            <Modal
                show={openUpgradeCard}
                onHide={() => setOpenUpgradeCard(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <UpgradeToPremiumAccountCard />
                </Modal.Body>
            </Modal>
        </AdvanceTableWrapper>
    );
};

export default BulkWorkspacesPreviewer;
