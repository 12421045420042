/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIService from 'http/api_service';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getPublishablePostMedia } from 'redux/slices/post_media_slice';
import { getPublishablePostTextBody } from 'redux/slices/post_text_body_slice';
import { canPublishIGImageAsStories, canPublishIGVideoAsReel, canPublishIGVideoAsStories, getAutoSaveDraftContent, getChangesFoundForDraft, getContentUploadingProgress, getCurrentDraft, getDraftContent, getDraftInQueue, getDraftLocalSaveToken, getDraftPersistentSaveToken, getDraftSaveState, getFacebookCarouselLinks, getFirstComment, getGMBCTAActionType, getGMBCTAActionURL, getGMBCouponCode, getGMBEventSchedule, getGMBEventTitle, getGMBPostType, getGMBRedeemURL, getGMBTermsAndConditions, getIgnoreDraftUpdate, getIsFacebookPostCarousel, getIsFacebookPostReel, getIsFacebookPostStory, getIsYouTubeVideoEmbeddable, getIsYouTubeVideoMadeForKids, getManuallyEditedPlatforms, getMediaBank, getPinterestAltText, getPinterestDestinationLink, getPinterestTitle, getPinterestVideoThumbnail, getPostlyCloudMediaUrls, getPostlyCloudTotalStorageLimit, getPostlyCloudUsedBytes, getPostlyCloudUserAlerts, getProcessingDraft, getRedditTitle, getSaveToDraftsButtonClicked, getSelectedPlatforms, getStartedCollectingDraftData, getStitchAllowTiktok, getTiktokAllowComments, getTiktokAllowDuet, getTiktokSelectedViewSetting, getTiktokVideoTitle, getTimeToPostContent, getTimeZoneToPostContent, getUploadingDraftMedia, getYouTubeVideoComment, getYouTubeVideoLicense, getYouTubeVideoPrivacy, getYouTubeVideoTags, getYouTubeVideoThumbnail, getYouTubeVideoTitle, setChangesFoundForDraft, setCurrentDraft, setDraftContent, setDraftLocalSaveToken, setDraftPersistentSaveToken, setDraftSaveState, setMediaBank, setPostlyCloudMediaUrls, setPostlyCloudTotalStorageLimit, setPostlyCloudUsedBytes, setPostlyCloudUserAlerts, setProcessingDraft, setSaveToDraftsButtonClicked, setStartedCollectingDraftData, setUploadingDraftMedia, shouldNotifyYouTubeSubscribers } from 'redux/slices/postslice';
import { getMediaArrangement } from 'redux/slices/publishable_media_arrangement_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { createDateWithTimeZone } from 'utils/time';
import { v4 as uuidv4 } from 'uuid';
import { PlatformIdentifier } from '../../../../constants';

const DraftProgressIndicator = (props) => {
    const dispatch = useDispatch();
    const saveToDraftsButtonClicked = useSelector(getSaveToDraftsButtonClicked);
    const body = useSelector(getPublishablePostTextBody);
    const draftSaveState = useSelector(getDraftSaveState);
    const workSpace = useSelector(getActiveWorkSpace);
    const workSpaceId = workSpace?._id;
    const currentDraft = useSelector(getCurrentDraft)
    const draftContent = useSelector(getDraftContent)
    const startedCollectingDraftData = useSelector(getStartedCollectingDraftData)
    const selectedPlatforms = useSelector(getSelectedPlatforms);
    const mediaItemsArrangement = useSelector(getMediaArrangement);
    const draftLocalSaveToken = useSelector(getDraftLocalSaveToken)
    const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
    const contentUploadProgress = useSelector(getContentUploadingProgress);
    const mediaBank = useSelector(getMediaBank);
    const uploadingDraftMedia = useSelector(getUploadingDraftMedia)
    const mediaObjects = useSelector(getPublishablePostMedia);
    const [draftableMediaObjects, setDraftableMediaObjects] = useState([]);
    const timeZone = useSelector(getTimeZoneToPostContent);
    const timeToPost = useSelector(getTimeToPostContent);
    const postlyCloudMediaUrls = useSelector(getPostlyCloudMediaUrls)
    const postlyCloudUsedBytes = useSelector(getPostlyCloudUsedBytes)
    const postlyCloudTotalStorageLimit = useSelector(getPostlyCloudTotalStorageLimit)
    const postlyCloudUserAlerts = useSelector(getPostlyCloudUserAlerts)
    const isFacebookPostCarousel = useSelector(getIsFacebookPostCarousel)
    const facebookCarouselLinks = useSelector(getFacebookCarouselLinks);
    const isFacebookPostReel = useSelector(getIsFacebookPostReel);
    const isFacebookPostStory = useSelector(getIsFacebookPostStory);
    const canVideoBePublishedAsReel = useSelector(canPublishIGVideoAsReel);
    const canVideoBePublishedAsStory = useSelector(canPublishIGVideoAsStories);
    const canImageBePublishedAsStory = useSelector(canPublishIGImageAsStories);
    const firstComment = useSelector(getFirstComment);
    const youtubeVideoTitle = useSelector(getYouTubeVideoTitle);
    const youtubeVideoComment = useSelector(getYouTubeVideoComment);
    const youtubeVideoPrivacy = useSelector(getYouTubeVideoPrivacy);
    const youTubeVideoThumbnail = useSelector(getYouTubeVideoThumbnail);
    const notifyYouTubeSubscribers = useSelector(shouldNotifyYouTubeSubscribers);
    const youTubeVideoLicense = useSelector(getYouTubeVideoLicense);
    const youTubeVideoTags = useSelector(getYouTubeVideoTags);
    const madeForKids = useSelector(getIsYouTubeVideoMadeForKids);
    const YouTubeVideoEmbeddable = useSelector(getIsYouTubeVideoEmbeddable);
    const redditTitle = useSelector(getRedditTitle);
    const pinterestTitle = useSelector(getPinterestTitle);
    const pinterestVideoThumbnail = useSelector(getPinterestVideoThumbnail);
    const pinterestDestinationLink = useSelector(getPinterestDestinationLink);
    const pinterestAltText = useSelector(getPinterestAltText);
    const tiktokVideoTitle = useSelector(getTiktokVideoTitle);
    const tiktokAllowComment = useSelector(getTiktokAllowComments);
    const tiktokAllowDuet = useSelector(getTiktokAllowDuet);
    const stitchAllowTiktok = useSelector(getStitchAllowTiktok);
    const selectedViewSetting = useSelector(getTiktokSelectedViewSetting);
    const gmbPostType = useSelector(getGMBPostType);
    const gmbCtaActionURL = useSelector(getGMBCTAActionURL);
    const gmbCtaActionType = useSelector(getGMBCTAActionType);
    const gmbCouponCode = useSelector(getGMBCouponCode);
    const gmbRedeemURL = useSelector(getGMBRedeemURL);
    const gmbTermsAndConditions = useSelector(getGMBTermsAndConditions);
    const gmbEventTitle = useSelector(getGMBEventTitle);
    const gmbEventSchedule = useSelector(getGMBEventSchedule);
    const [saveModeDisappeared, setSaveModeDisappeared] = useState(true);
    const changesFoundForDraft = useSelector(getChangesFoundForDraft)

    const getPostlyCloudUsage = () => {
        return new Promise((resolve, reject) => {
            APIService.getPostlyCloudUsage(workSpaceId, (response, error) => {
                if (error) {
                    // dispatch(setPostlyCloudUsedBytes('unavailable'));
                    reject(error);
                    return;
                }
                // let { data } = response;
                // dispatch(setPostlyCloudTotalStorageLimit(data?.total));
                // dispatch(setPostlyCloudUsedBytes(data?.used));
                resolve(response);
            });
        });
    };

    useEffect(() => {
        if (!workSpaceId) return;
        getPostlyCloudUsage();
    }, [draftLocalSaveToken, workSpaceId])


    const isAFreshDraft = () => {
        if (startedCollectingDraftData) return true;
        if (currentDraft) return true;

        const freshSaveToken = uuidv4();
        let newDraft = {
            ...draftContent,
            uniqueContentDraftId: uuidv4() + workSpace._id,
            workSpace: workSpace._id,
            data: {
                body: body,
                mediaItemsArrangement,
                selectedPlatforms,
                mediaObjects: draftableMediaObjects,
                manuallyEditedPlatforms: manuallyEditedPlatforms,
                mediaUrls: postlyCloudMediaUrls,
                // targets: selectedPlatforms,
                time_to_post: createDateWithTimeZone(
                    timeToPost,
                    timeZone,
                    true
                ).toString(),
                time_zone: timeZone,
                isFacebookPostCarousel,
                facebookCarouselLinks,
                isFacebookPostStory,
                isFacebookPostReel,
                canImageBePublishedAsStory,
                canVideoBePublishedAsStory,
                canVideoBePublishedAsReel,
                firstComment,
                youtubeVideoTitle,
                youtubeVideoComment,
                youtubeVideoPrivacy,
                youTubeVideoThumbnail,
                notifyYouTubeSubscribers,
                youTubeVideoLicense,
                youTubeVideoTags,
                madeForKids,
                YouTubeVideoEmbeddable,
                redditTitle,
                pinterestTitle,
                pinterestVideoThumbnail,
                pinterestDestinationLink,
                pinterestAltText,
                tiktokVideoTitle,
                tiktokAllowComment,
                tiktokAllowDuet,
                stitchAllowTiktok,
                selectedViewSetting,
                gmbPostType,
                gmbCtaActionType,
                gmbCtaActionURL,
                gmbCouponCode,
                gmbRedeemURL,
                gmbTermsAndConditions,
                gmbEventTitle,
                gmbEventSchedule
            },
            saveToken: freshSaveToken,
            drafted: true,
            posted: false,
            created_by: '',
        }

        dispatch(setDraftContent(newDraft))
        dispatch(setCurrentDraft(newDraft));
        dispatch(setStartedCollectingDraftData(true))
        dispatch(setDraftLocalSaveToken(freshSaveToken))
        return true;
    }

    useEffect(() => {
        if (currentDraft) {
            dispatch(setDraftContent(currentDraft))
            dispatch(setStartedCollectingDraftData(true))
        } else {
            dispatch(setDraftContent({}))
        }
    }, [currentDraft])


    const addStateValueToDraftContent = (key, value, mediaObjects, mediaUrls) => {
        let oldDraftContent = draftContent;
        let newDraftContent = {
            ...oldDraftContent,
            data: {
                ...oldDraftContent.data,
                [key]: value,
                selectedPlatforms: selectedPlatforms,
                body: body,
                manuallyEditedPlatforms: manuallyEditedPlatforms,
                mediaObjects: mediaObjects,
                mediaUrls: mediaUrls,
                // targets: selectedPlatforms, 
                time_to_post: createDateWithTimeZone(
                    timeToPost,
                    timeZone,
                    true
                ).toString(),
                time_zone: timeZone,
                isFacebookPostCarousel,
                facebookCarouselLinks,
                isFacebookPostStory,
                isFacebookPostReel,
                canImageBePublishedAsStory,
                canVideoBePublishedAsStory,
                canVideoBePublishedAsReel,
                firstComment,
                youtubeVideoTitle,
                youtubeVideoComment,
                youtubeVideoPrivacy,
                youTubeVideoThumbnail,
                notifyYouTubeSubscribers,
                youTubeVideoLicense,
                youTubeVideoTags,
                madeForKids,
                YouTubeVideoEmbeddable,
                redditTitle,
                pinterestTitle,
                pinterestVideoThumbnail,
                pinterestDestinationLink,
                pinterestAltText,
                tiktokVideoTitle,
                tiktokAllowComment,
                tiktokAllowDuet,
                stitchAllowTiktok,
                selectedViewSetting,
                gmbPostType,
                gmbCtaActionType,
                gmbCtaActionURL,
                gmbCouponCode,
                gmbRedeemURL,
                gmbTermsAndConditions,
                gmbEventTitle,
                gmbEventSchedule
            }
        }
        dispatch(setDraftContent(newDraftContent))
        handleUploadDraft(newDraftContent);
    }

    const convertToBytes = (value, unit) => {
        const units = {
            'bytes': 1,
            'kb': 1024,
            'mb': 1024 * 1024,
            'gb': 1024 * 1024 * 1024
        };
        const normalizedUnit = unit.toLowerCase();
        if (units[normalizedUnit] === undefined) {
            console.error('Invalid unit. Please use "bytes", "kb", "mb", or "gb".');
            return NaN;
        }
        return value * units[normalizedUnit];
    }

    const repackageMediaItemsArrangementForUpload = async () => {
        let newMediaItemsArrangement = {};
        let _mediaBank = structuredClone(mediaBank);
        let _mediaObjects = structuredClone(mediaObjects);
        let newMediaObjects = [];
        for (let index = 0; index < _mediaObjects?.length; index++) {
            const mediaItem = _mediaObjects[index];
            // if (mediaItem.is_video) {
            //     newMediaObjects.push(mediaItem);
            //     continue
            // }
            if (mediaItem?.external || mediaItem?.uploaded) {
                newMediaObjects.push(mediaItem);
                continue;
            }
            const mediaExist = _mediaBank.find(mediaBankItem => mediaBankItem?.old?.url === mediaItem.url || mediaBankItem?.old?.name === mediaItem.name);
            if (mediaExist) {
                const { file, ...others } = mediaItem;
                let _media = { ...others, url: mediaExist.new.url, external: true, uploaded: true, thumbnail: false, fileSize: mediaExist.new.fileSize }
                newMediaObjects.push(_media);
                continue;
            }
            const _file = mediaItem.file;
            if (!_file) {
                newMediaObjects.push(mediaItem);
                continue;
            }
            let totalStorageLimitInBytes = convertToBytes(postlyCloudTotalStorageLimit, 'kb');
            let uploadRequestBody = new FormData();
            let fileSize = _file.size;
            let potentialUsedBytes = postlyCloudUsedBytes + fileSize;
            if (postlyCloudUsedBytes === 'unavailable') {
                if (!postlyCloudUserAlerts.includes('Unable to determine your Postly Cloud storage usage. Media could not be saved to draft.')) {
                    let newAlerts = [...postlyCloudUserAlerts, 'Unable to determine your Postly Cloud storage usage. Media could not be saved to draft.'];
                    dispatch(setPostlyCloudUserAlerts(newAlerts));
                    toast.info(`Unable to determine your Postly Cloud storage usage. Media could not be saved to draft.`, { theme: 'colored' });
                }
                continue
            }
            if (potentialUsedBytes > totalStorageLimitInBytes) {
                if (!postlyCloudUserAlerts.includes('You have exceeded your Postly Cloud storage limit. Please upgrade your plan to continue using Postly Cloud.')) {
                    let newAlerts = [...postlyCloudUserAlerts, 'You have exceeded your Postly Cloud storage limit. Please upgrade your plan to continue using Postly Cloud.'];
                    dispatch(setPostlyCloudUserAlerts(newAlerts));
                    toast.info(`You have exceeded your Postly Cloud storage limit. Please upgrade your plan to continue using Postly Cloud.`, { theme: 'colored' });
                }
                continue;
            }
            uploadRequestBody.append("file", _file);
            dispatch(setUploadingDraftMedia(true));
            let response = await APIService.uploadNewFile(workSpace._id, uploadRequestBody, fileSize, "Drafts", 1);
            if (!response.data) {
                dispatch(setUploadingDraftMedia(false));
                newMediaObjects.push(mediaItem);
                continue;
            }
            await getPostlyCloudUsage();
            dispatch(setUploadingDraftMedia(false));
            let url = response.data;
            const { file, ...others } = mediaItem;
            let _media = { ...others, url, external: true, uploaded: true, thumbnail: false, fileSize: fileSize }
            _mediaBank.push({ old: mediaItem, new: _media });
            newMediaObjects.push(_media);
        }
        setDraftableMediaObjects(newMediaObjects);
        let newMediaUrls = [];
        for (let mediaItem of newMediaObjects) {
            newMediaUrls.push(mediaItem.url);
        }
        dispatch(setPostlyCloudMediaUrls(newMediaUrls))
        for (let key in mediaItemsArrangement) {
            if (key === PlatformIdentifier.TWITTER) {
                let newTwitterThreads = []
                for (let thread of mediaItemsArrangement[key]) {
                    let { media, ...others } = thread;
                    let newMedia = [];
                    for (let index = 0; index < media?.length; index++) {
                        const mediaItem = media[index];
                        // if (mediaItem.is_video) {
                        //     newMedia.push(mediaItem);
                        //     continue
                        // }
                        if (mediaItem?.external || mediaItem?.uploaded) {
                            newMedia.push(mediaItem);
                            continue;
                        }
                        const mediaExist = _mediaBank.find(mediaBankItem => mediaBankItem?.old?.url === mediaItem.url || mediaBankItem?.old?.name === mediaItem.name);
                        if (mediaExist) {
                            const { file, ...others } = mediaItem;
                            let _media = { ...others, url: mediaExist.new.url, external: true, uploaded: true, thumbnail: false, fileSize: mediaExist.new.fileSize }
                            newMedia.push(_media);
                            continue;
                        }
                        const _file = mediaItem.file;
                        if (!_file) {
                            newMedia.push(mediaItem);
                            continue;
                        }
                        let uploadRequestBody = new FormData();
                        let fileSize = _file.size;
                        uploadRequestBody.append("file", _file);
                        dispatch(setUploadingDraftMedia(true));
                        let response = await APIService.uploadNewFile(workSpace._id, uploadRequestBody, fileSize, "Drafts", 1);
                        if (!response.data) {
                            dispatch(setUploadingDraftMedia(false));
                            newMedia.push(mediaItem);
                            continue;
                        }
                        dispatch(setUploadingDraftMedia(false));
                        let url = response.data;
                        const { file, ...others } = mediaItem;
                        let _media = { ...others, url, external: true, uploaded: true, thumbnail: false, fileSize: fileSize }
                        _mediaBank.push({ old: mediaItem, new: _media });
                        newMedia.push(_media);
                    }
                    newTwitterThreads.push({ ...others, media: newMedia });
                }
                newMediaItemsArrangement[key] = newTwitterThreads;
                continue;
            }
            let { media, ...others } = mediaItemsArrangement[key];
            let newMedia = [];
            for (let index = 0; index < media?.length; index++) {
                const mediaItem = media[index];
                // if (mediaItem.is_video) {
                //     newMedia.push(mediaItem);
                //     continue
                // }
                if (mediaItem?.external || mediaItem?.uploaded) {
                    newMedia.push(mediaItem);
                    continue;
                }
                const mediaExist = _mediaBank.find(mediaBankItem => mediaBankItem?.old?.url === mediaItem.url || mediaBankItem?.old?.name === mediaItem.name);
                if (mediaExist) {
                    const { file, ...others } = mediaItem;
                    let _media = { ...others, url: mediaExist.new.url, external: true, uploaded: true, thumbnail: false, fileSize: mediaExist.new.fileSize }
                    newMedia.push(_media);
                    continue;
                }
                const _file = mediaItem.file;
                if (!_file) {
                    newMedia.push(mediaItem);
                    continue;
                }
                let uploadRequestBody = new FormData();
                let fileSize = _file.size;
                uploadRequestBody.append("file", _file);
                dispatch(setUploadingDraftMedia(true));
                let response = await APIService.uploadNewFile(workSpace._id, uploadRequestBody, fileSize, "Drafts", 1);
                if (!response.data) {
                    dispatch(setUploadingDraftMedia(false));
                    newMedia.push(mediaItem);
                    continue;
                }
                dispatch(setUploadingDraftMedia(false));
                let url = response.data;
                const { file, ...others } = mediaItem;
                let _media = { ...others, url, external: true, uploaded: true, thumbnail: false, fileSize: fileSize }
                _mediaBank.push({ old: mediaItem, new: _media });
                newMedia.push(_media);
            }

            newMediaItemsArrangement[key] = { ...others, media: newMedia };
        }
        dispatch(setMediaBank(_mediaBank));
        return {
            mediaItemsArrangement: newMediaItemsArrangement,
            mediaObjects: newMediaObjects,
            mediaUrls: newMediaUrls,
        }
    }

    const handleLocalSaveTokenChange = async () => {
        const { mediaItemsArrangement, mediaObjects, mediaUrls } = await repackageMediaItemsArrangementForUpload();
        addStateValueToDraftContent('mediaItemsArrangement', mediaItemsArrangement, mediaObjects, mediaUrls);
    }

    useEffect(() => {
        if (draftLocalSaveToken) {
            handleLocalSaveTokenChange();
        }
    }, [draftLocalSaveToken])

    const handleUploadDraft = (draftContent) => {
        setSaveModeDisappeared(false);
        dispatch(setDraftSaveState('saving'));
        APIService.updateDraftContent(workSpace._id, draftContent, (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                dispatch(setDraftSaveState('saved'))
                setTimeout(() => {
                    setSaveModeDisappeared(true)
                }, 7000);
                dispatch(setProcessingDraft(false))
                dispatch(setSaveToDraftsButtonClicked(false))
                dispatch(setChangesFoundForDraft(false));
                return;
            }
            let returnedData = response.data;
            dispatch(setDraftPersistentSaveToken(returnedData.saveToken))
            dispatch(setDraftSaveState('saved'))
            setTimeout(() => {
                setSaveModeDisappeared(true)
            }, 7000);
            setProcessingDraft(false);
            dispatch(setSaveToDraftsButtonClicked(false))
            dispatch(setChangesFoundForDraft(false));
        })
    }

    useEffect(() => {
    }, [draftContent, draftSaveState])

    const hasDataChanged = draftSaveState === 'waitingToSave';

    useEffect(() => {
        if (hasDataChanged) {
            console.log(draftSaveState, 'draftSaveState', hasDataChanged, 'hasDataChanged')
            handleUploadDraft();
        }
    }, [hasDataChanged])


    useEffect(() => {
        if (!saveToDraftsButtonClicked) {
            return
        }

        if (!isAFreshDraft()) {
            return
        }
        let freshSaveToken = uuidv4();
        dispatch(setDraftLocalSaveToken(freshSaveToken))
    }, [
        saveToDraftsButtonClicked
    ])

    useEffect(() => {
        if (!saveToDraftsButtonClicked && !changesFoundForDraft) {
            dispatch(setChangesFoundForDraft(true));
        }
    }, [
        mediaItemsArrangement, selectedPlatforms, timeToPost, timeZone,
        facebookCarouselLinks, isFacebookPostStory, isFacebookPostReel, isFacebookPostCarousel, canImageBePublishedAsStory, canVideoBePublishedAsReel, canVideoBePublishedAsStory, firstComment, youtubeVideoTitle, youtubeVideoComment, youtubeVideoPrivacy, youTubeVideoThumbnail, notifyYouTubeSubscribers, youTubeVideoLicense, youTubeVideoTags, madeForKids, YouTubeVideoEmbeddable, redditTitle, pinterestTitle, pinterestVideoThumbnail, pinterestDestinationLink, pinterestAltText, tiktokVideoTitle, tiktokAllowComment, tiktokAllowDuet, stitchAllowTiktok, selectedViewSetting, gmbPostType,
        gmbCtaActionType, gmbCtaActionURL, gmbCouponCode, gmbRedeemURL, gmbTermsAndConditions, gmbEventTitle, gmbEventSchedule
    ])



    const sharedStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 3,
    };

    const renderDraftStatus = () => {
        if (uploadingDraftMedia && (contentUploadProgress?.completed || contentUploadProgress?.completed == 0)) {
            return <div style={sharedStyle}>
                <Spinner animation="border" size="sm" variant="success" />
                <span style={{ fontSize: 11, color: '#B0BEC5' }}>
                    Uploading media... {`${contentUploadProgress?.completed}%`}
                </span>
            </div>
        }
        switch (draftSaveState) {
            case 'waitingToSave':
                return (
                    <div style={sharedStyle}>
                        <span style={{ fontSize: 11, color: '#B0BEC5' }}>
                            Changes found for draft
                        </span>
                    </div>
                );
            case 'saving':
                return (
                    <div style={sharedStyle}>
                        <span style={{ fontSize: 11, color: '#B0BEC5' }}>
                            Saving changes...
                        </span>
                    </div>
                );
            case 'saved':
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 2,
                            textDecoration: 'none'
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 15,
                                height: 15,
                                borderRadius: 100,
                                backgroundColor: draftSaveState === 'saved' ? "#81C784" : '#B0BEC5'
                            }}>
                            <FontAwesomeIcon
                                icon={faCheck}
                                style={{
                                    width: 8,
                                    height: 8,
                                    color: 'white'
                                }}
                            />
                        </div>
                        <span
                            variant="contained"
                            style={{
                                fontSize: 12,
                                color: '#81C784',
                                paddingLeft: 2
                            }}
                        >
                            {
                                'Changes saved'
                            }
                        </span>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div
                id='draft-heads-up'
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: 3,
                }}>
                {!saveModeDisappeared && renderDraftStatus()}
            </div>
        </>
    )
}
export default DraftProgressIndicator;