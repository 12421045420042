import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import FeedCardHeader from "./FeedCardHeader";
import { Button, Card, FloatingLabel, Form, Modal } from "react-bootstrap";
import FeedCardContent from "./FeedCardContent";
import FeedCardFooter from "./FeedCardFooter";
import getUrls from "get-urls";
import { isPhoto, isVideo } from "utils/file_utils";
import {
  PlatformIdentifier,
  WorkSpaceContentApprovalWorkFlow,
} from "../../../../constants";
import { useSelector } from "react-redux";
import { getActiveBatchDelete, getPostSpecificDataVisualRequest } from "redux/slices/postslice";
import ReactLoading from "react-loading";
import {
  faFacebookF,
  faGoogle,
faInstagram,
  faLinkedin,
  faPinterest,
  faReddit,
  faTelegram,
  faTiktok,
  faTwitter,
  faWordpress,
  faYoutube,
  faBlogger,
} from "@fortawesome/free-brands-svg-icons";
import { faRss } from "@fortawesome/free-solid-svg-icons";
import wordpressBlueIcon from "../../../../assets/images/wordpress_blue.svg";
import InstagramSvgIcon from "../../../../assets/images/instagram.svg";
import GoogleMyBusinessSvgIcon from "../../../../assets/images/google-my-business.svg";
import RedditSvgIcon from "../../../../assets/images/reddit.svg";
import TikTokSvgIcon from "../../../../assets/images/tiktok.svg";
import RSSFeedSvgIcon from "../../../../assets/images/rss.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import { getActiveWorkSpace, getPlatformVideoUploadLimit, setPlatformVideoUploadLimit } from "redux/slices/workspaceslice";
import { getAccountUserId } from "redux/slices/analytics_slice";
import { getLoggedInUser } from "redux/slices/user_slice";
import PostPreviewerOnSpecificPlatform from "./previewer/PostPreviewerOnSpecificPlatform";
import PostPreviewCanvas from "./previewer/PostPreviewCanvas";
import { dispatchHandler } from "utils/post_utils";

const getFirstLongestText = (refText, platformSpecificData) => {
  platformSpecificData = Object.entries(platformSpecificData);
  for (let data of platformSpecificData) {
    let dataContent = data[1];
    let identifier = data[0];
    if (identifier === PlatformIdentifier.TWITTER) {
      for (let thread of dataContent) {
        if ((thread.text ?? "").length > refText.length) {
          refText = thread.text;
          break;
        }
      }
    } else {
      if ((dataContent.text ?? "").length > refText.length) {
        refText = dataContent.text;
        break;
      }
    }
  }
  return refText;
};

const getFirstAvailablePlatformSpecificMedia = (
  existingMedia,
  platformSpecificData
) => {
  if (existingMedia) {
    return existingMedia;
  }
  let media;
  platformSpecificData = Object.entries(platformSpecificData);
  for (let data of platformSpecificData) {
    let dataContent = data[1];
    let identifier = data[0];
    if (identifier === PlatformIdentifier.TWITTER) {
      for (let thread of dataContent) {
        if (thread.media) {
          media = [];
          for (let mediaItem of thread.media) {
            media.push(mediaItem.url);
          }
          break;
        }
      }
    } else {
      if (dataContent.media) {
        media = [...dataContent.media];
        break;
      }
    }
  }
  return media;
};

export const extractUrlsFromPost = (post) => {
  post = JSON.parse(JSON.stringify(post));
  let attachedUrls = getUrls(post?.data?.body ?? "");
  if (attachedUrls) {
    attachedUrls = Array.from(attachedUrls);
    if (attachedUrls.length > 0) {
      let validUrls = attachedUrls.filter(
        (url) => isPhoto(url) || isVideo(url)
      );
      let existingMediaUrls = post.data.mediaUrls ?? [];
      existingMediaUrls = [...existingMediaUrls, ...validUrls];
      post.data.mediaUrls = existingMediaUrls;
    }
  }
  let media = post?.data?.mediaUrls;
  let postTextBody = post?.data?.body ?? "";
  let platformSpecificData = post?.data.platformSpecificData ?? {};
  postTextBody = getFirstLongestText(postTextBody, platformSpecificData);
  post.data.body = postTextBody;
  media = getFirstAvailablePlatformSpecificMedia(media, platformSpecificData);
  post.data.mediaUrls = media;
  return post;
};

const FeedCard = ({
  post,
  headerProps = {},
  contentProps = {},
  footerProps = {},
  rootCardClassNames = "",
  postType,
  fromPostDetailsDialog,
  fetchPostsFromRemoteSource,
}) => {
  const workSpace = useSelector(getActiveWorkSpace);
  const loggedInUser = useSelector(getLoggedInUser);
  const [approvingPost, setApprovingPost] = useState(false);
  const [approved, setApproved] = useState(false);
  const [disapproved, setDisapproved] = useState(false);
  const [disapprovingPost, setDisapprovingPost] = useState(false);
  const [isUserInDisapprovals, setIsUserInDisapprovals] = useState(false);
  const [isUserInApprovals, setIsUserInApprovals] = useState(false);
  const [showPostActionModal, setShowPostActionModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [comment, setComment] = useState("");
  const postSpecificDataVisualRequest = useSelector(getPostSpecificDataVisualRequest)
  const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
  
  let workSpaceSettings = workSpace.settings;


  const _getPlatformVideoUploadLimit = async () => {
    if (workSpace._id) {
      APIService.platformVideoUploadLimit(workSpace._id, async (response, error) => {
        if (error) {
          return;
        }
        const data = response?.data;
        dispatchHandler(setPlatformVideoUploadLimit(data))
      });
    }
  }

  useEffect(() => {
    if(platformVideoUploadLimit) return
    _getPlatformVideoUploadLimit()
  }, [workSpace?._id])

  const [refinedPost, setRefinedPost] = useState(
    extractUrlsFromPost({ ...post })
  );
  const activeBatchDelete = useSelector(getActiveBatchDelete);
  const batchId = refinedPost?.data?.batch_id;

  useEffect(() => {
    setRefinedPost(extractUrlsFromPost({ ...post }));
  }, [post]);

  const checkUserInDisapprovals = useCallback(() => {
    if (post?.data?.disapprovals) {
      const isUserInDisapprovalsArray = Object.values(
        post.data.disapprovals
      ).some((item) => item.member_id === loggedInUser?._id);
      if (isUserInDisapprovalsArray) {
        setIsUserInDisapprovals(true);
      }
    }
  }, [post, loggedInUser]);

  const checkUserInApprovals = useCallback(() => {
    if (post?.data?.approvals) {
      const isUserInApprovalsArray = Object.values(post.data.approvals).some(
        (item) => item.member_id === loggedInUser?._id
      );
      if (isUserInApprovalsArray) {
        setIsUserInApprovals(true);
      }
    }
  }, [post, loggedInUser]);

  useEffect(() => {
    checkUserInDisapprovals();
  }, [checkUserInDisapprovals, loggedInUser]);

  useEffect(() => {
    checkUserInApprovals();
  }, [checkUserInApprovals, loggedInUser]);
  //end
  let platforms = [
    {
      name: "Facebook",
      color: "#4267B2",
      icon: faFacebookF,
      identifier: PlatformIdentifier.FACEBOOK,
      selected: false,
      textLimit: 62206,
      metaFamily: true,
      disabled: false,
      pro: false,
    },
    {
      name: "Twitter",
      color: "#1DA1F2",
      icon: faTwitter,
      identifier: PlatformIdentifier.TWITTER,
      selected: false,
      pro: true,
    },
    {
      name: "Instagram",
      color: "#800080",
      // color: "#EB4869",
      icon: faInstagram,
      identifier: PlatformIdentifier.INSTAGRAM,
      svg: InstagramSvgIcon,
      selected: false,
      textLimit: 2200,
      metaFamily: true,
      disabled: false,
      pro: false,
    },
    {
      name: "LinkedIn",
      color: "#0077b5",
      icon: faLinkedin,
      identifier: PlatformIdentifier.LINKED_IN,
      selected: false,
      textLimit: 3000,
      pro: true,
    },
    {
      name: "Google My Business",
      color: "#4285F4",
      // icon: faGoogle,
      identifier: PlatformIdentifier.GOOGLE_MY_BUSINESS,
      svg: GoogleMyBusinessSvgIcon,
      selected: false,
      textLimit: 750,
      pro: true,
    },
    {
      name: "Reddit",
      color: "#FF5700",
      icon: faReddit,
      svg: RedditSvgIcon,
      identifier: PlatformIdentifier.REDDIT,
      selected: false,
      textLimit: 10000,
      pro: false,
    },
    {
      name: "Telegram",
      color: "#0088cc",
      icon: faTelegram,
      identifier: PlatformIdentifier.TELEGRAM,
      selected: false,
      textLimit: 4096,
      pro: false,
    },
    {
      name: "Pinterest",
      color: "#E60023",
      icon: faPinterest,
      identifier: PlatformIdentifier.PINTEREST,
      selected: false,
      pro: false,
    },
    {
      name: "YouTube",
      color: "#FF0000",
      icon: faYoutube,
      identifier: PlatformIdentifier.YOUTUBE,
      selected: false,
      textLimit: 5000,
      pro: true,
    },
    {
      name: "TikTok",
      color: "#000000",
      icon: faTiktok,
      svg: TikTokSvgIcon,
      identifier: PlatformIdentifier.TIKTOK,
      selected: false,
      textLimit: 300,
      pro: true,
    },
    {
      name: "Wordpress",
      color: "#000000",
      icon: faWordpress,
      svg: wordpressBlueIcon,
      identifier: PlatformIdentifier.WORDPRESS,
      selected: false,
      textLimit: 300,
      pro: false,
      disabled: false, //! WORDPRESS regains support.
    },
    {
      name: "RSS Feed",
      color: "#FFA500",
      icon: faRss,
      svg: RSSFeedSvgIcon,
      identifier: PlatformIdentifier.RSS_FEED,
      selected: false,
      textLimit: 300,
      pro: false,
      disabled: false, //!  taking RSS feeds offline
    },
    {
      name: "Blogger",
      color: "#FF5722",
      icon: faBlogger,
      identifier: PlatformIdentifier.BLOGGER,
      selected: false,
      textLimit: 300,
      pro: false,
    },
  ];

  if (fromPostDetailsDialog) {
    return (
      <>
        <Card
          {...refinedPost}
          className={`mb-3 ${rootCardClassNames}`}
          style={{
            position: "relative",
          }}
        >
          <FeedCardHeader
            {...refinedPost}
            {...headerProps}
            postType={postType}
            post={post}
            fromPostDetailsDialog={fromPostDetailsDialog}
          />
          <FeedCardContent
            {...refinedPost}
            {...contentProps}
            fromPostDetailsDialog={fromPostDetailsDialog}
          />

          {postType !== "draft" && (
            <FeedCardFooter
              id={refinedPost._id}
              {...refinedPost}
              {...footerProps}
              fromPostDetailsDialog={fromPostDetailsDialog}
            />
          )}
          {activeBatchDelete[batchId] && (
            <div
              style={{
                height: "100%",
                borderRadius: 5,
                background: "rgba(0, 0, 0, 0.5)",
                position: "absolute",
                width: "100%",
                zIndex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <ReactLoading width={30} height={30} type="spinningBubbles" />
              <span style={{ color: "white", fontWeight: "bold" }}>
                Deleting...
              </span>
            </div>
          )}
        </Card>
      </>
    );
  }

  const approvePost = () => {
    setApprovingPost(true);
    APIService.approvePost(
      workSpace["_id"],
      post._id,
      {
        reason: comment,
      },
      (response, error) => {
        setApprovingPost(false);
        if (error) {
          toast.error(error, { theme: "colored" });
          return;
        }
        let { data, message } = response;
        toast.success(message, { theme: "colored" });
        // setPost(data);
        fetchPostsFromRemoteSource();
        setApproved(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    );
  };

  const disapprovePost = () => {
    setDisapprovingPost(true);
    APIService.disApprovePost(
      workSpace["_id"],
      post._id,
      {
        reason: comment,
      },
      (response, error) => {
        setDisapprovingPost(false);
        if (error) {
          toast.error(error, { theme: "colored" });
          return;
        }
        let { data, message } = response;
        toast.success(message, { theme: "colored" });
        // setPost(data);
        fetchPostsFromRemoteSource();
        setDisapproved(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    );
  };

  const approvalNumber =
    workSpaceSettings[
      WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION
    ] === 3
      ? workSpaceSettings?.third_approval_option_value
      : workSpaceSettings[
      WorkSpaceContentApprovalWorkFlow.ACTIVE_WORKSPACE_APPROVAL_OPTION
      ] === 2 && 1;
  const numberApproved = Object.entries(post?.data?.approvals || {})?.length;

  return (
    <>
      <Card
        {...refinedPost}
        className={`mb-3 ${rootCardClassNames}`}
        style={{
          position: "relative",
          width: "24%",
          height: 400,
          paddingBottom: 50,
        }}
      >
        <FeedCardHeader
          {...refinedPost}
          {...headerProps}
          postType={postType}
          post={post}
        />
        <FeedCardContent {...refinedPost} {...contentProps} />
        {postType === "pending_approval" && (
          <>
            <Flex>
              <Card.Text
                style={{ fontSize: "12px", paddingLeft: "10px" }}
              >{`Count of approvals: ${numberApproved}/${approvalNumber}`}</Card.Text>
            </Flex>
            {(!isUserInApprovals && !isUserInDisapprovals) && !post?.data?.disapprovals && <Flex justifyContent={"center"} gap={1} className={"mt-3 mb-3"}>
              <Button
                style={{
                  fontSize: "12px",
                  width: "fit-content",
                  height: "fit-content",
                  // color: "#444f5e"
                }}
                onClick={() => {
                  // approvePost();
                  setShowPostActionModal(true);
                  setSelectedAction("approve");
                }}
                isWorking={approvingPost}
                disabled={approvingPost}
                variant={"light"}
              >
                <Card.Text>
                  {approvingPost ? "Submitting..." : "Approve"}
                </Card.Text>
              </Button>
              <Button
                variant={"light"}
                style={{
                  fontSize: "12px",
                  width: "fit-content",
                  height: "fit-content",
                }}
                className="text-danger"
                onClick={() => {
                  // disapprovePost();
                  setShowPostActionModal(true);
                  setSelectedAction("reject");
                }}
              >
                {disapprovingPost ? "Submitting..." : "Reject"}
              </Button>
            </Flex>}
          </>
        )}
        {/* <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "20px", padding: "10px"}}>
          <span>Platforms:</span>
          <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px"}}>
          {post?.data?.selectedPlatforms?.map((selectedPlatform, index) => {
            const chosenSocial = platforms?.find(
              (p) => p.identifier === selectedPlatform.identifier
            );
            return (
              <>
                {chosenSocial.svg ? (
                  <img
                    src={chosenSocial.svg}
                    alt={chosenSocial.name}
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={chosenSocial.icon}
                    style={{
                      color: chosenSocial.color,
                      fontSize: 20,
                    }}
                  />
                )}
              </>
            );
          })}
          </div>
        </div> */}
        {/* {postType !== "draft" && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: 50,
              display: "flex",
              fontWeight: "bold",
              fontSize: 18,
              cursor: "pointer",
              // backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <FeedCardFooter
              id={refinedPost._id}
              {...refinedPost}
              {...footerProps}
            />
          </div>
        )} */}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: 50,
            display: "flex",
            fontWeight: "bold",
            fontSize: 18,
            cursor: "pointer",
            // backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <FeedCardFooter
            id={refinedPost._id}
            {...refinedPost}
            {...footerProps}
          />
        </div>
        {activeBatchDelete[batchId] && (
          <div
            style={{
              height: "100%",
              borderRadius: 5,
              background: "rgba(0, 0, 0, 0.5)",
              position: "absolute",
              width: "100%",
              zIndex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <ReactLoading width={30} height={30} type="spinningBubbles" />
            <span style={{ color: "white", fontWeight: "bold" }}>
              Deleting...
            </span>
          </div>
        )}
      </Card>
      <Modal
        show={showPostActionModal}
        centered
        onHide={() => {
          setShowPostActionModal(false);
          setComment("");
        }}
        onExit={() => {
          setShowPostActionModal(false);
          setComment("");
        }}
      >
        <Modal.Header>
          <Modal.Title>
            {selectedAction === "approve" ? "Approve Post" : "Reject Post"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel
            controlId="floatingTextarea2"
            label="Comments (optional)"
          >
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Flex
            style={{ width: "100%" }}
            className={"gap-2"}
            alignItems={"center"}
          >
            <Button
              onClick={() => {
                setShowPostActionModal(false);
                setComment("");
              }}
              style={{
                fontSize: "12px",
                width: "fit-content",
                height: "fit-content",
                // color: "#444f5e"
              }}
              variant="danger"
            >
              Cancel
            </Button>
            <Button
              style={{
                fontSize: "12px",
                width: "fit-content",
                height: "fit-content",
                // color: "#444f5e"
              }}
              onClick={() => {
                if (selectedAction === "approve") {
                  approvePost();
                } else {
                  disapprovePost();
                }
              }}
            >
              {selectedAction === "approve"
                ? approvingPost
                  ? "Submitting..."
                  : "Approve"
                : selectedAction === "reject" && disapprovingPost
                  ? "Submitting..."
                  : "Reject"}
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>
      {/* <Modal
        show={postSpecificDataVisualRequest ? true : false}
        centered
        onHide={() => {
        }}
        onExit={() => {
        }}
      >
        <Modal.Header>
          <Modal.Title>
            Post preview for Facebook
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PostPreviewerOnSpecificPlatform
            data={postSpecificDataVisualRequest}
          />
        </Modal.Body>
        <Modal.Footer>
          <Flex
            style={{ width: "100%" }}
            className={"gap-2"}
            alignItems={"center"}
          >
            <Button
              onClick={() => {

              }}
              style={{
                fontSize: "12px",
                width: "fit-content",
                height: "fit-content",
              }}
              variant="danger"
            >
              Close
            </Button>
            <Button
              style={{
                fontSize: "12px",
                width: "fit-content",
                height: "fit-content",
                // color: "#444f5e"
              }}
              onClick={() => {

              }}
            >
              Open for editing
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal> */}

      <PostPreviewCanvas />
    </>
  );
};

FeedCard.Header = FeedCardHeader;
FeedCard.propTypes = {
  feed: PropTypes.object,
};
export default FeedCard;
