/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {
  faEarth,
  faImage,
  faPencil,
  faPlus,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";

import MultimediaSelectionDropdown from "components/common/MultimediaSelectionDropdown";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import recursiveAspectFinder from "utils/recursiveAspectFinder";
import { darkTheme, lightTheme } from "utils/theme";
import {
  extractLinksFromTweet,
  getPreviewFromUrl,
  url_replacer,
} from "utils/url";
import { PlatformData, PlatformIdentifier } from "../../../../../constants";
import {
  canOpenPixelFilePickerDialog,
  getGoogleDriveManager,
  getManuallyEditedPlatforms,
  getMediaAddedViaSpecificPlaformUrlMethod,
  getNotifyPreviewsOfLiveMediaChanges,
  getSelectingPhotoFromHome,
  getSpecificMediaPlatformTied,
  setGoogleDriveManager,
  setManuallyEditedPlatforms,
  setOpenPixelFilePickerDialog,
  setSpecificMediaPlatformTied
} from "../../../../../redux/slices/postslice";
import { platforms } from "../../../platforms";
import EditTextBox from "./editTextBox.js";
// import ImageEditButton from "./image_edit_button";
import LinkPreviewer from "./link_previewer";
// import PlayMediaFb from "./playMediaFb.js";
import { scanImages } from "./scanImages";
// import { EditPopUp, EditPopUpBtnDiv } from "./styles/facebook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "components/common/ConfirmModal";
import CircularButton from "components/common/circularbutton";
import GDriveDashboard from "components/common/g-drive/dashboard";
import AppContext from "context/Context";
import { Modal } from "react-bootstrap";
import { RiDragDropLine } from "react-icons/ri";
import { setSelectedPlatformForPreview } from "redux/slices/platformSlice";
import { getMediaArrangement, setMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import DragToPositionImage from "./DragToPositionImage";
import ExtrernalImageSearchDialogue from "./ExtrernalImageSearchDialogue";
import GDriveConnectionRequestListener from "./GDriveConnectionRequestListener";
import LinkedinMediaDisplay from "./linkedin_media_display";
import {
  DotsBox,
  EachBtn,
  EditContainer,
  FirstLayer,
  GridImage,
  GridImageOne,
  ImageHolder,
  Info,
  ParentSection,
  PreviewContentArea,
  SecondLayer,

  ThirdLayer,
  Title,
  Wrapper,
} from "./styles/linkedin";
import Flex from "components/common/Flex";
import { displayPlatformIcon } from "utils/platform_utils";
import TextareaAutoSize from "react-textarea-autosize";
import { getPlatformVideoUploadLimit } from "redux/slices/workspaceslice";
import FigureUtils from "utils/figures";

const LinkedIn = (props) => {
  const { viewOnly } = props;
  const dispatch = useDispatch();
  const googleDriveManager = useSelector(getGoogleDriveManager)
  const googleDriveRef = useRef(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [repostionImages, setRepositionImages] = useState(false)
  const [toggleEditTextInput, setToggleEditTextInput] = useState(false);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const [storedIndex, setStoredIndex] = useState(0);
  const [expandMedias, setExpandMedias] = useState(false);
  const [currentMediaType, setCurrentMediaType] = useState(null);
  const [openMediaSelction, setOpenMediaSelection] = useState(false);
  const mediaObjectsTotalLength = 10;
  const selectingPhotoFromHome = useSelector(getSelectingPhotoFromHome);
  const [useableMedia, setUseableMedia] = useState([]);
  const myRef = useRef(null);
  const focusImageRef = useRef(null);
  const textEditRef = useRef(null);
  const [timer, setTimer] = useState();
  const [limitedPostTextBody, setLimitedPostTextBody] = useState("");
  const [openEditArea, setOpenEditArea] = useState(false);
  const [imageToEditUrl, setImageToEditUrl] = useState("");
  const [comparebleUrl, setComparebleUrl] = useState("");
  const [fileNameBackup, setFileNameBackup] = useState("");
  const openPixelDialoguePicker = useSelector(canOpenPixelFilePickerDialog);
  const mediaAddedViaSpecificPlaformUrlMethod = useSelector(
    getMediaAddedViaSpecificPlaformUrlMethod
  );
  const specificMediaPlatformTied = useSelector(getSpecificMediaPlatformTied);
  const [canOPenPopOver, setCanOpenPopOver] = useState(true);
  const [openVideoEditArea, setOpenVideoEditArea] = useState(false);
  const [formattedPostBodyForLinks, setFormattedPostBodyForLinks] =
    useState("");
  const [linkExtracted, setLinkExtracted] = useState();
  const [previewerData, setPreviewerData] = useState(null);
  const [blockMediaItemsUpdate, setBlockMediaItemsUpdate] = useState(true);
  const manuallyEditedPlatforms = useSelector(getManuallyEditedPlatforms);
  const platformsWithLimits = [...platforms];
  const { config } = useContext(AppContext);
  const notifyPreviewsOfLiveMediaChanges = useSelector(getNotifyPreviewsOfLiveMediaChanges);
  const [platform] = useState(PlatformIdentifier.LINKED_IN)
  const platformVideoUploadLimit = useSelector(getPlatformVideoUploadLimit);
  let typingTimeout;

  const closeImgEditor = () => {
    setOpenEditArea(false);
  };

  useEffect(() => {
    const shouldOpenTextEdit = localStorage.getItem('open_hashtag_pop');
    if (shouldOpenTextEdit === 'true') {
      handleEditClick();
    }
  }, [])

  useEffect(() => {
    const result = url_replacer(limitedPostTextBody ?? "");
    setFormattedPostBodyForLinks(result);
  }, [limitedPostTextBody]);

  useEffect(() => {
    const result = extractLinksFromTweet(limitedPostTextBody ?? "");
    setLinkExtracted(result[0]);
  }, [limitedPostTextBody]);

  const getPreviewLinkDetails = async (url) => {
    try {
      const result = await getPreviewFromUrl(url ?? "");
      setPreviewerData(result?.data);
    } catch (error) { }
  };

  useEffect(() => {
    if (!linkExtracted) {
      return;
    }
    getPreviewLinkDetails(linkExtracted);
  }, [linkExtracted]);

  const handleExpansion = () => {
    !expandMedias ? setExpandMedias(true) : setExpandMedias(false);
  };

  useEffect(async () => {
    const imagesMedia = media_items_arrangement?.linked_in?.media?.filter(
      (x) => x.is_video === false
    );
    await scanImages(imagesMedia);
    setUseableMedia(media_items_arrangement?.linked_in?.media);
  }, [notifyPreviewsOfLiveMediaChanges]);

  const syncNewThread = async () => {
    let syncingThread = [];
    try {
      const mappedThread = useableMedia?.map((thread, index) => {
        const newThreadEntry = {
          name: thread?.name,
          url: thread?.url,
          is_video: thread?.is_video,
          optimized: thread?.optimized ?? false,
          external: thread?.external ?? false,
          uploaded: thread?.uploaded ?? false,
          file: thread?.file,
        };
        return newThreadEntry;
      });
      const oldData = { ...media_items_arrangement };
      const diapatchableData = {
        ...oldData,
        linked_in: { ...oldData.linked_in, media: mappedThread },
      };
      dispatch(setMediaArrangement(diapatchableData));
    } catch (error) { }
    return syncingThread;
  };

  const handleUpdateTextFromEditBox = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      if (!manuallyEditedPlatforms[PlatformIdentifier.LINKED_IN]) {
        const newData = { ...manuallyEditedPlatforms, linked_in: true };
        dispatch(setManuallyEditedPlatforms(newData || {}));
      }
      const oldData = { ...media_items_arrangement };
      const getTextLimit = platformsWithLimits.find(
        (x) => x.identifier === PlatformIdentifier.LINKED_IN
      );
      const diapatchableData = {
        ...oldData,
        linked_in: {
          ...oldData.linked_in,
          text: text.substring(0, getTextLimit.textLimit),
        },
      };
      dispatch(setMediaArrangement(diapatchableData));
    }, 1000);
  };

  useEffect(() => {
    syncNewThread();
  }, [useableMedia]);

  const updateMediaItems = async (
    files,
    canCreateObjectURL = true,
    moreProps = {}
  ) => {
    let expectedFilesLength = files.length + useableMedia.length;
    if (
      files.length > mediaObjectsTotalLength ||
      useableMedia.length > mediaObjectsTotalLength || expectedFilesLength > mediaObjectsTotalLength
    ) {
      toast.error(
        `Sorry, you can only upload a maximum of ${mediaObjectsTotalLength} files at a time`
      );
      return;
    }

    let mo = [];
    for (let file of files) {
      if (canCreateObjectURL) {
        let fileUrl = URL.createObjectURL(file);
        let fileName = file.name;
        let existingFile = mo.find((x) => x.url === fileUrl);
        if (!existingFile) {
          let fileExtension = `.${fileName.split(".").pop()}`;
          let newThreadObject = {
            name: fileName,
            url: fileUrl,
            extension: fileExtension,
            file: file,
            ...moreProps,
          };
          if (file?.size / 1000000 > 5 && moreProps?.is_video === false) {
            toast.error(
              `Image(s) above 5MB have been removed from linkedin. Kindly upload images below 5MB.`,
              { theme: "colored" }
            );
            return;
          }
          console.log(platform, 'platform')
          console.log(platformVideoUploadLimit, 'platformVideoUploadLimit')
          if (platformVideoUploadLimit[platform] && file.size > platformVideoUploadLimit[platform]) {
            let errorMsg = `${file?.name} have been removed from ${platform} because it exceeds the allowed sized limit of ${FigureUtils.convertBytes(platformVideoUploadLimit[platform])?.value}.`
            if (platformVideoUploadLimit['canUpgrade']) {
              errorMsg = errorMsg + ` Upgrade your plan to get more upload limits.`;
            }
            toast.error(errorMsg, { theme: "colored" });
          } else {
            mo.push(newThreadObject);
          }
        }
      } else {
        let fileWithName = mo.find((item) => item.name === file.name);
        if (!fileWithName) {
          mo.push(file);
        }
      }
    }
    const imagesMedia = mo?.filter((x) => x.is_video === false);
    await scanImages(imagesMedia);
    mo?.map((item) => {
      setBlockMediaItemsUpdate(false);
      setUseableMedia((oldArray) => [...oldArray, item]);
    });
    if (!manuallyEditedPlatforms[PlatformIdentifier.LINKED_IN]) {
      const newData = { ...manuallyEditedPlatforms, linked_in: true };
      dispatch(setManuallyEditedPlatforms(newData || {}));
    }

    setOpenMediaSelection(false);
    return;
  };

  const mediaRemoveHandler = (removableMediaObject) => {
    try {
      let cachedArrayMedia = useableMedia;
      const filterMedia = cachedArrayMedia?.filter(
        (item) => item.url !== removableMediaObject.url
      );
      setBlockMediaItemsUpdate(false);
      setUseableMedia((prevState) => (prevState = filterMedia));
    } catch (error) { }
  };

  const handleDeleteMedia = (data) => {
    if (!manuallyEditedPlatforms[PlatformIdentifier.LINKED_IN]) {
      const newData = { ...manuallyEditedPlatforms, linked_in: true };
      dispatch(setManuallyEditedPlatforms(newData || {}));
    }
    mediaRemoveHandler(data);
  };

  const mediaOpener = (
    <div
      ref={myRef}
      style={{
        position: "relative",
        width: "96%",
        marginLeft: "4%",
        zIndex: 5,
        marginBottom: 20,
        marginTop: 20,
      }}
    >
      {
        <MultimediaSelectionDropdown
          fromPreview={true}
          specificPlatformTied={PlatformIdentifier.LINKED_IN}
          initiatorId={
            currentMediaType === "image"
              ? `${PlatformIdentifier.LINKED_IN}-photo-chooser`
              : `${PlatformIdentifier.LINKED_IN}-video-chooser`
          }
          externalFilePickerTitle={
            currentMediaType === "image"
              ? "Enter Photo Location"
              : "Enter Video Location"
          }
          prompt={
            currentMediaType === "image" ? "Add Photo From" : "Add Video From"
          }
          fileType={currentMediaType === "image" ? "image" : "video"}
          externalPickerPromptLabel={
            currentMediaType === "image" ? "Photo" : "Video"
          }
          multiple={currentMediaType === "image" ? true : false}
          fileSelectionHandler={(response) => {
            if (response) {
              let { files, from_local, ...others } = response;
              updateMediaItems(files, from_local, others);
            }
          }}
        />
      }
    </div>
  );

  const handleOpeningEditArea = async (source, width, height, myFile) => {
    setFileNameBackup(myFile?.name);
    const { new_width, new_height } = await recursiveAspectFinder(
      1080,
      1080,
      width,
      height,
      1,
      0.06
    );
    try {
      const image = new Image();
      image.src = source;
      const canvas = document.createElement("canvas");
      canvas.width = new_width;
      canvas.height = new_height;
      let scale = Math.max(
        canvas.width / image.naturalWidth,
        canvas.height / image.naturalHeight
      );
      let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
      let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
      canvas
        .getContext("2d")
        .drawImage(
          image,
          x,
          y,
          image.naturalWidth * scale,
          image.naturalHeight * scale
        );
      const dataURL = canvas.toDataURL("image/png");
      setImageToEditUrl(dataURL);
      setComparebleUrl(source);
      setOpenEditArea(!openEditArea);
    } catch (error) {
      const image = new Image();
      image.src = source;
      const canvas = document.createElement("canvas");
      setTimeout(() => {
        canvas.width = 1080;
        canvas.height = 1080;
        let scale = Math.max(
          canvas.width / image.naturalWidth,
          canvas.height / image.naturalHeight
        );
        let x = canvas.width / 2 - (image.naturalWidth / 2) * scale;
        let y = canvas.height / 2 - (image.naturalHeight / 2) * scale;
        canvas
          .getContext("2d")
          .drawImage(
            image,
            x,
            y,
            image.naturalWidth * scale,
            image.naturalHeight * scale
          );
        const dataURL = canvas.toDataURL();
        setImageToEditUrl(dataURL);
        setComparebleUrl(source);
        setOpenEditArea(!openEditArea);
      }, 1000);
    }
  };

  const displayMedias = useableMedia?.map((item, index) => {
    return <LinkedinMediaDisplay
      item={item}
      useableMedia={useableMedia}
      index={index}
      handleOpeningEditArea={handleOpeningEditArea}
      handleDeleteMedia={handleDeleteMedia}
      openVideoEditArea={openVideoEditArea}
      setOpenVideoEditArea={setOpenVideoEditArea}
      canOPenPopOver={canOPenPopOver}
      expandMedias={expandMedias}
      handleExpansion={handleExpansion}
      setUseableMedia={setUseableMedia}
      viewOnly={viewOnly}
    />
  });

  const limitChars = (text, limit) => {
    setLimitedPostTextBody(text?.substring(0, limit));
  };

  const waitTime = 1000;

  function runWhenUserStopTying() {
    window.clearTimeout(timer);
    setTimer(
      window.setTimeout(() => {
        if (!manuallyEditedPlatforms[PlatformIdentifier.LINKED_IN]) {
          limitChars(media_items_arrangement?.linked_in?.text, 3000);
        }
      }, waitTime)
    );
  }

  useEffect(() => {
    runWhenUserStopTying();
  }, [media_items_arrangement?.linked_in?.text]);

  useEffect(() => {
    limitChars(media_items_arrangement?.linked_in?.text, 3000);
  }, []);

  const handleEditClick = () => {
    setToggleEditTextInput(true);
  };

  const addMediaFromUrl = (obj = mediaAddedViaSpecificPlaformUrlMethod[0]) => {
    const { extension, thumbnail, ...rest } =
      obj;
    setBlockMediaItemsUpdate(false);
    useableMedia.length
      ? setUseableMedia((oldArray) => [...oldArray, rest])
      : setUseableMedia([rest]);

    if (!manuallyEditedPlatforms[PlatformIdentifier.LINKED_IN]) {
      const newData = { ...manuallyEditedPlatforms, linked_in: true };
      dispatch(setManuallyEditedPlatforms(newData || {}));
    }
    dispatch(setSpecificMediaPlatformTied("global"));
    setOpenMediaSelection(false);
    if (googleDriveManager?.docs) {
      dispatch(setGoogleDriveManager({ ...googleDriveManager, docs: null }))
    }
  };

  const immediateActionWhenClieked = (remove) => {
    if (remove) {
      localStorage.removeItem("gdrivePlatform");
      return;
    }
    localStorage.setItem("gdrivePlatform", PlatformIdentifier.LINKED_IN);
  }

  useEffect(() => {
    if (!googleDriveManager?.docs || localStorage.getItem('gdrivePlatform') !== PlatformIdentifier.LINKED_IN) return;
    addMediaFromUrl(googleDriveManager?.docs);
    immediateActionWhenClieked(true)
  }, [googleDriveManager])




  const saveDesignStateImage = async (newUrl) => {
    closeImgEditor();
    const blob = await (await fetch(newUrl)).blob();
    const new_file = new File([blob], fileNameBackup, {
      type: "image/jpeg",
      lastModified: new Date(),
    });
    try {
      setUseableMedia((prevState) => {
        const newState = prevState.map((obj) => {
          if (obj.url === comparebleUrl) {
            setBlockMediaItemsUpdate(false);
            return { ...obj, url: newUrl, file: new_file, optimized: true };
          }
          return obj;
        });
        if (!manuallyEditedPlatforms[PlatformIdentifier.LINKED_IN]) {
          const newData = { ...manuallyEditedPlatforms, linked_in: true };
          dispatch(setManuallyEditedPlatforms(newData || {}));
        }
        dispatch(setSelectedPlatformForPreview("refresh"));
        setTimeout(() => {
          dispatch(setSelectedPlatformForPreview(PlatformIdentifier.LINKED_IN));
        }, 1000);
        return newState;
      });
    } catch (error) { }
  };
  useEffect(() => {
    !openEditArea ? setCanOpenPopOver(true) : setCanOpenPopOver(false);
  }, [openEditArea]);
  useEffect(() => {
    specificMediaPlatformTied === "linked_in" &&
      mediaAddedViaSpecificPlaformUrlMethod.length &&
      addMediaFromUrl();
  }, [mediaAddedViaSpecificPlaformUrlMethod]);

  const handleConnect = async () => {
    try {
      googleDriveRef.current.click();
    } catch (error) {

    }
  }
  useEffect(() => {
    if (googleDriveManager['fromPreviews'] && googleDriveManager['promptConnect']) {
      setOpenConfirmation(true)
    }
  }, [googleDriveManager])

  const mediaPopulateFunction = () => {
    if (previewerData && !useableMedia.length) {
      return <GridImageOne>
        <LinkPreviewer
          domain={previewerData?.siteName}
          img={previewerData?.images?.length ? previewerData?.images[0] : null}
          requestUrl={previewerData?.url}
          title={previewerData?.title}
          favicon={previewerData?.favicons?.length ? previewerData?.favicons[0] : null}
        />
      </GridImageOne>
    }

    if (useableMedia.length === 1) {
      return <GridImageOne>{displayMedias}</GridImageOne>
    }

    if (useableMedia.length > 1) {
      return <GridImage
        gridRow={useableMedia.length < 3 ? "150px" : "100px"}
        gridColumn={
          useableMedia.length === 1
            ? "1fr"
            : useableMedia.length < 5
              ? "1fr 1fr"
              : "1fr 1fr"
        }
        gridOverFiveShow={
          useableMedia.length < 5 ? "repeat(2, 1fr)" : null
        }
        style={{
          gridTemplateAreas:
            !expandMedias &&
            useableMedia.length > 4 &&
            `'header header header menu menu menu'
  'main main right right footer footer'`,
          gridTemplateColumns:
            useableMedia.length < 5
              ? "repeat(2, 1fr)"
              : expandMedias
                ? "repeat(3, 1fr)"
                : null,
        }}
      >
        {displayMedias}
      </GridImage>
    }

    return null
  }

  // if (!useableMedia.length && !limitedPostTextBody && !manuallyEditedPlatforms[PlatformIdentifier.LINKED_IN]) {
  //   return <></>
  // }

  return (
    <>
      {
        googleDriveManager?.openPicker ? <GDriveDashboard platform={PlatformIdentifier.LINKED_IN} /> : null
      }
      {openEditArea && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100%",
            zIndex: 10000,
          }}
        >
          <FilerobotImageEditor
            source={imageToEditUrl}
            theme={{
              palette: {
                "bg-secondary": !config.isDark
                  ? lightTheme?.rootBackground
                  : "#0b1727",
                "txt-primary": !config.isDark ? "#2e2e2e" : "#919090",
              },
              p: {
                fontFamily: "Roboto, Arial",
              },
            }}
            onBeforeSave={() => false}
            onSave={(editedImageObject, designState) => {
              let canvas = editedImageObject.imageCanvas;
              saveDesignStateImage(canvas.toDataURL());
            }}
            onClose={closeImgEditor}
            annotationsCommon={{
              fill: "#ff0000",
            }}
            Text={{ text: "Postly..." }}
            tabsIds={[
              TABS.ADJUST,
              TABS.ANNOTATE,
              TABS.WATERMARK,
              TABS.FILTERS,
              TABS.FINETUNE,
              TABS.RESIZE,
            ]}
            defaultTabId={TABS.ANNOTATE} // or 'Annotate'
            defaultToolId={TOOLS.TEXT} // or 'Text'
          />
        </div>
      )}

      {
        !viewOnly &&
        <Flex
          gap={3}
          className={`mt-2`}
          wrap={'wrap'}
        >
          <MultimediaSelectionDropdown
            dropDownAnchor={
              <CircularButton onClick={() => immediateActionWhenClieked(false)} title="Add Image">
                <FontAwesomeIcon icon={faImage} />
              </CircularButton>
            }
            specificPlatformTied={PlatformIdentifier.LINKED_IN}
            fromPreview={true}
            initiatorId={`${PlatformIdentifier.LINKED_IN}-photo-chooser`}
            externalFilePickerTitle={"Enter Photo Location"}
            prompt={"Add Photo From"}
            fileType={"image"}
            externalPickerPromptLabel={"Photo"}
            multiple={true}
            fileSelectionHandler={(response) => {
              if (response) {
                let { files, from_local, ...others } = response;
                updateMediaItems(files, from_local, others);
              }
            }}
          />
          <MultimediaSelectionDropdown
            dropDownAnchor={
              <CircularButton title="Add Video">
                <FontAwesomeIcon icon={faVideo} />
              </CircularButton>
            }
            specificPlatformTied={PlatformIdentifier.LINKED_IN}
            fromPreview={true}
            initiatorId={`${PlatformIdentifier.LINKED_IN}-video-chooser`}
            externalFilePickerTitle={"Enter Video Location"}
            prompt={"Add Video From"}
            fileType={"video"}
            externalPickerPromptLabel={"Video"}
            multiple={false}
            fileSelectionHandler={(response) => {
              if (response) {
                let { files, from_local, ...others } = response;
                updateMediaItems(files, from_local, others);
              }
            }}
          />
          {!toggleEditTextInput && (
            <CircularButton
              editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
              onClick={() => handleEditClick()}
              title="Edit Text"
            >
              <FontAwesomeIcon icon={faPencil} />
            </CircularButton>
          )}
          <CircularButton
            editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
            onClick={() => setRepositionImages(!repostionImages)}
            title="re-position images"
          >
            <RiDragDropLine size={20} />
          </CircularButton>
        </Flex >
      }

      <ParentSection>
        <GDriveConnectionRequestListener
          googleDriveRef={googleDriveRef}
        />
        <Wrapper>
          {!viewOnly && openMediaSelction && !selectingPhotoFromHome && mediaOpener}
          {toggleEditTextInput && (
            <EditTextBox
              ref={textEditRef}
              isPlatformEdited={PlatformIdentifier.LINKED_IN}
              incomingRef={textEditRef}
              value={limitedPostTextBody}
              setValue={setLimitedPostTextBody}
              setToggleEditTextInput={setToggleEditTextInput}
              platform={"linked_in"}
              setter={{ ...manuallyEditedPlatforms, linked_in: true }}
              updateText={handleUpdateTextFromEditBox}
            />
          )}

          <PreviewContentArea
            mainBgColor={!config.isDark ? "white" : "#0b1727"}
            theme={!config.isDark ? "1px 1px 3px #949494" : "none"}
            style={{
              borderColor: `${!config.isDark ? "#ededed" : "transparent"}`,
            }}
            ref={focusImageRef}
          >
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: 5,
                fontSize: 9,
              }}
            >
              Character Count: {formattedPostBodyForLinks?.length} / 3000
            </div>
            {manuallyEditedPlatforms[PlatformIdentifier.LINKED_IN] && (
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  fontStyle: "italic",
                  position: "absolute",
                  top: 0,
                  right: 5,
                  fontSize: 11,
                }}
              >
                <p
                  style={{
                    color: !config.isDark ? "grey" : "grey",
                  }}
                >
                  Edited
                </p>
              </div>
            )}
            <FirstLayer>
              {/* <ImageHolder> */}
              {displayPlatformIcon(PlatformIdentifier.LINKED_IN)}
              {/* </ImageHolder> */}
              <Info>
                <Title
                  style={{
                    fontWeight: "600",
                    fontSize: 11,
                    color: `${!config.isDark ? "#000000E6" : "#FFFFFFE6"}`,
                  }}
                >
                  {"LinkedIn Preview"}
                </Title>
                <p
                  type={"p"}
                  style={{
                    color: "grey",
                    fontSize: 9,
                    margin: 0,
                    padding: 0
                  }}
                >
                  Social Media Manager
                </p>
                <p
                  type={"p"}
                  style={{
                    color: "grey",
                    fontSize: 9,
                    margin: 0,
                    padding: 0
                  }}
                >
                  now -{" "}
                  <FontAwesomeIcon
                    style={{
                      color: "#78909C",
                      fontSize: 11,
                      margin: 0,
                      padding: 0
                    }}
                    icon={faEarth}
                  />
                </p>
              </Info>
              <DotsBox>
                <p
                  type={"p"}
                  style={{
                    color: `${!config.isDark ? "#0a66c2" : "#70B5F9"}`,
                    fontSize: 11,
                    fontWeight: 600,
                    padding: 5
                  }}
                >
                  Follow
                </p>
              </DotsBox>
            </FirstLayer>
            {
              repostionImages ? <DragToPositionImage useableMedia={useableMedia} setter={setUseableMedia} platform={PlatformIdentifier.LINKED_IN} /> : <SecondLayer
                style={{
                  fontFamily:
                    '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;',
                  fontWeight: 400,
                }}
              >
                <pre
                  style={{
                    margin: "1%",
                    marginTop: 10,
                    marginBottom: 10,
                    color: `${!config.isDark ? "black" : "#b3b3b3"}`,
                    fontFamily:
                      'Roboto, -apple-system, "apple color emoji", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                    lineHeight: "21px",
                    fontWeight: 400,
                    maxWidth: "100%",
                    width: "100%",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {parse(formattedPostBodyForLinks)}
                </pre>
                {mediaPopulateFunction()}

                {expandMedias && (
                  <button
                    onClick={handleExpansion}
                    style={{
                      margin: 20,
                      padding: "1%",
                      height: 40,
                      width: "90%",
                      backgroundColor: "white",
                      borderRadius: "10px",
                    }}
                  >
                    Collapse Media
                  </button>
                )}
              </SecondLayer>
            }

            
          </PreviewContentArea>
        </Wrapper>
        <Modal
          show={openPixelDialoguePicker}
          onHide={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
          onExit={() => {
            dispatch(setOpenPixelFilePickerDialog(false));
          }}
        >
          <Modal.Header>
            <Modal.Title>Pexels.com</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExtrernalImageSearchDialogue
              setUseableMedia={setUseableMedia}
              determiner={PlatformIdentifier.LINKED_IN}
              modalClose={() => {
                // dispatch(setOpenExternalFilePickerDialog(false));
              }}
            />
          </Modal.Body>
        </Modal>
        <ConfirmModal
          open={openConfirmation}
          title={"Connect Your Google Drive Account"}
          message={'Your google drive account is not connected or has been disconnected. Do you wish to connect?'}
          confirmText={"PROCEED"}
          cancelText={"CANCEL"}
          showDialoguePrompt
          onCancel={() => {
            setOpenConfirmation(false);
            dispatch(setGoogleDriveManager({
              ...googleDriveManager,
              promptConnect: false,
            }))
          }}
          onConfirm={() => {
            handleConnect();
            dispatch(setGoogleDriveManager({ ...googleDriveManager, promptConnect: false, fromPreviews: false }))
          }}
        />
      </ParentSection>
    </>
  );
};

export default LinkedIn;