import APIService from "http/api_service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getDatePeriod,
  getOpenExportModal,
  getPageId,
  setAudienceData,
  setDatePeriod,
  setInsightData,
  setOpenExportModal,
  setOverviewData,
  setPlatformIdentifier
} from "redux/slices/analytics_slice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { convertToTitleFormat } from "utils/platform_utils";
import DateSelection from "../../DateSelection/DateSelection";
import InstagramOverview from "../../Overview";
import PlatformHeader from "../../PlatformHeader";
import ExportReportHeader from "../../ReportExportHeader";
import ReportExportModal from "../../ReportExportPreview";
import Audience from "../../audience/Audience";
import TopPosts from '../../top-posts/TopPosts';
import UsersByCountry from "../../users-by-country/UsersByCountry";

const InstagramAnalyticsPage = (props) => {
  const { account, platform } = props;
  const dispatch = useDispatch();

  const workSpace = useSelector(getActiveWorkSpace);
  const pageId = useSelector(getPageId);
  const datePeriod = useSelector(getDatePeriod);
  const openExportModal = useSelector(getOpenExportModal);

  const [pageInsightLoading, setPageInsightLoading] = useState(false);
  const [pageInsightData, setPageInsightData] = useState([]);
  const [regionLoading, setRegionLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [postsLoading, setPostsLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [pageInsightValue, setPageInsightValue] = useState({});
  const [pageProfileStats, setPageProfileStats] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [audienceReport, setAudienceReport] = useState([]);

  const insightErrorMessage = `You need at least 100 followers to view this report`;

  useEffect(() => {
    APIService.fetchPagesAndGroups(workSpace, account.identifier, account.user_id, (response, error) => {
        if (error) {
          toast.error(error, { theme: "colored" });
          return;
        }
        const { data } = response ?? [];
        let instagramPages = [];
        for (let page of data) {
          if (page.is_page) {
            instagramPages.push(page);
          }
        }
        setPages(instagramPages);
      }
    );
    dispatch(setPlatformIdentifier(platform?.identifier));
  }, [account]);

  useEffect(() => {
    let { since, until } = datePeriod;
    if (!(since && until)) {
      since = moment().utc().subtract(28, "days").startOf("day").format();
      until = moment().utc().endOf("day").format();
      dispatch(setDatePeriod({ since, until }));
    }
  }, [account.user_id, datePeriod]);

  const fetchPageInsights = () => {
    dispatch(setInsightData([]));
    let { since, until } = datePeriod;
    const requestBody = {
      since: since,
      until: until,
      page_id: pageId,
    };
    setPageInsightLoading(true);
    APIService.fetchPageInsights(workSpace._id, account.user_id, requestBody, platform.identifier, (response, error) => {
        if (error) {
          setPageInsightLoading(false);
          setPageInsightData([]);
          return;
        }
        setPageInsightLoading(false);
        const responseData = response["data"][0] ?? [];
        if (responseData) {
          setReportData([responseData]);
          const insightKeys = ["impressions", "reach", "visits", "email_clicks", "phone_call_clicks", "website_clicks"];
          let totalValues = {};
          let insightValue = {};
          const pageInsightValues = insightKeys.map((key) => {
            if (responseData?.hasOwnProperty(key)) {
              insightValue[key] = responseData[key];
              let values = insightValue[key]?.length ? insightValue[key] : [];
              values = values?.map(obj => obj?.value);
              const totalValue = values?.reduce((acc, curr) => acc + curr, 0);
              totalValues[key] = totalValue;
            }
            else {
              insightValue[key] = [];
            };

            return {
              title: convertToTitleFormat(key),
              value: insightValue[key]
            };
          });
          setPageInsightValue({ ...totalValues });
          setPageInsightData(pageInsightValues);
          dispatch(setInsightData(pageInsightValues));
        }
      });
  }

  useEffect(() => {
    if (!pageId) {
      return;
    }
    fetchPageInsights();
  }, [pageId, datePeriod]);

  const fetchUsersByRegion = () => {
    dispatch(setAudienceData([]));
    setRegionLoading(true);
    const requestBody = { page_id: pageId };
    APIService.fetchUsersByCountry(workSpace._id, account.user_id, requestBody, platform.identifier, (response, error) => {
        if (error) {
          setRegionLoading(false);
          return;
        }
        setRegionLoading(false);
        const responseData = response?.data ?? [];
        if (!responseData || responseData.length === 0) {
          return;
        }
        const [responseItem] = responseData;
        const insightKeys = ["audience_country", "audience_city"];
        const pageDemographicData = insightKeys.map((key) => {
          const insightData = responseItem[key];
          setAudienceReport((prev) => {
            if (!Array.isArray(prev)) {
              return [{ title: key, value: insightData }];
            }
            const existingIndex = prev.findIndex((item) => item.title === key);
            if (existingIndex !== -1) {
              return prev;
            }
            return [...prev, { title: key, value: insightData }];
          });

          const newData = Object.entries(insightData || {}).reduce(
            (acc, [key, value]) => {
              acc.key.push(key);
              acc.data.push(value);
              return acc;
            },
            { key: [], data: [] }
          );
          return {
            title: convertToTitleFormat(key),
            value: Object.values(newData),
          };
        });
        setRegionData(pageDemographicData);
        dispatch(setAudienceData(pageDemographicData));
      }
    );
  };

  useEffect(() => {
    if (!pageId) {
      return;
    }
    fetchUsersByRegion();
  }, [pageId]);

  const fetchTopPosts = () => {
    setPostsLoading(true);
    let requestBody = {
      page_id: pageId,
    };
    APIService.fetchTopPosts(workSpace._id, account.user_id, requestBody, platform.identifier, (response, error) => {
        if (error) {
          setPostsLoading(false);
          toast.error(error, { theme: "colored" });
          return;
        }
        setPostsLoading(false);
        const { data } = response;
        setPosts(data ?? []);
      }
    );
  };

  useEffect(() => {
    if (!pageId) {
      return;
    }
    fetchTopPosts();
  }, [pageId]);

  const fetchPageProfileStats = () => {
    dispatch(setOverviewData([]));
    APIService.fetchPageProfileOverview(workSpace._id, account.user_id, 
      { page_id: pageId }, platform.identifier, (response, error) => {
        if (error) {
          return;
        }
        const responseData = response?.data ?? {};
        let profileFields = ['followers', 'following', 'posts'];
        let profileData = profileFields.map(key => ({ title: convertToTitleFormat(key), value: responseData[key] }));
        setPageProfileStats(profileData);
        dispatch(setOverviewData(profileData));
      });
  }

  useEffect(() => {
    if (!pageId) {
      return;
    }
    fetchPageProfileStats();
  }, [pageId]);

  return (
    <div>
      <PlatformHeader pages={pages} platform={platform} account={account} />
      <Row className="g-3 mb-3">
        <Col xxl={8}>
          <DateSelection />
        </Col>

        <Col lg={12} xxl={12}>
          <ExportReportHeader
            identifier={platform.identifier}
            data={reportData}
            account={account}
          />
        </Col>

        {pageProfileStats?.length > 0 &&
          <Row
            className="mt-3"
            style={{ backgroundColor: "none" }}>
            <h5 className="mb-3 mt-2">Overview</h5>
            {pageProfileStats?.map(({ title, value }) => (
              <Col lg={6} xxl={4} md={6}>
                <InstagramOverview title={title} value={value} />
              </Col>
            ))}
          </Row>
        }

        <Col xxl={12} md={12} className="mt-4">
          <Audience
            values={pageInsightValue}
            chartData={pageInsightData}
            loading={pageInsightLoading}
            identifier={platform.identifier}
            className="mb-3"
          />
        </Col>

        {!regionLoading &&
          regionData.map(({ title, value }) => {
            return (
              <Col md={12} xxl={4} className="mb-4">
                <UsersByCountry
                  title={title}
                  chartData={value}
                  loading={regionLoading}
                  emptyDataMessage={insightErrorMessage}
                  reportData={audienceReport}
                />
              </Col>
            )
          })}

        <Col lg={12} xxl={8}>
          <TopPosts
            tableData={posts}
            loading={postsLoading}
            identifier={platform.identifier}
          />
        </Col>
      </Row>

      {openExportModal &&
        <ReportExportModal
          show={openExportModal}
          onHide={() => dispatch(setOpenExportModal(false))}
        />}
    </div>
  );
};

export default InstagramAnalyticsPage;
